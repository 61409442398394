import useVariables from '@/store/variables/hooks';
import { useAppDispatch, useAppSelector } from '@store';
import { useCallback } from 'react';
import { selectfeatureList } from './slice';
import { getFeatureList } from './thunks';

export const useFeatureList = () => {
  const dispatch = useAppDispatch();
  const { featureList: feature } = useAppSelector(selectfeatureList);
  const { contents: featureList } = useVariables(feature);

  const fetchFeatureList = useCallback(
    (companyCode: string, propertyId?: number | string, locale = 'en') => {
      if (companyCode && (propertyId || typeof propertyId === 'number')) {
        dispatch(
          getFeatureList({ propertyId, locale, companyCode: companyCode }),
        );
      }
    },
    [dispatch],
  );

  return {
    featureList,
    fetchFeatureList,
  };
};
