import AppConfig from '@config';
import useLocalization from '@localizations';
import { useNavigation } from '@react-navigation/native';
import { dispatchChatNotify, Member, useAppStore } from '@store';
import format from 'date-fns/format';
import fromUnixTime from 'date-fns/fromUnixTime';
import subHours from 'date-fns/subHours';
import { useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useTheme } from 'react-native-paper';
import { StreamChat } from 'stream-chat';
import Popup from './Popup';

export const getEarlyCheckInHoursFromTimestamp = (
  timestamp?: number,
  count = 6,
) => {
  if (!timestamp) {
    return [];
  }

  const date = fromUnixTime(timestamp);
  let hours: string[] = [];
  for (let i = 1; i <= count; ++i) {
    hours = [...hours, format(subHours(date, i), 'hh:mm a')];
  }

  return hours;
};

interface PropTypes {
  showToggle: boolean;
}

export function EarlyCheckInTimes({ showToggle }: PropTypes) {
  const navigation = useNavigation();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
  const [autoReplyPopup, setAutoReplyPopup] = useState(false);
  const { colors } = useTheme();
  const { t } = useLocalization();
  const { company, reservation, messagingToken, host } = useAppStore();

  if (!showToggle) {
    return null;
  }

  const guestName =
    reservation?.guestName ||
    `A Guest in unit '${reservation?.property?.name}' `;
  const message = `${guestName} has requested an early Check-in of ${selectedTimeSlot}`;
  const popupMessage =
    'Your request for early check-in has been received. A member of our Guest Services team will reply with confirmation of availability or alternate suggestion.';

  const handleRequestClicked = () => {
    if (!company?.mpGuestMessengerEnabled && company?.earlyCheckinEnabled) {
      let channel;
      const chatClient = StreamChat.getInstance(
        AppConfig.Settings.GET_STREAM_API_KEY as string,
      );
      const userToken = messagingToken?.token;
      const member = (host?.members as Member[]).find(Boolean);
      const people = member?.id;

      if (messagingToken?.id) {
        chatClient.connectUser(
          {
            id: messagingToken?.id || '',
            name: reservation?.guestName || 'Guest',
            image: '',
          },
          userToken,
        );
        channel = chatClient.channel(
          `${messagingToken?.channel_id.split(':')[0]}`,
          `${messagingToken?.channel_id.split(':')[1]}`,
          {
            // add as many custom fields as you'd like
            image: `${host?.welcomeAvatar}`,
            name: `${member?.name}`,
            members: [`${people}`],
          },
        );

        channel.sendMessage({
          text: message,
        });
      }

      dispatchChatNotify(message, company.id, company.reservation as string);
      setAutoReplyPopup(!autoReplyPopup);
    } else {
      navigation.navigate('Messages', {
        text: message,
      });
    }
  };

  return (
    <View style={styles.container}>
      <Text>{t('available_early_check_in')}</Text>
      <View style={styles.badgeWrapper}>
        {getEarlyCheckInHoursFromTimestamp(reservation?.checkin).map(
          (time, idx) => (
            <TouchableOpacity
              key={idx}
              style={
                selectedTimeSlot === time
                  ? [styles.badgeSelected, { backgroundColor: colors.primary }]
                  : styles.badge
              }
              onPress={() => setSelectedTimeSlot(`${time}`)}>
              <Text
                style={selectedTimeSlot === time && styles.badgeTextSelected}>
                {time}
              </Text>
            </TouchableOpacity>
          ),
        )}
      </View>
      {selectedTimeSlot !== '' && (
        <TouchableOpacity onPress={handleRequestClicked}>
          <Text
            style={[styles.requestButton, { backgroundColor: colors.primary }]}>
            {' '}
            {t('request')}
          </Text>
        </TouchableOpacity>
      )}
      <Popup
        visible={autoReplyPopup}
        onDismiss={() => setAutoReplyPopup(!autoReplyPopup)}
        text={popupMessage}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    paddingVertical: 20,
  },
  badgeWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  badge: {
    padding: 10,
    marginVertical: 10,
    marginRight: 10,
    borderRadius: 30,
    backgroundColor: '#e0e0e0',
    alignSelf: 'flex-start',
  },
  badgeSelected: {
    padding: 10,
    marginVertical: 10,
    marginRight: 10,
    borderRadius: 30,
    alignSelf: 'flex-start',
  },
  badgeTextSelected: {
    color: 'white',
  },
  requestButton: {
    padding: 10,
    color: 'white',
    borderRadius: 5,
    alignSelf: 'center',
    textTransform: 'uppercase',
  },
});
