export enum VerificationIDType {
  PASSPORT = 'passport',
  DRIVERS = 'drivers license',
  ID_CARD = 'id card',
}

// NOTE: it is not one to one mapping with the server response.
export type Verification = {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  message: string | null;
  verification: IVerification | null;
};

export type IVerification = {
  id?: string;
  phone?: string;
  title?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  dateOfBirth?: string;
  address?: {
    addressLine1?: string;
    addressLine2?: string;
    town?: string;
    province?: string;
    county?: string;
    countryIso?: string;
    postcode?: string;
    country?: string;
  };
  countryISO: string;
  idType: VerificationIDType | string;
  governmentIdBase64?: string;
  selfieBase64?: string;
  termsAccepted?: boolean;
};
