import AppConfig from '@config';
import { useNavigation } from '@react-navigation/native';
import { Image, StyleSheet, Text, View } from 'react-native';
import { IconButton } from 'react-native-paper';

interface PropTypes {
  source: string;
  name: string;
}

export function CustomChannelHeader({ source, name }: PropTypes) {
  const navigation = useNavigation();

  return (
    <View style={styles.header}>
      <View style={styles.leftSide}>
        <IconButton
          icon="arrow-left"
          size={AppConfig.Styles.getAdjustedSize(30)}
          onPress={() => navigation.goBack()}
        />
        <Image source={{ uri: source }} style={styles.avatar} />
        <Text style={styles.text}>{name}</Text>
      </View>
      <View>
        <IconButton
          icon="account-circle"
          size={AppConfig.Styles.getAdjustedSize(30)}
          onPress={() => navigation.navigate('Profile')}
        />
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  header: {
    width: '100%',
    alignSelf: 'center',
    backgroundColor: '#fff',
    borderBottomColor: '#d6d6d6',
    borderBottomWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  leftSide: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
  },
  avatar: {
    width: 50,
    height: 50,
    marginRight: 10,
    borderRadius: 50,
  },
  text: {
    fontWeight: 'bold',
  },
});
