import { StyleSheet, Text, View } from 'react-native';
import { TextInput } from 'react-native-paper';

type SignatureTextFieldProps = {
  textSignature: string;
  onChangeText: (text: string) => void;
};

export const SignatureTextField = ({
  textSignature,
  onChangeText,
}: SignatureTextFieldProps) => {
  return (
    <View style={{ width: '90%' }}>
      {/** hidden behind text input to be converted to image */}
      <View
        nativeID="text-signature"
        style={{
          position: 'absolute',
          width: 330,
          height: 58,
          left: 0,
          right: 0,
          margin: 'auto',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Text style={styles.hiddenText}>{textSignature}</Text>
      </View>

      <TextInput
        nativeID="signature-text-field"
        value={textSignature}
        onChangeText={onChangeText}
        maxLength={18}
        contentStyle={[styles.hiddenText, styles.textInput]}
        style={{
          backgroundColor: '#FAF8FF',
          borderRadius: 28,
          borderStyle: 'solid',
          borderWidth: 1,
          borderTopRightRadius: 28,
          borderTopLeftRadius: 28,
        }}
        underlineStyle={{
          height: '0',
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  textInput: {
    fontSize: 57,
    textAlign: 'center',
    height: 180,
    width: '100%',
  },
  hiddenText: {
    fontFamily: 'Dancing Script',
    fontSize: 44,
  },
});
