import { Reservation } from '@/store/models';
import { AccessCodeActionSheet } from './AccessCodeActionSheet';
import { SelectDoorActionSheet } from './SelectDoorActionSheet';
import { StayMyWaySheet } from './StayMyWaySheet';

export interface IEntranceInstructionActionSheetProps {
  reservation?: Reservation;
  modalType?: 'EntranceInstructions' | 'AccessCode' | 'StayMyWay';
  onModalVisibleChange: (
    modal: 'EntranceInstructions' | 'AccessCode' | 'StayMyWay' | undefined,
  ) => void;
}

export const EntranceInstructionsActionSheet = ({
  reservation,
  modalType,
  onModalVisibleChange,
}: IEntranceInstructionActionSheetProps) => {
  return (
    <>
      <SelectDoorActionSheet
        isOpen={modalType === 'EntranceInstructions'}
        onClose={() => onModalVisibleChange(undefined)}
        onUnitPress={() => {
          if (reservation?.smw) {
            onModalVisibleChange('StayMyWay');
          } else {
            onModalVisibleChange('AccessCode');
          }
        }}
      />
      <StayMyWaySheet
        onDismiss={() => onModalVisibleChange(undefined)}
        isOpen={modalType === 'StayMyWay'}
      />
      <AccessCodeActionSheet
        onDismiss={() => onModalVisibleChange(undefined)}
        isOpen={modalType === 'AccessCode'}
      />
    </>
  );
};
