import { SupportedLocale } from '@/assets/localizations';
import { logger } from '@/libs/logger';
import {
  dispatchReservations,
  getAccessToken,
  processError,
  processResponse,
  Theme,
  useAppContext,
  useAppStore,
} from '@/store';
import { ActionButton } from '@components';
import AppConfig from '@config';
import getUnixTime from 'date-fns/getUnixTime';
import { useCallback, useState } from 'react';

export type QRCodeData = {
  title: string;
  message: string;
  buttons?: ActionButton[];
};

export type QRCodeMessagesData = {
  locale?: SupportedLocale;
  theme?: Partial<Theme>;
  checkinQRCodeEnabled: boolean;
  qrCode: {
    en: QRCodeData;
    es: QRCodeData;
    de: QRCodeData;
    it: QRCodeData;
    fr: QRCodeData;
    cs: QRCodeData;
  };
};

type QRCodeMessagesJSON = {
  locale?: string;
  theme_enabled?: boolean;
  checkin_qr_enabled?: boolean;
  welcome_images_enabled?: boolean;
  primary_color?: string;
  welcome_logo?: string;
  qr_code: {
    en: QRCodeData;
    es: QRCodeData;
    de: QRCodeData;
    it: QRCodeData;
    fr: QRCodeData;
    cs: QRCodeData;
  };
};

const parseQRCodeMessages = (json: QRCodeMessagesJSON): QRCodeMessagesData => {
  return {
    theme:
      json.theme_enabled !== undefined
        ? {
            enabled: json.theme_enabled ?? false,
            welcomeImagesEnabled: json.welcome_images_enabled ?? false,
            primaryColor: json.primary_color ?? AppConfig.Colors.PRIMARY,
            welcomeLogoUrl: json.welcome_logo,
          }
        : undefined,
    locale: json.locale as SupportedLocale,
    checkinQRCodeEnabled: json.checkin_qr_enabled ?? false,
    qrCode: json.qr_code,
  };
};

type QRCodeMessagesState = {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  message?: string;
  data?: QRCodeMessagesData;
};

type APICallPromiseType = [QRCodeMessagesData | undefined, string | undefined];

const initialState: QRCodeMessagesState = {
  status: 'idle',
};

export const useQRCodeMessages = () => {
  const { companyId, setLocale } = useAppContext();
  const { reservation } = useAppStore();
  const [qrCodeMessageState, setQRCodeMessageState] = useState(initialState);
  const isLoading = qrCodeMessageState.status === 'loading';
  const isError = qrCodeMessageState.status === 'failed';

  // internal helper methods
  const doAPICall = useCallback(
    async (apiCall: () => Promise<APICallPromiseType>) => {
      setQRCodeMessageState(prev => ({ ...prev, status: 'loading' }));

      try {
        const [data, message] = await apiCall();
        setQRCodeMessageState({ data, message, status: 'succeeded' });
      } catch (error) {
        const message = processError(error);

        setQRCodeMessageState(prev => ({ ...prev, message, status: 'failed' }));
        logger.error(message);
        throw message;
      }
    },
    [],
  );

  // public methods
  const postQRCodeCheckin = async () => {
    await doAPICall(async () => {
      const formData = new FormData();
      formData.append('request_message', 'Guest is requesting early Check-In');
      formData.append('type', 'checkin_requested');

      const response = await fetch(
        `${AppConfig.Settings.BASE_API_URL}/portal/guest/chat-notify`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${(await getAccessToken())?.token}`,
          },
          body: formData,
        },
      );

      await processResponse(response);

      // this will be replace hopefully with actual from server during reservation refresh polling
      if (reservation) {
        const updated = {
          ...reservation,
          checkinRequestedAt: getUnixTime(new Date()),
        };
        dispatchReservations([updated]);
      }

      return [qrCodeMessageState.data, undefined];
    });
  };

  const patchCheckinConfirmation = async (reservationCode: string) => {
    await doAPICall(async () => {
      const response = await fetch(
        `${AppConfig.Settings.BASE_API_URL}/portal/guest/reservation`,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${(await getAccessToken())?.token}`,
          },
          body: new URLSearchParams({ reservationCode }),
        },
      );

      const json = await processResponse(response);
      if (reservation) {
        const updated = {
          ...reservation,
          roomAssignmentConfirmed:
            json?.data?.reservation?.gp_room_assignment_confirmed,
        };
        dispatchReservations([updated]);
      }

      return [qrCodeMessageState.data, undefined];
    });
  };

  const getQRCodeMessages = useCallback(
    async (type: 'welcome' | 'checkin') => {
      await doAPICall(async () => {
        let response: Response;
        if (reservation && type === 'checkin') {
          response = await fetch(
            `${AppConfig.Settings.BASE_API_URL}/portal/guest/qr-code/checkin-messages?companyCode=${companyId}&reservationCode=${reservation.id}`,
            {
              headers: {
                Authorization: `Bearer ${(await getAccessToken()).token}`,
              },
            },
          );
        } else {
          response = await fetch(
            `${AppConfig.Settings.BASE_API_URL}/portal/companies/${companyId}/qr-code/welcome-messages`,
          );
        }

        const json = await processResponse(response);
        const result = parseQRCodeMessages(json.data);
        if (result && companyId) {
          setLocale((result.locale as SupportedLocale) ?? 'en');
        }

        return [result, undefined];
      });
    },
    [companyId, doAPICall, setLocale, reservation],
  );

  const clearStatus = () => {
    setQRCodeMessageState(prev => ({
      ...prev,
      status: 'idle',
      message: undefined,
    }));
  };

  return {
    data: qrCodeMessageState.data,
    message: qrCodeMessageState.message,
    getQRCodeMessages,
    postQRCodeCheckin,
    patchCheckinConfirmation,
    clearStatus,
    isLoading,
    isError,
  };
};
