import { DecodeHtmlFormat } from '@/libs';
import { INavigationParamsProps, MapView, Rating } from '@components';
import AppConfig from '@config';
import useLocalization from '@localizations';
import { useRoute } from '@react-navigation/native';
import React, { useState } from 'react';
import { Linking, StyleSheet, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { Divider, IconButton, List, Title, useTheme } from 'react-native-paper';
import { NearMe } from '../../store';
import { sharePlace } from './PlaceCard';

const renderIcon = (icon: string, color: string) => {
  return (
    <List.Icon
      style={{
        marginLeft: -10,
      }}
      color={color}
      icon={icon}
    />
  );
};

type PlaceItemProps = {
  icon: string;
  title: string;
  onPress?: () => void;
};

const PlaceItem: React.FC<PlaceItemProps> = props => {
  const { icon, title, onPress } = props;
  const { colors } = useTheme();

  return (
    <List.Item
      style={{
        marginVertical: -5,
        margin: 0,
        padding: 0,
      }}
      title={DecodeHtmlFormat(title)}
      left={() => renderIcon(icon, colors.primary)}
      onPress={onPress}
    />
  );
};

const renderPlaceItem = (open: boolean) => {
  return (
    <PlaceItem title={open ? 'Open Now' : 'Closed'} icon="clock-time-eleven" />
  );
};

type PlaceDetailProps = {
  place: NearMe;
};

const PlaceDetail: React.FC<PlaceDetailProps> = props => {
  const { place } = props;
  const { t } = useLocalization();
  const theme = useTheme();

  return (
    <View style={styles.background}>
      <View style={styles.paddings}>
        <View style={AppConfig.Styles.rowContainer}>
          <Title>{place?.title}</Title>
          {false && ( // GP-201: Hide Share button on React GP
            <IconButton
              icon="share-variant"
              iconColor={theme.colors.primary}
              size={AppConfig.Styles.getAdjustedSize(25)}
              onPress={() => sharePlace(place, t('amenities_framing_text'))}
            />
          )}
        </View>
        <Rating rating={place.rating} />
      </View>
      <Divider />
      <View style={styles.paddings}>
        {place?.address?.length > 0 && (
          <PlaceItem
            title={place.address}
            icon="map-marker"
            onPress={() =>
              Linking.openURL(
                `https://www.google.com/maps/dir/?api=1&destination_id=${
                  place.id
                }&destination=${encodeURIComponent(
                  place.address,
                )}&travelmode=walking`,
              )
            }
          />
        )}
        {place?.hoursOfOperation.length > 0 && (
          <List.Accordion
            title={null}
            style={{
              padding: 0,
            }}
            left={() => renderPlaceItem(place.open)}>
            {place.hoursOfOperation.map(hours => (
              <List.Item
                key={hours}
                style={{
                  marginTop: -5,
                  padding: 0,
                  paddingHorizontal: 50,
                }}
                titleStyle={[AppConfig.Styles.body1, { color: 'gray' }]}
                title={DecodeHtmlFormat(hours)}
              />
            ))}
          </List.Accordion>
        )}
        {place?.phone?.length > 0 && (
          <PlaceItem
            title={place.phone}
            icon="phone"
            onPress={() => Linking.openURL(`tel:${place?.phone}`)}
          />
        )}
        {place?.url?.length > 0 && (
          <PlaceItem
            title={place.url}
            icon="mouse"
            onPress={() => Linking.openURL(place?.url)}
          />
        )}
      </View>
    </View>
  );
};

export const Place = () => {
  const route = useRoute();
  const { model } = route.params as INavigationParamsProps;
  const place: NearMe = model as NearMe;
  const [size, setSize] = useState(375); // GP-190: hardcoded value, seems some safari version acts weird

  return (
    <ScrollView style={AppConfig.Styles.container}>
      <PlaceDetail place={place} />
      <View
        style={[styles.mapContainer]}
        onLayout={event => setSize(event.nativeEvent.layout.width)}>
        <MapView
          style={{
            width: size,
            height: size,
          }}
          latitude={parseFloat(place?.latitude)}
          longitude={parseFloat(place?.longitude)}
        />
      </View>
    </ScrollView>
  );
};

export default Place;

const styles = StyleSheet.create({
  background: {
    marginBottom: -30,
    backgroundColor: 'white',
    borderBottomColor: 'lightgray',
    borderBottomLeftRadius: 30,
    borderBottomRightRadius: 30,
    borderBottomWidth: 1,
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.5,
    shadowRadius: 20,
    zIndex: 100,
  },
  paddings: {
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  mapContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
