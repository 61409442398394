import { logger } from '@/libs/logger';
import useVariables from '@/store/variables/hooks';
import { EarlyCheckInTimes } from '@components';
import AppConfig from '@config';
import {
  BannerStatus,
  canShowCheckInOutButton,
  canShowEntranceInstructionsButton,
  canShowRequestEarlyCheckin,
  determineReservationState,
  reservationBannerStatus,
} from '@libs';
import useLocalization from '@localizations';
import { useAppStore } from '@store';
import { useMemo, useState } from 'react';
import { Text, View } from 'react-native';
import { Button, useTheme } from 'react-native-paper';
import RenderHtml from 'react-native-render-html';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { EntranceInstructionsActionSheet } from './EntranceInstructionsActionSheet';
import { IEntranceInstructions } from './FrontDoorInstructionsSheet';

interface IReservationActionsProps {
  onCheckInOutPress: () => void;
  loading: boolean;
}

export const ReservationActions = ({
  onCheckInOutPress,
  loading,
}: IReservationActionsProps) => {
  const { t } = useLocalization();
  const theme = useTheme();
  const { reservation, company } = useAppStore();
  const [showEarlyCheckInTimes, setShowEarlyCheckInTimes] = useState(false);
  const [modalVisible, setModalVisible] = useState<
    'EntranceInstructions' | 'AccessCode' | 'StayMyWay'
  >();

  const entranceInstructions: IEntranceInstructions[] = useMemo(() => {
    return [
      {
        buttonLabel: reservation?.entranceInstructions.buttonLabel,
      },
    ];
  }, [reservation?.entranceInstructions.buttonLabel]);

  const { contents: entranceInstructionsContents } =
    useVariables<IEntranceInstructions>(entranceInstructions);

  const instructions = entranceInstructionsContents?.find(Boolean);

  if (reservation?.smw || !company) {
    return null;
  }

  const bannerStatus = reservationBannerStatus(company, reservation);
  const reservationState = determineReservationState(reservation);
  logger.info('Reservation state', reservationState);

  const buttonTitle = t(
    reservationState === 'arrival' ? 'check_in_action' : 'check_out_action',
  );

  return (
    <>
      <View
        style={{
          marginLeft: 16,
          marginRight: 16,
          marginBottom: 12,
          marginTop: 12,
        }}>
        {canShowRequestEarlyCheckin(company, reservation) && (
          <>
            <Button
              testID="early-check-in-button"
              buttonColor={theme.colors.primary}
              style={{ marginBottom: 16 }}
              labelStyle={{
                fontSize: 14,
                fontStyle: 'normal',
                fontWeight: '700',
                color: '#FFFFFF',
                textTransform: 'none',
                //@ts-expect-error OPN1-14946 we need this in order to render the multiline button text
                whiteSpace: 'initial',
              }}
              contentStyle={{
                width: '100%',
                height: AppConfig.Styles.buttonHeight,
              }}
              mode="contained"
              onPress={() => {
                setShowEarlyCheckInTimes(!showEarlyCheckInTimes);
              }}>
              <Text numberOfLines={2} ellipsizeMode="tail">
                {t('booking_request_early_check-in')}
              </Text>
              {showEarlyCheckInTimes ? (
                <Icon
                  color="white"
                  name="chevron-up"
                  size={20}
                  style={{ position: 'absolute', right: 20, bottom: 12 }}
                />
              ) : (
                <Icon
                  color="white"
                  name="chevron-down"
                  size={20}
                  style={{ position: 'absolute', right: 20, bottom: 12 }}
                />
              )}
            </Button>
            <EarlyCheckInTimes showToggle={showEarlyCheckInTimes} />
          </>
        )}
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          {canShowCheckInOutButton(company, reservationState) && (
            <>
              <Button
                style={{ flex: 1 }}
                labelStyle={{
                  fontSize: 14,
                  fontStyle: 'normal',
                  fontWeight: '700',
                  color: '#FFFFFF',
                  textTransform: 'none',
                  //@ts-expect-error OPN1-14946 we need this in order to render the multiline button text
                  whiteSpace: 'initial',
                }}
                testID="check-in-out-button"
                disabled={
                  bannerStatus === BannerStatus.VERIFICATION ||
                  bannerStatus === BannerStatus.INCIDENTAL_PAYMENT ||
                  (reservationState === 'arrival'
                    ? !company?.checkinEnabled
                    : !company?.checkoutEnabled)
                }
                contentStyle={{
                  width: '100%',
                  height: AppConfig.Styles.buttonHeight,
                }}
                loading={loading}
                mode="contained"
                onPress={onCheckInOutPress}>
                <Text numberOfLines={2} ellipsizeMode="tail">
                  {loading ? '' : buttonTitle}
                </Text>
              </Button>
            </>
          )}
          {canShowEntranceInstructionsButton(company, reservation) && (
            <>
              <Button
                style={{
                  flex: 1,
                  marginLeft: canShowCheckInOutButton(company, reservationState)
                    ? 12
                    : 0,
                }}
                labelStyle={{
                  fontSize: 14,
                  fontStyle: 'normal',
                  fontWeight: '700',
                  color: '#FFFFFF',
                  textTransform: 'none',
                }}
                testID="how-to-access-button"
                mode="contained"
                contentStyle={{
                  height: AppConfig.Styles.buttonHeight,
                }}
                onPress={() => {
                  setModalVisible('EntranceInstructions');
                }}>
                <RenderHtml
                  // tagsStyles={tagsStyles}
                  defaultTextProps={{
                    ellipsizeMode: 'tail',
                    numberOfLines: 2,
                    style: {
                      //@ts-expect-error OPN1-14946 we need this in order to render the multiline button text
                      whiteSpace: 'initial',
                    },
                  }}
                  source={{
                    html: instructions?.buttonLabel || t('booking_key_button'),
                  }}
                />
              </Button>
            </>
          )}
        </View>
      </View>
      <EntranceInstructionsActionSheet
        reservation={reservation}
        modalType={modalVisible}
        onModalVisibleChange={setModalVisible}
      />
    </>
  );
};
