import { QRCodeOverlay } from '@svgs';
import { StyleSheet, View } from 'react-native';
import { QrReader, QrReaderProps } from 'react-qr-reader';

type QRCodeScannerProps = Partial<QrReaderProps>;

export const QRCodeScanner = (props: QRCodeScannerProps) => {
  return (
    <>
      <QrReader
        videoStyle={{ objectFit: 'cover' }}
        constraints={{ facingMode: 'environment' }}
        {...props}
      />
      <View style={StyleSheet.absoluteFill}>
        <QRCodeOverlay width="100%" height="100%" fill="black" />
      </View>
    </>
  );
};
