import { trackEvent } from '@/hooks/useAnalytics';
import AppConfig from '@config';
import { useLocalization } from '@localizations';
import { View } from 'react-native';
import { Button, Dialog, Portal, Text } from 'react-native-paper';
import { useClaimError } from '../providers/ClaimErrorProvider';

interface ClaimErrorProps {
  visible: boolean;
  onDismiss: () => void;
}

export const GlobalClaimErrorDialog = () => {
  const { showClaimError, setShowClaimError } = useClaimError();

  return (
    <ClaimErrorDialog
      visible={showClaimError}
      onDismiss={() => setShowClaimError(false)}
    />
  );
};

const ClaimErrorDialog = ({ visible, onDismiss }: ClaimErrorProps) => {
  trackEvent({
    screen: 'ClaimErrorDialog',
    event: 'OPENED',
    feature: 'sign_in',
    action: 'sign_in_reservation_already_linked',
  });
  const { t } = useLocalization();

  return (
    <Portal>
      <Dialog
        visible={visible}
        onDismiss={onDismiss}
        style={{
          alignSelf: 'center',
          width: '90%',
          maxWidth: AppConfig.Styles.getAdjustedSize(350),
        }}>
        <Dialog.Content>
          <Text variant="headlineSmall" style={{ marginBottom: 16 }}>
            {t('web_login_error_reservation_title')}
          </Text>
          <Text variant="bodyMedium" style={{ marginBottom: 24 }}>
            {t('web_login_error_reservation_body')}
          </Text>
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              alignSelf: 'stretch',
            }}>
            <Button mode="contained" buttonColor="black" onPress={onDismiss}>
              {t('web_login_error_reservation_button')}
            </Button>
          </View>
        </Dialog.Content>
      </Dialog>
    </Portal>
  );
};

export default ClaimErrorDialog;
