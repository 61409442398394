import { isTermsChallengeRequired } from '@/libs/ChallengeHelper';
import { SnackBar, Spacer } from '@components';
import AppConfig from '@config';
import { DecodeHtmlFormat, trackEvent } from '@libs';
import useLocalization from '@localizations';
import { Company, dispatchUpdateCompany, useAppStore } from '@store';
import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Linking, ScrollView, StyleSheet, View } from 'react-native';
import Autolink from 'react-native-autolink';
import { Checkbox, Text } from 'react-native-paper';
import { ButtonView, HeaderView } from './Common';
import { LoginStyles } from './Styles';

// @@ Scroll Container
export const ScrollContainer = ({ company }: { company: Company }) => (
  <View style={[styles.scrollContainer]}>
    <ScrollView style={[styles.scroll]}>
      <Autolink
        text={
          company?.terms?.message
            ? DecodeHtmlFormat(company?.terms?.message)
            : ''
        }
        textProps={{ style: AppConfig.Styles.caption }}
        linkStyle={{ textDecorationLine: 'underline', color: 'blue' }}
        stripPrefix={false}
        onPress={url => Linking.openURL(url)}
      />
    </ScrollView>
  </View>
);

// @@ Checkbox View
interface ICheckboxViewProps {
  checked: boolean;
  setChecked: Dispatch<SetStateAction<boolean>>;
  error: string | null;
}
export const CheckboxView: FunctionComponent<ICheckboxViewProps> = ({
  checked,
  setChecked,
  error,
}) => {
  const { t } = useLocalization();

  return (
    <View style={[styles.checkboxContainer, AppConfig.Styles.rowContainer]}>
      <Checkbox
        status={checked ? 'checked' : 'unchecked'}
        onPress={() => {
          setChecked(!checked);
        }}
      />
      <Text style={AppConfig.Styles.caption}>
        {t('terms_conditions_subtitle')}
      </Text>
      <SnackBar title={error ?? ''} visible={!!error} color="red" />
    </View>
  );
};

// @@ Terms
export const Terms = () => {
  const { company, reservation, theme } = useAppStore();
  const [checked, setChecked] = useState(reservation?.termsAgreed ?? false);
  const [disabled, setDisabled] = useState(isTermsChallengeRequired(company));

  // if we mount here, we need to go through the whole available login steps
  useEffect(() => {
    if (company && !company.loginStepsInProgress) {
      dispatchUpdateCompany({
        ...company,
        loginStepsInProgress: true,
      });
    }
  }, [company]);

  useEffect(() => {
    if (isTermsChallengeRequired(company)) {
      setDisabled(!checked);
    }
  }, [checked, company]);

  const submit = useCallback(async () => {
    if (checked) {
      trackEvent('Welcome', 'Terms:Accepted');
      return true;
    }

    return false;
  }, [checked]);

  return (
    <View style={[AppConfig.Styles.container, AppConfig.Styles.center]}>
      <View style={[AppConfig.Styles.container, LoginStyles.container]}>
        <View style={[AppConfig.Styles.center]}>
          {theme && <HeaderView theme={theme} />}
        </View>
        {company && <ScrollContainer company={company} />}
        <Spacer />
        <CheckboxView checked={checked} setChecked={setChecked} error={null} />
        <Spacer />
        <ButtonView
          backButton={false}
          disabled={disabled}
          onSubmit={submit}
          company={company}
          currentPage="Terms"
        />
      </View>
    </View>
  );
};

export default Terms;

const styles = StyleSheet.create({
  scroll: {
    flexBasis: 1,
    padding: AppConfig.Styles.getAdjustedSize(16),
    borderColor: 'lightgray',
    borderWidth: StyleSheet.hairlineWidth,
    borderRadius: 5,
  },
  checkboxContainer: {
    flexGrow: 0,
    alignItems: 'center',
    minHeight: 32,
  },
  scrollContainer: {
    flexGrow: 1,
    flexBasis: 1,
  },
});
