// @ts-nocheck - fix me
import useLocalization from '@/assets/localizations';
import { Spacer } from '@components';
import AppConfig from '@config';
import { trackEvent } from '@libs';
import { useNavigation } from '@react-navigation/native';
import { useAppStore, VerificationIDType } from '@store';
import { VERIFICATIONFingerprint } from '@svgs';
import 'react-image-crop/dist/ReactCrop.css';
import { Image as NativeImage, View } from 'react-native';
import { Button, Text } from 'react-native-paper';
import Svg from 'react-native-svg';
import { useVerification } from '../../../store/verification';
import { ContentView, HeaderView } from '../../login/Common';
import { LoginStyles } from '../../login/Styles';

// @@ Action view
const ActionView = ({ img }: { img: string }) => {
  const navigation = useNavigation();
  const { t } = useLocalization();

  return (
    <View
      style={{
        width: '100%',
        paddingHorizontal: AppConfig.Styles.getAdjustedSize(24),
        paddingBottom: AppConfig.Styles.getAdjustedSize(24),
      }}>
      <View
        style={[
          AppConfig.Styles.container,
          AppConfig.Styles.alignCenter,
          {
            flexDirection: 'column',
            justifyContent: 'space-between',
          },
        ]}>
        <NativeImage
          source={{ uri: img }}
          style={{
            height: AppConfig.Styles.getHeightByPercentage(40),
            width: AppConfig.Styles.getWidthByPercentage(50),
            borderRadius: 10,
            marginBottom: 30,
          }}
        />

        <View style={[AppConfig.Styles.container]}>
          <View
            style={[AppConfig.Styles.container, AppConfig.Styles.alignCenter]}>
            <Text
              style={[
                AppConfig.Styles.headline6,
                AppConfig.Styles.alignCenter,
                AppConfig.Styles.lighterText,
              ]}>
              {t('confirm_id_capture')}
            </Text>
          </View>
          <Spacer />
          <View style={[AppConfig.Styles.container, { marginBottom: 50 }]}>
            <Text
              style={[
                AppConfig.Styles.subtitle2,
                AppConfig.Styles.lighterText,
                { marginBottom: 10 },
              ]}>
              {t('photo_clarity')}
            </Text>
            <Text
              style={[
                AppConfig.Styles.subtitle2,
                AppConfig.Styles.lighterText,
              ]}>
              {t('photo_another')}
            </Text>
          </View>

          <View
            style={[
              AppConfig.Styles.rowContainer,
              {
                justifyContent: 'space-between',
                borderTopColor: '#dddddd',
                borderTopWidth: 1,
                paddingTop: 20,
                marginTop: 20,
              },
            ]}>
            <Button
              contentStyle={{
                height: AppConfig.Styles.buttonHeight,
              }}
              labelStyle={{ color: 'black' }}
              onPress={() => {
                navigation.navigate('CaptureId');
                trackEvent('Superhog', 'ID Confirm:Retake');
              }}>
              {t('retake')}
            </Button>
            <Button
              contentStyle={{
                height: AppConfig.Styles.buttonHeight,
              }}
              labelStyle={{ color: 'black' }}
              onPress={() => {
                navigation.navigate('Details');
                trackEvent('Superhog', 'ID Confirm:Back');
              }}>
              {t('back')}
            </Button>
            <Button
              contentStyle={{
                width: 150,
                height: AppConfig.Styles.buttonHeight,
              }}
              labelStyle={{ color: 'white' }}
              mode="contained"
              onPress={() => {
                navigation.navigate('CaptureSelfie');
                trackEvent('Superhog', 'ID Confirm:Confirmed');
              }}>
              {t('use_photo')}
            </Button>
          </View>
        </View>
      </View>
    </View>
  );
};

// @@ Default Component
export const ConfirmId = () => {
  const { theme } = useAppStore();
  const { verification } = useVerification();
  const { t } = useLocalization();
  let label = t('your_identity_card');

  if (verification?.idType === VerificationIDType.ID_CARD) {
    label = t('your_passport');
  } else if (verification?.idType === VerificationIDType.DRIVERS) {
    label = t('your_drivers_license');
  }
  return (
    <View style={[AppConfig.Styles.container, AppConfig.Styles.center]}>
      <View style={[AppConfig.Styles.container, LoginStyles.container]}>
        <View style={[LoginStyles.contentContainer]}>
          {theme && (
            <HeaderView
              theme={theme}
              logo={
                <Svg
                  width="100%"
                  height="100%"
                  viewBox="0 0 50 50"
                  fill={theme.primaryColor}
                  style={{ margin: 0, padding: 0 }}>
                  <VERIFICATIONFingerprint />
                </Svg>
              }
            />
          )}
          <ContentView title={label} description="" />
        </View>
        <ActionView img={verification.governmentIdBase64} />
      </View>
    </View>
  );
};

export default ConfirmId;
