import {
  canShowHomePage,
  isContactChallengeRequired,
  isLoginChallengeRequired,
  isTermsChallengeRequired,
  isVerificationChallengeRequired,
  isWelcomeChallengeRequired,
} from '@/libs/ChallengeHelper';
import { Splash } from '@components';
import { createStackNavigator } from '@react-navigation/stack';
import { useAppStore } from '@store';
import { default as VerifyGuest } from '../home/VerificationFlow';
import { Challenge } from './Challenge';
import Contact from './Contact';
import Auth from './LoginCognito/RequestCredentials';
import RequestOtp from './LoginCognito/RequestOtp';
import { shouldNavigateToAuth } from './LoginCognito/utils';
import Summary from './Summary';
import { Terms } from './Terms';
import { Welcome } from './Welcome';

const Stack = createStackNavigator();

export const LoginNavigator = () => {
  const { company, reservation } = useAppStore();

  const shouldShowSplash = () => {
    // if no company = loading /config call. splash should show
    // if company is loaded but challenge has passed and we're here:
    //  /reservation is loading = splash
    if (
      canShowHomePage(company) &&
      !isLoginChallengeRequired(company) &&
      (company?.challengePassed === undefined || company?.challengePassed)
    ) {
      return true;
    }

    return false;
  };

  if (shouldShowSplash()) {
    return <Splash />;
  }

  const getInitialRouteName = () => {
    // TODO: REGISTRATION_RELEASE - remove this on final
    if (!company && !reservation) {
      return 'Splash';
    }

    if (shouldNavigateToAuth(company)) {
      return 'Auth';
    }

    switch (true) {
      case isLoginChallengeRequired(company):
        return 'LoginChallenge';
      case isVerificationChallengeRequired(company):
        return 'LoginVerificationFlow';
      case isTermsChallengeRequired(company):
        return 'LoginTerms';
      case isWelcomeChallengeRequired(company):
        return 'LoginWelcome';
      case isContactChallengeRequired(company):
        return 'LoginContact';
      default:
        return 'LoginSummary';
    }
  };

  let index = 1;
  const initialRoute = getInitialRouteName();
  return (
    <Stack.Navigator
      initialRouteName={initialRoute}
      screenOptions={() => ({
        gestureEnabled: false,
        headerShown: false,
      })}>
      {!company &&
        !reservation && ( // TODO: REGISTRATION_RELEASE - remove this on final
          <Stack.Screen
            name="Splash"
            component={Splash}
            options={{ headerShown: false }}
          />
        )}
      <Stack.Screen
        name="VerifyOtp"
        component={RequestOtp}
        options={{ title: 'Operto' }}
      />
      <Stack.Screen
        name="Auth"
        component={Auth}
        options={{ title: 'Operto' }}
      />
      <Stack.Screen
        name="LoginChallenge"
        component={Challenge}
        options={{ title: `Login Step ${index++}` }}
      />
      {isVerificationChallengeRequired(company) && (
        <Stack.Screen
          name="LoginVerificationFlow"
          component={VerifyGuest}
          options={{ title: 'Guest Verification' }}
        />
      )}

      <Stack.Screen
        name="LoginTerms"
        component={Terms}
        options={{ title: `Login Step ${index++}` }}
      />
      <Stack.Screen
        name="LoginWelcome"
        component={Welcome}
        options={{
          title: `Login Step ${index++}`,
        }}
      />
      <Stack.Screen
        name="LoginContact"
        component={Contact}
        options={{
          title: `Login Step ${index++}`,
        }}
      />
      <Stack.Screen
        name="LoginSummary"
        component={Summary}
        options={{ title: `Login Step ${index++}` }}
      />
    </Stack.Navigator>
  );
};

export default LoginNavigator;
