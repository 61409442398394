import { logger } from '@/libs/logger';
import AppConfig from '@config';
import getImage from '@images';
import { trackEvent } from '@libs';
import { DrawerActions, useNavigationState } from '@react-navigation/native';
import { StackHeaderProps } from '@react-navigation/stack';
import { Amenity, Company, HouseManual, Reservation } from '@store';
import { Image, StyleSheet, View, useWindowDimensions } from 'react-native';
import { Headline, IconButton, useTheme } from 'react-native-paper';

export type INavigationParamsProps = {
  title?: string;
  contentTitle?: string;
  contentDescription?: string;
  headerBackgroundImage?: string;
  headerHeight?: number;
  headerBackgroundColor?: string;
  text?: string;
  model?: Company | Reservation | Amenity | HouseManual;
  titleFontSize?: number;
  titleMarginLeft?: number;
  titleMarginBottom?: number;
  autoReply?: string;
};

export const NavigationHeader = (props: StackHeaderProps) => {
  const { scene, navigation } = props;
  const routesLength = useNavigationState(state => state.routes.length);
  const theme = useTheme();
  let navProps: INavigationParamsProps | undefined = scene?.route?.params;
  const { width } = useWindowDimensions();
  if (navProps === undefined) {
    navProps = scene?.descriptor?.options as INavigationParamsProps;
  }

  return (
    <View
      style={[
        styles.container,
        {
          height: AppConfig.Styles.getAdjustedSize(
            navProps?.headerHeight ||
              (navProps?.headerBackgroundImage && 200) ||
              130,
          ),
          backgroundColor:
            navProps?.headerBackgroundColor || theme.colors.primary,
        },
      ]}>
      {navProps?.headerBackgroundImage ? (
        <Image
          style={StyleSheet.absoluteFill}
          source={{
            uri: getImage(
              `${
                navProps?.headerBackgroundImage
              }?tr=w-${AppConfig.Styles.getWindowWidth()}`,
            ),
          }}
        />
      ) : (
        <Image
          style={StyleSheet.absoluteFill}
          source={{
            uri: getImage(
              `https://ik.imagekit.io/operto/tr:w-${width},h-400/portals/guest-portal-assets/placeholders/guides_placeholder.png`,
            ),
          }}
        />
      )}
      {/* determine if we are in the root of the stack or deep nested */}
      {routesLength === 0 ? (
        <IconButton
          testID="drawer-menu-button"
          icon="menu"
          iconColor={AppConfig.Colors.ON_SECONDARY}
          size={AppConfig.Styles.getAdjustedSize(30)}
          onPress={() => navigation.dispatch(DrawerActions.openDrawer())}
        />
      ) : (
        <IconButton
          testID="back-nav-button"
          style={{ backgroundColor: `${AppConfig.Colors.TERTIARY}33` }}
          icon="arrow-left"
          iconColor={AppConfig.Colors.ON_SECONDARY}
          size={AppConfig.Styles.getAdjustedSize(30)}
          onPress={() => {
            trackEvent('Guides', 'Back Button:Clicked');
            if (routesLength === 1) {
              // NOTE: we are at the root of the stack, user must have reloaded the web page
              logger.info('Navigating to Home');

              navigation.navigate('Main', { screen: 'Home' });
            } else if (navigation.canGoBack()) {
              navigation.goBack();
            } else {
              navigation.pop();
            }
          }}
        />
      )}
      <View
        style={[
          AppConfig.Styles.container,
          AppConfig.Styles.bottom,
          styles.bottom,
        ]}>
        <Headline
          style={[
            styles.title,
            navProps?.titleFontSize
              ? {
                  fontSize: AppConfig.Styles.getAdjustedSize(
                    navProps.titleFontSize,
                  ),
                }
              : { fontSize: AppConfig.Styles.getAdjustedSize(36) },
            navProps?.titleMarginLeft
              ? {
                  marginLeft: AppConfig.Styles.getAdjustedSize(
                    navProps.titleMarginLeft,
                  ),
                }
              : { marginLeft: 0 },
            navProps?.titleMarginBottom
              ? {
                  marginBottom: AppConfig.Styles.getAdjustedSize(
                    navProps.titleMarginBottom,
                  ),
                }
              : { marginBottom: AppConfig.Styles.getAdjustedSize(12) },
          ]}>
          {navProps?.title}
        </Headline>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: AppConfig.Styles.getAdjustedSize(10),
  },
  title: {
    color: AppConfig.Colors.ON_SECONDARY,
    alignSelf: 'flex-start',
    textShadowOffset: { width: 0, height: 1 },
    textShadowRadius: 5,
    textShadowColor: `${AppConfig.Colors.TERTIARY}88`,
  },
  bottom: {
    marginHorizontal: AppConfig.Styles.getAdjustedSize(20),
    backgroundColor: 'transparent',
  },
});

export default NavigationHeader;
