import html2canvas from 'html2canvas';

export const drawSignatureToCanvas = async () => {
  const inputElement = document.getElementById('text-signature');
  if (!inputElement) {
    return;
  }
  const canvas = await html2canvas(inputElement, {
    scale: 2,
    useCORS: true,
  });
  const dataURL = canvas.toDataURL();

  return dataURL;
};
