import {
  GUEST_AUTH_DOMAIN,
  GUEST_AUTH_FIXED_PASSWORD,
  GUEST_AUTH_USER_POOL_WEB_CLIENT_ID,
  GUEST_PORTAL_URL,
  REGION,
} from '@/assets/configurations/Settings';
import { logger } from '@/libs/logger';
import { Company, store } from '@/store';
import { setToken } from '@/store/auth';
import {
  CognitoIdentityProviderClient,
  GetUserCommand,
  GetUserCommandInput,
  GlobalSignOutCommand,
  GlobalSignOutCommandInput,
  InitiateAuthCommand,
  InitiateAuthCommandInput,
  RespondToAuthChallengeCommand,
  RespondToAuthChallengeCommandInput,
  SignUpCommand,
  SignUpCommandInput,
} from '@aws-sdk/client-cognito-identity-provider';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  ASYNC_STORAGE_GUEST_PORTAL_TOKEN_KEY,
  ASYNC_STORAGE_REDIRECT_INFO_KEY,
  ASYNC_STORAGE_REGISTRATION_INFO_KEY,
  AUTH_FLOW,
  CHALLENGE_NAME,
  REFRESH_TOKEN_AUTH,
} from './constants';

export const signUpUser = async (email: string) => {
  const client = new CognitoIdentityProviderClient({ region: REGION });

  const input: SignUpCommandInput = {
    Username: email,
    Password: GUEST_AUTH_FIXED_PASSWORD,
    ClientId: GUEST_AUTH_USER_POOL_WEB_CLIENT_ID,
  };

  try {
    const command = new SignUpCommand(input);

    await client.send(command);
  } catch (error) {
    const err = error as Error;
    if (err.name !== 'UsernameExistsException') {
      logger.error(error);
      return { error: err };
    }
  }
  return {};
};

export const signInUser = async (email: string) => {
  const client = new CognitoIdentityProviderClient({ region: REGION });

  const input: InitiateAuthCommandInput = {
    AuthFlow: AUTH_FLOW,
    AuthParameters: { USERNAME: email },
    ClientId: GUEST_AUTH_USER_POOL_WEB_CLIENT_ID,
  };

  try {
    const command = new InitiateAuthCommand(input);

    const res = await client.send(command);
    return { session: res.Session };
  } catch (error) {
    const err = error as Error;
    logger.error(error);
    return { error: err };
  }
};

export const signOutUser = async () => {
  const accessToken = (await getCognitoAccessTokenFromAsyncStorage()) ?? '';

  if (accessToken && accessToken.length > 0) {
    const client = new CognitoIdentityProviderClient({ region: REGION });

    const input: GlobalSignOutCommandInput = {
      AccessToken: accessToken,
    };

    try {
      const command = new GlobalSignOutCommand(input);

      await client.send(command);
    } catch (error) {
      logger.error(error);
    }
  }
};

export const respondToAuthChallenge = async (
  session: string,
  email: string,
  completeOtp: string,
) => {
  const client = new CognitoIdentityProviderClient({ region: REGION });

  const input: RespondToAuthChallengeCommandInput = {
    ChallengeName: CHALLENGE_NAME,
    ChallengeResponses: {
      USERNAME: email,
      ANSWER: completeOtp,
    },
    ClientId: GUEST_AUTH_USER_POOL_WEB_CLIENT_ID,
    Session: session,
  };

  try {
    const command = new RespondToAuthChallengeCommand(input);
    const { Session, AuthenticationResult } = await client.send(command);
    const { AccessToken, RefreshToken, IdToken, ExpiresIn } =
      AuthenticationResult ?? {};
    const userAuthorized = !Session && AccessToken && RefreshToken && IdToken;

    if (userAuthorized) {
      await setCognitoAccessTokenToAsyncStorage({
        accessToken: AccessToken,
        refreshToken: RefreshToken,
        idToken: IdToken,
        expiresIn: ExpiresIn ?? 3600,
      });

      store.dispatch(setToken({ idToken: IdToken, tokenType: 'AWS' }));
    }

    return {
      userAuthorized: userAuthorized,
      session: Session,
      accessToken: AccessToken,
      refreshToken: RefreshToken,
      idToken: IdToken,
    };
  } catch (error) {
    logger.error(error);
  }

  return {};
};

// Doesn't work with external identity providers instead use getUserInfo from cognitoApi
export const getUserInformation = async () => {
  const accessToken = (await getCognitoAccessTokenFromAsyncStorage()) ?? '';

  if (accessToken && accessToken.length > 0) {
    const client = new CognitoIdentityProviderClient({ region: REGION });

    const input: GetUserCommandInput = {
      AccessToken: accessToken,
    };

    try {
      const command = new GetUserCommand(input);

      return await client.send(command);
    } catch (error) {
      logger.error(error);
    }
  }
};

export const getTokenWithRefreshToken = async () => {
  const client = new CognitoIdentityProviderClient({ region: REGION });

  const refreshToken = await getCognitoRefreshTokenFromAsyncStorage();

  const input: InitiateAuthCommandInput = {
    AuthFlow: REFRESH_TOKEN_AUTH,
    AuthParameters: { REFRESH_TOKEN: refreshToken ?? '' },
    ClientId: GUEST_AUTH_USER_POOL_WEB_CLIENT_ID,
  };

  try {
    const command = new InitiateAuthCommand(input);
    const { AuthenticationResult } = await client.send(command);

    const { AccessToken, IdToken, ExpiresIn } = AuthenticationResult ?? {};

    const userAuthorized = AccessToken && IdToken;

    if (userAuthorized) {
      await setCognitoAccessTokenToAsyncStorage({
        accessToken: AccessToken,
        refreshToken: refreshToken,
        idToken: IdToken,
        expiresIn: ExpiresIn ?? 3600,
      });

      store.dispatch(setToken({ idToken: IdToken, tokenType: 'AWS' }));

      return {
        accessToken: AccessToken,
        refreshToken: refreshToken,
        idToken: IdToken,
      };
    }
  } catch (error) {
    logger.error(error);
  }

  return {};
};

export const FEDERATED_LOGIN_IDENTITY_PROVIDER = {
  GOOGLE: 0,
  FACEBOOK: 1,
  APPLE: 2,
};

export const federatedLoginRedirectLink = (provider: number) => {
  let scope = 'openid+profile+email+aws.cognito.signin.user.admin';
  let identity_provider = '';

  switch (provider) {
    case FEDERATED_LOGIN_IDENTITY_PROVIDER.GOOGLE:
      identity_provider = 'Google';
      break;
    case FEDERATED_LOGIN_IDENTITY_PROVIDER.APPLE:
      identity_provider = 'SignInWithApple';
      break;
    case FEDERATED_LOGIN_IDENTITY_PROVIDER.FACEBOOK:
      identity_provider = 'Facebook';
      scope = 'openid+email+aws.cognito.signin.user.admin';
      break;
    default:
      return null;
  }

  return `${GUEST_AUTH_DOMAIN}/oauth2/authorize?client_id=${GUEST_AUTH_USER_POOL_WEB_CLIENT_ID}&redirect_uri=${encodeURIComponent(
    GUEST_PORTAL_URL + '/auth/',
  )}&scope=${scope}&response_type=code&identity_provider=${identity_provider}`;
};

export const getCognitoAuthFromAsyncStorage = async () => {
  const GuestPortalTokenString = await AsyncStorage.getItem(
    ASYNC_STORAGE_GUEST_PORTAL_TOKEN_KEY,
  );
  if (GuestPortalTokenString) {
    return JSON.parse(GuestPortalTokenString);
  }

  return null;
};

export const getCognitoAccessTokenFromAsyncStorage = async () => {
  const cognitoAuth = await getCognitoAuthFromAsyncStorage();
  if (cognitoAuth) {
    return cognitoAuth.awscognito?.access_token;
  }

  return null;
};

export const getCognitoIdTokenFromAsyncStorage = async () => {
  const cognitoAuth = await getCognitoAuthFromAsyncStorage();
  if (cognitoAuth) {
    return cognitoAuth.awscognito?.id_token;
  }

  return null;
};

export const getCognitoRefreshTokenFromAsyncStorage = async () => {
  const cognitoAuth = await getCognitoAuthFromAsyncStorage();
  if (cognitoAuth) {
    return cognitoAuth.awscognito?.refresh_token;
  }

  return null;
};

export const getCognitoExpiryTimeFromAsyncStorage = async () => {
  const cognitoAuth = await getCognitoAuthFromAsyncStorage();
  if (cognitoAuth) {
    return cognitoAuth.awscognito?.expiryDate;
  }

  return null;
};

export const setCognitoAccessTokenToAsyncStorage = async ({
  accessToken,
  refreshToken,
  idToken,
  expiresIn,
}: {
  accessToken: string;
  refreshToken: string;
  idToken: string;
  expiresIn: number;
}) => {
  await AsyncStorage.setItem(
    ASYNC_STORAGE_GUEST_PORTAL_TOKEN_KEY,
    JSON.stringify({
      awscognito: {
        access_token: accessToken,
        refresh_token: refreshToken,
        id_token: idToken,
        expiryDate: new Date().valueOf() + expiresIn * 1000,
      },
    }),
  );
};

export const removeCognitoAccessTokenFromAsyncStorage = async () => {
  await AsyncStorage.removeItem(ASYNC_STORAGE_GUEST_PORTAL_TOKEN_KEY);
};

export const getRegistrationInfoFromAsyncStorage = async () => {
  const RegistrationInfoString = await AsyncStorage.getItem(
    ASYNC_STORAGE_REGISTRATION_INFO_KEY,
  );

  if (RegistrationInfoString) {
    return JSON.parse(RegistrationInfoString);
  }

  return null;
};

export const setRegistrationInfoToAsyncStorage = async (
  value: Record<string, unknown>,
) => {
  await AsyncStorage.setItem(
    ASYNC_STORAGE_REGISTRATION_INFO_KEY,
    JSON.stringify(value),
  );
};

export const removeRegistrationInfoFromAsyncStorage = async () => {
  await AsyncStorage.removeItem(ASYNC_STORAGE_REGISTRATION_INFO_KEY);
};

export const isCognitoTokenStillValid = async () => {
  const expiryTime = await getCognitoExpiryTimeFromAsyncStorage();

  if (expiryTime) {
    return new Date().valueOf() < expiryTime;
  }
  return false;
};

export const validateEmail = (email: string) => {
  const re =
    /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)*[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/; // validates format: string@string.string
  return re.test(email);
};

export const getRedirectInfoFromAsyncStorage = async () => {
  const redirectInfoString = await AsyncStorage.getItem(
    ASYNC_STORAGE_REDIRECT_INFO_KEY,
  );

  if (redirectInfoString) {
    return JSON.parse(redirectInfoString);
  }

  return null;
};

export const setRedirectInfoToAsyncStorage = async (
  value: Record<string, unknown>,
) => {
  await AsyncStorage.setItem(
    ASYNC_STORAGE_REDIRECT_INFO_KEY,
    JSON.stringify(value),
  );
};

export const removeRedirectInfoFromAsyncStorage = async () => {
  await AsyncStorage.removeItem(ASYNC_STORAGE_REDIRECT_INFO_KEY);
};

export const shouldNavigateToAuth = (company?: Company) => {
  // TODO: REGISTRATION POST MVP -- remove the dependency on for full release experience
  if (
    !company ||
    (company &&
      !company.challengePassed &&
      !company.isTestReservation &&
      company.newLoginEnabled)
  ) {
    return true;
  }

  return false;
};
