import {
  isContactChallengeRequired,
  isLoginChallengeRequired,
  isTermsChallengeRequired,
  isVerificationChallengeRequired,
  isWelcomeChallengeRequired,
} from '@/libs/ChallengeHelper';
import { Spacer } from '@components';
import AppConfig from '@config';
import { trackEvent } from '@libs';
import useLocalization from '@localizations';
import { useNavigation } from '@react-navigation/native';
import { Company, Theme, dispatchUpdateCompany } from '@store';
import { OpertoLogo } from '@svgs';
import {
  FunctionComponent,
  ReactNode,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
} from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Button, Text, useTheme } from 'react-native-paper';

// @@ Header view
interface IHeaderViewProps {
  theme?: Theme;
  logo?: ReactNode;
}
export const HeaderView: FunctionComponent<IHeaderViewProps> = ({
  theme,
  logo,
}) => {
  const themeProvider = useTheme();

  const adjustHeight = (imagesEnabled: boolean) => {
    let height;
    if (AppConfig.Styles.isMobile()) {
      if (imagesEnabled) {
        height = ((AppConfig.Styles.getWindowWidth() * 0.5) / 8) * 5;
      } else {
        height = ((AppConfig.Styles.getWindowWidth() * 0.8) / 8) * 5;
      }
    } else if (imagesEnabled) {
      height = (AppConfig.Styles.getAdjustedSize(250) / 8) * 5;
    } else {
      height = (AppConfig.Styles.getAdjustedSize(400) / 8) * 5;
    }
    return height;
  };

  const adjustWidth = (imagesEnabled: boolean) => {
    let width;
    if (AppConfig.Styles.isMobile()) {
      if (imagesEnabled) {
        width = AppConfig.Styles.getWindowWidth() * 0.5;
      } else {
        width = AppConfig.Styles.getWindowWidth() * 0.8;
      }
    } else if (imagesEnabled) {
      width = AppConfig.Styles.getAdjustedSize(250);
    } else {
      width = AppConfig.Styles.getAdjustedSize(400);
    }
    return width;
  };

  return (
    <View
      style={[
        AppConfig.Styles.center,
        {
          marginBottom: theme?.welcomeImagesEnabled
            ? AppConfig.Styles.getAdjustedSize(20)
            : AppConfig.Styles.getAdjustedSize(10),
        },
      ]}>
      <>
        {!logo && theme?.enabled && theme?.welcomeLogoUrl && (
          <Image
            style={{
              height: adjustHeight(theme.welcomeImagesEnabled),
              width: adjustWidth(theme.welcomeImagesEnabled),
              resizeMode: 'contain',
            }}
            source={{
              uri: theme.welcomeLogoUrl,
            }}
          />
        )}
        {logo && (
          <View
            style={[
              AppConfig.Styles.centeredContainer,
              // AppConfig.Styles.center,
              {
                height: AppConfig.Styles.getAdjustedSize(50),
                width: AppConfig.Styles.getAdjustedSize(50),
                marginBottom: AppConfig.Styles.getAdjustedSize(10),
              },
            ]}>
            {logo}
          </View>
        )}
        {!theme?.enabled && !theme?.welcomeLogoUrl && !logo && (
          <OpertoLogo
            fill={themeProvider.colors.primary}
            width={theme?.welcomeImagesEnabled ? 120 : 200}
          />
        )}
      </>
    </View>
  );
};

// @@ Content view
interface IContentViewProps {
  title: string;
  description: string;
  children?: ReactNode;
}
export const ContentView: FunctionComponent<IContentViewProps> = ({
  title,
  description,
  children,
}) => (
  <View style={AppConfig.Styles.center}>
    {children}
    <Spacer size={2} />
    <Text style={[AppConfig.Styles.headline6]}>{title}</Text>
    <Spacer />
    <Text
      style={[
        AppConfig.Styles.subtitle2,
        AppConfig.Styles.textAlignCenter,
        AppConfig.Styles.lighterText,
      ]}>
      {description}
    </Text>
    <Spacer />
  </View>
);

export interface IButtonProps {
  company?: Company;
  currentPage: string;
  disabled?: boolean;
  backButton?: boolean;
  loading?: boolean;
  onSubmit?: () => Promise<boolean>;
  customNextText?: string;
}

export type ButtonViewHandle = {
  submit: () => void;
};

// @@ Button view
export const ButtonView = forwardRef<ButtonViewHandle, IButtonProps>(
  function ButtonView(
    {
      company,
      currentPage,
      backButton = true,
      disabled = false,
      loading = false,
      onSubmit = () => true,
      customNextText,
    },
    ref,
  ) {
    const { t } = useLocalization();
    const navigation = useNavigation();

    // Grab the next and previous pages with step number
    const { pages, currentIndex } = useMemo(() => {
      const requiredPages = [];
      if (isLoginChallengeRequired(company)) {
        requiredPages.push('Challenge');
      }

      if (isVerificationChallengeRequired(company)) {
        requiredPages.push('Verification');
      } else if (!company?.challengePassed) {
        if (isTermsChallengeRequired(company)) {
          requiredPages.push('Terms');
        }

        if (isWelcomeChallengeRequired(company)) {
          requiredPages.push('Welcome');
        }

        if (isContactChallengeRequired(company)) {
          requiredPages.push('Contact');
        }

        if (requiredPages.length) {
          requiredPages.push('Summary');
        }
      }

      const index = requiredPages.findIndex(page => page === currentPage);

      return {
        pages: requiredPages,
        currentIndex: index,
      };
    }, [company, currentPage]);

    const navigateNextPage = useCallback(() => {
      let nextPage = '';
      if (currentIndex + 1 < pages.length) {
        nextPage = `Login${pages[currentIndex + 1]}`;
      }

      if (nextPage?.length) {
        trackEvent('Welcome', `${pages?.[currentIndex + 1]}:Clicked`, {
          step: currentIndex + 1,
        });
        navigation.navigate(nextPage);
      } else {
        if (company) {
          // Update company can now proceed to home screen
          dispatchUpdateCompany({
            ...company,
            accessed: true,
            challengePassed: true,
            loginStepsInProgress: false,
          });
        }
      }
    }, [company, currentIndex, navigation, pages]);

    const onPress = useCallback(async () => {
      const resp = await onSubmit?.();

      // Prevent page change if
      if (resp === false) {
        return false;
      }

      navigateNextPage();

      return true;
    }, [navigateNextPage, onSubmit]);

    const canSkip = (): boolean => {
      // Kept for future usage. at this point skip button is not requried in any cases.
      return false;
    };

    useImperativeHandle(
      ref,
      () => ({
        submit: onPress,
      }),
      [onPress],
    );

    return (
      <View style={[{ flexShrink: 1 }, AppConfig.Styles.justifyCenter]}>
        <Button
          contentStyle={[styles.buttonContent]}
          mode="contained"
          disabled={disabled}
          loading={loading}
          onPress={onPress}>
          {loading ? '' : customNextText ?? t('next')}
        </Button>
        <View
          style={[
            AppConfig.Styles.alignCenter,
            styles.stepsContainer,
            { marginVertical: 20 },
          ]}>
          {backButton && navigation.canGoBack() && (
            <Button
              onPress={navigation.goBack}
              style={[styles.backButton]}
              textColor="grey">
              {t('back')}
            </Button>
          )}

          {pages.length > 1 && (
            <Text
              style={[
                AppConfig.Styles.container,
                AppConfig.Styles.caption,
                {
                  textAlign: 'center',
                },
              ]}>
              {t('step')} {currentIndex + 1} {t('of')} {pages.length}
            </Text>
          )}

          {canSkip() && (
            <Button
              style={[styles.skipButton]}
              onPress={navigateNextPage}
              textColor="grey">
              {t('skip')}
            </Button>
          )}
        </View>
      </View>
    );
  },
);

const styles = StyleSheet.create({
  buttonContent: {
    width: '100%',
    height: AppConfig.Styles.buttonHeight,
  },
  stepsContainer: {
    flexDirection: 'row',
  },
  backButton: {
    position: 'absolute',
    left: 0,
  },
  skipButton: {
    position: 'absolute',
    right: 0,
  },
});
