import {
  BorderedListItem,
  ContentView,
  INavigationParamsProps,
  Spacer,
} from '@components';
import AppConfig from '@config';
import { unixTimestampToDate } from '@libs';
import useLocalization from '@localizations';
import { useNavigation } from '@react-navigation/native';
import { HouseManual as HouseManualModel, useAppStore } from '@store';
import { useCallback } from 'react';

const canShowHouseManualItem = (manual?: HouseManualModel): boolean => {
  if (manual) {
    const { instructions, imageUrl = '', videoUrl = '' } = manual;
    return (
      instructions?.length > 0 || imageUrl?.length > 0 || videoUrl?.length > 0
    );
  }

  return false;
};

export const HouseManual = () => {
  const { t } = useLocalization();
  const navigation = useNavigation();
  const { company, reservation } = useAppStore();

  const onManualPress = useCallback(
    (params: INavigationParamsProps) =>
      navigation.navigate('ManualDetail', params),
    [navigation],
  );

  return (
    <ContentView
      title={t('house_manual_title')}
      description={t('house_manual_content')}>
      {canShowHouseManualItem(company?.houseManual?.arrivalInstructions) && (
        <>
          <BorderedListItem
            title={t('arrival_instructions')}
            description={
              reservation
                ? `${t('check_in')}: ${unixTimestampToDate(
                    reservation.checkin,
                  )}`
                : ''
            }
            icon="airplane-landing"
            color={AppConfig.Colors.ICON_ARRIVAL}
            onPress={() =>
              onManualPress({
                title: t('arrival_instructions'),
                headerBackgroundImage: 'header_arrival',
                contentTitle: t('arrival_instruction_header'),
                model: company?.houseManual?.arrivalInstructions,
              })
            }
          />
          <Spacer size={1} />
        </>
      )}

      {canShowHouseManualItem(company?.houseManual?.departureInstructions) && (
        <>
          <BorderedListItem
            title={t('departure_instructions')}
            description={
              reservation
                ? `${t('check_out')}: ${unixTimestampToDate(
                    reservation.checkout,
                  )}`
                : ''
            }
            icon="airplane-takeoff"
            color={AppConfig.Colors.ICON_DEPARTURE}
            onPress={() =>
              onManualPress({
                title: t('departure_instructions'),
                headerBackgroundImage: 'header_departures',
                contentTitle: t('departure_instruction_header'),
                model: company?.houseManual?.departureInstructions,
              })
            }
          />
          <Spacer size={1} />
        </>
      )}

      {canShowHouseManualItem(company?.houseManual?.entertainment) && (
        <>
          <BorderedListItem
            title={t('entertainment')}
            description={t('entertainment_subtitle')}
            icon="wifi"
            color={AppConfig.Colors.ICON_ENTERTAINMENT}
            onPress={() =>
              onManualPress({
                title: t('entertainment'),
                headerBackgroundImage: 'header_entertainment',
                contentTitle: t('entertainment_subtitle'),
                model: company?.houseManual?.entertainment,
              })
            }
          />
          <Spacer size={1} />
        </>
      )}

      {canShowHouseManualItem(company?.houseManual?.rules) && (
        <BorderedListItem
          title={t('house_rules')}
          description={t('house_rules_subtitle')}
          icon="fire"
          color={AppConfig.Colors.ICON_HOUSE_RULES}
          onPress={() =>
            onManualPress({
              title: t('house_rules'),
              headerBackgroundImage: 'header_house_rules',
              contentTitle: t('house_rules_subtitle'),
              model: company?.houseManual?.rules,
            })
          }
        />
      )}
    </ContentView>
  );
};

export default HouseManual;
