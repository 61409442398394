import { getValidColor, isColorFormatValid } from '@/libs/ColorHelper';
import { DecodeHtmlFormat } from '@/libs/StringFormat';
import { INavigationParamsProps } from '@/screens/components';

type ContentButton = {
  color: string;
  title: string;
  enabled: boolean;
  subject: string;
  hyperlink: string;
  autoreply_message: string;
  messenger_enabled: boolean;
};

type OrderObject = {
  type: 'category' | 'content';
  id: string;
};

export type GuidesState = {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  message: string | null;
  sections: SectionItem[];
  categories: CategoryItem[];
  categoriesOrder: OrderObject[];
  contents: ContentItem[];
  uncategorized: ContentItem[];
};

export type SectionItem = {
  row_num: number;
  property_id: number | null;
  section: string;
  published: boolean;
  created_at: string;
  created_by: string;
  section_id: string;
  last_edit_by: string;
  featured_list: string[];
  section_color: string;
  section_cover: string;
};

export type SectionRequestHeader = {
  propertyId: number | string | null | undefined;
  locale: string | undefined;
  companyCode: string;
};

export type CategoryRequestHeader = {
  propertyId: number | string | null | undefined;
  sectionId: string | null | undefined;
  locale: string | undefined;
  companyCode: string;
};

export type ContentRequestHeader = {
  propertyId: number | string | null | undefined;
  sectionId: string | null | undefined;
  categoryId: string | null | undefined;
  locale: string | undefined;
  companyCode: string;
};

export type CategoryItem = {
  category_id: string;
  property_id: number | null;
  category: string;
  published: boolean;
  scheduled: boolean;
  last_edit_at: number;
  last_edit_by: string;
  scheduled_end: number;
  scheduled_start: number;
  featured: boolean | null;
  category_cover: string | null;
  section_id: string | null;
  section_color: string | null;
  contents?: string; // NOTE: this is a json stringified array of content items for uncategorized type
};

export type ContentItem = {
  url: string;
  email: string | null;
  phone: string;
  title: string;
  address: string;
  enabled: boolean;
  published: boolean;
  latitude: string;
  subtitle: string;
  images_url: string[];
  longitude: string;
  scheduled: boolean;
  video_url: string;
  content_id: string;
  description: string;
  last_edit_at: string;
  last_edit_by: string;
  scheduled_end: string | null;
  scheduled_start: string | null;
  featured: boolean | null;
  button: ContentButton;
  section_id: string | null;
  section_color: string | null;
  category?: string | null;
  category_id: string;
};

const getCommonNavParams = (overrides: INavigationParamsProps) => ({
  headerHeight: 72,
  titleFontSize: 20,
  titleMarginLeft: 46,
  titleMarginBottom: 12,
  ...overrides,
});

export const getNavigationParamsForSection = (item: SectionItem) => ({
  sectionId: item.section_id,
  ...getCommonNavParams({
    title: item?.section ? DecodeHtmlFormat(item?.section) : 'no name',
    titleFontSize: 36,
    titleMarginLeft: 0,
    titleMarginBottom: 32,
    headerHeight: 358,
    headerBackgroundImage: item?.section_cover ?? undefined,
    headerBackgroundColor: isColorFormatValid(item?.section_color)
      ? getValidColor(item?.section_color ?? '')
      : undefined,
  }),
});

export const getNavigationParamsForCategory = (item: CategoryItem) => ({
  sectionId: item.section_id,
  categoryId: item.category_id,
  ...getCommonNavParams({
    title: item?.category ? DecodeHtmlFormat(item?.category) : 'no name',
    titleFontSize: 36,
    titleMarginLeft: 0,
    titleMarginBottom: 32,
    headerHeight: 358,
    headerBackgroundImage: item?.category_cover ?? undefined,
    headerBackgroundColor: isColorFormatValid(item?.section_color)
      ? getValidColor(item?.section_color ?? '')
      : undefined,
  }),
});

export const getNavigationParamsForContent = (item: ContentItem) => ({
  sectionId: item.section_id,
  categoryId: item.category_id,
  contentId: item.content_id,
  ...getCommonNavParams({
    title: item?.title ? DecodeHtmlFormat(item?.title) : 'no name',
    headerBackgroundColor: isColorFormatValid(item?.section_color)
      ? getValidColor(item?.section_color ?? '')
      : undefined,
  }),
});
