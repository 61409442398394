import { logger } from '@/libs/logger';
import AppConfig from '@config';
import {
  determineReservationState,
  getAccessCode,
  reservationBannerStatus,
  trackEvent,
} from '@libs';
import useLocalization from '@localizations';
import { useAppStore } from '@store';
import { useEffect } from 'react';
import { View } from 'react-native';
import { Caption, Headline, useTheme } from 'react-native-paper';

export const ReservationHeader = () => {
  const { reservation, company } = useAppStore();
  const { t } = useLocalization();
  const theme = useTheme();
  const reservationState = determineReservationState(reservation);

  useEffect(() => {
    if (reservationState === 'departure') {
      logger.info('Reservation is departed');

      trackEvent('Home', 'PostStay:Page Load');
    }
  }, [reservationState]);

  if (!company || !reservation) {
    return null;
  }

  return (
    <>
      <View
        style={[
          AppConfig.Styles.rowContainer,
          {
            backgroundColor: theme.colors.primary,
          },
        ]}>
        <View
          style={{
            width: '60%',
            justifyContent: 'center',
            padding: 20,
          }}>
          <Headline style={{ color: 'white' }}>
            {t(
              reservationState === 'arrival'
                ? 'booking_arrival_title'
                : 'departure',
            )}
          </Headline>
          <Caption style={{ color: 'white' }}>
            {`${
              reservationState === 'arrival'
                ? reservation?.timeToArrival || ''
                : reservation?.timeToDeparture || ''
            } ${
              reservationState === 'arrival'
                ? t('booking_arrival_checkin-ticker')
                : t('until_check_out')
            }`}
          </Caption>
        </View>
        <View
          style={{
            width: '40%',
            justifyContent: 'center',
            alignItems: 'flex-end',
            padding: 20,
          }}>
          {company?.accessCodeControlEnabled && !reservation?.smw && (
            <>
              <View
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  backgroundColor: '#00000033',
                }}
              />
              <Headline style={{ color: 'white', zIndex: 1 }}>
                {getAccessCode(
                  company,
                  reservation,
                  reservationBannerStatus(company, reservation),
                )}
              </Headline>
              <Caption style={{ color: 'white', zIndex: 1 }}>
                {t('door_access_code')}
              </Caption>
            </>
          )}

          {reservation?.smw && (
            <>
              <View
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  backgroundColor: '#00000033',
                }}
              />
              <Headline style={{ color: 'white', zIndex: 1 }}>
                {reservation?.smw?.booking_code}
              </Headline>
              <Caption style={{ color: 'white', zIndex: 1 }}>
                {t('booking_code')}
              </Caption>
            </>
          )}
        </View>
      </View>
    </>
  );
};
