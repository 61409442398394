import { Rating, Spacer } from '@components';
import AppConfig from '@config';
import useLocalization from '@localizations';
import { useNavigation } from '@react-navigation/native';
import { Amenity, NearMe } from '@store';
import React, { useCallback } from 'react';
import { Linking, View, ViewProps } from 'react-native';
import {
  Button,
  Caption,
  Card,
  IconButton,
  Title,
  useTheme,
} from 'react-native-paper';
import { INavigationParamsProps } from '../components';

export const sharePlace = (place: Amenity, body: string) => {
  Linking.openURL(
    `mailto:?subject=${place?.title}&body=${body} ${
      place?.url?.length > 0 ? place?.url : 'www.operto.com'
    }`,
  );
};

type PlaceCardProps = ViewProps & {
  place: NearMe;
};
export const PlaceCard: React.FC<PlaceCardProps> = props => {
  const { place } = props;
  const navigation = useNavigation();
  const { t } = useLocalization();
  const theme = useTheme();

  const onPlacePress = useCallback(
    () =>
      navigation.navigate('Place', {
        headerBackgroundImage: place?.imageUrl,
        headerHeight: 250,
        model: place,
      } as INavigationParamsProps),
    [navigation, place],
  );

  return (
    <Card
      elevation={3}
      style={{
        marginVertical: AppConfig.Styles.getAdjustedSize(10),
      }}>
      <Card.Cover source={{ uri: place?.imageUrl }} />
      <Spacer size={2} />
      <Card.Content>
        {place?.title?.length > 0 && <Title>{place?.title}</Title>}
        <Spacer size={1} />
        <Rating rating={place.rating} />
        <Spacer size={1} />
        {place?.subtitle?.length > 0 && (
          <Caption>{`${place?.subtitle}`}</Caption>
        )}
        {place?.description?.length > 0 && (
          <Caption>{place?.description}</Caption>
        )}
      </Card.Content>
      <Card.Actions>
        <Button onPress={onPlacePress}>{t('more')}</Button>
        <View style={AppConfig.Styles.container} />
        {false && ( // GP-201: Hide Share button on React GP
          <IconButton
            icon="share-variant"
            iconColor={theme.colors.primary}
            size={AppConfig.Styles.getAdjustedSize(25)}
            onPress={() => sharePlace(place, t('amenities_framing_text'))}
          />
        )}
      </Card.Actions>
    </Card>
  );
};

export default PlaceCard;
