import { logger } from '@/libs/logger';
import { useAppContext } from '@/store';
import { dispatchReset } from '@/store/actions/utils';
import {
  GuestUser,
  useCreateGuestUserMutation,
  useGetGuestUserQuery,
} from '@/store/services/userApi';
import AppConfig from '@config';
import useLocalization from '@localizations';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { ParamListBase, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useState } from 'react';
import { KeyboardAvoidingView, Linking, StyleSheet, View } from 'react-native';
import {
  Button,
  HelperText,
  Text,
  TextInput,
  TouchableRipple,
  useTheme,
} from 'react-native-paper';
import UpdateGuestProfile from './UpdateGuestProfile';

const TERMS_CONDITIONS_URL = 'https://operto.com/company/msa/';
const PRIVARY_POLICY_URL = 'https://operto.com/company/privacy/';

const GuestProfileMain = () => {
  const navigator = useNavigation<StackNavigationProp<ParamListBase>>();
  const { t } = useLocalization();
  const { colors } = useTheme();

  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [preferedName, setPreferedName] = useState('');

  const { data: guestServiceUserData, emailFromToken } = useGetGuestUserQuery();

  const [createUser, { isLoading: isCreatingUser }] =
    useCreateGuestUserMutation();
  useAppContext();

  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg] = useState('');

  const agreement_translation = t('signup_t&c');
  const agreement_parts = agreement_translation.split(
    /{terms_conditions_link}|{privacy_policy_link}/,
  );

  const handleSubmitProfile = async () => {
    if (firstname === '' || lastname === '') {
      setShowError(true);
    } else {
      setShowError(false);

      if (!guestServiceUserData?.emailPrimary && emailFromToken) {
        logger.info(
          'User does not exists. Creating user in guest service with email:',
          emailFromToken,
        );

        try {
          setIsLoading(true);
          const newGuest: Omit<GuestUser, 'id'> = {
            emailPrimary: emailFromToken,
            emailPrimaryIsVerified: true,
            emailNotification: true,
            smsNotification: true,
          };

          if (firstname) {
            newGuest.givenName = firstname;
          }

          if (lastname) {
            newGuest.familyName = lastname;
          }

          if (preferedName) {
            newGuest.preferredName = preferedName;
          }

          const localeStorage = await AsyncStorage.getItem('locale');

          if (localeStorage) {
            newGuest.locale = localeStorage;
          }

          await createUser(newGuest).unwrap();
        } catch (error) {
          // TODO: show error message to user in UI
          logger.error('Guest not created in guest service: ' + error);
          setIsLoading(false);
          return;
        }

        navigator.replace('AddPhoneNumber');
      }
    }
  };

  const handleTermsAndConditions = async () => {
    const supported = await Linking.canOpenURL(TERMS_CONDITIONS_URL);
    if (supported) {
      await Linking.openURL(TERMS_CONDITIONS_URL);
    }
  };

  const handlePrivacyPolicy = async () => {
    const supported = await Linking.canOpenURL(PRIVARY_POLICY_URL);
    if (supported) {
      await Linking.openURL(PRIVARY_POLICY_URL);
    }
  };

  const handleBack = async () => {
    dispatchReset();
  };

  const isSubmitting = isLoading || isCreatingUser;

  return (
    <>
      <UpdateGuestProfile
        title={t('sign_up_profile_setup_header')}
        subtitle={t('sign_up_profile_setup_title')}
        onHeaderAction={handleBack}>
        <View>
          <View>
            <TextInput
              testID="first-name-input"
              mode="outlined"
              activeOutlineColor={colors.primary}
              label={t('signup_first_name')}
              style={styles.inputText}
              error={showError && !firstname}
              onChangeText={setFirstname}
            />
            {showError && !firstname && (
              <HelperText type="error" visible={showError}>
                {t('signup_first_name_error')}
              </HelperText>
            )}
          </View>
          <View>
            <TextInput
              testID="preferred-name-input"
              mode="outlined"
              activeOutlineColor={colors.primary}
              label={t('signup_preferred_name')}
              style={styles.inputText}
              onChangeText={setPreferedName}
            />
          </View>
          <View>
            <TextInput
              testID="last-name-input"
              mode="outlined"
              activeOutlineColor={colors.primary}
              label={t('signup_last_name')}
              style={styles.inputText}
              error={showError && !lastname}
              onChangeText={setLastname}
            />
            {showError && !lastname && (
              <HelperText type="error" visible={showError}>
                {t('signup_last_name_error')}
              </HelperText>
            )}
          </View>
          <View style={styles.tandCContainer}>
            <Text style={styles.tandCText}>
              {agreement_parts[0]}
              <TouchableRipple
                testID="terms-and-conditions-link"
                onPress={handleTermsAndConditions}
                style={{ marginHorizontal: 2 }}>
                <Text style={styles.link}>{t('guest_profile_terms')}</Text>
              </TouchableRipple>
              {agreement_parts[1]}
              <TouchableRipple
                testID="privacy-policy-link"
                onPress={handlePrivacyPolicy}
                style={{ marginHorizontal: 2 }}>
                <Text style={styles.link}>{t('guest_profile_privacy')}</Text>
              </TouchableRipple>
              {agreement_parts[2]}
            </Text>
          </View>
        </View>
        <HelperText type="error" visible={showError}>
          {errorMsg}
        </HelperText>
      </UpdateGuestProfile>
      <KeyboardAvoidingView style={styles.footer}>
        <Button
          mode="contained"
          buttonColor={colors.primary}
          loading={isSubmitting}
          onPress={handleSubmitProfile}>
          <Text variant="labelLarge" style={styles.footerButton}>
            {t('agree_and_continue')}
          </Text>
        </Button>
      </KeyboardAvoidingView>
    </>
  );
};

const styles = StyleSheet.create({
  inputText: {
    marginTop: 16,
  },
  tandCText: {
    fontWeight: '400',
    lineHeight: 24,
    fontSize: 16,
    letterSpacing: 0.5,
  },
  tandCContainer: {
    marginTop: 16,
  },
  link: {
    textDecorationLine: 'underline',
    color: AppConfig.Colors.PRIMARY,
  },
  footer: {
    padding: 16,
    backgroundColor: AppConfig.Colors.ON_SECONDARY,
    borderTopWidth: 1,
    borderTopColor: '#C3C5D9',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
  },
  footerButton: { color: AppConfig.Colors.ON_SECONDARY },
});

export default GuestProfileMain;
