import AppConfig from '@config';
import {
  getAccessCode,
  isRoomType,
  reservationBannerStatus,
  trackEvent,
} from '@libs';
import useLocalization from '@localizations';
import { useNavigation } from '@react-navigation/native';
import { dispatchGetNearMe, useAppStore } from '@store';
import { KeyIcon } from '@svgs';
import { useState } from 'react';
import { StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';
import { Button, Caption, Icon, Text, useTheme } from 'react-native-paper';
import { mobileKeyDisabled } from '../home/AccessCodeActionSheet';
import { EntranceInstructionsActionSheet } from '../home/EntranceInstructionsActionSheet';
import SnackBar from './SnackBar';

const renderKeyIcon = () => <KeyIcon style={{ width: 22, height: 12 }} />;

const TabItem = ({
  title,
  icon,
  onPress,
}: {
  title: string;
  icon: string;
  onPress: () => void;
}) => {
  return (
    <TouchableOpacity onPress={onPress} style={styles.button}>
      <Icon
        source={icon}
        color="white"
        size={AppConfig.Styles.getAdjustedSize(25)}
      />
      <Caption style={{ color: '#fff', fontSize: 10 }}>{title}</Caption>
    </TouchableOpacity>
  );
};

const GlobalBottomBar = ({
  bottomBarStyle,
}: {
  bottomBarStyle?: ViewStyle;
}) => {
  const navigation = useNavigation();
  const theme = useTheme();
  const { company, reservation } = useAppStore();
  const { t } = useLocalization();
  const [modalVisible, setModalVisible] = useState<
    'EntranceInstructions' | 'AccessCode' | 'StayMyWay'
  >();
  const [snackBarOptions, setSnackBarOptions] = useState({
    title: '',
    color: AppConfig.Colors.ERROR,
    visible: false,
  });
  const isNearMeEnabled = company?.nearmeEnabled;
  const isEntranceInstructionsEnabled =
    reservation?.entranceInstructions.enabled;
  const isLockButtonEnabled =
    !isRoomType(reservation) &&
    (company?.accessCodeControlEnabled ||
      reservation?.mobile_key_button_enabled ||
      mobileKeyDisabled(company, reservation));

  const navigationRoutes = navigation?.getState?.()?.routes;
  const isOnMessengerScreen =
    navigationRoutes?.[navigationRoutes.length - 1]?.name === 'Messages';

  if (isOnMessengerScreen) {
    return <></>;
  }

  return (
    <>
      <View style={[styles.container, bottomBarStyle]}>
        <TabItem
          title={t('booking_button_title')}
          icon="bag-suitcase-outline"
          onPress={() => {
            trackEvent('Footer', 'Home:Clicked');
            navigation.navigate('Home');
          }}
        />

        <TabItem
          title={t('booking_guides_button_title')}
          icon="bookmark-box-multiple-outline"
          onPress={() => {
            trackEvent('Footer', 'Home:Clicked');
            navigation.navigate('SectionsList');
          }}
        />

        {isNearMeEnabled && (
          <TabItem
            title={t('booking_local_button_title')}
            icon="map-marker-outline"
            onPress={() => {
              setSnackBarOptions({
                title: t('please_wait_while_data_loading'),
                color: theme.colors.primary,
                visible: true,
              });
              trackEvent('Footer', 'Near Me:Clicked');
              dispatchGetNearMe(company)
                .then(nearmeList => {
                  if (nearmeList?.length > 0) {
                    setSnackBarOptions({
                      title: '',
                      color: theme.colors.primary,
                      visible: false,
                    });
                    navigation.navigate('Places');
                  } else {
                    setSnackBarOptions({
                      title: `${t('no_google_activities')}`,
                      color: theme.colors.primary,
                      visible: true,
                    });
                  }
                })
                .catch(_e => {
                  setSnackBarOptions({
                    title: `${t('error_activities')}`,
                    color: AppConfig.Colors.ERROR,
                    visible: true,
                  });
                });
            }}
          />
        )}

        {company?.mpGuestMessengerEnabled && (
          <TabItem
            title={t('booking_inbox_button_title')}
            icon="message-outline"
            onPress={() => {
              navigation.navigate('Messages');
              trackEvent('Footer', 'Messages:Clicked');
            }}
          />
        )}

        {(company?.accessCodeControlEnabled ||
          reservation?.mobile_key_button_enabled) && (
          <View>
            <View
              style={{
                position: 'absolute',
                top: -66,
                right: 16,
              }}>
              <Button
                icon={renderKeyIcon}
                mode="elevated"
                testID="key-fab"
                disabled={!isLockButtonEnabled}
                style={{
                  borderRadius: 16,
                  backgroundColor: 'rgb(0, 0, 0)',
                  height: 56,
                  width: 129,
                  padding: 0,
                  justifyContent: 'center',
                  shadowColor: 'rgb(0, 0, 0)',
                  shadowOffset: { width: 1, height: 3 },
                  shadowRadius: 10,
                  shadowOpacity: 0.3,
                }}
                onPress={() => {
                  if (isEntranceInstructionsEnabled) {
                    setModalVisible('EntranceInstructions');
                  } else if (reservation && reservation?.smw) {
                    setModalVisible('StayMyWay');
                  } else {
                    setModalVisible('AccessCode');
                  }
                  trackEvent('Global', 'Lock Button:Clicked');
                }}>
                <Text style={{ color: 'white' }}>
                  {getAccessCode(
                    company,
                    reservation,
                    reservationBannerStatus(company, reservation),
                  )}
                </Text>
              </Button>
            </View>
          </View>
        )}
        <EntranceInstructionsActionSheet
          reservation={reservation}
          modalType={modalVisible}
          onModalVisibleChange={setModalVisible}
        />
      </View>
      <SnackBar
        {...snackBarOptions}
        onDismiss={() =>
          setSnackBarOptions({ ...snackBarOptions, visible: false })
        }
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    // @ts-ignore - RN web supports sticky
    position: 'sticky',
    flexShrink: 0,
    bottom: '0',
    width: '100%',
    flexDirection: 'row',
    flexGrow: 1,
    borderTopWidth: StyleSheet.hairlineWidth,
    backgroundColor: 'rgb(0, 0, 0)',
  },
  button: {
    flex: 1,
    alignItems: 'center',
    paddingVertical: AppConfig.Styles.getAdjustedSize(10),
  },
});

export default GlobalBottomBar;
