import { ChatToken, Company, Reservation } from '../models/models';

// APP ACTIONS CONSTANTS
export const COMPANIES = 'companies';
export const RESERVATIONS = 'reservations';

export const RESET = 'reset';
export const CHATTOKEN = 'chatToken';

export const OPEN_LANGUAGE_PANEL = 'openLanguagePanel';
export const CLOSE_LANGUAGE_PANEL = 'closeLanguagePanel';

export const SET_ID_TOKEN = 'setIdToken';

//
export type AppAction = {
  type:
    | typeof COMPANIES
    | typeof RESERVATIONS
    | typeof RESET
    | typeof CHATTOKEN;
  data?: Company[] | Reservation[] | ChatToken[];
  deletedIds?: string[];
};
