import { useAppDispatch, useAppSelector } from '@store';
import { useCallback } from 'react';
import { selectAuth, setToken } from './slice';

export type GuidesSectionsRouteParams = {
  sectionId: string;
};

export const useAuth = () => {
  const dispatch = useAppDispatch();
  const authState = useAppSelector(selectAuth);

  const setIdToken = useCallback(
    ({ idToken, tokenType }: { idToken: string; tokenType: string }) => {
      dispatch(setToken({ idToken, tokenType }));
    },
    [dispatch],
  );

  return {
    authState,
    setIdToken,
  };
};
