export const isIOS = () => {
  return /iPad|iPhone|iPod/.test(
    navigator.userAgent.length ? navigator.userAgent : navigator.vendor,
  );
};

export const isAndroid = () => {
  return /android/i.test(
    navigator.userAgent.length ? navigator.userAgent : navigator.vendor,
  );
};

export const getBrowserLocale = () => {
  return navigator.language.substring(0, 2) ?? 'unknown';
};
