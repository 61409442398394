import AppConfig from '@config';
import { StyleSheet, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Icon, Text } from 'react-native-paper';

interface PhoneNumberVerificationProps {
  children?: React.ReactNode;
  title?: string;
  subtitle?: string;
  headerType?: 'cancel' | 'previous';
  onHeaderAction?: () => void;
}

const PhoneNumberVerification = ({
  children,
  title,
  subtitle,
  headerType = 'cancel',
  onHeaderAction,
}: PhoneNumberVerificationProps) => {
  return (
    <View style={[styles.container, styles.center]}>
      <View style={styles.container}>
        <View style={styles.header}>
          {headerType === 'previous' && (
            <TouchableOpacity testID="previous-btn" onPress={onHeaderAction}>
              <Icon
                source="arrow-left"
                size={24}
                color={AppConfig.Colors.TERTIARY}
              />
            </TouchableOpacity>
          )}
          {headerType === 'cancel' && (
            <TouchableOpacity testID="cancel-btn" onPress={onHeaderAction}>
              <Icon
                source="close"
                size={24}
                color={AppConfig.Colors.TERTIARY}
              />
            </TouchableOpacity>
          )}
        </View>
        <View style={styles.innerContainer}>
          {title ? <Text style={styles.title}>{title}</Text> : null}
          {subtitle ? <Text style={styles.subtitle}>{subtitle}</Text> : null}
          {children}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: AppConfig.Colors.ON_SECONDARY,
    padding: 15,
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    width: '100%',
    justifyContent: 'flex-start',
    paddingBottom: 16,
  },
  innerContainer: {
    flex: 1,
    width: '100%',
  },
  title: {
    fontSize: 24,
    paddingBottom: 24,
  },
  subtitle: {
    fontSize: 16,
    letterSpacing: 0.25,
    paddingBottom: 16,
  },
});

export default PhoneNumberVerification;
