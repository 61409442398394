import YourKeyIconBlack from './MK_key_black.svg';
import YourKeyIconWhite from './MK_key_white.svg';
import PaymentsIcon from './PaymentsIcon.svg';
import PriceFrameBGIcon from './PriceDetailsFrame.svg';
import SecurityDepositIcon from './SecurityDepositIcon.svg';
import VERIFICATIONComplete from './VERIFICATIONComplete.svg';
import VERIFICATIONFingerprint from './VERIFICATIONFingerprint.svg';
import VERIFICATIONLicenseBack from './VERIFICATIONLicenseBack.svg';
import VERIFICATIONLicenseFront from './VERIFICATIONLicenseFront.svg';
import VERIFICATIONProcessing from './VERIFICATIONProcessing.svg';
import VERIFICATIONUploadPhoto from './VERIFICATIONUploadPhoto.svg';
import AppleLogo from './apple-logo.svg';
import BubbleLoading from './bubble-loading.svg';
import Draw from './draw.svg';
import ErrorIcon from './error_icon.svg';
import ErrorIcon404 from './error_icon_404.svg';
import FacebookLogo from './facebook-logo.svg';
import FrontDoorIcon from './front_door.svg';
import GooglePlusLogo from './google-logo.svg';
import KeyIcon from './key_icon.svg';
import KeyIconOutline from './key_icon_outline.svg';
import Keyboard from './keyboard.svg';
import LoadingIcon from './loading_icon.svg';
import LoginChallengeLogo from './login_challenge.svg';
import LoginContactLogo from './login_contact.svg';
import LoginVerificationLogo from './login_verification.svg';
import LoginWelcomeLogo from './login_welcome.svg';
import MagicFingerLogo from './magicFinger.svg';
import OpertoIcon from './operto_icon.svg';
import OpertoLogo from './operto_logo.svg';
import QRCodeOverlay from './qr_overlay.svg';
import RoomIcon from './room_icon.svg';
import SuccessIcon from './success_icon.svg';
import UnitDoorIcon from './unit_door.svg';

export {
  AppleLogo,
  BubbleLoading,
  Draw,
  ErrorIcon,
  ErrorIcon404,
  FacebookLogo,
  FrontDoorIcon,
  GooglePlusLogo,
  KeyIcon,
  KeyIconOutline,
  Keyboard,
  LoadingIcon,
  LoginChallengeLogo,
  LoginContactLogo,
  LoginVerificationLogo,
  LoginWelcomeLogo,
  MagicFingerLogo,
  OpertoIcon,
  OpertoLogo,
  PaymentsIcon,
  PriceFrameBGIcon,
  QRCodeOverlay,
  RoomIcon,
  SecurityDepositIcon,
  SuccessIcon,
  UnitDoorIcon,
  VERIFICATIONComplete,
  VERIFICATIONFingerprint,
  VERIFICATIONLicenseBack,
  VERIFICATIONLicenseFront,
  VERIFICATIONProcessing,
  VERIFICATIONUploadPhoto,
  YourKeyIconBlack,
  YourKeyIconWhite,
};
