import AppConfig from '@config';
import React, { useEffect } from 'react';
import { App } from '../src';

// Generate required css
const iconFonts = require('react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf');

const iconFontStyles = `@font-face {
  font-family: 'MaterialCommunityIcons';
  src: url('${iconFonts.default}') format('truetype');
}`;

export default () => {
  useEffect(() => {
    // Inject stylesheet
    const element = document.createElement('style') as HTMLStyleElement;
    if (element.sheet) {
      element.sheet.cssRules[0].cssText = iconFontStyles;
    } else {
      element.appendChild(document.createTextNode(iconFontStyles));
    }
    document.head.appendChild(element);

    // add script with google map api key
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${AppConfig.Settings.GOOGLE_API_KEY}`;
    script.async = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(element);
      document.head.removeChild(script);
    };
  });

  return <App />;
};
