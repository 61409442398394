import { getValidColor } from '@/libs/ColorHelper';
import AppConfig from '@config';
import {
  FlatList,
  Linking,
  ListRenderItemInfo,
  StyleSheet,
  ViewStyle,
} from 'react-native';
import { Button } from 'react-native-paper';
import { Spacer } from './Spacer';

export type ActionButton = {
  title: string;
  action: 'tel' | 'email' | 'url';
  value: string;
  color: string;
};

type ActionButtonsProps = {
  buttons: ActionButton[];
  style?: ViewStyle;
};

export const ActionButtons = ({ style, buttons }: ActionButtonsProps) => {
  if (buttons.length === 0) {
    return null;
  }

  const onActionButtonPress = async (item: ActionButton) => {
    let url = '';
    if (item.action === 'tel') {
      url = `tel:${item.value}`;
    } else if (item.action === 'email' && (await Linking.canOpenURL('email'))) {
      url = `mailto:${item.value}`;
    } else if (item.action === 'url' && (await Linking.canOpenURL('http'))) {
      url = item.value;
    }

    if (await Linking.canOpenURL(url)) {
      window.location.href = url;
    }
  };

  const renderItem = ({ item }: ListRenderItemInfo<ActionButton>) => {
    return (
      <Button
        mode="contained"
        contentStyle={styles.button}
        buttonColor={getValidColor(item.color)}
        textColor="white"
        onPress={() => onActionButtonPress(item)}>
        {item.title}
      </Button>
    );
  };

  const renderItemSeparator = () => {
    return <Spacer size={2} />;
  };

  return (
    <FlatList
      style={[styles.list, style]}
      data={buttons}
      keyExtractor={(_item, index) => `${index}`}
      renderItem={renderItem}
      ItemSeparatorComponent={renderItemSeparator}
    />
  );
};

const styles = StyleSheet.create({
  list: {
    width: '100%',
  },
  button: {
    marginHorizontal: AppConfig.Styles.getAdjustedSize(20),
    height: AppConfig.Styles.buttonHeight,
  },
});
