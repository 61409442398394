import * as FullStory from '@fullstory/browser';
import { isProduction } from './constants';

/** Will not work in Office because we are blocking the network request */
export const trackEvent = (
  page: string,
  eventName: string,
  properties: { [key: string]: unknown } = {},
) => {
  const title = `[${page}] ${eventName}`;
  if (isProduction) {
    FullStory.init({ orgId: '12TVFB' });
    FullStory.event(title, properties);
  }

  // Need it for testing on QA, remove before release!!
  // console.log('For QA: ', title, properties);
};
