import { useAppDispatch, useAppSelector } from '@store';
import { useCallback } from 'react';
import {
  closeLanguagePanel,
  openLanguagePanel,
  selectLanguagePanel,
} from './slice';

export const useLanguagePanel = () => {
  const dispatch = useAppDispatch();
  const { status } = useAppSelector(selectLanguagePanel);

  const openPanel = useCallback(() => {
    dispatch(openLanguagePanel());
  }, [dispatch]);

  const closePanel = useCallback(() => {
    dispatch(closeLanguagePanel());
  }, [dispatch]);

  return {
    status,
    openPanel,
    closePanel,
  };
};
