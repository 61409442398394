import AppConfig from '@config';

const HEX_VALUE_REGEX = /^#?([0-9a-f]{3}){1,2}$/i;

export const isColorFormatValid = (color: string | null | undefined) => {
  if (color) {
    return HEX_VALUE_REGEX.test(color);
  }

  return false;
};

export const getValidColor = (color = '') => {
  const copy = `#${String(color).replaceAll('#', '')}`;
  if (isColorFormatValid(copy)) {
    return copy;
  }

  return AppConfig.Colors.PRIMARY;
};
