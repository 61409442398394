import { useGetAppFeaturesQuery } from '@/store/app-features/api-slice';
import { AppFeatureData } from '@/store/app-features/types';

// declare your app features here
const appFeatures: Record<string, AppFeatureData> = {
  upsells: {
    enabled: true,
    includedCompanies: [],
  },
};

type AppFeatureFlags = keyof typeof appFeatures;

export const useAppFeatures = () => {
  const { data: { data = {} } = {}, ...rest } = useGetAppFeaturesQuery();
  const mergedFeatures = { ...appFeatures, ...data };

  const isFeatureEnabled = (feature: AppFeatureFlags, companyId?: number) => {
    const featureData = mergedFeatures[feature];
    if (!featureData || !featureData.enabled) {
      return false;
    }

    if (companyId && featureData.excludedCompanies) {
      return !featureData.excludedCompanies.includes(companyId);
    }

    if (companyId && featureData.includedCompanies) {
      return featureData.includedCompanies.includes(companyId);
    }

    return true;
  };

  return {
    isFeatureEnabled,
    ...rest,
  };
};
