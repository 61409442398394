import AppConfig from '@config';
import React from 'react';
import { Modal, StyleSheet, TouchableHighlight, View } from 'react-native';
import { IconButton, Text } from 'react-native-paper';

interface PopupProps {
  visible: boolean;
  onDismiss?: () => void;
  text: string;
}

const Popup: React.FC<PopupProps> = ({ visible, onDismiss, text }) => (
  <Modal
    animationType="fade"
    transparent
    visible={visible}
    onRequestClose={onDismiss}>
    <TouchableHighlight
      style={[
        AppConfig.Styles.container,
        AppConfig.Styles.bottom,
        { backgroundColor: '#000000AA' },
      ]}
      onPress={onDismiss}>
      <View style={styles.sheetContent}>
        <View style={styles.iconContainer}>
          <IconButton
            style={styles.icon}
            icon="check"
            iconColor="#259D72"
            size={AppConfig.Styles.getAdjustedSize(16)}
          />
        </View>
        <View style={styles.textContainer}>
          <Text style={styles.text}>{text}</Text>
        </View>
      </View>
    </TouchableHighlight>
  </Modal>
);

const styles = StyleSheet.create({
  sheetContent: {
    width: '90%',
    minWidth: AppConfig.Styles.getAdjustedSize(300),
    maxWidth: AppConfig.Styles.responsiveMaxWidth,
    borderRadius: AppConfig.Styles.getAdjustedSize(8),
    padding: AppConfig.Styles.getAdjustedSize(15),
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.25,
    shadowRadius: 5,
    marginBottom: AppConfig.Styles.getAdjustedSize(45),
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: '#259D72',
  },
  iconContainer: { width: '12%' },
  icon: {
    backgroundColor: '#fff',
    width: AppConfig.Styles.getAdjustedSize(20),
    height: AppConfig.Styles.getAdjustedSize(20),
  },
  textContainer: { width: '88%' },
  text: {
    color: '#fff',
    fontSize: AppConfig.Styles.getAdjustedSize(14),
    lineHeight: AppConfig.Styles.getAdjustedSize(21),
  },
});

export default Popup;
