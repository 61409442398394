import { useLocalization } from '@/assets/localizations';
import AppConfig from '@config';
import { ErrorIcon } from '@svgs';
import { StyleSheet, Text, View } from 'react-native';
import { Button } from 'react-native-paper';

interface ErrorProps {
  title?: string;
  description?: string;
  onCancel?: () => void;
  onRetry?: () => void;
}

export const Error = ({
  title,
  description,
  onCancel,
  onRetry,
}: ErrorProps) => {
  const { t } = useLocalization();

  return (
    <View style={AppConfig.Styles.center}>
      <ErrorIcon
        width={AppConfig.Styles.getAdjustedSize(180)}
        height={AppConfig.Styles.getAdjustedSize(180)}
      />
      <Text
        style={[
          AppConfig.Styles.headline6,
          {
            marginTop: AppConfig.Styles.getAdjustedSize(8),
            marginBottom: AppConfig.Styles.getAdjustedSize(16),
          },
        ]}>
        {title ?? t('generic_error')}
      </Text>
      <Text style={[AppConfig.Styles.subtitle2]}>
        {description ?? t('generic_error_description')}
      </Text>
      <View style={[AppConfig.Styles.rowContainer, styles.buttonContainer]}>
        <Button
          style={styles.button}
          onPress={onCancel}
          contentStyle={{
            height: AppConfig.Styles.buttonHeight,
          }}>
          {t('cancel')}
        </Button>
        <Button
          mode="contained"
          style={styles.button}
          onPress={onRetry}
          contentStyle={{
            height: AppConfig.Styles.buttonHeight,
          }}>
          {t('retry')}
        </Button>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    width: '100%',
    justifyContent: 'space-between',
    maxWidth: AppConfig.Styles.responsiveMaxWidth - 48,
    marginHorizontal: AppConfig.Styles.getAdjustedSize(24),
    marginTop: AppConfig.Styles.getAdjustedSize(50),
    marginBottom: AppConfig.Styles.getAdjustedSize(36),
  },
  button: {
    width: '45%',
  },
});
