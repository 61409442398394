import { logger } from '@/libs/logger';
import { trpc } from '@operto/trpc-client';
import { Variable } from '@operto/variables-shared';
import { useAppStore } from '@store';
import format from 'date-fns/format';
import fromUnixTime from 'date-fns/fromUnixTime';
import cloneDeep from 'lodash/cloneDeep';
import { useEffect, useState } from 'react';

export const SYSTEM_VARIABLES_GUEST_NAME = 'Guest Name';
export const SYSTEM_VARIABLES_GUEST_EMAIL = 'Guest Email';
export const SYSTEM_VARIABLES_GUEST_PHONE = 'Guest Phone';
export const SYSTEM_VARIABLES_PROPERTY_NAME = 'Property Name';
export const SYSTEM_VARIABLES_PROPERTY_ADDRESS = 'Property Address';
export const SYSTEM_VARIABLES_CONFIRMATION_CODE = 'Confirmation Code';
export const SYSTEM_VARIABLES_CHECKIN_DATE = 'Reservation check-in time';
export const SYSTEM_VARIABLES_CHECKOUT_DATE = 'Reservation check-out time';

const sanitizeVariableValue = (value = '') => {
  let sanitizedValue = value;

  sanitizedValue = sanitizedValue.replaceAll(/\n/g, '\\n');
  sanitizedValue = sanitizedValue.replaceAll(/"/g, '\\"'); // escape double quotes

  return sanitizedValue;
};

export default function useVariables<T>(originalContents?: T[] | null) {
  const { reservation, company } = useAppStore();
  const [contents, setContents] = useState<T[] | null>();
  const { data: variables }: { data: Variable[] } =
    trpc.variables.getVariables.useQuery(
      {
        companyId: company?.companyId ?? 0,
      },
      { enabled: !!company?.companyId },
    );

  useEffect(() => {
    /**
     * NOTE: https://operto.atlassian.net/browse/OPN1-3309
     * for now, we do client side logic as we don't have a way to do it on the server.
     * variables service dont have access to reservation data.
     *
     * ensure when systems variables are updated in the server, we update the logic here as well.
     */
    const processSystemVariable = (content: string, variable: Variable) => {
      let value = '';
      const dateFormat = 'dd MMM yyyy hh:mm a';
      switch (variable.name) {
        case SYSTEM_VARIABLES_GUEST_NAME:
          value = reservation?.guestName ?? '';
          break;

        case SYSTEM_VARIABLES_GUEST_EMAIL:
          value = reservation?.guestEmail ?? '';
          break;

        case SYSTEM_VARIABLES_GUEST_PHONE:
          value = reservation?.guestPhone ?? '';
          break;

        case SYSTEM_VARIABLES_PROPERTY_NAME:
          value = reservation?.property.name ?? '';
          break;

        case SYSTEM_VARIABLES_PROPERTY_ADDRESS:
          value = reservation?.property.address ?? '';
          break;

        case SYSTEM_VARIABLES_CONFIRMATION_CODE:
          value = reservation?.id ?? ''; // reservation external id
          break;

        case SYSTEM_VARIABLES_CHECKIN_DATE:
          value = reservation?.checkin
            ? format(fromUnixTime(reservation?.checkin), dateFormat)
            : '';
          break;

        case SYSTEM_VARIABLES_CHECKOUT_DATE:
          value = reservation?.checkout
            ? format(fromUnixTime(reservation?.checkout), dateFormat)
            : '';
          break;

        default:
          return content;
      }

      return content.replaceAll(`*{{${variable.name}}}*`, value);
    };

    const processContent = (content: unknown, variable: Variable) => {
      if (!content || typeof content !== 'string') {
        return undefined;
      }

      if (!variable.enabled || !content.includes(`*{{${variable.name}}}*`)) {
        return content;
      }

      if (variable.type === 'system') {
        return processSystemVariable(content, variable);
      }

      let value = variable.value ?? '';
      const propertyId = Number(reservation?.property.id);
      if (propertyId) {
        const property = variable.properties?.[propertyId];
        if (property?.enabled) {
          value = property?.value ?? value;
        }
      }

      return content.replaceAll(
        `*{{${variable.name}}}*`,
        sanitizeVariableValue(value),
      );
    };

    const newContents = originalContents ? cloneDeep(originalContents) : null;
    if (newContents) {
      variables?.forEach(variable => {
        for (let i = 0; i < newContents.length; i++) {
          const content = JSON.stringify(newContents[i]);
          const processedContent = processContent(content, variable);
          if (processedContent) {
            try {
              newContents[i] = JSON.parse(processedContent);
            } catch (error) {
              logger.error(
                `error applying variable: ${variable.name}, error: ${error}`,
              );
            }
          }
        }
      });
    }

    setContents(newContents);
  }, [originalContents, reservation, variables]);

  return { contents, variables };
}
