import useLocalization from '@/assets/localizations';
import { useLanguagePanel } from '@/store/languagePanel';
import { useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { StyleSheet, View } from 'react-native';
import { IconButton, MD3LightTheme, Modal, Text } from 'react-native-paper';
import RBSheet from 'react-native-raw-bottom-sheet';
import LanguageSelectorContent from './LanguageSelectorContent';

export default function LanguageSelector() {
  const { status, closePanel } = useLanguagePanel();
  const { t } = useLocalization();
  const refRBSheet = useRef<{ open: () => void; close: () => void }>(null);

  const handleClose = () => closePanel();

  useEffect(() => {
    if (refRBSheet.current) {
      if (status === 'open') {
        refRBSheet.current.open();
      } else {
        refRBSheet.current.close();
      }
    }
  }, [status]);

  if (isMobile) {
    return (
      //ActionSheet is not working correctly with state changes
      <RBSheet
        draggable
        dragOnContent
        height={360}
        ref={refRBSheet}
        useNativeDriver={true}
        onClose={handleClose}
        customStyles={{
          draggableIcon: {
            backgroundColor: '#000',
            margin: '15px',
          },
          container: {
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
          },
        }}
        customModalProps={{
          animationType: 'fade',
          statusBarTranslucent: true,
        }}>
        <View style={styles.bottomSheetContainer}>
          <Text
            theme={MD3LightTheme}
            variant="headlineSmall"
            style={styles.headerTextMobileStyle}>
            {t('guest_profile_language_title')}
          </Text>

          <LanguageSelectorContent onClose={handleClose} />
        </View>
      </RBSheet>
    );
  }

  return (
    <Modal
      visible={status === 'open'}
      onDismiss={handleClose}
      contentContainerStyle={styles.modalContainerStyle}>
      <View style={styles.headerStyle}>
        <Text
          theme={MD3LightTheme}
          variant="titleLarge"
          style={styles.headerTextStyle}>
          {t('guest_profile_language_title')}
        </Text>

        <IconButton
          icon="close"
          size={24}
          onPress={handleClose}
          style={styles.headerCloseButtonStyle}
        />
      </View>

      <LanguageSelectorContent onClose={handleClose} />
    </Modal>
  );
}

const styles = StyleSheet.create({
  headerStyle: {
    width: '100%',
    paddingTop: '24px',
    paddingRight: '12px',
    paddingBottom: '16px',
    paddingLeft: '24px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerTextStyle: {
    color: 'rgba(67, 70, 86, 1)',
    flex: 1,
  },
  headerTextMobileStyle: {
    color: 'rgba(25, 27, 36, 1)',
    flex: 1,
    textAlign: 'center',
    marginTop: '10px',
    width: '100%',
  },
  headerCloseButtonStyle: {
    margin: '4px',
  },
  modalContainerStyle: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 0,
    width: '400px',
    height: 'auto',
    borderRadius: 16,
    alignSelf: 'center',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  bottomSheetContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 0,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    alignSelf: 'center',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
});
