import { useTrackScreen } from '@/hooks/useAnalytics';
import AppConfig from '@config';
import useLocalization, { TranslationKey } from '@localizations';
import { NearMe, useAppStore } from '@store';
import assign from 'lodash/assign';
import map from 'lodash/map';
import memoize from 'lodash/memoize';
import sortBy from 'lodash/sortBy';
import sortedUniq from 'lodash/sortedUniq';
import { useState } from 'react';
import { FlatList, StyleSheet, useWindowDimensions } from 'react-native';
import { useTheme } from 'react-native-paper';
import { SceneMap, TabBar, TabView } from 'react-native-tab-view';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import Layout from '../components/Layout';
import RootContainer from '../components/RootContainer';
import { PlaceCard } from './PlaceCard';

const filterPlacesMemo = memoize((filter = 'shopping', places: NearMe[]) => {
  const results = places.filter(
    (place: NearMe) => place.type.toLowerCase() === filter.toLowerCase(),
  );

  return sortBy(results, ['title']);
});

const TabContentView = ({ route: { key } }: { route: { key: string } }) => {
  const [height, setHeight] = useState(0);
  const { nearme } = useAppStore();
  const places: NearMe[] | undefined = filterPlacesMemo(key, nearme ?? []);

  return (
    <FlatList
      onLayout={event => {
        setHeight(event.nativeEvent.layout.height);
      }}
      style={[styles.body, { height }]}
      data={places}
      renderItem={({ item }) => <PlaceCard place={item} />}
      keyExtractor={item => item.id}
    />
  );
};

// @ts-ignore - type inferred for TabBar component
const TabBarView = props => {
  const theme = useTheme();

  return (
    <TabBar
      {...props}
      style={{ backgroundColor: theme.colors.primary }}
      indicatorStyle={{ backgroundColor: theme.colors.background }}
      inactiveColor={`${theme.colors.background}88`}
      renderIcon={({ route, color }) => {
        let icon = 'map-marker';
        switch (route.key) {
          case 'shopping':
            icon = 'cart-plus';
            break;
          case 'dining':
            icon = 'silverware';
            break;
          default:
            icon = 'map-marker';
            break;
        }

        return (
          <Icon
            name={icon}
            color={color}
            size={AppConfig.Styles.getAdjustedSize(20)}
          />
        );
      }}
    />
  );
};

export const Places = () => {
  useTrackScreen({
    screen: 'Places',
    feature: 'guest_portal',
    action: 'reservation_page_near',
  });
  const layout = useWindowDimensions();
  const [index, setIndex] = useState(0);
  const { t } = useLocalization();

  // contruct tabs from places data using its types
  const { nearme } = useAppStore();
  const placesKey = sortedUniq(map(nearme, 'type')) as TranslationKey[];

  const [routes] = useState(
    placesKey.map(key => ({
      key,
      title: t(key),
    })),
  );

  const [scenes] = useState(
    assign({}, ...placesKey.map(k => ({ [k]: TabContentView }))),
  );

  return (
    <RootContainer>
      <Layout>
        <TabView
          navigationState={{ index, routes }}
          renderScene={SceneMap(scenes)}
          renderTabBar={TabBarView}
          onIndexChange={setIndex}
          initialLayout={{ width: layout.width }}
          style={{ flex: 1 }}
        />
      </Layout>
    </RootContainer>
  );
};

export default Places;

const styles = StyleSheet.create({
  body: {
    width: '100%',
    alignSelf: 'center',
    maxWidth: AppConfig.Styles.responsiveMaxWidth,
    padding: AppConfig.Styles.getAdjustedSize(20),
  },
});
