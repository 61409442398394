import { isVerificationFinished } from '@/libs';
import { isVerificationChallengeRequired } from '@/libs/ChallengeHelper';
import { useAppStore } from '@/store';
import { StackActions, useNavigation } from '@react-navigation/native';
import { useLayoutEffect, useRef } from 'react';

export const useWelcomeFlowRedirect = () => {
  const { company, reservation } = useAppStore();
  const navigation = useNavigation();
  const redirectedRef = useRef(false);

  useLayoutEffect(() => {
    if (!company || !reservation || redirectedRef.current === true) {
      return;
    }

    // TODO: REGISTRATION remove new login enabled from cases
    if (
      company?.terms?.enabled &&
      !reservation?.termsAgreed &&
      company?.newLoginEnabled &&
      !company.challengePassed
    ) {
      redirectedRef.current = true;
      return navigation.dispatch(StackActions.replace('SignatureTerms'));
    }

    if (
      isVerificationChallengeRequired(company) &&
      !isVerificationFinished(reservation)
    ) {
      redirectedRef.current = true;
      return navigation.dispatch(StackActions.replace('VerifyGuest'));
    }
  }, [company, navigation, reservation]);
};
