import { isMobile } from 'react-device-detect';
import { ImageBackground, StyleSheet, View } from 'react-native';

export default function RegistrationLayout({
  children,
  isRequestingOtp,
  justifyContent = 'flex-start',
}: registrationLayoutProps) {
  const styles = StyleSheet.create({
    backgroundImage: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    headerContainer: {
      width: isMobile ? '100%' : isRequestingOtp ? 600 : 500,
      minHeight: isMobile ? '100%' : isRequestingOtp ? 412.44 : 638.44,
      borderRadius: isMobile ? 0 : 60,
      backgroundColor: 'rgba(0, 0, 0, 0.60)',
      backdropFilter: 'blur(50px)',
      paddingTop: isMobile ? 50 : 60,
      paddingRight: isMobile ? 16 : 60,
      paddingBottom: isMobile ? 50 : 60,
      paddingLeft: isMobile ? 16 : 60,
      display: 'flex',
      justifyContent: justifyContent,
      alignItems: 'center',
      flexDirection: 'column',
    },
    logoContainer: {
      width: '100%',
      height: 56.44,
      alignItems: 'center',
      display: 'flex',
      marginBottom: 16,
    },
    logo: { width: 200, height: 56.44 },
  });

  return (
    <View style={{ flex: 1 }}>
      <ImageBackground
        testID="background-image"
        style={styles.backgroundImage}
        resizeMode={'cover'}
        source={require('@images/login_background.png')}>
        <View style={styles.headerContainer}>
          <View style={styles.logoContainer}>
            <ImageBackground
              testID="logo-image"
              style={styles.logo}
              resizeMode="contain"
              source={require('@images/operto_logo_white.png')}
            />
          </View>
          {children}
        </View>
      </ImageBackground>
    </View>
  );
}

interface registrationLayoutProps {
  children: React.ReactNode;
  isRequestingOtp?: boolean;
  justifyContent?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
}
