import { useAppContext, useAppStore } from '@/store';
import AppConfig from '@config';
import useLocalization from '@localizations';
import { useNavigation } from '@react-navigation/native';
import { ErrorIcon404, LoginWelcomeLogo } from '@svgs';
import { StyleSheet, View } from 'react-native';
import { Button, Text, useTheme } from 'react-native-paper';
import { LanguageMenu, ThemedLogoHeader } from '../components';

type NotFoundProps = {
  showLanguageSelector?: boolean;
};

export const NotFound = ({ showLanguageSelector = true }: NotFoundProps) => {
  const { locale, setLocale } = useAppContext();
  const { theme, company } = useAppStore();
  const { colors } = useTheme();
  const { t } = useLocalization();

  return (
    <View style={[AppConfig.Styles.container, AppConfig.Styles.center]}>
      <View
        style={[
          AppConfig.Styles.container,
          AppConfig.Styles.center,
          styles.container,
        ]}>
        <ThemedLogoHeader theme={theme}>
          <LoginWelcomeLogo
            style={{ color: colors.primary }}
            width={AppConfig.Styles.getAdjustedSize(90)}
          />
        </ThemedLogoHeader>
        <View style={styles.errorImage}>
          <ErrorIcon404
            fill={colors.primary}
            width={AppConfig.Styles.getAdjustedSize(218)}
            height={AppConfig.Styles.getAdjustedSize(172)}
          />
        </View>
        <Text style={[AppConfig.Styles.headline6, styles.text]}>
          {t('error_page_title')}
        </Text>
        <Text style={[AppConfig.Styles.subtitle2, styles.text]}>
          {t('error_page_desc')}
        </Text>
      </View>

      {showLanguageSelector &&
        (!company?.newLoginEnabled ||
          (company.newLoginEnabled && company.challengePassed)) && (
          <LanguageMenu selected={locale} onClose={setLocale} />
        )}
    </View>
  );
};

export const NotFoundWithBackToHome = () => {
  const navigation = useNavigation();
  const { t } = useLocalization();

  return (
    <View
      style={[
        AppConfig.Styles.container,
        AppConfig.Styles.center,
        styles.container,
      ]}>
      <NotFound showLanguageSelector={false} />
      <Button
        style={styles.button}
        contentStyle={styles.buttonContent}
        mode="contained"
        onPress={() => navigation.navigate('Home')}>
        {t('return_to_guest_portal')}
      </Button>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    maxWidth: AppConfig.Styles.responsiveMaxWidth,
    padding: AppConfig.Styles.getAdjustedSize(16),
  },
  errorImage: {
    marginVertical: AppConfig.Styles.getAdjustedSize(32),
  },
  text: {
    width: '100%',
    marginBottom: AppConfig.Styles.getAdjustedSize(20),
    marginHorizontal: AppConfig.Styles.getAdjustedSize(24),
    textAlign: 'center',
  },
  button: {
    width: '100%',
    margin: AppConfig.Styles.getAdjustedSize(16),
    marginBottom: AppConfig.Styles.getAdjustedSize(90),
    maxWidth: AppConfig.Styles.responsiveMaxWidth,
  },
  buttonContent: {
    height: AppConfig.Styles.buttonHeight,
  },
});
