import {
  ChallengeNames,
  Company,
  isAccessTokenValid,
  LoginChallenge,
  Reservation,
} from '@/store';

export const findLoginChallenge = (company: Company | undefined) => {
  return company?.loginChallenges?.find(
    ({ name }) => name === ChallengeNames.LOGIN,
  );
};

export const findTermsChallenge = (company: Company | undefined) => {
  return company?.loginChallenges?.find(
    ({ name }) => name === ChallengeNames.TERMS,
  );
};

export const findWelcomeChallenge = (company: Company | undefined) => {
  return company?.loginChallenges?.find(
    ({ name }) => name === ChallengeNames.WELCOME,
  );
};

export const findContactChallenge = (company: Company | undefined) => {
  return company?.loginChallenges?.find(
    ({ name }) => name === ChallengeNames.CONTACT,
  );
};

export const findVerificationChallenge = (company: Company | undefined) => {
  return company?.loginChallenges?.find(
    ({ name }) => name === ChallengeNames.VERIFICATION,
  );
};

export const isLoginChallengeRequired = (company: Company | undefined) => {
  return !!findLoginChallenge(company)?.enabled;
};

export const isTermsChallengeRequired = (company: Company | undefined) => {
  return !!findTermsChallenge(company)?.enabled;
};

export const isWelcomeChallengeRequired = (company: Company | undefined) => {
  return !!findWelcomeChallenge(company)?.enabled;
};

export const isContactChallengeRequired = (company: Company | undefined) => {
  return !!findContactChallenge(company)?.enabled;
};

export const isVerificationChallengeRequired = (
  company: Company | undefined,
) => {
  return !!findVerificationChallenge(company)?.enabled;
};

export const isNoneChallengeRequired = (company: Company | undefined) => {
  return (
    !isLoginChallengeRequired(company) &&
    !isTermsChallengeRequired(company) &&
    !isWelcomeChallengeRequired(company) &&
    !isContactChallengeRequired(company) &&
    !isVerificationChallengeRequired(company)
  );
};

export const showLastNameField = (company: Company | undefined) => {
  if (company === undefined) {
    return false;
  }
  const loginData = findLoginChallenge(company)?.data as LoginChallenge;
  return loginData?.lastname;
};

export const showDateField = (company: Company | undefined) => {
  if (company === undefined) {
    return false;
  }
  const loginData = findLoginChallenge(company)?.data as LoginChallenge;
  return loginData?.date;
};

export const canShowHomePage = (company?: Company | undefined) => {
  const reservationId =
    typeof company?.reservation === 'string'
      ? (company?.reservation as string)
      : (company?.reservation as Reservation)?.id;

  return (
    company?.challengePassed &&
    reservationId &&
    isAccessTokenValid(reservationId)
  );
};

export default findLoginChallenge;
