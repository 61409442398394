import { logger } from '@/libs/logger';
import { createFullStoryProvider, setupAnalytics } from '@operto/analytics';

const { trackEvent, addProvider, hasProvider, useTrackScreen } = setupAnalytics(
  {
    appName: 'GUEST',
  },
);

// this hook encapsulate all logic required to setup FullStory

const useAnalytics = () => {
  try {
    const provider = createFullStoryProvider({ orgId: '12TVFB' });
    addProvider(provider);
  } catch (error) {
    logger.error(error);
  }
};

export { hasProvider, trackEvent, useAnalytics, useTrackScreen };
