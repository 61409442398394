import { logger } from '@/libs/logger';
import { ContentView, SnackBar } from '@components';
import AppConfig from '@config';
import { DecodeHtmlFormat } from '@libs';
import useLocalization from '@localizations';
import { Reservation, dispatchUpdateTerms, useAppStore } from '@store';
import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import { Linking, StyleSheet, View } from 'react-native';
import Autolink from 'react-native-autolink';
import { Button, Caption, Checkbox } from 'react-native-paper';

interface ITermsAgreedViewProps {
  reservation: Reservation;
  setState: Dispatch<SetStateAction<'none' | 'success' | 'error'>>;
}
export const TermsAgreedView: FunctionComponent<ITermsAgreedViewProps> = ({
  reservation,
  setState,
}) => {
  const { t } = useLocalization();
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(reservation?.termsAgreed);

  const onPress = useCallback(async () => {
    if (loading) {
      return;
    }

    setState('none');
    setLoading(true);

    try {
      await dispatchUpdateTerms(reservation.id);
      setState('success');
    } catch (err) {
      logger.error(err);
      setState('error');
    }

    setLoading(false);
  }, [loading, setState, reservation]);

  if (reservation?.termsAgreed) {
    return null;
  }

  return (
    <View>
      <View style={styles.check}>
        <Checkbox
          status={checked ? 'checked' : 'unchecked'}
          onPress={() => {
            setChecked(!checked);
          }}
        />
        <Caption>{t('terms_conditions_subtitle')}</Caption>
      </View>
      <Button
        contentStyle={{
          width: '100%',
          height: AppConfig.Styles.buttonHeight,
        }}
        loading={loading}
        mode="contained"
        onPress={onPress}>
        {loading ? '' : t('save')}
      </Button>
    </View>
  );
};

export const Terms = () => {
  const { company, reservation } = useAppStore();
  const { t } = useLocalization();
  const [state, setState] = useState<'none' | 'success' | 'error'>('none');

  return (
    <View style={AppConfig.Styles.container}>
      <ContentView>
        <Autolink
          style={[AppConfig.Styles.border, styles.terms]}
          text={
            company?.terms?.message
              ? DecodeHtmlFormat(company?.terms?.message)
              : ''
          }
          textProps={{ style: AppConfig.Styles.caption }}
          linkStyle={{ textDecorationLine: 'underline', color: 'blue' }}
          stripPrefix={false}
          onPress={url => Linking.openURL(url)}
        />
        {reservation && (
          <TermsAgreedView reservation={reservation} setState={setState} />
        )}
      </ContentView>
      <SnackBar
        title={
          state === 'error'
            ? 'Error updating terms agreement'
            : t('update_profile_success')
        }
        visible={state !== 'none'}
        color={state === 'error' ? 'red' : 'green'}
        onDismiss={() => setState('none')}
      />
    </View>
  );
};

export default Terms;

const styles = StyleSheet.create({
  terms: {
    padding: AppConfig.Styles.getAdjustedSize(5),
    paddingHorizontal: AppConfig.Styles.getAdjustedSize(10),
  },
  check: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: AppConfig.Styles.getAdjustedSize(10),
  },
});
