import AppConfig from '@config';
import useLocalization, { SupportedLocale } from '@localizations';
import { useState } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { Text } from 'react-native-paper';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { ActionSheet, ActionSheetProps } from './ActionSheet';

type LanguageSelectorProps = Omit<ActionSheetProps, 'children' | 'onClose'> & {
  selected: SupportedLocale;
  onClose?: (selected: SupportedLocale) => void;
};

export const LanguageSelector = ({
  isOpen,
  selected,
  onClose,
  ...props
}: LanguageSelectorProps) => {
  const { languages } = useLocalization();

  const renderItems = () => {
    return languages.map(lng => (
      <TouchableOpacity
        key={lng.locale}
        style={[
          AppConfig.Styles.center,
          AppConfig.Styles.rowContainer,
          styles.item,
        ]}
        onPress={() => onClose?.(lng.locale)}>
        <Text style={AppConfig.Styles.subtitle1}>{lng.name}</Text>
        {selected === lng.locale && <Icon name="check" size={20} />}
      </TouchableOpacity>
    ));
  };

  return (
    <ActionSheet
      {...props}
      testID={isOpen ? 'language-selector-visible' : 'language-selector-hidden'}
      isOpen={isOpen}
      onClose={() => onClose?.(selected)}>
      {renderItems()}
    </ActionSheet>
  );
};

type LanguageMenuProps = Omit<LanguageSelectorProps, 'isOpen'>;

export const LanguageMenu = ({ selected, onClose }: LanguageMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { languages } = useLocalization();
  const current = languages.find(l => l.locale === selected);

  return (
    <TouchableOpacity
      style={[AppConfig.Styles.center, styles.menuContainer]}
      onPress={() => setIsOpen(!isOpen)}>
      <Icon
        color="gray"
        name="web"
        size={AppConfig.Styles.getAdjustedSize(20)}
      />
      <Text
        testID="language-menu-title"
        style={[AppConfig.Styles.subtitle1, styles.menuTitle]}>
        {current?.name}
      </Text>
      <Icon
        color="gray"
        name="menu-down"
        size={AppConfig.Styles.getAdjustedSize(20)}
      />
      <LanguageSelector
        selected={selected}
        isOpen={isOpen}
        onClose={value => {
          setIsOpen(false);
          onClose?.(value);
        }}
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  menuContainer: {
    flexDirection: 'row',
    position: 'absolute',
    top: AppConfig.Styles.getAdjustedSize(20),
    right: AppConfig.Styles.getAdjustedSize(20),
  },
  menuTitle: {
    color: 'gray',
    marginHorizontal: AppConfig.Styles.getAdjustedSize(10),
  },
  item: {
    justifyContent: 'space-between',
    width: '100%',
    marginVertical: AppConfig.Styles.getAdjustedSize(10),
    paddingHorizontal: AppConfig.Styles.getAdjustedSize(16),
  },
});
