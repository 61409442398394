import AppConfig from '@config';
import { FunctionComponent } from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';
import { List, useTheme } from 'react-native-paper';
import { IconSource } from 'react-native-paper/lib/typescript/components/Icon';

interface IBorderedListItemProps {
  color?: string | null;
  description?: string;
  icon?: IconSource;
  onPress?: () => void;
  title: string;
}

/**
 * BorderedListItem - a styled list component that's utilizing react-native-papers list item
 */
export const BorderedListItem: FunctionComponent<IBorderedListItemProps> = ({
  color = null,
  description = '',
  icon,
  onPress,
  title,
}) => {
  const theme = useTheme();

  const renderLeft = (props: {
    color?: string;
    style?: StyleProp<ViewStyle>;
  }) => {
    if (!icon) {
      return null;
    }

    return (
      <List.Icon
        {...props}
        style={[
          styles.icon,
          { backgroundColor: color ?? theme.colors.primary },
        ]}
        icon={icon}
        color={AppConfig.Colors.ON_SECONDARY}
      />
    );
  };

  return (
    <List.Item
      style={[AppConfig.Styles.border, AppConfig.Styles.borderItem]}
      title={title}
      description={description}
      left={renderLeft}
      onPress={onPress}
    />
  );
};

const styles = StyleSheet.create({
  icon: {
    borderWidth: StyleSheet.hairlineWidth,
    borderRadius: AppConfig.Styles.getAdjustedSize(30),
    borderColor: 'transparent',
    width: AppConfig.Styles.getAdjustedSize(40),
    height: AppConfig.Styles.getAdjustedSize(40),
  },
});

export default BorderedListItem;
