import {
  CategoryItem,
  ContentItem,
  GuidesCategoriesRouteParams,
  getNavigationParamsForCategory,
  getNavigationParamsForContent,
  useGuidesCategories,
} from '@/store/guides';
import { Layout, NavigationHeader, RootContainer } from '@components';
import { trackEvent } from '@libs';
import { useNavigation, useRoute } from '@react-navigation/native';
import { StackHeaderProps } from '@react-navigation/stack';
import { FlatList } from 'native-base';
import { useCallback, useLayoutEffect } from 'react';
import { TouchableOpacity, View, useWindowDimensions } from 'react-native';
import CardRouter from '../components/CardRouter';

const renderHeader = (props: StackHeaderProps) => {
  return <NavigationHeader {...props} />;
};

const CARD_HEIGHT = 215;
const CARD_MAX_WIDTH = 568;

const Section = () => {
  const navigation = useNavigation();
  const { width } = useWindowDimensions();
  const route = useRoute();
  const { categories } = useGuidesCategories(
    route.params as GuidesCategoriesRouteParams,
  );

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: true,
      header: renderHeader,
    });
  }, [navigation]);

  const renderItem = useCallback(
    ({ item }: { item: ContentItem | CategoryItem }) => {
      const { title, thumbnail } =
        'title' in item
          ? {
              title: item.title,
              thumbnail: item.images_url[0],
            }
          : { title: item.category, thumbnail: item.category_cover };

      const cardWidth = Math.min(width - 20, CARD_MAX_WIDTH);
      const element = {
        id: item.section_id || '',
        title: title || '',
        imageUri:
          thumbnail && thumbnail.length > 0
            ? `${thumbnail}?tr=w-384,h-384`
            : `https://ik.imagekit.io/operto/tr:w-${width},h-${CARD_HEIGHT}/portals/guest-portal-assets/placeholders/guides_placeholder.png`,
      };

      return (
        <TouchableOpacity
          testID="list-item"
          style={{
            height: 205,
            width: cardWidth,
            margin: 10,
          }}
          onPress={() => {
            if ('title' in item) {
              trackEvent('Guides', 'Content:Clicked', {
                guideId: item?.section_id,
                title: item?.title,
                id: item?.content_id,
              });

              navigation.navigate(
                'Content',
                getNavigationParamsForContent(item as ContentItem),
              );
            } else {
              trackEvent('Guides', 'Category:Clicked', {
                guideId: item.section_id,
                title: item.category,
                id: item.category_id,
              });

              navigation.navigate(
                'Category',
                getNavigationParamsForCategory(item as CategoryItem),
              );
            }
          }}>
          <CardRouter contentItem={element} />
        </TouchableOpacity>
      );
    },
    [navigation, width],
  );

  return (
    <RootContainer>
      <Layout>
        <View style={{ height: '100%' }}>
          <FlatList
            testID="section-list"
            data={categories}
            contentContainerStyle={{
              position: 'relative',
              alignItems: 'center',
            }}
            renderItem={({ item }) => renderItem({ item })}
          />
        </View>
      </Layout>
    </RootContainer>
  );
};

export default Section;
