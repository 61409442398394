import useLocalization from '@/assets/localizations';
import AppConfig from '@config';
import { LoadingIcon } from '@svgs';
import { Animated, Easing, Text, View } from 'react-native';

interface LoadingProps {
  title?: string;
  description?: string;
}

export const Loading = ({ title, description }: LoadingProps) => {
  const { t } = useLocalization();
  const spinValue = new Animated.Value(0);
  const rotate = spinValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg'],
  });

  Animated.loop(
    Animated.timing(spinValue, {
      toValue: 1,
      duration: 2000,
      easing: Easing.linear,
      useNativeDriver: false,
    }),
  ).start();

  return (
    <View style={AppConfig.Styles.center}>
      <Animated.View
        style={{
          transform: [{ rotate }],
        }}>
        <LoadingIcon
          width={AppConfig.Styles.getAdjustedSize(96)}
          height={AppConfig.Styles.getAdjustedSize(96)}
        />
      </Animated.View>
      <Text
        style={[
          AppConfig.Styles.headline6,
          {
            marginTop: AppConfig.Styles.getAdjustedSize(34),
            marginBottom: AppConfig.Styles.getAdjustedSize(16),
          },
        ]}>
        {title ?? t('generic_loading')}
      </Text>
      <Text style={[AppConfig.Styles.subtitle2]}>
        {description ?? t('generic_loading_description')}
      </Text>
    </View>
  );
};
