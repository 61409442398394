import useLocalization from '@/assets/localizations';
import { SignatureIcon } from '@/assets/svgs/SignatureIcon';
import { trackEvent } from '@/hooks/useAnalytics';
import { logger } from '@/libs/logger';
import { useUpdateGuestTermsMutation } from '@/store/guest-terms/api-slice';
import { useGetGuestUserQuery } from '@/store/services/userApi';
import AppConfig from '@config';
import { StackActions, useNavigation } from '@react-navigation/native';
import { HeaderBackButton } from '@react-navigation/stack';
import { dispatchReservations, useAppStore } from '@store';
import { Suspense, useLayoutEffect, useRef, useState } from 'react';
import {
  Linking,
  NativeScrollEvent,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';
import Autolink from 'react-native-autolink';
import { Button, Text, useTheme } from 'react-native-paper';
import { Loading } from '../components';
import SnackBar from '../components/SnackBar';
import { SignatureDialog } from './SignatureDialog.web';

export const SignatureTerms = () => {
  const navigation = useNavigation();
  const [showSignature, setShowSignature] = useState(false);
  const [isLocalError, setLocalError] = useState(false);
  const scrollViewRef = useRef<ScrollView>(null);
  const [agreeButtonDisabled, setAgreeButtonDisabled] =
    useState<boolean>(false);
  const { t } = useLocalization();
  const { company, reservation } = useAppStore();
  const { colors } = useTheme();
  const { data: guest } = useGetGuestUserQuery();
  const [updateGuestTerms, { isLoading, isError, reset }] =
    useUpdateGuestTermsMutation();

  const termsMessage = company?.terms?.message ?? '';

  const handleSubmit = async (guestSignatureBase64Image?: string) => {
    if (!reservation || !guest) {
      logger.debug('unable to submit terms, missing data');
      return setLocalError(true);
    }

    if (isLoading) {
      logger.debug('unable to submit terms, already loading');
      return setLocalError(true);
    }

    try {
      await updateGuestTerms({
        reservationCode: reservation.id,
        guestId: guest.id,
        guestName: `${guest.givenName} ${guest.familyName}`,
        guestEmail: guest.emailPrimary,
        guestPhone: guest.phonePrimary ?? '',
        guestSignatureBase64Image: guestSignatureBase64Image ?? '',
      }).unwrap();
      dispatchReservations([{ ...reservation, termsAgreed: true }]);
      trackEvent({
        event: 'CLICKED',
        screen: 'SignatureTerms',
        feature: 'guest_terms',
        action: 'guest_terms_agree',
      });
      setShowSignature(false);

      // TODO: navigate to the Welcome screen
      // https://operto.atlassian.net/browse/OPN1-18885
      navigation.dispatch(StackActions.replace('Home')); // TEMP: need to determine if there are other welcome flows
    } catch (error) {
      logger.error('Error updating terms agreement', error);
    }
  };

  const handleDismiss = () => {
    reset();
    setLocalError(false);
  };

  const renderLoader = () => {
    return (
      <View
        style={[
          StyleSheet.absoluteFill,
          { backgroundColor: colors.background, justifyContent: 'center' },
        ]}>
        <Loading title={t('loading')} />
      </View>
    );
  };

  const isCloseToBottom = ({
    layoutMeasurement,
    contentOffset,
    contentSize,
  }: NativeScrollEvent) => {
    return (
      layoutMeasurement.height + contentOffset.y >= contentSize.height - 20
    );
  };

  useLayoutEffect(() => {
    navigation.setOptions({ headerShown: false });
    const { offsetHeight: contentOffset } =
      scrollViewRef.current?.getScrollableNode() ?? {};
    const { offsetHeight: contentHeight } =
      scrollViewRef.current?.getInnerViewNode() ?? {};
    if (contentOffset + 60 <= contentHeight) {
      setAgreeButtonDisabled(true);
    }
  }, [navigation]);

  const handleClose = () => {
    trackEvent({
      event: 'CLICKED',
      screen: 'SignatureTerms',
      feature: 'Guest Terms',
      details: 'guest_terms_abandon',
    });
    setShowSignature(false);
  };

  return (
    <Suspense fallback={renderLoader()}>
      <View
        style={[
          StyleSheet.absoluteFill,
          { backgroundColor: colors.background },
        ]}>
        <View style={styles.header}>
          <HeaderBackButton
            onPress={() =>
              navigation.canGoBack()
                ? navigation.goBack()
                : navigation.dispatch(StackActions.replace('Reservations'))
            }
          />
          <Text variant="titleLarge" style={styles.headerTitle}>
            {t('guest_terms_title')}
          </Text>
        </View>
        <ScrollView
          ref={scrollViewRef}
          style={[AppConfig.Styles.container, styles.scroll]}
          scrollEventThrottle={16}
          onScroll={({ nativeEvent }) => {
            if (isCloseToBottom(nativeEvent)) {
              if (agreeButtonDisabled) {
                setAgreeButtonDisabled(false);
              }
            }
          }}>
          {termsMessage.length > 0 && (
            <Autolink
              text={termsMessage}
              textProps={{
                style: {
                  ...AppConfig.Styles.body1,
                  color: colors.onSurfaceVariant,
                },
              }}
              linkStyle={{ textDecorationLine: 'underline', color: 'blue' }}
              stripPrefix={false}
              onPress={url => Linking.openURL(url)}
            />
          )}
        </ScrollView>
        <View
          style={[
            styles.footer,
            {
              borderTopColor: colors.surfaceDisabled,
              backgroundColor: colors.background,
            },
          ]}>
          {company?.terms?.signatureEnabled ? (
            <Button
              onPress={() => setShowSignature(true)}
              mode="contained"
              disabled={agreeButtonDisabled}>
              <View style={styles.signatureButton}>
                <SignatureIcon width={24} height={24} />
                <View style={{ width: AppConfig.Styles.getAdjustedSize(8) }} />
                <Text variant="labelLarge" style={{ color: colors.onPrimary }}>
                  {t('guest_terms_esign_signature-button')}
                </Text>
              </View>
            </Button>
          ) : (
            <Button
              loading={isLoading}
              disabled={agreeButtonDisabled}
              onPress={() => handleSubmit()}
              mode="contained">
              {t('guest_terms_button_agree')}
            </Button>
          )}
        </View>

        <SnackBar
          title={t('guest_terms_error')}
          visible={isError || isLocalError}
          color="red"
          onDismiss={handleDismiss}
        />

        <SignatureDialog
          writer={`${guest?.givenName} ${guest?.familyName}`}
          visible={showSignature}
          onClose={handleClose}
          onSubmit={handleSubmit}
          loading={isLoading}
        />
      </View>
    </Suspense>
  );
};

const styles = StyleSheet.create({
  scroll: {
    padding: AppConfig.Styles.getAdjustedSize(16),
    paddingTop: AppConfig.Styles.getAdjustedSize(0),
    maxWidth: AppConfig.Styles.getAdjustedSize(600),
    alignSelf: 'center',
  },
  header: {
    flexDirection: 'row',
  },
  headerTitle: {
    padding: AppConfig.Styles.getAdjustedSize(16),
  },
  footer: {
    width: '100%',
    borderTopWidth: StyleSheet.hairlineWidth,
    alignItems: 'center',
    paddingVertical: AppConfig.Styles.getAdjustedSize(16),
  },
  signatureButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
