import { ReactNode } from 'react';
import { ScrollView, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

type LayoutProps = {
  children: ReactNode;
};

export const Layout = ({ children }: LayoutProps) => {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        minHeight: '100vh',
      }}>
      <SafeAreaView>
        <ScrollView
          style={{
            paddingBottom: '60px',
            flex: 1,
            minHeight: '100%',
          }}>
          {children}
        </ScrollView>
      </SafeAreaView>
    </View>
  );
};

export default Layout;
