import { createStackNavigator } from '@react-navigation/stack';
import CaptureId from './CaptureId';
import CaptureSelfie from './CaptureSelfie';
import ConfirmId from './ConfirmId';
import ConfirmSelfie from './ConfirmSelfie';
import Details from './Details';
import Disclaimer from './Disclaimer';
import Methods from './Methods';
import Submit from './Submit';

const Stack = createStackNavigator();

export const VerficationFlowNavigator = () => {
  return (
    <Stack.Navigator
      initialRouteName="Disclaimer"
      screenOptions={() => ({
        gestureEnabled: false,
        headerShown: false,
      })}>
      <Stack.Screen
        name="Disclaimer"
        component={Disclaimer}
        options={{ title: 'Disclaimer' }}
      />
      <Stack.Screen
        name="Details"
        component={Details}
        options={{ title: 'Details' }}
      />
      <Stack.Screen
        name="Methods"
        component={Methods}
        options={{ title: 'Methods' }}
      />
      <Stack.Screen
        name="CaptureId"
        component={CaptureId}
        options={{ title: 'CaptureId' }}
      />
      <Stack.Screen
        name="ConfirmId"
        component={ConfirmId}
        options={{ title: 'ConfirmId' }}
      />
      <Stack.Screen
        name="CaptureSelfie"
        component={CaptureSelfie}
        options={{ title: 'CaptureSelfie' }}
      />
      <Stack.Screen
        name="ConfirmSelfie"
        component={ConfirmSelfie}
        options={{ title: 'ConfirmSelfie' }}
      />
      <Stack.Screen
        name="Submit"
        component={Submit}
        options={{ title: 'Submit' }}
      />
    </Stack.Navigator>
  );
};

export default VerficationFlowNavigator;
