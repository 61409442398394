import { Dimensions, StyleSheet } from 'react-native';
import { MD2Colors } from 'react-native-paper';
import * as colors from './Colors';

// TODO: keep an eye on this if the magic numbers works for most screen sizes
const getAdjustedSize = (size: number) => {
  const { width, height } = Dimensions.get('window');
  const adjustment = Math.floor((height - width) / width);
  return size * Math.min(Math.max(adjustment, 0.865), 1);
};

// TODO: keep an eye on this if the magic numbers works for most screen sizes
const getAdjustedTextSize = (size: number) => {
  const { fontScale } = Dimensions.get('window');
  const adjustment = Math.max(Math.floor((1 - fontScale) * 40), 0);
  return getAdjustedSize(size) + adjustment;
};

const getWidthByPercentage = (percentage: number) => {
  const { width } = Dimensions.get('window');

  return (width * percentage) / 100;
};

const getHeightByPercentage = (percentage: number) => {
  const { height } = Dimensions.get('window');

  return (height * percentage) / 100;
};

const getWindowWidth = () => {
  const { width } = Dimensions.get('window');
  return width;
};

const getWindowHeight = () => {
  const { height } = Dimensions.get('window');
  return height;
};

const responsiveMaxWidth = 600;
const responsiveMaxHeight = 800;
const buttonHeight = 48;
const inputHeight = 48;
const borderRadius = getAdjustedSize(7);

const isMobile = () => getWindowWidth() <= responsiveMaxWidth;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.ON_SECONDARY,
  },
  centeredContainer: {
    maxWidth: responsiveMaxWidth,
    maxHeight: responsiveMaxHeight,
    flex: 1,
    backgroundColor: colors.ON_SECONDARY,
  },
  rowContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  flexEndRowContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  colContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  left: {
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  alignCenter: {
    alignItems: 'center',
  },
  bottom: {
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  fullWidth: {
    width: '100%',
  },
  border: {
    borderColor: 'lightgray',
    borderWidth: StyleSheet.hairlineWidth,
    borderRadius,
  },
  borderItem: {
    padding: getAdjustedSize(12),
  },
  defaultTextLabel: {
    textAlign: 'center',
    color: colors.PRIMARY,
    fontSize: getAdjustedTextSize(18),
  },
  smallTextLabel: {
    textAlign: 'center',
    color: `${colors.TERTIARY}AA`,
    fontSize: getAdjustedTextSize(15),
  },
  largeTextLabel: {
    textAlign: 'center',
    color: colors.TERTIARY,
    fontSize: getAdjustedTextSize(24),
  },
  lighterText: {
    color: `${colors.TERTIARY}AA`,
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  listItem: {
    backgroundColor: '#fff',
    height: 84,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    borderBottomWidth: 1,
    borderBottomColor: MD2Colors.blueGrey100,
    flexDirection: 'row',
    alignItems: 'center',
  },

  // Typography - from material
  // https://material.io/design/typography/the-type-system.html#type-scale
  headline1: {
    fontSize: 96,
    fontWeight: '300',
    letterSpacing: -1.5,
  },
  headline2: {
    fontSize: 60,
    fontWeight: '300',
    letterSpacing: -0.5,
  },
  headline3: {
    fontSize: 48,
    fontWeight: '400',
    letterSpacing: 0,
  },
  headline4: {
    fontSize: 34,
    fontWeight: '400',
    letterSpacing: 0.25,
  },
  headline5: {
    fontSize: 24,
    fontWeight: '400',
    letterSpacing: 0,
  },
  headline6: {
    fontSize: 20,
    fontWeight: '500',
    letterSpacing: 0.15,
  },
  subtitle1: {
    fontSize: 16,
    fontWeight: '400',
    letterSpacing: 0.15,
  },
  subtitle2: {
    fontSize: 14,
    fontWeight: '500',
    letterSpacing: 0.1,
  },
  body1: {
    fontSize: 16,
    fontWeight: '400',
    letterSpacing: 0.5,
  },
  body2: {
    fontSize: 14,
    fontWeight: '500',
    letterSpacing: 0.25,
  },
  caption: {
    fontSize: 14,
    fontWeight: '400',
    letterSpacing: 0.4,
    color: `${colors.TERTIARY}AA`,
  },
  overtline: {
    fontSize: 10,
    fontWeight: '400',
    letterSpacing: 1.5,
  },
  bold: {
    fontWeight: '800',
  },
});

export default {
  getHeightByPercentage,
  getWidthByPercentage,
  getAdjustedSize,
  getAdjustedTextSize,
  getWindowWidth,
  getWindowHeight,
  isMobile,
  responsiveMaxWidth,
  responsiveMaxHeight,
  buttonHeight,
  inputHeight,
  borderRadius,
  ...styles,
  colors,
};
