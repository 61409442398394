import { logger } from '@/libs/logger';
import { SnackBar } from '@components';
import AppConfig from '@config';
import useLocalization from '@localizations';
import { useNavigation } from '@react-navigation/native';
import {
  Company,
  dispatchUnlockDoor,
  Reservation,
  useAppContext,
  useAppStore,
} from '@store';
import { YourKeyIconBlack } from '@svgs';
import { Actionsheet } from 'native-base';
import { lighten } from 'polished';
import React, { useCallback, useState } from 'react';
import { Linking, StyleSheet, View } from 'react-native';
import { Button, FAB, Text, useTheme } from 'react-native-paper';
import { SwipeLockButton } from './SwipeLockButton';

const getAccessCode = (company: Company, reservation?: Reservation): string =>
  (company?.incident?.paymentConfirmed && company?.incident?.paymentEnabled) ||
  company?.incident?.paymentEnabled === false
    ? reservation?.accessCode ?? 'unknown'
    : '******';

interface IStayMyWaySheetProps {
  onDismiss?: () => void;
  isOpen?: boolean;
}

const StayMyWaySheet: React.FC<IStayMyWaySheetProps> = ({
  onDismiss,
  isOpen,
}) => {
  const paperTheme = useTheme();
  const { t } = useLocalization();
  const navigation = useNavigation();
  const [loadingLock, setLoadingLock] = useState(false);
  const { company, reservation, theme } = useAppStore();
  const { locale } = useAppContext();

  /**
   * Swipe To Unlock - does API call then redirect to a popup modal page for results
   */
  const onSwipeToUnlock = useCallback(async () => {
    setLoadingLock(true);
    let success = true;
    try {
      if (company) {
        await dispatchUnlockDoor(company.id, company.reservation as string);
      }
    } catch (error) {
      logger.error(error);
      success = false;
    }

    navigation.navigate('Confirmation', {
      contentTitle: t(
        success ? 'unlock_success_title' : 'unlock_unsuccessful_title',
      ),
      contentDescription: t(
        success ? 'unlock_success_subtitle' : 'unlock_unsuccessful_subtitle',
      ),
    });

    setLoadingLock(false);
    onDismiss?.();
  }, [t, navigation, onDismiss, company]);

  const lighterPrimary = lighten(0.1, paperTheme.colors.primary);
  const lastName = reservation?.guestName.split(/[, ]+/).pop() || '';
  const [snackbarTitle, setSnackbarTitle] = useState('');
  const [visibleSnack, setVisibleSnack] = useState(false);
  const [colorSnack, setColorSnack] = useState('green');
  const dataObj = `~MKOPERTOID~/${company?.id}_${reservation?.id}/${lastName}/${locale}/${paperTheme.colors.primary}/${theme?.dashboardLogoUrl}`;

  const copyToClipboard = (
    text: string,
    title: string,
    callback: (isDone: boolean | null) => void,
  ) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setSnackbarTitle(`${title} has been copied successfully.`);
        setColorSnack('green');
        setVisibleSnack(true);
        callback(true);
      })
      .catch(err => {
        logger.error(err.toString());
        setSnackbarTitle(`${title} has NOT copied successfully.`);
        setColorSnack('red');
        setVisibleSnack(true);
        callback(false);
      });
  };

  const yourKeyIconBlack = () => {
    return (
      <YourKeyIconBlack
        throwIfNamespace={false}
        width={AppConfig.Styles.getAdjustedSize(40)}
      />
    );
  };

  return (
    <Actionsheet isOpen={isOpen} onClose={onDismiss} hideDragIndicator>
      <Actionsheet.Content
        style={{ backgroundColor: paperTheme.colors.primary }}>
        <View
          style={[
            styles.sheetContent,
            { backgroundColor: paperTheme.colors.primary },
          ]}>
          <Text
            style={[
              AppConfig.Styles.subtitle1,
              {
                color: 'white',
                marginBottom: AppConfig.Styles.getAdjustedSize(16),
                marginTop: AppConfig.Styles.getAdjustedSize(38),
              },
            ]}>
            {t('your_booking_code')}
          </Text>
          <Text
            style={[
              AppConfig.Styles.subtitle2,
              {
                color: 'white',
                marginBottom: AppConfig.Styles.getAdjustedSize(36),
                textAlign: 'center',
              },
            ]}>
            {reservation?.smw?.booking_code}
          </Text>
          {company?.lockControlEnabled && (
            <SwipeLockButton
              testID="smw-sheet-swipe-lock-button"
              onEndReached={onSwipeToUnlock}
              loading={loadingLock}
              disabled={
                reservation?.incident.paymentEnabled &&
                !reservation?.incident.paymentConfirmed
              }
            />
          )}
          {reservation?.smw && (
            <Button
              style={[styles.downloadBtn]}
              icon={yourKeyIconBlack}
              onPress={() => {
                copyToClipboard(
                  dataObj,
                  'Login Credential',
                  (isDone: boolean | null) => {
                    if (isDone) {
                      Linking.openURL(reservation.smw?.deeplink_url || '');
                    }
                  },
                );
              }}>
              <Text
                style={{
                  color: 'black',
                  fontSize: 16,
                  textTransform: 'none',
                  fontWeight: '600',
                  marginLeft:
                    (AppConfig.Styles.getWindowWidth() - 121) / 4 -
                    AppConfig.Styles.getAdjustedSize(40),
                }}>
                {t('launch_yourkey_plus')}
              </Text>
            </Button>
          )}
          <FAB
            icon="close"
            onPress={onDismiss}
            style={{
              backgroundColor: lighterPrimary,
              height: '70px',
              width: '70px',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'center',
              borderRadius: 35,
              marginBottom: AppConfig.Styles.getAdjustedSize(48),
            }}
          />
        </View>
        <SnackBar
          title={snackbarTitle}
          visible={visibleSnack}
          color={colorSnack}
          onDismiss={() => {
            setVisibleSnack(false);
          }}
        />
      </Actionsheet.Content>
    </Actionsheet>
  );
};

const styles = StyleSheet.create({
  sheetContent: {
    width: '100%',
    minWidth: AppConfig.Styles.getAdjustedSize(300),
    maxWidth: AppConfig.Styles.responsiveMaxWidth,
    padding: AppConfig.Styles.getAdjustedSize(15),
    alignItems: 'center',
    alignSelf: 'center',
    paddingLeft: AppConfig.Styles.getAdjustedSize(32),
    paddingRight: AppConfig.Styles.getAdjustedSize(32),
  },
  downloadBtn: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    borderRadius: 40,
    backgroundColor: 'white',
    padding: 15,
    paddingLeft: 1,
    marginBottom: AppConfig.Styles.getAdjustedSize(48),
  },
});

export { getAccessCode, StayMyWaySheet };
