import { Locale } from 'date-fns';
import dateFnsFormat from 'date-fns/format';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';

/**
 * Lib to help with formatting dates
 * Will handle localized dates as well, just need to pass in the locale
 */

interface DateFormatOptions {
  addSuffix?: boolean;
  apiFormat?: boolean;
  format?: string;
  locale?: string;
  strict?: boolean;
  timeAgo?: boolean;
}
export const DateFormat = (
  date: Date,
  {
    addSuffix = true,
    apiFormat = false,
    format,
    locale,
    strict = false,
    timeAgo = false,
  }: DateFormatOptions = {},
): string => {
  const options: {
    addSuffix?: boolean;
    unit?: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year';
    roundingMethod?: 'floor' | 'ceil' | 'round';
    locale?: Locale;
  } = {
    addSuffix,
  };

  // @@ Locale
  if (locale) {
    let safeLocale = locale;
    if (locale === 'en') {
      safeLocale += '-CA';
    } else if (locale === 'zh') {
      safeLocale += '-CN';
    }

    // eslint-disable-next-line @typescript-eslint/no-var-requires
    options.locale = require(`date-fns/locale/${safeLocale}`).default;
  }

  // @@ Timeago
  if (timeAgo) {
    if (strict) {
      return formatDistanceToNowStrict(date, options);
    }
    return formatDistanceToNow(date, options);
  }

  // @@ Format
  let formatStr = format ?? (locale ? 'PPPP' : 'yyy-MM-dd');
  if (apiFormat) {
    formatStr = 'yyyy-MM-dd HH:mm:ss';
  }

  return dateFnsFormat(date, formatStr, options);
};
