import { REGION } from '@/assets/configurations/Settings';
import {
  CognitoIdentityProviderClient,
  UpdateUserAttributesCommand,
  UpdateUserAttributesCommandOutput,
} from '@aws-sdk/client-cognito-identity-provider';
import { OTP_ERROR_MESSAGES } from '@operto/otp-service-shared';

export const updateUserPhoneNumberAttribute = async (
  number: string,
  accessToken: string,
): Promise<UpdateUserAttributesCommandOutput> => {
  const client = new CognitoIdentityProviderClient({ region: REGION });

  const input = {
    UserAttributes: [
      {
        Name: 'phone_number',
        Value: number,
      },
    ],
    AccessToken: accessToken,
  };

  const command = new UpdateUserAttributesCommand(input);

  return client.send(command);
};

/**
 * Handles error messages related to phone number OTP verification.
 *
 * Maps specific OTP error messages to corresponding UI messages or keys.
 * This function interprets the error message received during OTP verification
 * and returns a corresponding user-friendly message or UI key.
 *
 * @param errorMessage - The error message string from OTP verification failure.
 * @returns A translation file key string representing a user-friendly message or a UI key to be displayed to the user.
 */
export const handlePhoneNumberVerificationErrorResponse = (
  errorMessage: string,
) => {
  switch (errorMessage) {
    case OTP_ERROR_MESSAGES.EXPIRED_OTP_ERROR:
      return 'confirm_phone_number_resend_expired';
    case OTP_ERROR_MESSAGES.MAX_ATTEMPTS_REACHED_ERROR:
      return 'confirm_phone_number_resend_max_attempts_reached';

    // If OTP is invalid or not found, return a generic resend error key
    // Note: This case should ideally be more specific, and the product team should provide further details.
    case OTP_ERROR_MESSAGES.INVALID_OTP_ERROR:
    case OTP_ERROR_MESSAGES.NOT_FOUND_OTP_ERROR:
      return 'confirm_phone_number_resend_error'; // more specificity needed, awaiting input from product team [SHS]
    default:
      return 'confirm_phone_number_resend_error';
  }
};
