import { isTermsChallengeRequired } from '@/libs/ChallengeHelper';
import AppConfig from '@config';
import { DecodeHtmlFormat } from '@libs';
import { useAppStore } from '@store';
import { LoginWelcomeLogo } from '@svgs';
import { View } from 'react-native';
import { useTheme } from 'react-native-paper';
import { ButtonView, ContentView, HeaderView } from './Common';
import { LoginStyles } from './Styles';

export const Welcome = () => {
  const themeProvider = useTheme();
  const { company, host, theme } = useAppStore();

  return (
    <View style={[AppConfig.Styles.container, AppConfig.Styles.center]}>
      <View style={[AppConfig.Styles.container, LoginStyles.container]}>
        <View style={[LoginStyles.contentContainer]}>
          {theme && <HeaderView theme={theme} />}
          <ContentView
            title={
              host?.welcomeTitle ? DecodeHtmlFormat(host?.welcomeTitle) : ''
            }
            description={
              host?.welcomeMessage ? DecodeHtmlFormat(host?.welcomeMessage) : ''
            }>
            {theme?.welcomeImagesEnabled && (
              <LoginWelcomeLogo
                style={{ color: themeProvider.colors.primary }}
                width={AppConfig.Styles.getAdjustedSize(230)}
              />
            )}
          </ContentView>
        </View>
        <ButtonView
          company={company}
          currentPage="Welcome"
          backButton={!isTermsChallengeRequired(company)}
        />
      </View>
    </View>
  );
};

export default Welcome;
