import { INavigationParamsProps, Spacer } from '@components';
import AppConfig from '@config';
import useLocalization, { TranslationKey } from '@localizations';
import { useNavigation } from '@react-navigation/native';
import { Amenity, useAppStore } from '@store';
import assign from 'lodash/assign';
import map from 'lodash/map';
import memoize from 'lodash/memoize';
import sortBy from 'lodash/sortBy';
import sortedUniq from 'lodash/sortedUniq';
import { useCallback, useState } from 'react';
import { FlatList, StyleSheet, View, useWindowDimensions } from 'react-native';
import { Button, Caption, Card, Title, useTheme } from 'react-native-paper';
import { SceneMap, TabBar, TabView } from 'react-native-tab-view';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

const filterAmenitiesMemo = memoize(
  (filter = 'shopping', amenities: Amenity[]) => {
    const results = amenities.filter(
      (amenity: Amenity) => amenity.type.toLowerCase() === filter.toLowerCase(),
    );

    return sortBy(results, ['title']);
  },
);

const AmenityView = ({ amenity }: { amenity: Amenity }) => {
  const navigation = useNavigation();
  const { t } = useLocalization();

  const onAmenityPress = useCallback(
    () =>
      navigation.navigate('Amenity', {
        title: amenity?.title,
        headerBackgroundImage: amenity?.imageUrl,
        headerHeight: 250,
        model: amenity,
      } as INavigationParamsProps),
    [navigation, amenity],
  );

  return (
    <Card
      elevation={3}
      style={{
        marginVertical: AppConfig.Styles.getAdjustedSize(10),
      }}>
      <Card.Cover source={{ uri: amenity?.imageUrl }} />
      <Spacer size={2} />
      <Card.Content>
        {amenity?.title?.length > 0 && <Title>{amenity?.title}</Title>}
        {amenity?.subtitle?.length > 0 && (
          <Caption>{amenity?.subtitle}</Caption>
        )}
        {amenity?.description?.length > 0 && (
          <Caption numberOfLines={4} ellipsizeMode="tail">
            {amenity?.description}
          </Caption>
        )}
      </Card.Content>
      <Card.Actions>
        <Button onPress={onAmenityPress}>{t('more')}</Button>
        <View style={AppConfig.Styles.container} />
        {/* GP-201: Hide Share button on React GP
        <IconButton
          icon='share-variant'
          color={theme.colors.primary}
          size={AppConfig.Styles.getAdjustedSize(25)}
          onPress={() =>
            sharePlace(
              amenity,
              t('amenities_framing_text'),
            )
          }
        /> */}
      </Card.Actions>
    </Card>
  );
};

const TabContentView = ({ route: { key } }: { route: { key: string } }) => {
  const [height, setHeight] = useState(0);
  const { amenities } = useAppStore();
  const filteredAmenities: Amenity[] | undefined = filterAmenitiesMemo(
    key,
    amenities ?? [],
  );

  return (
    <FlatList
      onLayout={event => {
        setHeight(event.nativeEvent.layout.height);
      }}
      style={[styles.body, { height }]}
      data={filteredAmenities}
      renderItem={({ item }) => <AmenityView amenity={item} />}
      keyExtractor={item => item.id}
    />
  );
};

// @ts-ignore - type inferred
const TabBarView = props => {
  const theme = useTheme();

  return (
    <TabBar
      {...props}
      style={{ backgroundColor: theme.colors.primary }}
      indicatorStyle={{ backgroundColor: theme.colors.background }}
      inactiveColor={`${theme.colors.background}88`}
      renderIcon={({ route, color }) => {
        let icon = 'map-marker';
        switch (route.key) {
          case 'shopping':
            icon = 'cart-plus';
            break;
          case 'dining':
            icon = 'silverware';
            break;
          default:
            icon = 'map-marker';
            break;
        }

        return (
          <Icon
            name={icon}
            color={color}
            size={AppConfig.Styles.getAdjustedSize(20)}
          />
        );
      }}
    />
  );
};

export const Amenities = () => {
  const layout = useWindowDimensions();
  const [index, setIndex] = useState(0);
  const { t } = useLocalization();

  // contruct tabs from amenities data using its types
  const { amenities } = useAppStore();
  const amenitiesKey = sortedUniq(map(amenities, 'type')) as TranslationKey[];

  const [routes] = useState(
    amenitiesKey.map(key => ({
      key,
      title: t(key),
    })),
  );

  const [scenes] = useState(
    assign({}, ...amenitiesKey.map(k => ({ [k]: TabContentView }))),
  );

  return (
    <TabView
      navigationState={{ index, routes }}
      renderScene={SceneMap(scenes)}
      renderTabBar={TabBarView}
      onIndexChange={setIndex}
      initialLayout={{ width: layout.width }}
    />
  );
};

export default Amenities;

const styles = StyleSheet.create({
  body: {
    width: '100%',
    alignSelf: 'center',
    maxWidth: AppConfig.Styles.responsiveMaxWidth,
    padding: AppConfig.Styles.getAdjustedSize(20),
  },
});
