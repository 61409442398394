import { logger } from '@/libs/logger';
import {
  useAppContext,
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from '@store';
import useVariables from '@store/variables/hooks';
import uniqBy from 'lodash/uniqBy';
import { useEffect, useMemo } from 'react';
import { CategoryItem, ContentItem } from './models';
import {
  selectGuidesCategories,
  selectGuidesContents,
  selectGuidesSections,
} from './slice';
import {
  UNCATEGORIZED_ID,
  getCategories,
  getContents,
  getSection,
  getUncategorizedContents,
} from './thunks';

export type GuidesSectionsRouteParams = {
  sectionId: string;
};

export const useGuidesSections = (params?: GuidesSectionsRouteParams) => {
  const dispatch = useAppDispatch();
  const { locale } = useAppContext();
  const { reservation, company } = useAppStore();
  const sections = useAppSelector(selectGuidesSections);
  const selectedSection = sections?.find(
    c => c.section_id === params?.sectionId,
  );

  useEffect(() => {
    const fetchData = async () => {
      if (company && reservation?.property?.id) {
        logger.debug('fetching sections');

        dispatch(
          getSection({
            propertyId: reservation.property.id,
            locale,
            companyCode: company.id,
          }),
        );
      }
    };

    fetchData();
  }, [dispatch, locale, reservation?.property?.id, company]);

  return {
    sections,
    selectedSection,
  };
};

export type GuidesCategoriesRouteParams = {
  sectionId: string;
  categoryId: string;
};

export const useGuidesCategories = (params?: GuidesCategoriesRouteParams) => {
  const dispatch = useAppDispatch();
  const { locale } = useAppContext();
  const { reservation, company } = useAppStore();
  const { uncategorized, categories, categoriesOrder } = useAppSelector(
    selectGuidesCategories,
  );

  // calculated value
  const sorted: (CategoryItem | ContentItem)[] = useMemo(() => {
    const sortedCategoriesAndContents: (CategoryItem | ContentItem)[] = [];
    let allCategoriesAndContents: (CategoryItem | ContentItem)[] = [];
    if (categories) {
      allCategoriesAndContents = [...allCategoriesAndContents, ...categories];
    }

    if (uncategorized?.length) {
      allCategoriesAndContents = [
        ...allCategoriesAndContents,
        ...uncategorized,
      ];
    }

    categoriesOrder?.forEach(categoryOrContentOrder => {
      // find category or content
      const categoryOrContent = allCategoriesAndContents.find(item => {
        if (item.section_id !== params?.sectionId) {
          return false;
        }

        if ('content_id' in item) {
          return item.content_id === categoryOrContentOrder.id;
        }

        return (
          item.category_id !== UNCATEGORIZED_ID &&
          item.category_id === categoryOrContentOrder.id
        );
      });

      // skip if not found
      if (!categoryOrContent) {
        return;
      }

      // add to sorted list
      if ('content_id' in categoryOrContent) {
        sortedCategoriesAndContents.push(categoryOrContent);
      } else if (categoryOrContent.category_id !== UNCATEGORIZED_ID) {
        sortedCategoriesAndContents.push(categoryOrContent);
      }
    });
    // so far, sortedCategoriesAndContents contains categories in order, and contents if possible

    // if there was no contents in category order, and we had uncategorize data, add it to the end
    const hasUncategorizedInCategoryOrder = categoriesOrder?.find(
      categoryOrder => categoryOrder.type === 'content',
    );

    if (!hasUncategorizedInCategoryOrder && uncategorized?.length) {
      sortedCategoriesAndContents.push(...uncategorized);
    }
    return uniqBy(sortedCategoriesAndContents, f =>
      'content_id' in f
        ? `${f.section_id}-${f.category_id}-${f.content_id}`
        : `${f.section_id}-${f.category_id}`,
    );
  }, [categories, categoriesOrder, params?.sectionId, uncategorized]);

  const { contents: processedCategories } = useVariables(sorted);
  const selectedCategory = processedCategories?.find(
    c => c.category_id === params?.categoryId,
  );

  useEffect(() => {
    const fetchData = async () => {
      if (company && params?.sectionId && reservation?.property?.id) {
        logger.debug('fetching categories');

        dispatch(
          getCategories({
            propertyId: reservation.property.id,
            sectionId: params?.sectionId,
            locale,
            companyCode: company.id,
          }),
        );

        dispatch(
          getUncategorizedContents({
            propertyId: reservation.property.id,
            sectionId: params?.sectionId,
            locale,
            companyCode: company.id,
          }),
        );
      }
    };

    fetchData();
  }, [dispatch, locale, reservation?.property?.id, params?.sectionId, company]);

  return {
    categories: processedCategories,
    hasUncategorized: !!uncategorized?.length,
    selectedCategory,
  };
};

export type GuidesContentsRouteParams = {
  sectionId: string;
  categoryId: string;
  contentId: string;
  title?: string;
};

export const useGuidesContents = (params?: GuidesContentsRouteParams) => {
  const dispatch = useAppDispatch();
  const { locale } = useAppContext();
  const { reservation, company } = useAppStore();
  const { categories } = useGuidesCategories(params);
  const { contents: uncategorizedContents } = useVariables(categories);

  const rawCategorizedContents = useAppSelector(selectGuidesContents);
  const { contents: categorizedContents } = useVariables(
    rawCategorizedContents,
  );

  const combinedContentItems = [
    ...(categorizedContents ?? []),
    ...(uncategorizedContents?.filter(item => 'content_id' in item) ?? []),
  ] as ContentItem[];
  const selectedContent = combinedContentItems?.find(
    c =>
      c.content_id === params?.contentId &&
      c.section_id === params?.sectionId &&
      c.category_id === params?.categoryId,
  );

  useEffect(() => {
    const fetchData = async () => {
      if (
        company &&
        params?.sectionId &&
        params?.categoryId &&
        reservation?.property?.id
      ) {
        logger.debug('fetching contents');

        dispatch(
          getContents({
            propertyId: reservation.property.id,
            sectionId: params?.sectionId,
            categoryId: params?.categoryId,
            locale,
            companyCode: company.id,
          }),
        );
      }
    };

    fetchData();
  }, [
    dispatch,
    locale,
    params?.categoryId,
    params?.sectionId,
    reservation?.property?.id,
    company,
  ]);

  return {
    categorizedContents,
    selectedContent,
  };
};
