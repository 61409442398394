// @ts-ignore - no types definition available for RNW
import { unstable_createElement as createElement } from 'react-native-web';

/**
 * use web specific API to create platform agnostic video view component
 */
type VideoViewProps = {
  source: string;
  poster?: string;
};

export const VideoView = ({ source, poster }: VideoViewProps) => {
  const attrs = {
    src: `${source}#t=0.001`, // NOTE: #t=0.001 is for iOS Safari to have preview
    poster: poster,
    width: '100%',
    height: 'auto',
    controls: 'controls',
  };

  return createElement('video', attrs);
};

export default VideoView;
