import { BUGSNAG_API_KEY, RELEASE_STAGE } from '@/libs/constants';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { store } from '@store';
import { NativeBaseProvider } from 'native-base';
import React from 'react';
import { en, registerTranslation } from 'react-native-paper-dates';
import { Provider as ReduxProvider } from 'react-redux';
import Root from './root';

registerTranslation('en', en);

Bugsnag.start({
  apiKey: BUGSNAG_API_KEY,
  releaseStage: RELEASE_STAGE,
  plugins: [new BugsnagPluginReact()],
});
const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React);

const App = () => {
  const renderApp = (
    <NativeBaseProvider>
      <ReduxProvider store={store}>
        <Root />
      </ReduxProvider>
    </NativeBaseProvider>
  );

  if (ErrorBoundary) {
    return <ErrorBoundary>{renderApp}</ErrorBoundary>;
  }

  return renderApp;
};

export default App;
