import AppConfig from '@config';
import useLocalization from '@localizations';
import { useEffect, useRef, useState } from 'react';
import { Animated, Easing, PanResponder, StyleSheet, View } from 'react-native';
import { ActivityIndicator, FAB, Text, useTheme } from 'react-native-paper';

interface SwipeLockButtonProps {
  testID?: string;
  loading?: boolean;
  disabled?: boolean;
  onEndReached: () => void;
}
export const SwipeLockButton = ({
  onEndReached,
  disabled = false,
  loading = false,
  testID,
}: SwipeLockButtonProps) => {
  const theme = useTheme();
  const { t } = useLocalization();
  const [swipeRange, setSwipeRange] = useState(0);
  const [wasSwiped, setWasSwiped] = useState(false);
  const pan = useRef(new Animated.Value(0)).current;
  const panResponder = useRef(
    PanResponder.create({
      onMoveShouldSetPanResponder: () => true,
      onPanResponderTerminationRequest: () => false,
      onPanResponderMove: Animated.event([null, { dx: pan }], {
        useNativeDriver: true,
      }),
      onPanResponderRelease: () => {
        setWasSwiped(true);
        Animated.timing(pan, {
          toValue: 0,
          duration: 300,
          easing: Easing.inOut(Easing.ease),
          useNativeDriver: true,
        }).start();
      },
    }),
  ).current;

  useEffect(() => {
    if (wasSwiped) {
      setWasSwiped(false);

      if (parseFloat(JSON.stringify(pan)) >= swipeRange) {
        onEndReached();
      }
    }
  }, [wasSwiped, swipeRange, pan, onEndReached]);

  return (
    <View
      testID={testID ?? 'swipe-lock-button'}
      style={[styles.lockContainer]}
      onLayout={event => {
        setSwipeRange(
          // calculate swipe range by subtructing size of button + padding to the component's width
          event.nativeEvent.layout.width -
            AppConfig.Styles.getAdjustedSize(113),
        );
      }}>
      <View style={[AppConfig.Styles.center, StyleSheet.absoluteFill]}>
        {loading ? (
          <ActivityIndicator
            animating
            color={theme.colors.primary}
            size={AppConfig.Styles.getAdjustedSize(40)}
          />
        ) : (
          <Text
            style={[
              disabled ? { opacity: 0.3 } : AppConfig.Styles.lighterText,
            ]}>
            {t('unlock_control_subtitle')}
          </Text>
        )}
      </View>
      <Animated.View
        {...(disabled ? null : panResponder.panHandlers)}
        style={[
          styles.fab,
          {
            transform: [
              {
                translateX: pan.interpolate({
                  inputRange: [0, Math.max(swipeRange, 1)],
                  outputRange: [0, Math.max(swipeRange, 1)],
                  extrapolate: 'clamp',
                }),
              },
            ],
          },
        ]}>
        <FAB
          style={{
            alignItems: 'center',
            opacity: disabled ? 0.3 : 1,
            backgroundColor: theme.colors.primary,
          }}
          icon="lock"
        />
      </Animated.View>
    </View>
  );
};

const styles = StyleSheet.create({
  lockContainer: {
    width: '100%',
    height: AppConfig.Styles.getAdjustedSize(70),
    backgroundColor: 'lightgray',
    borderWidth: StyleSheet.hairlineWidth,
    borderRadius: AppConfig.Styles.getAdjustedSize(40),
    borderColor: 'transparent',
    marginBottom: AppConfig.Styles.getAdjustedSize(20),
    padding: AppConfig.Styles.getAdjustedSize(5),
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
  },
  fab: {
    width: AppConfig.Styles.getAdjustedSize(100),
    height: AppConfig.Styles.getAdjustedSize(65),
  },
});

export default SwipeLockButton;
