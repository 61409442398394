import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { MD3DarkTheme, Text } from 'react-native-paper';

export default function OpertoActivityIndicator({
  message,
}: ActivityIndicatorProps) {
  return (
    <View style={styles.container}>
      <ActivityIndicator animating={true} size={48} style={{ margin: 16 }} />

      {message && <Text style={styles.sentCodeMessage}>{message}</Text>}
    </View>
  );
}

const styles = StyleSheet.create({
  container: { alignItems: 'center' },
  sentCodeMessage: {
    ...MD3DarkTheme.fonts.headlineMedium,
    color: 'white',
    marginBottom: 16,
    textAlign: 'center',
  },
});

interface ActivityIndicatorProps {
  message?: string;
}
