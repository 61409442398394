import useLocalization from '@/assets/localizations';
import { logger } from '@/libs/logger';
import {
  CategoryItem,
  ContentItem,
  SectionItem,
  getNavigationParamsForCategory,
  getNavigationParamsForContent,
  getNavigationParamsForSection,
} from '@/store/guides';
import AppConfig from '@config';
import { trackEvent } from '@libs';
import { useNavigation } from '@react-navigation/native';
import { FeatureItem, useFeatureList } from '@store/featured-list';
import { Image, StyleSheet, View } from 'react-native';
import { List, MD2Colors, Text } from 'react-native-paper';
import RenderHtml from 'react-native-render-html';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import ReactPlayer from 'react-player';

const renderThumbnail = (featuredItem: FeatureItem) => {
  const renderPlaceholder = () => {
    if (featuredItem.cover?.length) {
      return (
        <Image
          style={{ width: 84, height: 84 }}
          source={{
            uri: `${featuredItem.cover}?tr=w-84,h-84`,
          }}
        />
      );
    }

    if (
      'video_url' in featuredItem.data &&
      featuredItem.data.video_url?.length
    ) {
      <ReactPlayer
        light
        url={featuredItem.data.video_url}
        height={84}
        width={84}
        style={{ marginRight: 24 }}
      />;
    }

    return (
      <Icon
        name="image-area"
        color={MD2Colors.grey700}
        size={AppConfig.Styles.getAdjustedSize(50)}
      />
    );
  };

  return <View style={styles.thumbnail}>{renderPlaceholder()}</View>;
};

const renderChevron = () => {
  return (
    <View style={{ justifyContent: 'center' }}>
      <List.Icon color={MD2Colors.grey900} icon="chevron-right" />
    </View>
  );
};

export const ReservationFeaturedList = () => {
  const navigation = useNavigation();
  const { featureList } = useFeatureList();
  const filteredList = featureList?.filter(
    f => 'published' in f.data && f.data?.published,
  );
  const { t } = useLocalization();

  const onListItemPress = (
    type: string,
    data: ContentItem | CategoryItem | SectionItem | null,
  ) => {
    switch (type) {
      case 'category':
        {
          const categoryObj = data as CategoryItem;

          trackEvent('Home', 'Featured:Clicked', {
            title: categoryObj?.category,
            type: 'category',
            id: categoryObj?.category_id,
          });

          navigation.navigate(
            'Category',
            getNavigationParamsForCategory(categoryObj),
          );
        }
        break;
      case 'content': {
        const contentObj = data as ContentItem;

        trackEvent('Home', 'Featured:Clicked', {
          title: contentObj?.title,
          type: 'page',
          id: contentObj?.content_id,
        });

        navigation.navigate(
          'Content',
          getNavigationParamsForContent(contentObj),
        );
        break;
      }
      case 'section': {
        const sectionObj = data as SectionItem;

        trackEvent('Home', 'Featured:Clicked', {
          title: sectionObj?.section,
          type: 'guide',
          id: sectionObj?.section_id,
        });

        navigation.navigate(
          'Section',
          getNavigationParamsForSection(sectionObj),
        );
        break;
      }
      default:
        logger.debug('not supported');
    }
  };

  return (
    <View>
      {/**
       * NOTE: we can probaly optimize this more without looping multiple times.
       * for e.g. combine filter and mapping in 1 iteration
       */}
      {filteredList && filteredList?.length > 0 && (
        <Text
          variant="titleLarge"
          style={{
            paddingHorizontal: AppConfig.Styles.getAdjustedSize(16),
            paddingVertical: AppConfig.Styles.getAdjustedSize(18),
          }}>
          {t('booking_featured_for_you_header')}
        </Text>
      )}
      {filteredList?.map(val => (
        <View
          key={val.id}
          style={{ height: AppConfig.Styles.getAdjustedSize(84) }}>
          <List.Item
            key={val.id}
            title={
              <RenderHtml
                source={{
                  html: val.title || '',
                }}
              />
            }
            titleStyle={AppConfig.Styles.body1}
            left={() => renderThumbnail(val)}
            right={renderChevron}
            onPress={() => onListItemPress(val.type, val.data)}
            style={AppConfig.Styles.listItem}
          />
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  thumbnail: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 84,
    height: 84,
    backgroundColor: MD2Colors.grey300,
    borderBottomWidth: 1,
    borderBottomColor: MD2Colors.blueGrey100,
  },
});
