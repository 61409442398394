import { useAppContext } from '@store';
import { I18n, TranslateOptions } from 'i18n-js';
import en from './en.json';

const i18n = new I18n({ en });
i18n.enableFallback = true;

export type TranslationKey = keyof typeof en;
export type SupportedLocale = 'en' | 'fr' | 'de' | 'it' | 'es' | 'cs';
export type SupportedLanguage = {
  name: string;
  locale: SupportedLocale;
};

const languages: SupportedLanguage[] = [
  { name: 'English', locale: 'en' },
  { name: 'Français', locale: 'fr' },
  { name: 'Deutsch', locale: 'de' },
  // { name: 'Indonesian', locale: 'id' },
  { name: 'Italiano', locale: 'it' },
  // { name: 'Japanese', locale: 'ja' },
  // { name: 'Chinese (Simplified)', locale: 'zh' },
  { name: 'Español', locale: 'es' },
  { name: 'Čeština', locale: 'cs' },
];

const loader = {
  // lazy requires (metro bundler does not support symlinks)
  de: () => require('./de.json'),
  en: () => require('./en.json'),
  es: () => require('./es.json'),
  fr: () => require('./fr.json'),
  // id: () => require('./id.json'),
  it: () => require('./it.json'),
  // ja: () => require('./ja.json'),
  // pt: () => require('./pt.json'),
  // zh: () => require('./zh.json'),
  cs: () => require('./cs.json'),
};

const translate = (scope: TranslationKey, options?: TranslateOptions) => {
  return i18n.t(scope, options);
};

export const useLocalization = () => {
  const { locale } = useAppContext();

  if (i18n.locale !== locale && loader[locale]) {
    i18n.locale = locale;
    i18n.store({ [locale]: loader[locale]() });
  }

  return {
    t: translate,
    languages,
  };
};

export default useLocalization;
