import { utcToZonedTime } from 'date-fns-tz';

/**
 * Converts a unix timestamp to a Date object
 * @param unixTimestamp unix timestamp in seconds
 * @returns Date
 */
export const unixTimestampToDate = (unixTimestamp: number) => {
  const milliseconds = unixTimestamp * 1000;
  return new Date(milliseconds);
};

/**
 * Determines if a timestamp is earlier than now
 * @param timestamp date and time to compare
 * @param timestampTimeZone timezone of the timestamp
 * @returns boolean
 */
export const isTimestampEarlierThanNow = (
  timestamp?: number,
  timestampTimeZone?: string,
) => {
  if (!timestamp) {
    return false;
  }

  let now = new Date();
  const until = unixTimestampToDate(timestamp);

  if (timestampTimeZone) {
    now = utcToZonedTime(now, timestampTimeZone);
  }

  return now.valueOf() > until.valueOf();
};
