// @ts-nocheck - fix me
import { Spacer } from '@components';
import AppConfig from '@config';
import { trackEvent } from '@libs';
import useLocalization from '@localizations';
import { useNavigation } from '@react-navigation/native';
import { useAppStore } from '@store';
import { useEffect } from 'react';

import { VERIFICATIONFingerprint } from '@svgs';
import { useState } from 'react';
import { View } from 'react-native';
import { Button, Checkbox, Text } from 'react-native-paper';
import Svg from 'react-native-svg';
import { useVerification } from '../../../store/verification/hooks';
import { ContentView, HeaderView } from '../../login/Common';
import { LoginStyles } from '../../login/Styles';

// @@ Action view
const ActionView = () => {
  const { verification, setVerification } = useVerification();
  const navigation = useNavigation();
  const [approved, setApproved] = useState(false);
  const checkboxGetter = () => (approved ? 'checked' : 'unchecked');
  const onPressApprove = () => {
    setVerification({ ...verification, termsAccepted: true });
    trackEvent('Superhog', 'Terms>Next:Clicked');
    navigation.navigate('Details');
  };

  const { t } = useLocalization();

  useEffect(() => {
    trackEvent('Superhog', 'Welcome:Loaded');
  }, []);

  return (
    <View
      style={{
        width: '100%',
        paddingHorizontal: AppConfig.Styles.getAdjustedSize(24),
        paddingBottom: AppConfig.Styles.getAdjustedSize(24),
      }}>
      <View
        style={[
          AppConfig.Styles.container,
          {
            borderTopColor: '#dddddd',
            borderTopWidth: 1,
            paddingTop: 20,
            marginTop: 20,
          },
        ]}>
        <View
          style={[
            AppConfig.Styles.rowContainer,
            { justifyContent: 'space-between' },
          ]}>
          <Text
            style={[
              AppConfig.Styles.body1,
              AppConfig.Styles.textAlignCenter,
              AppConfig.Styles.lighterText,
            ]}>
            {t('sh_accept')}
          </Text>
          <Checkbox.Android
            testID="checkbox-approval"
            status={checkboxGetter()}
            color={approved ? 'green' : AppConfig.Colors.ON_SECONDARY}
            onPress={() => {
              setApproved(!approved);
            }}
          />
        </View>
        <View
          style={[
            AppConfig.Styles.left,
            {
              borderTopColor: '#dddddd',
              borderTopWidth: 1,
              paddingTop: 20,
              marginTop: 20,
            },
          ]}>
          <Text
            style={[
              AppConfig.Styles.subtitle2,
              AppConfig.Styles.textAlignCenter,
              AppConfig.Styles.lighterText,
            ]}>
            {t('sh_disclaimer')}{' '}
            <a
              href="https://superhog.com/privacy-policydata-protection-policy/"
              target="_blank"
              rel="noreferrer">
              {t('sh_private_policy')}
            </a>
          </Text>
          <Spacer />
        </View>
        <View
          style={[
            AppConfig.Styles.rowContainer,
            {
              justifyContent: 'flex-end',
              borderTopColor: '#dddddd',
              borderTopWidth: 1,
              paddingTop: 20,
              marginTop: 20,
            },
          ]}>
          <Button
            testID="next-button"
            contentStyle={{
              width: 120,
              height: AppConfig.Styles.buttonHeight,
            }}
            labelStyle={{ color: 'white' }}
            mode="contained"
            onPress={onPressApprove}
            disabled={!approved}>
            {t('next')}
          </Button>
        </View>
      </View>
    </View>
  );
};

// @@ Default Component
export const Disclaimer = () => {
  const { t } = useLocalization();
  const { theme } = useAppStore();

  return (
    <View style={[AppConfig.Styles.container, AppConfig.Styles.center]}>
      <View style={[AppConfig.Styles.container, LoginStyles.container]}>
        <View style={[LoginStyles.contentContainer]}>
          {theme && (
            <HeaderView
              theme={theme}
              logo={
                <Svg
                  width="100%"
                  height="100%"
                  viewBox="0 0 50 50"
                  fill={theme.primaryColor}>
                  <VERIFICATIONFingerprint />
                </Svg>
              }
            />
          )}
          <ContentView
            title={t('guest_check_in')}
            description={t('step_one_title')}
          />
        </View>
        <ActionView />
      </View>
    </View>
  );
};

export default Disclaimer;
