import AppConfig from '@config';
import {
  TCountryCode,
  getCountryCode,
  getEmojiFlag,
  type ICountry,
  type TCountries,
} from 'countries-list';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { RadioButton, Text } from 'react-native-paper';

interface CountryListProps {
  countries: TCountries;
  onSelect: (country: ICountry) => void;
}

interface CountryItemProps {
  name: string;
  dialCode?: string;
  selected: boolean;
  onPress: () => void;
}

interface CountryListProps {
  countries: TCountries;
  selectedCountry: string;
  onSelect: (country: ICountry) => void;
}

const CountryItem = ({
  name,
  dialCode,
  selected,
  onPress,
}: CountryItemProps) => {
  return (
    <TouchableOpacity style={styles.touchArea} onPress={onPress}>
      <View style={styles.container}>
        <View style={{ flexDirection: 'row' }}>
          <Text style={styles.countryEmoji}>
            {getEmojiFlag((getCountryCode(name) ?? 'US') as TCountryCode)}
          </Text>
          <Text style={styles.countryNameAndDialCode}>{name}</Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text style={styles.countryNameAndDialCode}>(+{dialCode})</Text>
          <RadioButton
            testID={`radio-btn-${getCountryCode(name)}`}
            value="first"
            onPress={onPress}
            color={AppConfig.Colors.TERTIARY}
            status={selected ? 'checked' : 'unchecked'}
          />
        </View>
      </View>
    </TouchableOpacity>
  );
};

const CountryList = ({
  countries,
  selectedCountry,
  onSelect,
}: CountryListProps) => {
  return (
    <View style={{ width: '100%' }}>
      {Object.entries(countries).map(([_, country], index) => (
        <CountryItem
          key={index}
          name={country.name}
          dialCode={country.phone[0].toString()}
          selected={selectedCountry === getCountryCode(country.name)}
          onPress={() => {
            onSelect(country);
          }}
        />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  countryNameAndDialCode: {
    fontSize: 16,
    color: 'black',
    alignItems: 'center',
    display: 'flex',
  },
  countryEmoji: { fontSize: 24, marginRight: 10 },
  touchArea: {
    paddingVertical: 10,
    paddingHorizontal: 15,
    backgroundColor: 'transparent',
  },
});

export default CountryList;
