import { Theme } from '@/store';
import AppConfig from '@config';
import { ReactNode } from 'react';
import { Image, View } from 'react-native';
import Spacer from './Spacer';

const adjustHeight = (imagesEnabled: boolean) => {
  let height;
  if (AppConfig.Styles.isMobile()) {
    if (imagesEnabled) {
      height = ((AppConfig.Styles.getWindowWidth() * 0.5) / 8) * 5;
    } else {
      height = ((AppConfig.Styles.getWindowWidth() * 0.8) / 8) * 5;
    }
  } else if (imagesEnabled) {
    height = (AppConfig.Styles.getAdjustedSize(250) / 8) * 5;
  } else {
    height = (AppConfig.Styles.getAdjustedSize(400) / 8) * 5;
  }
  return height;
};

const adjustWidth = (imagesEnabled: boolean) => {
  let width;
  if (AppConfig.Styles.isMobile()) {
    if (imagesEnabled) {
      width = AppConfig.Styles.getWindowWidth() * 0.5;
    } else {
      width = AppConfig.Styles.getWindowWidth() * 0.8;
    }
  } else if (imagesEnabled) {
    width = AppConfig.Styles.getAdjustedSize(250);
  } else {
    width = AppConfig.Styles.getAdjustedSize(400);
  }
  return width;
};

type ThemedLogoHeaderProps = {
  theme?: Partial<Theme>;
  children?: ReactNode;
};

export const ThemedLogoHeader = ({
  theme,
  children,
}: ThemedLogoHeaderProps) => {
  if (!theme?.enabled) {
    return null;
  }

  return (
    <View style={AppConfig.Styles.center}>
      {theme?.welcomeLogoUrl && (
        <>
          <Image
            style={{
              height: adjustHeight(theme?.welcomeImagesEnabled ?? false),
              width: adjustWidth(theme?.welcomeImagesEnabled ?? false),
              resizeMode: 'contain',
            }}
            source={{ uri: theme.welcomeLogoUrl }}
          />
          <Spacer size={4} />
        </>
      )}
      {theme?.welcomeImagesEnabled && children}
    </View>
  );
};
