import { SVGProps } from 'react';
import Svg, { Path } from 'react-native-svg';

export const ErrorIcon = ({
  fill = 'currentColor',
  width = 80,
  height = 80,
}: SVGProps<SVGSVGElement>) => (
  <Svg viewBox="0 0 81 80" width={width} height={height}>
    <Path
      d="M40.4939 70C38.8877 70 37.5152 69.4281 36.3763 68.2842C35.2374 67.1405 34.668 65.7655 34.668 64.1593C34.668 62.5531 35.2399 61.1806 36.3837 60.0417C37.5275 58.9028 38.9025 58.3333 40.5087 58.3333C42.1149 58.3333 43.4874 58.9052 44.6263 60.0491C45.7652 61.1929 46.3346 62.5679 46.3346 64.1741C46.3346 65.7803 45.7627 67.1528 44.6189 68.2917C43.4751 69.4306 42.1001 70 40.4939 70ZM34.668 50V10H46.3346V50H34.668Z"
      fill={fill}
    />
  </Svg>
);
