// @ts-nocheck - fix me
import useLocalization from '@/assets/localizations';
import { Spacer } from '@components';
import AppConfig from '@config';
import { trackEvent } from '@libs';
import { useNavigation } from '@react-navigation/native';
import { useAppStore } from '@store';
import { VERIFICATIONFingerprint } from '@svgs';
import { View } from 'react-native';
import { Button, Text } from 'react-native-paper';
import Svg from 'react-native-svg';
import { useVerification } from '../../../store/verification';
import { ContentView, HeaderView } from '../../login/Common';
import { LoginStyles } from '../../login/Styles';

// @@ Action view
const ActionView = () => {
  const { verification, setVerification } = useVerification();
  const navigation = useNavigation();
  const { t } = useLocalization();
  const onPressApprove = (text: string) => {
    setVerification({ ...verification, idType: text });
    trackEvent('Superhog', 'IDSelection:Selected', { type: text });
    navigation.navigate('CaptureId');
  };
  const onPressBack = () => {
    navigation.navigate('Details');
  };

  return (
    <View
      style={{
        width: '100%',
        paddingHorizontal: AppConfig.Styles.getAdjustedSize(24),
        paddingBottom: AppConfig.Styles.getAdjustedSize(24),
      }}>
      <View style={[AppConfig.Styles.container]}>
        <View style={[AppConfig.Styles.container]}>
          <Button
            testID="passport-button"
            contentStyle={{
              width: '100%',
              height: 68,
              justifyContent: 'flex-start',
            }}
            style={{
              borderRadius: 40,
              justifyContent: 'center',
            }}
            labelStyle={{
              fontSize: 30,
              color: 'white',
            }}
            mode="contained"
            onPress={() => {
              onPressApprove('passport');
            }}
            icon="web">
            <View
              style={[
                AppConfig.Styles.container,
                {
                  backgroundColor: 'inherit',
                  alignContent: 'center',
                  height: '100%',
                },
              ]}>
              <Text
                style={[
                  AppConfig.Styles.caption,
                  AppConfig.Styles.textAlignCenter,
                  AppConfig.Styles.lighterText,
                  { marginBottom: 15, color: 'white' },
                ]}>
                {t('passport')}
              </Text>
            </View>
          </Button>
        </View>
        <Spacer />
        <View style={[AppConfig.Styles.container]}>
          <Button
            testID="drivers-license-button"
            contentStyle={{
              width: '100%',
              height: 68,
              justifyContent: 'flex-start',
            }}
            style={{
              borderRadius: 40,
            }}
            labelStyle={{
              fontSize: 30,
              color: 'white',
            }}
            mode="contained"
            onPress={() => {
              onPressApprove('drivers license');
            }}
            icon="car">
            <View
              style={[
                AppConfig.Styles.container,
                {
                  backgroundColor: 'inherit',
                  alignContent: 'center',
                  height: '100%',
                },
              ]}>
              <Text
                style={[
                  AppConfig.Styles.caption,
                  AppConfig.Styles.textAlignCenter,
                  AppConfig.Styles.lighterText,
                  { marginBottom: 15, color: 'white' },
                ]}>
                {t('drivers_license')}
              </Text>
            </View>
          </Button>
        </View>
        <Spacer />
        <View style={[AppConfig.Styles.container, { borderRadius: 200 }]}>
          <Button
            contentStyle={{
              width: '100%',
              height: 68,
              justifyContent: 'flex-start',
            }}
            style={{
              borderRadius: 40,
            }}
            labelStyle={{
              fontSize: 30,
              color: 'white',
            }}
            mode="contained"
            onPress={() => {
              onPressApprove('identity card');
            }}
            icon="account-check">
            <View
              style={[
                AppConfig.Styles.container,
                {
                  backgroundColor: 'inherit',
                  alignContent: 'center',
                  height: '100%',
                },
              ]}>
              <Text
                style={[
                  AppConfig.Styles.caption,
                  AppConfig.Styles.textAlignCenter,
                  AppConfig.Styles.lighterText,
                  { marginBottom: 15, color: 'white' },
                ]}>
                {t('identity_card')}
              </Text>
            </View>
          </Button>
        </View>

        <Spacer />

        <View
          style={[
            AppConfig.Styles.rowContainer,
            {
              justifyContent: 'space-between',
              borderTopColor: '#dddddd',
              borderTopWidth: 1,
              paddingTop: 20,
              marginTop: '30%',
            },
          ]}>
          <Button
            contentStyle={{
              width: 120,
              height: AppConfig.Styles.buttonHeight,
            }}
            labelStyle={{ color: 'black' }}
            onPress={onPressBack}>
            {t('back')}
          </Button>
        </View>
      </View>
    </View>
  );
};

// @@ Default Component
export const Methods = () => {
  const { theme } = useAppStore();
  const { t } = useLocalization();
  return (
    <View style={[AppConfig.Styles.container, AppConfig.Styles.center]}>
      <View style={[AppConfig.Styles.container, LoginStyles.container]}>
        <View style={[LoginStyles.contentContainer]}>
          {theme && (
            <HeaderView
              theme={theme}
              logo={
                <Svg
                  width="100%"
                  height="100%"
                  viewBox="0 0 50 50"
                  fill={theme.primaryColor}
                  style={{ margin: 0, padding: 0 }}>
                  <VERIFICATIONFingerprint />
                </Svg>
              }
            />
          )}
          <ContentView
            title={t('id_type')}
            description={t('id_type_invalid')}
          />
        </View>
        <ActionView />
      </View>
    </View>
  );
};

export default Methods;
