import AppConfig from '@config';
import React from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import { Text } from 'react-native-paper';
import StarRating from 'react-native-star-rating-widget';

type RatingProps = ViewProps & {
  rating: number;
  size?: number;
};

export const Rating: React.FC<RatingProps> = props => {
  const { rating, size = 20 } = props;
  return (
    <View style={[AppConfig.Styles.rowContainer, styles.container]}>
      <Text style={styles.label}>{`${rating}`}</Text>
      <StarRating
        rating={rating}
        onChange={() => {
          // do nothing - star rating is static, not user editable
        }}
        color="orange"
        emptyColor="gray"
        starSize={size}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  label: {
    color: 'gray',
  },
});

export default Rating;
