import { useAppDispatch, useAppSelector } from '@store';
import { IVerification } from './models';
import { selectVerification, setVerification } from './slice';

export const useVerification = () => {
  const dispatch = useAppDispatch();
  const { verification } = useAppSelector(selectVerification);

  return {
    setVerification: (item: IVerification) => {
      dispatch(setVerification(item));
    },
    verification,
  };
};
