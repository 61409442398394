export type Company = {
  id: string;
  companyId: number;
  locale: string;
  accessToken: string;
  accessed: boolean;
  accessCodeControlEnabled: boolean;
  challengePassed: boolean;
  lockControlEnabled: boolean;
  portalConfigEnabled: boolean;
  amenitiesEnabled: boolean;
  lastName: string;
  checkinEnabled: boolean;
  checkoutEnabled: boolean;
  earlyCheckinEnabled: boolean;
  mpGuestMessengerEnabled: boolean;
  smartButtonMessengerEnabled: boolean;
  extendedCheckoutEnabled: boolean;
  contactEnabled: boolean;
  nearmeEnabled: boolean;
  host: string | Host;
  theme: string | Theme;
  reservation?: string | Reservation;
  reservationDisplayEnabled?: boolean;
  amenities: string[] | Amenity[];
  nearme?: string[] | NearMe[];
  loginChallenges: Challenge[];
  isTestReservation: boolean;
  newLoginEnabled: boolean;
  verificationEnabled: boolean;
  incident: {
    paymentConfirmed: boolean;
    paymentEnabled: boolean;
    message: string;
    version: string;
  };
  terms: {
    enabled: boolean;
    signatureEnabled: boolean;
    message: string;
    version: string;
  };
  houseManual: {
    wifi: {
      network: string;
      password: string;
    };
    rules: HouseManual;
    entertainment: HouseManual;
    arrivalInstructions: HouseManual;
    departureInstructions: HouseManual;
  };

  // local flags
  loginStepsInProgress: boolean;
  paymentProviders?: (PaymentProvider | StripeProvider)[];
  deposits?: Deposit[];
};
export enum ChallengeNames {
  LOGIN = 'login',
  WELCOME = 'welcome',
  TERMS = 'terms',
  CONTACT = 'contact',
  VERIFICATION = 'verification',
}

export type ChallengeJson = {
  name: ChallengeNames;
  enabled: boolean;
  data:
    | LoginChallenge
    | TermsChallengeJson
    | WelcomeChallenge
    | ContactChallenge
    | VerificationChallenge;
};

export type Challenge = {
  name: string;
  enabled: boolean;
  data:
    | LoginChallenge
    | TermsChallenge
    | WelcomeChallenge
    | ContactChallenge
    | VerificationChallenge;
};

export type LoginChallenge = {
  lastname: boolean;
  date: boolean;
};

export type TermsChallenge = {
  message: string;
  isCustom: boolean;
};

export type TermsChallengeJson = {
  message: string;
  is_custom: boolean;
};

export type WelcomeChallenge = {
  message: string;
  title: string;
};

export type ContactChallenge = [];

export type VerificationChallenge = [];

export type HouseManual = {
  layout: string | '1' | '2' | '3' | '4';
  instructions: string;
  imageUrl?: string;
  videoUrl?: string;
};

export type Theme = {
  id: string;
  enabled: boolean;
  primaryColor: string;
  accentColor: string;
  dashboardLogoUrl: string;
  dashboardBackgroundUrl: string;
  welcomeLogoUrl: string;
  welcomeStep1ImageUrl: string;
  welcomeStep2ImageUrl: string;
  welcomeStep3ImageUrl: string;
  welcomeStep4ImageUrl: string;
  welcomeImagesEnabled: boolean;
};

export type Member = {
  id: string;
  name: string;
  email: string;
  phone: string;
  member: boolean;
};

export type Host = {
  id: string;
  enabled: boolean;
  welcomeAvatar: string;
  welcomeTitle: string;
  welcomeMessage: string;
  goodbyeTitle: string;
  goodbyeMessage: string;
  members: string[] | Member[];
};

export type Reservation = {
  id: string;
  reservationNumber: number;
  guestLocale: string;
  guestName: string;
  guestEmail: string;
  guestPhone: string;
  emailEnabled: boolean;
  smsEnabled: boolean;
  optin: boolean;
  timeToArrival: string;
  timeToDeparture: string;
  accessCode: string;
  termsAgreed: boolean;
  matched: boolean;
  checkinWithoutParsing: string;
  checkinRequestedAt: number;
  checkin: number;
  checkout: number;
  checkoutAccessCode: string;
  mobile_key_button_enabled?: boolean;
  roomAssignmentConfirmed: boolean;
  welcomeAccepted: boolean;
  incident: {
    paymentConfirmed: boolean;
    paymentEnabled: boolean;
    message: string;
    version: string;
  };
  property: {
    id: string;
    name: string;
    address: string;
    address2?: string;
    city?: string;
    state?: string;
    postalCode?: string;
    country?: string;
    timezone: string;
    latitude: string;
    longitude: string;
    roomStatus: string;
    type: PropertyType;
  };
  smw?: {
    deeplink_url: string;
    booking_code: string;
  };
  verification_details: {
    companyChosenWorkflow: 'none' | 'superhog';
    isPropertyActive: boolean;
    verificationProviderId: number | null;
    status: string;
    providerResult: unknown | null;
    usedProvider: string;
  };
  entranceInstructions: {
    enabled: boolean;
    title: string;
    details: string;
    buttonLabel: string;
  };
};

export type Deposit = {
  amount: number;
  status: string;
  currency: string;
  type: DepositType;
};

export enum DepositType {
  SECURITY = 'security',
  INCIDENTAL = 'incidental',
}
export type ChatToken = {
  token: string;
  expiry: string;
  id: string;
  channel_id: string;
};

export enum PropertyType {
  ADDITIONAL = 'additional',
  COMMON = 'common',
  PRIMARY = 'primary',
  ROOM = 'room',
  VIRTUAL = 'virtual',
}

type Place = {
  id: string;
  type: string | 'dining' | 'shopping' | 'activities' | 'general';
  title: string;
  subtitle: string;
  description: string;
  address: string;
  url: string;
  imageUrl: string;
  email: string;
  phone: string;
  latitude: string;
  longitude: string;
};

export type Amenity = Place;

export type NearMe = Place & {
  rating: number;
  open: boolean;
  detailUrl: string;
  hoursOfOperation: string[];
  distance: {
    url: string;
    origin: string;
    destination: string;
    length: string;
    duration: string;
  };
};

export type PaymentProvider = {
  provider: 'stripe';
};

export type StripeProvider = PaymentProvider & {
  publishable_key: string;
};

// use in redux app state
export type AppState = {
  companies: { [string: string]: Company };
  hosts: { [string: string]: Host };
  members: { [string: string]: Member };
  themes: { [string: string]: Theme };
  reservations: { [string: string]: Reservation };
  amenities: { [string: string]: Amenity };
  nearme: { [string: string]: NearMe };
  chatToken: { [string: string]: ChatToken };
};
