import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import mergeWith from 'lodash/mergeWith';
import uniq from 'lodash/uniq';
import { normalize as normalizr } from 'normalizr';
import {
  AppAction,
  CHATTOKEN,
  COMPANIES,
  RESERVATIONS,
} from '../actions/actions';
import { AppState } from '../models/models';
import { AppSchema } from '../models/schema';

const merge = (prevState: AppState, newState: object) => {
  const merged = mergeWith(prevState, newState, (a, b) => {
    if (isEqual(a, b)) {
      return a;
    }

    if (isArray(a)) {
      return uniq([...a, ...b]);
    }

    return mergeWith({}, a, b);
  });

  return merged;
};

const clean = (state: AppState, action: AppAction) => {
  if (state[action.type as keyof AppState] !== undefined) {
    action.deletedIds?.forEach((id: string) => {
      if (state[action.type as keyof AppState][id] !== undefined) {
        delete state[action.type as keyof AppState][id];
      }
    });
  }

  return {
    ...state,
  };
};

const normalize = (action: AppAction) =>
  normalizr({ [action.type]: action.data }, AppSchema).entities;

const reduce = (state: AppState, action: AppAction) => {
  const cleaned = clean(state, action);
  // log.info('CLEANED STATE', cleaned);
  const normalized = normalize(action);
  // log.info('NORMALIZED STATE', normalized);
  const merged = merge(cleaned, normalized);
  // console.log('MERGED STATE', merged);
  return merged;
};

export const initialState: AppState = {
  companies: {},
  themes: {},
  hosts: {},
  members: {},
  reservations: {},
  amenities: {},
  nearme: {},
  chatToken: {},
};

export default (state: AppState = initialState, action: AppAction) => {
  switch (action.type) {
    case COMPANIES:
    case CHATTOKEN:
    case RESERVATIONS:
      return reduce(state, action);

    default:
      return state;
  }
};
