import AppConfig from '@config';
import { StyleSheet, Text, View } from 'react-native';
import { Card } from 'react-native-paper';
import RenderHtml from 'react-native-render-html';

type CardRouterProps = {
  contentItem?: {
    id?: string;
    title?: string;
    category?: string;
    imageUri?: string;
  };
};

const CARD_MAX_WIDTH = 568;
const BORDER_RADIUS = 12;

const CardRouter = ({ contentItem }: CardRouterProps) => {
  return (
    <View testID="card" style={styles.container}>
      <Card
        testID={`more-button-${contentItem?.id}`}
        style={{ position: 'relative' }}>
        <Card.Cover
          testID="card-cover"
          source={{
            uri: `${
              contentItem?.imageUri
            }?tr=w-${AppConfig.Styles.getWindowWidth()}`,
          }}
        />
        <View style={styles.absoluteCard}>
          <Card.Content style={styles.cardContent}>
            <Text testID="card-title" style={styles.cardText}>
              <RenderHtml source={{ html: contentItem?.title || '' }} />
            </Text>
          </Card.Content>
        </View>
      </Card>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxWidth: CARD_MAX_WIDTH,
    width: '100%',
    marginTop: 10,
    marginBottom: 10,
    borderRadius: BORDER_RADIUS,
  },
  absoluteCard: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    justifyContent: 'center',
    borderRadius: BORDER_RADIUS,
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    height: '100%',
  },
  cardContent: {
    flex: 1,
    justifyContent: 'flex-end',
    padding: 10,
    borderRadius: BORDER_RADIUS,
    height: 300,
    backgroundImage: 'linear-gradient(to top, #000000d7, #00000041, #00000000)',
  },
  cardText: {
    color: AppConfig.Styles.colors.ON_SECONDARY,
    fontSize: 20,
    fontWeight: 'bold',
  },
});

export default CardRouter;
