import { trackEvent } from '@/libs';
import { ContentItem, getNavigationParamsForContent } from '@/store/guides';
import {
  GuidesContentsRouteParams,
  useGuidesContents,
} from '@/store/guides/hooks';
import { Layout, NavigationHeader, RootContainer } from '@components';
import AppConfig from '@config';
import { useNavigation, useRoute } from '@react-navigation/native';
import { StackHeaderProps } from '@react-navigation/stack';
import { useCallback, useLayoutEffect, useState } from 'react';
import { FlatList, StyleSheet, TouchableOpacity } from 'react-native';
import CardRouter from '../components/CardRouter';

const renderHeader = (props: StackHeaderProps) => {
  return <NavigationHeader {...props} />;
};

const Category = () => {
  const [height, setHeight] = useState(0);
  const route = useRoute();
  const { categorizedContents } = useGuidesContents(
    route.params as GuidesContentsRouteParams,
  );
  const navigation = useNavigation();

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: true,
      header: renderHeader,
    });
  }, [navigation]);

  const renderItem = useCallback(
    ({ contentItem }: { contentItem: ContentItem }) => {
      const element = {
        id: contentItem.content_id || '',
        title: contentItem.title || '',
        category: contentItem.category || '',
        imageUri:
          `${
            contentItem?.images_url[0]
          }?tr=w-${AppConfig.Styles.getWindowWidth()}` || '',
      };

      return (
        <TouchableOpacity
          onPress={() => {
            trackEvent('Guides', 'Category>Page Card>More:Clicked', {
              title: contentItem.title,
              id: contentItem.content_id,
              category: contentItem.category,
            });
            navigation.navigate(
              'Content',
              getNavigationParamsForContent(contentItem),
            );
          }}>
          <CardRouter contentItem={element} />
        </TouchableOpacity>
      );
    },
    [navigation],
  );

  return (
    <RootContainer>
      <Layout>
        <FlatList
          testID="category-card-list"
          onLayout={event => {
            setHeight(event.nativeEvent.layout.height);
          }}
          style={[styles.body, { height }]}
          data={categorizedContents}
          renderItem={({ item }) => renderItem({ contentItem: item })}
        />
      </Layout>
    </RootContainer>
  );
};

const styles = StyleSheet.create({
  body: {
    flex: 1,
    width: '100%',
    alignSelf: 'center',
    maxWidth: AppConfig.Styles.responsiveMaxWidth,
    padding: AppConfig.Styles.getAdjustedSize(20),
  },
});

export default Category;
