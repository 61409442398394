import { Spacer } from '@components';
import AppConfig from '@config';
import { unixTimestampToDate } from '@libs';
import useLocalization from '@localizations';
import format from 'date-fns/format';
import { FunctionComponent } from 'react';
import { StyleSheet, View } from 'react-native';
import { Caption, Subheading, Text } from 'react-native-paper';

interface ICheckInOutViewProps {
  date: number;
  title: string;
}
export const CheckInOutView: FunctionComponent<ICheckInOutViewProps> = ({
  title,
  date,
}) => (
  <View style={[AppConfig.Styles.border, styles.checkInOut]}>
    <Subheading>{title}</Subheading>
    <Caption>{format(unixTimestampToDate(date), 'dd MMM yyyy')}</Caption>
    <Caption>{format(unixTimestampToDate(date), 'hh:mm a')}</Caption>
  </View>
);

interface IReservationDetailsProps {
  checkin: number;
  checkout: number;
  name?: string;
  address?: string;
}
export const ReservationDetails: FunctionComponent<
  IReservationDetailsProps
> = ({ name, address, checkin, checkout }) => {
  const { t } = useLocalization();

  return (
    <>
      <View style={styles.labels}>
        <Text variant="titleLarge">
          {t('booking_reservation_details_header')}
        </Text>
        {(name || address) && (
          <View style={styles.labels}>
            {name && <Caption>{name}</Caption>}
            {address && <Caption>{address}</Caption>}
          </View>
        )}
      </View>
      <View style={[AppConfig.Styles.rowContainer]}>
        <CheckInOutView title={t('check_in')} date={checkin} />
        <Spacer horizontal size={3} />
        <CheckInOutView title={t('check_out')} date={checkout} />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  labels: {
    paddingVertical: AppConfig.Styles.getAdjustedSize(8),
  },
  checkInOut: {
    flex: 1,
    padding: AppConfig.Styles.getAdjustedSize(10),
  },
});

export default ReservationDetails;
