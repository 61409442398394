import useLocalization from '@/assets/localizations';
import { FrontDoorIcon, UnitDoorIcon } from '@/assets/svgs';
import { ActionSheet, Spacer } from '@components';
import { trackEvent } from '@libs';
import { useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { Text } from 'react-native-paper';
import { FrontDoorInstructionsSheet } from './FrontDoorInstructionsSheet';

interface SelectDoorItemType {
  label: string;
  icon: JSX.Element;
  callback: () => void;
}

interface SelectDoorActionSheetProps {
  isOpen: boolean;
  onClose: () => void;
  onUnitPress: () => void;
}

export const SelectDoorActionSheet = ({
  isOpen,
  onClose,
  onUnitPress,
}: SelectDoorActionSheetProps) => {
  const [frontDoorVisible, setFrontDoorVisible] = useState(false);

  const onPress = (item: SelectDoorItemType) => {
    item.callback();
  };

  const { t } = useLocalization();

  const itemObjects = [
    {
      label: t('front_door'),
      icon: <FrontDoorIcon />,
      callback: () => {
        setFrontDoorVisible(true);
        trackEvent('Global', 'Select Door Type:Front Door');
      },
    },
    {
      label: t('unit_door'),
      icon: <UnitDoorIcon />,
      callback: () => {
        onClose();
        onUnitPress();
        trackEvent('Global', 'Select Door Type:Unit Door');
      },
    },
  ];

  return (
    <>
      <ActionSheet isOpen={isOpen} onClose={onClose}>
        <Text style={styles.header}>{t('select_door_to_open')}</Text>
        <View style={styles.doorTypeContainer}>
          {itemObjects.map((item: SelectDoorItemType, index: number) => (
            <TouchableOpacity
              key={index}
              onPress={() => onPress(item)}
              style={styles.door}>
              {item.icon}
              <Text style={styles.doorName}>{item.label}</Text>
              <Spacer horizontal size={3} />
            </TouchableOpacity>
          ))}
        </View>
      </ActionSheet>
      <FrontDoorInstructionsSheet
        onDismiss={() => {
          setFrontDoorVisible(false);
          onClose();
        }}
        isOpen={frontDoorVisible}
        onBackPress={() => setFrontDoorVisible(false)}
      />
    </>
  );
};

const styles = StyleSheet.create({
  doorTypeContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginVertical: '24px',
    marginHorizontal: '18px',
    gap: '18px',
    alignSelf: 'stretch',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  door: {
    borderWidth: 1,
    borderColor: 'rgba(21, 41, 122, 0.12));',
    padding: 16,
    borderRadius: 10,
    flew: 1,
    alignItems: 'center',
    width: '50%',
  },
  doorName: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: '600',
    marginTop: 8,
    letterSpacing: 0.1,
    textAlign: 'center',
  },
  header: {
    fontWeight: 'bold',
    fontSize: 20,
    letterSpacing: 0.15,
    color: 'rgba(0, 0, 0, 0.87)',
    textAlign: 'center',
    margin: '16px',
  },
});
