import { logger } from '@/libs/logger';
import { ContentView, SnackBar } from '@components';
import AppConfig from '@config';
import { trackEvent } from '@libs';
import useLocalization from '@localizations';
import {
  dispatchFetchGuest,
  dispatchUpdateGuest,
  processError,
  useAppStore,
} from '@store';
import { FunctionComponent, useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, Caption, HelperText, TextInput } from 'react-native-paper';

interface ITextInputViewProps {
  label: string;
  value: string;
  maxLength?: number;
  error?: boolean;
  onChangeText: (value: string) => void;
}
export const TextInputView: FunctionComponent<ITextInputViewProps> = ({
  label,
  value,
  error = false,
  maxLength,
  onChangeText,
}) => {
  const { t } = useLocalization();

  return (
    <View style={styles.inputContainer}>
      <TextInput
        style={[styles.input]}
        mode="outlined"
        label={label}
        value={value}
        maxLength={maxLength}
        onChangeText={onChangeText}
      />
      <HelperText type="error" visible={error}>
        {t('validation_required')}
      </HelperText>
    </View>
  );
};

export const Contact = () => {
  const { t } = useLocalization();
  const { reservation } = useAppStore();
  const [name, setName] = useState(reservation?.guestName ?? '');
  const [phone, setPhone] = useState(
    reservation?.guestPhone?.length
      ? `+${reservation?.guestPhone.replace('+', '')}`
      : '+',
  );
  const [email, setEmail] = useState(reservation?.guestEmail ?? '');
  const [loading, setLoading] = useState(false);
  const [snackBarOptions, setSnackBarOptions] = useState({
    title: '',
    color: AppConfig.Colors.SUCCESS,
    visible: false,
  });

  useEffect(() => {
    if (reservation) {
      dispatchFetchGuest(reservation);
    }
  }, [reservation]);

  const onPress = async () => {
    if (loading || !reservation) {
      return;
    }

    trackEvent('Menu', 'Profile>Contact:Submitted', {
      nameFilled: (name?.length ?? 0) > 0,
      emailFilled: (email?.length ?? 0) > 0,
      phoneFilled: (phone?.length ?? 0) > 0,
    });

    setLoading(true);
    setSnackBarOptions({
      ...snackBarOptions,
      visible: false,
    });

    try {
      if (
        (name?.length ?? 0) > 0 &&
        (phone?.length ?? 0) > 0 &&
        (email?.length ?? 0) > 0
      ) {
        await dispatchUpdateGuest({
          ...reservation,
          guestName: name,
          guestEmail: email,
          guestPhone: phone,
        });

        setSnackBarOptions({
          title: t('update_profile_success'),
          color: AppConfig.Colors.SUCCESS,
          visible: true,
        });
      }
    } catch (error) {
      const message = processError(error);
      logger.error(message);
      setSnackBarOptions({
        title: message,
        color: AppConfig.Colors.ERROR,
        visible: true,
      });
    }

    setLoading(false);
  };

  return (
    <View style={AppConfig.Styles.container}>
      <ContentView description={t('your_profile_content')}>
        <TextInputView
          label={t('name')}
          value={name}
          error={name?.length === 0}
          onChangeText={setName}
        />
        <TextInputView
          label={t('web_login_email')}
          value={email}
          error={email?.length === 0}
          onChangeText={setEmail}
        />
        <TextInputView
          label={t('telephone')}
          value={phone}
          error={phone?.length === 0}
          maxLength={12}
          onChangeText={text => setPhone(`+${text.replace('+', '')}`)}
        />
        <Caption style={{ marginBottom: AppConfig.Styles.getAdjustedSize(20) }}>
          {t('information_disclaimer')}
        </Caption>
        <Button
          contentStyle={{
            width: '100%',
            height: AppConfig.Styles.buttonHeight,
          }}
          disabled={
            name?.length === 0 || phone?.length === 0 || email?.length === 0
          }
          loading={loading}
          mode="contained"
          onPress={onPress}>
          {loading ? '' : t('save')}
        </Button>
      </ContentView>
      <SnackBar
        {...snackBarOptions}
        onDismiss={() =>
          setSnackBarOptions({ ...snackBarOptions, visible: false })
        }
      />
    </View>
  );
};

export default Contact;

const styles = StyleSheet.create({
  inputContainer: {
    width: '100%',
    height: AppConfig.Styles.getAdjustedSize(90),
  },
  input: {
    width: '100%',
    height: AppConfig.Styles.getAdjustedSize(50),
  },
});
