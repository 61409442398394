import useOtp from '@/store/otp/hooks';
import {
  useGetGuestUserQuery,
  usePatchGuestUserByIdMutation,
} from '@/store/services/userApi';
import { useLocalization } from '@localizations';
import { useNavigation, useRoute } from '@react-navigation/core';
import { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import {
  Button,
  HelperText,
  MD3DarkTheme,
  MD3LightTheme,
  Text,
} from 'react-native-paper';
import OtpInput from '../../login/LoginCognito/OtpInput';
import PhoneNumberVerification from './PhoneNumberVerification';
import { CODE_LENGTH, COUNT_TO_RESEND_CODE } from './constants';
import { handlePhoneNumberVerificationErrorResponse } from './utils';

const VerifyPhoneNumber = () => {
  const [otpCode, setOtpCode] = useState(Array(CODE_LENGTH).fill(''));
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [countToResend, setCountToResend] = useState(COUNT_TO_RESEND_CODE);
  const [isVerifyOtpLoading, setIsVerifyOtpLoading] = useState(false);

  const route = useRoute();
  const navigation = useNavigation();
  const { phoneNumber } = (route.params as { phoneNumber: string }) ?? {};
  const { t } = useLocalization();

  const { data: guest } = useGetGuestUserQuery();
  const [patchGuestUserById] = usePatchGuestUserByIdMutation();

  const { requestOtp, verifyOtp } = useOtp();

  const renderOtpPad = () => {
    return (
      <View style={{ marginLeft: 'auto', marginRight: 'auto' }}>
        <OtpInput
          width={50}
          height={50}
          mode="outlined"
          contentStyle={{ minWidth: '100%' }}
          activeOutlineColor={MD3LightTheme.colors.primary}
          outlineColor={MD3DarkTheme.colors.outline}
          value={otpCode}
          onChange={async value => {
            setOtpCode(value);
            const code = value.join('');
            if (code.length === CODE_LENGTH) {
              await verifyOtpCode(code);
            }
          }}
          outlineStyle={{ backgroundColor: 'transparent' }}
          error={showError}
          isLoading={isVerifyOtpLoading}
        />

        <HelperText
          type="error"
          visible={!!showError}
          style={{ padding: 0, flexWrap: 'wrap', textAlign: 'center' }}>
          {errorMsg}
        </HelperText>

        <Button
          mode="outlined"
          textColor="black"
          labelStyle={{
            marginHorizontal: 0,
          }}
          testID="verify_phone_number_resend_button"
          style={{
            height: 40,
            marginBottom: 16,
            marginTop: 16,
            marginHorizontal: 0,
            justifyContent: 'center',
            width: countToResend > 0 ? 150 : 130,
          }}
          onPress={onResendClick}
          disabled={countToResend > 0 || isVerifyOtpLoading}>
          <Text
            variant="labelLarge"
            style={{
              color:
                countToResend > 0 || isVerifyOtpLoading
                  ? MD3LightTheme.colors.onSurfaceDisabled
                  : MD3LightTheme.colors.primary,
            }}>
            {`${t('confirm_phone_number_resend_button')} ${
              countToResend ? `${countToResend}s` : ''
            }`}
          </Text>
        </Button>
      </View>
    );
  };

  const onResendClick = async () => {
    try {
      // Reset the count to resend and show the loading spinner
      setIsVerifyOtpLoading(true);
      setErrorMsg('');
      setShowError(false);

      // Request a new OTP
      await requestOtp(phoneNumber);

      // Reset the loading spinner and the OTP code
      setIsVerifyOtpLoading(false);
      setOtpCode(Array(CODE_LENGTH).fill('')); //setting otp code to empty causes focus on first otp box
      setCountToResend(COUNT_TO_RESEND_CODE);
    } catch (e: unknown) {
      setIsVerifyOtpLoading(false);
      setErrorMsg(t('verification_send_otp_error'));
      setShowError(true);
    }
  };

  const verifyOtpCode = useCallback(
    async (code: string) => {
      try {
        setShowError(false);
        setErrorMsg('');
        setIsVerifyOtpLoading(true);

        await verifyOtp(phoneNumber, parseInt(code, 10));

        if (guest?.id) {
          await patchGuestUserById({
            ...guest,
            phonePrimary: phoneNumber,
            phonePrimaryIsVerified: true,
          });
        }
        setOtpCode(Array(CODE_LENGTH).fill(''));
        setIsVerifyOtpLoading(false);
        navigation.navigate('Reservations');
      } catch (e) {
        setOtpCode(Array(CODE_LENGTH).fill(''));
        if (e instanceof Error && e.message) {
          setErrorMsg(t(handlePhoneNumberVerificationErrorResponse(e.message)));
        } else {
          setErrorMsg(t(handlePhoneNumberVerificationErrorResponse('')));
        }
        setShowError(true);
        setIsVerifyOtpLoading(false);
      }
    },
    [guest, navigation, phoneNumber, verifyOtp, patchGuestUserById, t],
  );

  useEffect(() => {
    if (countToResend) {
      setTimeout(() => {
        if (countToResend > 0) {
          setCountToResend(countToResend - 1);
        }
      }, 1000);
    }
  }, [countToResend]);

  return (
    <PhoneNumberVerification
      title={t('add_phone_number_header')}
      subtitle={`${t('confirm_phone_number_body')} ${phoneNumber}`}
      headerType="previous"
      onHeaderAction={() => {
        navigation.navigate('AddPhoneNumber');
      }}>
      {renderOtpPad()}
    </PhoneNumberVerification>
  );
};

export default VerifyPhoneNumber;
