import { SVGProps } from 'react';
import Svg, { Path } from 'react-native-svg';

export const ImagePlaceHolder = ({
  fill = 'currentColor',
  width = 200,
  height = 200,
}: SVGProps<SVGSVGElement>) => (
  <Svg viewBox="0 0 200 191" fill="none" width={width} height={height}>
    <Path
      d="M100 0.863281C155.23 0.863281 200 43.2459 200 95.53C200 147.814 155.23 190.197 100 190.197C44.7704 190.197 0 147.814 0 95.53C0 43.2459 44.7825 0.863281 100 0.863281ZM100.258 144.805C129.004 144.805 152.309 122.743 152.309 95.53C152.309 68.317 129.004 46.255 100.258 46.255C71.5115 46.255 48.2041 68.317 48.2041 95.53C48.2187 122.738 71.514 144.791 100.258 144.805Z"
      fill={fill}
      fillOpacity="0.06"
    />
  </Svg>
);
