import useLocalization from '@/assets/localizations';
import useVariables from '@/store/variables/hooks';
import { ActionSheet } from '@components';
import AppConfig from '@config';
import { useAppStore } from '@store';
import { MagicFingerLogo } from '@svgs';
import { useMemo } from 'react';
import RenderHtml from 'react-native-render-html';

import { StyleSheet, View } from 'react-native';
import { Button, IconButton, Text } from 'react-native-paper';

export interface IEntranceInstructions {
  title?: string;
  details?: string;
  buttonLabel?: string;
}

interface IFrontDoorInstructionsSheetProps {
  onDismiss?: () => void;
  isOpen: boolean;
  onBackPress?: () => void;
}

const renderIcon = () => {
  return (
    <IconButton
      icon={'arrow-left'}
      iconColor={AppConfig.Styles.colors.PRIMARY}
      style={{ width: '24px', height: '24px', margin: 6 }}
    />
  );
};

export const FrontDoorInstructionsSheet = ({
  onDismiss,
  isOpen,
  onBackPress,
}: IFrontDoorInstructionsSheetProps) => {
  const { t } = useLocalization();
  const { reservation } = useAppStore();

  const entranceInstructions: IEntranceInstructions[] = useMemo(() => {
    return [
      {
        title: reservation?.entranceInstructions.title || '',
        details: reservation?.entranceInstructions.details || '',
      },
    ];
  }, [
    reservation?.entranceInstructions.title,
    reservation?.entranceInstructions.details,
  ]);

  const { contents: entranceInstructionsContents } =
    useVariables<IEntranceInstructions>(entranceInstructions);
  const instructions = entranceInstructionsContents?.find(Boolean);

  return (
    <>
      <ActionSheet isOpen={isOpen} onClose={onDismiss}>
        <View style={styles.sheetContent}>
          <Text
            style={[AppConfig.Styles.headline3, styles.headline3]}
            testID="instructions-title">
            <RenderHtml
              source={{
                html: instructions?.title || '',
              }}
            />
          </Text>
        </View>
        <View style={[styles.sheetContent, { flex: 1 }]}>
          <MagicFingerLogo
            style={{ color: 'white' }}
            width="180"
            testID="instructions-image"
          />
          <Text
            style={[AppConfig.Styles.body1, styles.body1]}
            testID="instructions-details">
            <RenderHtml
              source={{
                html: instructions?.details || '',
              }}
            />
          </Text>
        </View>
        <View
          style={[
            styles.sheetContent,
            {
              flexDirection: 'row',
              marginTop: 'auto',
            },
          ]}>
          <Button
            style={{ padding: '8px 11px' }}
            labelStyle={{
              marginLeft: '8px',
              fontSize: 15,
            }}
            textColor={AppConfig.Styles.colors.PRIMARY}
            uppercase={false}
            mode={'text'}
            onPress={onBackPress}
            icon={renderIcon}>
            {t('select_door')}
          </Button>
        </View>
      </ActionSheet>
    </>
  );
};

const styles = StyleSheet.create({
  sheetContent: {
    width: '100%',
    padding: 16,
    alignItems: 'center',
    alignSelf: 'center',
    gap: 16,
  },
  headline3: {
    color: 'black',
    fontSize: 20,
    fontWeight: '700',
  },
  body1: {
    textAlign: 'center',
    lineHeight: 24,
    color: 'rgba(0, 0, 0, 0.87)',
  },
});
