import { GUEST_PORTAL_URL, NEO_GP_URL } from '@/assets/configurations/Settings';
import { useAppFeatures } from '@/hooks/useAppFeatures';
import { getBrowserLocale } from '@/libs/DeviceDetect';
import { trackEvent } from '@/libs/UserEvents';
import { logger } from '@/libs/logger';
import { ConfigType, RootSnackbarState } from '@/screens/root';
import { Welcome } from '@/screens/root/Welcome';
import { NotFound, Splash } from '@components';
import AppConfig from '@config';
import { SupportedLocale } from '@localizations';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { AppState, dispatchGetCompany, dispatchReset } from '@store';
import React, { useEffect, useState } from 'react';
import { DefaultTheme, Provider as PaperProvider } from 'react-native-paper';
import { useSelector } from 'react-redux';
import { useGetGuestUserQuery } from './services/userApi';

const MAX_RETRY_ATTEMPTS = 3;
const SCREENS_FOR_GENERIC_URL = ['auth', 'verify-otp', 'trips'];

const AppContext = React.createContext({
  companyId: '',
  locale: 'en' as SupportedLocale,
  setLocale: (_locale: SupportedLocale) => {
    // do nothing - implementation is defined in setLocale useState
  },
  chatToken: {},
  urlConfig: { id: '', entryPath: '' } as ConfigType,
  setUrlConfig: (_config: ConfigType) => {
    // do nothing - implementation is defined in setConfig useState
  },
  openSnackbar: (_state: RootSnackbarState) => {
    // do nothing - implementation is defined in setRootSnackbarState useState
  },
});

export const useAppContext = () => React.useContext(AppContext);

type AppProviderProps = {
  config: {
    id: string;
    entryPath?: string;
    origin: string;
  };
  children: React.ReactNode;
  setConfig: (config: ConfigType) => void;
  openSnackbar: (state: RootSnackbarState) => void;
};

export const AppProvider = ({
  config,
  setConfig,
  children,
  openSnackbar,
}: AppProviderProps) => {
  const { isFeatureEnabled, isLoading: isLoadingAppFeatures } =
    useAppFeatures();
  const [companyId, setCompanyId] = useState('');
  const [locale, setLocale] = useState<SupportedLocale>('en');
  const [retryAttempts, setRetryAttempts] = useState(0);
  const [isLoadingCompany, setIsLoadingCompany] = useState(false);
  const { data: guest } = useGetGuestUserQuery();
  const { company, theme, chatToken } = useSelector(
    (state: { guestportal: AppState }) => {
      const appState = state.guestportal;
      const id = companyId.length ? companyId : config.id.split('_').shift();
      const tempCompany = appState.companies[id ?? ''];

      return {
        company: tempCompany,
        theme: appState.themes[tempCompany?.theme as string],
        chatToken: appState.chatToken,
      };
    },
  );

  // set the theme
  const appTheme = {
    ...DefaultTheme,
    roundness: AppConfig.Styles.borderRadius,
    colors: {
      ...DefaultTheme.colors,
      primary:
        theme?.enabled && (theme?.primaryColor?.length ?? 0) > 1
          ? theme?.primaryColor
          : AppConfig.Colors.PRIMARY,
      accent:
        theme?.enabled && (theme?.accentColor?.length ?? 0) > 1
          ? theme?.accentColor
          : AppConfig.Colors.ACCENT,
      background: AppConfig.Colors.ON_SECONDARY,
      card: AppConfig.Colors.ON_SECONDARY,
      primaryContainer: AppConfig.Colors.DARK_PRIMARY,
      tertiary: AppConfig.Colors.DARK_ON_PRIMARY,
    },
  };

  const renderEntryPath = () => {
    const currentHostname = window.location?.hostname;
    if (
      currentHostname &&
      !currentHostname.includes('localhost') &&
      !currentHostname.includes('guests.operto') &&
      !currentHostname.includes('guest.operto')
    ) {
      window.location.replace(`${GUEST_PORTAL_URL}/${config.id}/?r=true`);
    }

    if (
      SCREENS_FOR_GENERIC_URL.some(e => `/${e}` === config.entryPath) &&
      !isLoadingCompany
    ) {
      return children;
    }

    if (retryAttempts >= 3) {
      return <NotFound />;
    }

    if (
      config.id &&
      (companyId.length === 0 ||
        !company ||
        isLoadingCompany ||
        isLoadingAppFeatures)
    ) {
      return <Splash />;
    }

    if (config.entryPath === '/welcome') {
      return <Welcome />;
    }

    if (
      company &&
      !company.isTestReservation &&
      isFeatureEnabled('upsells', company.companyId)
    ) {
      window.location.replace(`${NEO_GP_URL}/${config.id}`);
      return <Splash />;
    }

    return children;
  };

  const updateLocale = (newLocale: SupportedLocale) => {
    setLocale(newLocale);
    trackEvent('User Locale', 'Language: Selected', {
      browserLocale: getBrowserLocale() ?? 'unknown',
      companyLocale: company?.locale ?? 'unknown',
      userLocale: newLocale,
    });
  };

  useEffect(() => {
    const getLocaleSource = () => {
      if (companyId.length === 0 && company) {
        setCompanyId(company.id);
      }

      AsyncStorage.getItem('locale').then(storedLocale => {
        let newLocale = 'en' as SupportedLocale;

        if (companyId.length === 0 && company) {
          newLocale = company.locale as SupportedLocale;

          trackEvent('User Locale', 'Language: Loaded', {
            browserLocale: getBrowserLocale() ?? 'unknown',
            companyLocale: company?.locale ?? 'unknown',
          });
        }

        if (getBrowserLocale()) {
          newLocale = getBrowserLocale() as SupportedLocale;
        }

        if (guest?.locale) {
          newLocale = guest.locale as SupportedLocale;
          AsyncStorage.setItem('locale', guest.locale as string);
        } else {
          if (storedLocale) {
            newLocale = storedLocale as unknown as SupportedLocale;
          }
        }

        setLocale(newLocale);
      });
    };

    getLocaleSource();
  }, [company, companyId, guest?.locale]);

  useEffect(() => {
    const getTheming = async () => {
      try {
        if (config.id.split('_').length > 1) {
          setIsLoadingCompany(true);
          await dispatchGetCompany(config.id); // config.id => {companyId}_{externalReservationId}
          setIsLoadingCompany(false);
        }
      } catch (error) {
        logger.error(error, retryAttempts);

        if (error && retryAttempts < MAX_RETRY_ATTEMPTS) {
          setRetryAttempts(retryAttempts + 1);
        }
      }
    };

    if (retryAttempts >= MAX_RETRY_ATTEMPTS) {
      dispatchReset();
    } else {
      if (config.id.length === 0) {
        return;
      }

      if (config.entryPath === '/welcome') {
        setCompanyId(config.id); // config.id => {companyId}
      } else if (company === undefined) {
        getTheming();
      }
    }
  }, [company, config, retryAttempts]);

  return (
    <>
      <PaperProvider theme={appTheme}>
        <AppContext.Provider
          value={{
            companyId,
            locale,
            setLocale: updateLocale,
            chatToken,
            urlConfig: config,
            setUrlConfig: setConfig,
            openSnackbar,
          }}>
          {renderEntryPath()}
        </AppContext.Provider>
      </PaperProvider>
    </>
  );
};
