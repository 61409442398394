import { ActionSheet } from '@/screens/components';
import { useLocalization } from '@localizations';
import { ICountry, TCountries, countries } from 'countries-list';
import { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import {
  Button,
  Icon,
  MD3LightTheme,
  Text,
  TextInput,
} from 'react-native-paper';
import CountryList from './CountryList';

interface PhoneNumberPickerProps {
  countryDialCode: string;
  countryEmoji: string;
  phoneNumber: string;
  countryCode: string;
  isValidPhoneNumber: boolean;
  onChangePhoneNumber: (phoneNumber: string) => void;
  onChangeCountryCode: (country: ICountry) => void;
}

const PhoneNumberPicker = ({
  countryDialCode,
  countryEmoji,
  phoneNumber,
  countryCode,
  isValidPhoneNumber,
  onChangePhoneNumber,
  onChangeCountryCode,
}: PhoneNumberPickerProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [searchCountryText, setsearchCountryText] = useState('');

  const { t } = useLocalization();

  const onChangeCountry = (country: ICountry) => {
    onChangeCountryCode(country);
    setIsDrawerOpen(false);
  };

  const filteredCountries = Object.keys(countries)
    .filter(key => {
      const country = countries[key as keyof TCountries];
      return (
        country.name.toLowerCase().includes(searchCountryText.toLowerCase()) ||
        country.phone[0].toString().includes(searchCountryText)
      );
    })
    .reduce((obj, key) => {
      obj[key as keyof TCountries] = countries[key as keyof TCountries];
      return obj;
    }, {} as TCountries);

  return (
    <View style={styles.container}>
      <View style={styles.innerContainer}>
        <Button
          mode="outlined"
          style={styles.countryCodeButton}
          labelStyle={styles.countryCodeLabelStyle}
          onPress={() => setIsDrawerOpen(!isDrawerOpen)}>
          <Text style={styles.countryEmoji}>{countryEmoji}</Text>
          <Text style={styles.countryCodeText}>{countryDialCode}</Text>
          <Icon source="menu-down" size={24} color="black" />
        </Button>
        <TextInput
          testID="phone-number-input"
          style={styles.phoneNumberTextInput}
          error={!isValidPhoneNumber}
          mode="outlined"
          activeOutlineColor={MD3LightTheme.colors.primary}
          value={phoneNumber}
          defaultValue={phoneNumber}
          onChangeText={onChangePhoneNumber}
          keyboardType="phone-pad"
        />
      </View>
      <ActionSheet
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        isScrollable={true}>
        <Text style={styles.countryListTitle}>{t('country_region')}</Text>
        <TextInput
          mode="outlined"
          style={styles.phoneNumberTextInput}
          placeholder="Search"
          value={searchCountryText}
          onChangeText={setsearchCountryText}
        />
        <CountryList
          countries={filteredCountries}
          selectedCountry={countryCode}
          onSelect={onChangeCountry}
        />
      </ActionSheet>
    </View>
  );
};

const styles = StyleSheet.create({
  container: { paddingBottom: 16 },
  innerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  countryCodeButton: {
    borderRadius: 4,
    height: 56,
    width: 130,
  },
  countryCodeLabelStyle: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginHorizontal: 0,
    display: 'flex',
    flex: 1,
    marginVertical: 16,
  },
  countryCodeText: { fontSize: 16, marginRight: 15 },
  phoneNumberTextInput: {
    marginLeft: 10,
    flex: 1,
    backgroundColor: 'transparent',
  },
  countryEmoji: {
    fontSize: 24,
    marginHorizontal: 10,
  },
  countryListTitle: {
    fontSize: 24,
    fontWeight: '400',
    letterSpacing: 0,
    textAlign: 'center',
    marginBottom: 16,
  },
});

export default PhoneNumberPicker;
