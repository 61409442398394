import { logger } from '@/libs/logger';
import { OTPMessage, OTPVerify } from '@operto/otp-service-shared';
import { trpc } from '@operto/trpc-client';

/**
 * Custom hook that handles OTP (One-Time Password) operations like sending and verifying OTPs.
 */
const useOtp = () => {
  // Use TRPC's mutateAsync for requestOtp and verifyOtp
  const requestOtpMutate = trpc.otp.sendOtp.useMutation();
  const verifyOtpMutate = trpc.otp.verifyOtp.useMutation();

  /**
   * Sends an OTP to the specified phone number.
   *
   * @param phoneNumber - The recipient's phone number to which the OTP will be sent.
   * @returns Promise that resolves if sending OTP is successful, otherwise rejects.
   */
  const requestOtp = async (phoneNumber: string): Promise<void> => {
    try {
      await requestOtpMutate.mutateAsync({
        recipient: phoneNumber,
        deliveryMethod: 'sms',
      } as OTPMessage);
    } catch (error) {
      const errorMessage = `Failed to send OTP: ${(error as Error).message}`;
      logger.error(errorMessage);
      throw new Error(errorMessage);
    }
  };

  /**
   * Verifies the OTP provided by the user for the specified phone number.
   *
   * @param phoneNumber - The phone number to which the OTP was sent.
   * @param otp - The OTP value to verify.
   * @returns Promise that resolves if the OTP verification is successful, otherwise rejects.
   */
  const verifyOtp = async (phoneNumber: string, otp: number): Promise<void> => {
    try {
      await verifyOtpMutate.mutateAsync({
        recipient: phoneNumber,
        deliveryMethod: 'sms',
        otp: otp,
      } as OTPVerify);
    } catch (error) {
      const errorMessage = `Failed to verify OTP: ${(error as Error).message}`;
      logger.error(errorMessage);
      throw new Error(errorMessage);
    }
  };

  return { requestOtp, verifyOtp };
};

export default useOtp;
