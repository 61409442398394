import { ContentView, VideoView } from '@components';
import AppConfig from '@config';
import { useRoute } from '@react-navigation/native';
import { HouseManual } from '@store';
import { useState } from 'react';
import { Image, Linking, StyleSheet, View } from 'react-native';
import { Autolink } from 'react-native-autolink';
import Lightbox from 'react-native-lightbox-v2';
import { INavigationParamsProps } from '../components';

const InstructionsContentView = (props: {
  instructions: string | undefined;
}) => {
  const { instructions = '' } = props;
  if (instructions.length === 0) {
    return null;
  }

  return (
    <Autolink
      style={[styles.container]}
      text={instructions}
      textProps={{ style: AppConfig.Styles.caption }}
      linkStyle={{ textDecorationLine: 'underline', color: 'blue' }}
      stripPrefix={false}
      onPress={url => Linking.openURL(url)}
    />
  );
};

const ImageContentView = (props: { url: string | undefined }) => {
  const [size, setSize] = useState(375); // GP-206: force to have initial width/height for iOS Safari
  const { url = '' } = props;
  if (url.length === 0) {
    return null;
  }

  return (
    <View
      style={[styles.container]}
      onLayout={event => setSize(event.nativeEvent.layout.width)}>
      <Lightbox>
        <Image
          style={{
            width: size,
            height: size,
            resizeMode: 'contain',
          }}
          source={{
            uri: url,
          }}
        />
      </Lightbox>
    </View>
  );
};

const VideoContentView = (props: { url: string | undefined }) => {
  const { url = '' } = props;
  if (url.length === 0) {
    return null;
  }

  return (
    <View style={styles.container}>
      <VideoView source={url} />
    </View>
  );
};

export const ManualDetail = () => {
  const route = useRoute();
  const { contentTitle, model } = route.params as INavigationParamsProps;
  const houseManual = model as HouseManual;

  switch (houseManual?.layout) {
    case '2':
      return (
        <ContentView title={contentTitle}>
          <VideoContentView url={houseManual?.videoUrl} />
          <InstructionsContentView instructions={houseManual?.instructions} />
          <ImageContentView url={houseManual?.imageUrl} />
        </ContentView>
      );
    case '3':
      return (
        <ContentView title={contentTitle}>
          <InstructionsContentView instructions={houseManual?.instructions} />
          <VideoContentView url={houseManual?.videoUrl} />
          <ImageContentView url={houseManual?.imageUrl} />
        </ContentView>
      );
    case '4':
      return (
        <ContentView title={contentTitle}>
          <ImageContentView url={houseManual?.imageUrl} />
          <InstructionsContentView instructions={houseManual?.instructions} />
          <VideoContentView url={houseManual?.videoUrl} />
        </ContentView>
      );
    default:
      return (
        <ContentView title={contentTitle}>
          <InstructionsContentView instructions={houseManual?.instructions} />
        </ContentView>
      );
  }
};

export default ManualDetail;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    marginBottom: AppConfig.Styles.getAdjustedSize(20),
    justifyContent: 'center',
    alignItems: 'center',
  },
});
