import { GUEST_SERVICE_DOMAIN } from '@/assets/configurations/Settings';
import { getCognitoIdTokenFromAsyncStorage } from '@/screens/login/LoginCognito/utils';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { useSelector } from 'react-redux';
import { dispatchReset } from '../actions';
import { useAuth } from '../auth';
import { useAppContext } from '../context';
import {
  ReservationApi,
  useGetReservationQuery,
} from '../reservations/api-slice';
import { useAppSelector } from '../store';

// Encode the query params: fix special characters in the query params
const encodeGetParams = (p: Record<string, string>) =>
  Object.entries(p)
    .map(kv => kv.map(encodeURIComponent).join('='))
    .join('&');

export interface GuestUser {
  id: string;
  emailPrimary: string;
  emailPrimaryIsVerified?: boolean;
  givenName?: string;
  familyName?: string;
  preferredName?: string;
  phonePrimary?: string;
  phonePrimaryIsVerified?: boolean;
  emailNotification?: boolean;
  smsNotification?: boolean;
  locale?: string;
}
export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: GUEST_SERVICE_DOMAIN,
    prepareHeaders: async headers => {
      const accessToken = await getCognitoIdTokenFromAsyncStorage();
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Guest'],
  endpoints: builder => ({
    createGuestUser: builder.mutation({
      query: guestUser => ({
        url: '/guests',
        method: 'POST',
        body: guestUser,
      }),
      invalidatesTags: ['Guest'],
    }),
    getGuestUserById: builder.query({
      query: (id: string) => ({
        url: `/guests/${id}`,
      }),
      providesTags: ['Guest'],
    }),
    patchGuestUserById: builder.mutation({
      query: (guest: GuestUser) => ({
        url: `/guests/${guest.id}`,
        method: 'PATCH',
        body: guest,
      }),
      invalidatesTags: ['Guest'],
      async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          userApi.util.updateQueryData(
            'getGuestUser',
            { email: patch.emailPrimary },
            draft => {
              const user = draft.data[0] ?? {};
              Object.assign(user, patch);
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    getGuestUser: builder.query({
      query: (args: Record<string, string> | string) => {
        const queryParams = encodeGetParams(args as Record<string, string>);

        return {
          url: `/guests?${queryParams}`,
        };
      },
      providesTags: ['Guest'],
    }),
    deleteGuestUserById: builder.mutation({
      query: id => ({
        url: `/guests/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Guest'],
    }),
  }),
  // Serialize the arguments to the `getGuestUser` endpoint that way we can use selector without the need of the email
  serializeQueryArgs: ({ endpointName }) => {
    return endpointName;
  },
});

export const useGetGuestUserQuery = () => {
  const { urlConfig } = useAppContext();
  const { id: configId, origin } = urlConfig;
  const companyCode = configId.split('_')[0];
  const reservationCode = configId.split('_')[1];

  const {
    authState: {
      decodedToken: { email, isAdmin },
    },
  } = useAuth();

  const { getGuestUser } = userApi.endpoints;
  const { getReservation } = ReservationApi.endpoints;
  const { getGuestUserById } = userApi.endpoints;

  // Using selector because when we call the query, the data is not available immediately
  const getGuestUserSelector = getGuestUser.select('getGuestUser');
  const getReservationSelector = getReservation.select({
    companyCode: companyCode,
    reservationCode: reservationCode,
  });
  const guestState = useAppSelector(getGuestUserSelector);
  const reservationState = useSelector(getReservationSelector);
  const getGuestUserByIdSelector = getGuestUserById.select(
    reservationState?.data?.reservation?.guestId ?? '',
  );
  const guestByIdState = useAppSelector(getGuestUserByIdSelector);

  const {
    refetch,
    isUninitialized: isUninitializedGuest,
    isLoading,
  } = userApi.useGetGuestUserQuery(
    { email },
    {
      skip: isAdmin || !email,
    },
  );

  const { isSuccess: isReservationQuerySuccess } = useGetReservationQuery(
    {
      companyCode: companyCode,
      reservationCode: reservationCode,
    },
    {
      skip: !email || !isAdmin || !companyCode || !reservationCode,
    },
  );

  const {
    refetch: refetchById,
    isUninitialized: isUninitializedById,
    isLoading: isLoadingById,
  } = userApi.useGetGuestUserByIdQuery(
    reservationState?.data?.reservation?.guestId ?? '',
    {
      skip: !isAdmin || !reservationState?.data?.reservation?.guestId,
    },
  );

  let isUninitialized = isAdmin ? isUninitializedById : isUninitializedGuest;

  if (
    isReservationQuerySuccess &&
    !reservationState?.data?.reservation?.guestId
  ) {
    isUninitialized = false;
  }

  if (isAdmin && origin && !companyCode && !reservationCode) {
    dispatchReset();
    return {};
  }

  return {
    emailFromToken: email,
    data: (isAdmin
      ? guestByIdState?.data?.data
      : guestState?.data?.data?.[0]) as undefined | GuestUser,
    isLoading: isAdmin ? isLoadingById : isLoading,
    refetch: isAdmin ? refetchById : refetch,
    isUninitialized,
    isAdmin,
  };
};

export const {
  useCreateGuestUserMutation,
  useGetGuestUserByIdQuery,
  usePatchGuestUserByIdMutation,
  useDeleteGuestUserByIdMutation,
} = userApi;
