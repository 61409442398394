import { trackEvent } from '@/hooks/useAnalytics';
import { logger } from '@/libs/logger';
import { useAppContext, useAppStore } from '@/store';
import { useAuth } from '@/store/auth';
import { useLanguagePanel } from '@/store/languagePanel';
import { useGetTokenMutation } from '@/store/services/cognitoApi';
import AppConfig from '@config';
import useLocalization from '@localizations';
import { ParamListBase, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { AppleLogo, FacebookLogo, GooglePlusLogo } from '@svgs';
import { useEffect, useRef, useState } from 'react';
import {
  Linking,
  NativeSyntheticEvent,
  StyleSheet,
  Text,
  TextInputChangeEventData,
  TextInputKeyPressEventData,
  View,
} from 'react-native';
import {
  Button,
  HelperText,
  MD3DarkTheme,
  TextInput,
  useTheme,
} from 'react-native-paper';
import { ThemeProp } from 'react-native-paper/lib/typescript/types';
import { Svg } from 'react-native-svg';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import OpertoActivityIndicator from './OpertoActivityIndicator';
import RegistrationLayout from './RegistrationLayout';
import {
  FEDERATED_LOGIN_IDENTITY_PROVIDER,
  federatedLoginRedirectLink,
  getRedirectInfoFromAsyncStorage,
  getRegistrationInfoFromAsyncStorage,
  removeCognitoAccessTokenFromAsyncStorage,
  removeRedirectInfoFromAsyncStorage,
  removeRegistrationInfoFromAsyncStorage,
  setCognitoAccessTokenToAsyncStorage,
  setRedirectInfoToAsyncStorage,
  setRegistrationInfoToAsyncStorage,
  shouldNavigateToAuth,
  signInUser,
  signUpUser,
  validateEmail,
} from './utils';

const throwMismatchedLoginMethodError = () => {
  trackEvent({
    event: 'CLICKED',
    screen: 'RequestCredentials',
    feature: 'sign_in',
    action: 'Sign_in_errors',
    details: 'web_signup_mismatched_login_method',
  });
};

const throwEmailFormatError = () => {
  trackEvent({
    event: 'CLICKED',
    screen: 'RequestCredentials',
    feature: 'sign_in',
    action: 'Sign_in_errors',
    details: 'web_signup_email_format_error',
  });
};

const RequestCredentials = () => {
  const { t, languages } = useLocalization();
  const navigation = useNavigation<StackNavigationProp<ParamListBase>>();
  const { openPanel } = useLanguagePanel();
  const { locale, urlConfig, setUrlConfig } = useAppContext();
  const { setIdToken } = useAuth();
  const [isUserSigningIn, setIsUserSigningIn] = useState(false);
  const [loginError, setLoginError] = useState<string | null>();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const hasMounted = useRef(false);
  const { company, reservation } = useAppStore();
  const { colors } = useTheme();

  const [getSocialMediaTokens] = useGetTokenMutation();

  useEffect(() => {
    if (!company && !reservation) {
      navigation.navigate('Splash');
    }
    if (!shouldNavigateToAuth(company)) {
      navigation.navigate('LoginChallenge');
    }
  }, [company, navigation, reservation]);

  const onContinueClick = async () => {
    await removeRegistrationInfoFromAsyncStorage();
    setLoginError(null);
    trackEvent({
      event: 'CLICKED',
      screen: 'RequestCredentials',
      feature: 'sign_in',
      action: 'sign_in_visitors',
      details: 'sign_in_OTP',
    });
    if (isUserSigningIn) {
      return;
    }

    if (!validateEmail(email)) {
      throwEmailFormatError();
      setLoginError(t('web_signup_email_format_error'));
      return;
    }

    setIsUserSigningIn(true);
    trackEvent({
      event: 'CLICKED',
      screen: 'RequestCredentials',
      feature: 'login',
      action: 'sign_in_visitors',
    });

    const { error: signUpError } = await signUpUser(email);
    if (signUpError) {
      switch (true) {
        case signUpError.name === 'InvalidParameterException':
          throwEmailFormatError();
          setLoginError(t('web_signup_email_format_error'));
          setIsUserSigningIn(false);
          return;
        case signUpError.message?.includes('UsernameExistsException'):
          break;
        default:
          throwMismatchedLoginMethodError();
          setLoginError(t('web_signup_mismatched_login_method'));
          setIsUserSigningIn(false);
          return;
      }
    }

    const { session, error: signInError } = await signInUser(email);

    if (
      signInError?.name === 'UserNotFoundException' &&
      signUpError?.message?.includes('UsernameExistsException')
    ) {
      throwMismatchedLoginMethodError();
      setLoginError(t('web_signup_mismatched_login_method'));
      setIsUserSigningIn(false);
      return;
    }

    setIsUserSigningIn(false);

    if (session) {
      await setRegistrationInfoToAsyncStorage({ session, email });

      navigation.navigate('VerifyOtp');
    }
  };

  const inputKeyPress = ({
    nativeEvent,
  }: {
    nativeEvent: TextInputKeyPressEventData;
  }) => {
    if (nativeEvent.key === 'Enter') {
      onContinueClick();
    }
  };

  const onEmailUpdate = (
    event: NativeSyntheticEvent<TextInputChangeEventData>,
  ) => {
    event.preventDefault();

    const newEmail = event.nativeEvent.text.toLowerCase().trim();

    setEmail(newEmail);
    setLoginError(null);
  };

  const onLanguagePressed = () => {
    openPanel();
  };

  const eventLabels: { [key in number]: string } = {
    [FEDERATED_LOGIN_IDENTITY_PROVIDER.APPLE]: 'sign_in_Apple',
    [FEDERATED_LOGIN_IDENTITY_PROVIDER.FACEBOOK]: 'sign_in_Facebook',
    [FEDERATED_LOGIN_IDENTITY_PROVIDER.GOOGLE]: 'sign_in_Google',
  };

  const onFederatedLoginPressed = async (provider: number) => {
    trackEvent({
      event: 'CLICKED',
      screen: 'RequestCredentials',
      feature: 'sign_in',
      action: 'sign_in_visitors',
      details: eventLabels[provider],
    });
    setIsUserSigningIn(true);
    try {
      const cleanUpCognitoTokens = async () => {
        await removeCognitoAccessTokenFromAsyncStorage();
      };

      cleanUpCognitoTokens();

      const redirectLink = federatedLoginRedirectLink(provider);

      const { id: urlId } = urlConfig;
      await setRedirectInfoToAsyncStorage({ urlId });

      if (redirectLink) {
        window.location.href = redirectLink;
      }
    } catch (error) {
      logger.error(error);
    } finally {
      setIsUserSigningIn(false);
    }
  };

  useEffect(() => {
    const verifyError = async () => {
      const registrationError = await getRegistrationInfoFromAsyncStorage();

      if (registrationError) {
        const { error } = registrationError;

        if (error) {
          throwMismatchedLoginMethodError();
          setLoginError(t('web_signup_mismatched_login_method'));
        }

        await removeRegistrationInfoFromAsyncStorage();
      }
    };

    verifyError();
  }, [t]);

  useEffect(() => {
    const getSocialTokens = async () => {
      if (hasMounted.current) {
        return;
      }

      hasMounted.current = true;

      try {
        const initialUrl = (await Linking.getInitialURL()) ?? '';
        const { searchParams, origin } = new URL(initialUrl);

        const code = searchParams.get('code');

        if (code) {
          setIsLoading(true);

          const redirectInfo = await getRedirectInfoFromAsyncStorage();
          await removeRedirectInfoFromAsyncStorage();
          const tokenResult = await getSocialMediaTokens(code).unwrap();

          await setCognitoAccessTokenToAsyncStorage({
            accessToken: tokenResult.access_token,
            idToken: tokenResult.id_token,
            refreshToken: tokenResult.refresh_token,
            expiresIn: +tokenResult.expires_in,
          });

          if (redirectInfo && redirectInfo.urlId) {
            setUrlConfig({
              origin,
              id: redirectInfo.urlId,
              entryPath: '/trips',
            });
          }

          navigation.pop();
          setIdToken({ idToken: tokenResult.id_token, tokenType: 'AWS' });
        }
      } catch (error) {
        logger.error(error);
        setIsLoading(false);
      }
    };

    getSocialTokens();
  }, [
    getSocialMediaTokens,
    setIdToken,
    setUrlConfig,
    urlConfig,
    hasMounted,
    navigation,
  ]);

  return (
    <RegistrationLayout
      isRequestingOtp={isLoading}
      justifyContent={isLoading ? 'center' : 'flex-start'}>
      {isLoading ? (
        <OpertoActivityIndicator message={t('verification_code_check')} />
      ) : (
        <>
          <Text style={styles.loginSignupMessage}>{t('web_login_title')}</Text>

          <TextInput
            testID="email-input"
            mode="outlined"
            textContentType="emailAddress"
            label={t('web_login_email')}
            textColor={MD3DarkTheme.colors.onSurfaceVariant}
            outlineColor={MD3DarkTheme.colors.outline}
            activeOutlineColor={MD3DarkTheme.colors.onSurfaceVariant}
            theme={emailInputTheme}
            style={loginError ? styles.emailInputError : styles.emailInput}
            onChange={onEmailUpdate}
            onKeyPress={inputKeyPress}
            value={email}
            error={!!loginError}
          />

          <HelperText
            type="error"
            visible={!!loginError}
            style={{ padding: 0, alignSelf: 'flex-start' }}
            theme={{ colors: { error: MD3DarkTheme.colors.error } }}>
            {loginError}
          </HelperText>

          <Button
            loading={isUserSigningIn}
            style={styles.continueButton}
            labelStyle={MD3DarkTheme.fonts.labelLarge}
            mode="contained"
            buttonColor={colors.primaryContainer}
            textColor={colors.tertiary}
            onPress={onContinueClick}>
            {t('web_login_button')}
          </Button>

          <View style={styles.orView}>
            <View
              style={{
                borderColor: '#ECE6F0',
                borderWidth: 1,
                width: '41%',
                marginRight: '18px',
              }}
            />

            <Text style={styles.orText}>{t('web_login_or')}</Text>

            <View
              style={{
                borderColor: '#ECE6F0',
                borderWidth: 1,
                width: '41%',
                marginLeft: '18px',
              }}
            />
          </View>

          <Button
            style={styles.federatedButton}
            mode="outlined"
            textColor={colors.onSecondary}
            onPress={() => {
              onFederatedLoginPressed(FEDERATED_LOGIN_IDENTITY_PROVIDER.GOOGLE);
            }}>
            <View style={styles.socialMediaButton}>
              <Svg
                width="18"
                height="18"
                fill="#FFF"
                viewBox="0 0 18 18"
                style={{ marginRight: 8, padding: 0 }}>
                <GooglePlusLogo />
              </Svg>

              <Text>{t('web_login_google_button_title')}</Text>
            </View>
          </Button>

          <Button
            style={styles.federatedButton}
            mode="outlined"
            textColor={colors.onSecondary}
            onPress={() => {
              onFederatedLoginPressed(FEDERATED_LOGIN_IDENTITY_PROVIDER.APPLE);
            }}>
            <View style={styles.socialMediaButton}>
              <Svg
                width="16"
                height="19"
                fill="#FFF"
                viewBox="0 0 16 19"
                style={{ marginRight: 8, padding: 0 }}>
                <AppleLogo />
              </Svg>

              <Text>{t('web_login_apple_button_title')}</Text>
            </View>
          </Button>

          <Button
            style={styles.federatedButton}
            mode="outlined"
            textColor={colors.onSecondary}
            onPress={() => {
              onFederatedLoginPressed(
                FEDERATED_LOGIN_IDENTITY_PROVIDER.FACEBOOK,
              );
            }}>
            <View style={styles.socialMediaButton}>
              <Svg
                width="19"
                height="19"
                fill="#FFF"
                viewBox="0 0 19 19"
                style={{ marginRight: 8, padding: 0 }}>
                <FacebookLogo />
              </Svg>

              <Text>{t('web_login_facebook_button_title')}</Text>
            </View>
          </Button>

          <Button
            labelStyle={MD3DarkTheme.fonts.titleSmall}
            mode="text"
            textColor={colors.onSecondary}
            onPress={onLanguagePressed}>
            <Text style={styles.languageButton}>
              <Icon
                name="earth"
                size={AppConfig.Styles.getAdjustedSize(24)}
                style={{ marginRight: '8px' }}
              />
              {languages.find(l => l.locale === locale)?.name ?? 'English'}
            </Text>
          </Button>
        </>
      )}
    </RegistrationLayout>
  );
};

const styles = StyleSheet.create({
  loginSignupMessage: {
    ...MD3DarkTheme.fonts.headlineLarge,
    color: 'white',
    marginBottom: 16,
    textAlign: 'center',
  },
  continueButton: { width: '100%', height: 40, marginBottom: 16 },
  orView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginBottom: 16,
  },
  orText: {
    ...MD3DarkTheme.fonts.titleSmall,
    marginHorizontal: 10,
    color: '#FFFFFF',
  },
  federatedButton: {
    width: '100%',
    height: 40,
    marginBottom: 16,
  },
  languageButton: {
    display: 'flex',
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
  emailInput: {
    width: '100%',
    marginBottom: 16,
    backgroundColor: 'transparent',
  },
  emailInputError: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  socialMediaButton: {
    flexDirection: 'row',
  },
});

const emailInputTheme: ThemeProp = {
  colors: {
    ...MD3DarkTheme.colors,
    background: 'rgb(98,96,94)',
  },
};

export default RequestCredentials;
