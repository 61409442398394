import { PaymentPayload } from '@/types/payments';
import AppConfig from '@config';
import useLocalization from '@localizations';
import { StripeProvider, Theme, useAppContext, useAppStore } from '@store';
import { Actionsheet, View } from 'native-base';
import React from 'react';
import { StyleSheet } from 'react-native';
import StripeCheckoutForm from './StripeCheckoutForm';

interface IAccessCodeActionSheetProps {
  onDismiss?: () => void;
  isOpen?: boolean;
  payload?: PaymentPayload;
}

export interface PaymentViewBaseProps {
  onDismiss: () => void;
  theme?: Theme;
}

const PaymentActionSheet: React.FC<IAccessCodeActionSheetProps> = ({
  onDismiss = () => undefined,
  isOpen,
}) => {
  // Actionsheet component does not trigger hooks of children.
  // As a workaround we are passing through values from hooks as parameters.
  const { locale } = useAppContext();
  const { t } = useLocalization();

  const labels = {
    cancel: t('cancel'),
    confirm: t('confirm'),
    depositSuccessful: t('deposit_successful'),
    despositSuccessfulDescription: t('desposit_successful_description'),
  };

  const { theme, company, reservation } = useAppStore();
  // Later need to parse through different providers
  const stripeProvider = company?.paymentProviders?.[0] as StripeProvider;
  const configId = `${company?.id}_${reservation?.id}`;
  return (
    <Actionsheet isOpen={isOpen} onClose={onDismiss} hideDragIndicator>
      <Actionsheet.Content>
        <View style={[styles.sheetContent]}>
          {isOpen && (
            <StripeCheckoutForm
              configId={configId}
              onDismiss={onDismiss}
              theme={theme}
              publishableKey={stripeProvider?.publishable_key?.trim()}
              company={company}
              locale={locale}
              labels={labels}
            />
          )}
        </View>
      </Actionsheet.Content>
    </Actionsheet>
  );
};

const styles = StyleSheet.create({
  sheetContent: {
    width: '100%',
    height: '475px',
    minWidth: AppConfig.Styles.getAdjustedSize(300),
    maxWidth: AppConfig.Styles.responsiveMaxWidth,
    padding: AppConfig.Styles.getAdjustedSize(15),
    alignItems: 'center',
    alignSelf: 'center',
    paddingLeft: AppConfig.Styles.getAdjustedSize(32),
    paddingRight: AppConfig.Styles.getAdjustedSize(32),
  },
});
export default PaymentActionSheet;
