import { SVGProps } from 'react';
import Svg, { Path } from 'react-native-svg';

export const LuggageIcon = ({
  fill = 'currentColor',
  width = 80,
  height = 80,
}: SVGProps<SVGSVGElement>) => (
  <Svg viewBox="0 0 81 80" width={width} height={height}>
    <Path
      d="M22.168 69.9998C20.793 69.9998 19.6159 69.5103 18.6367 68.5311C17.6576 67.5519 17.168 66.3748 17.168 64.9998V24.9998C17.168 23.6248 17.6576 22.4478 18.6367 21.4686C19.6159 20.4894 20.793 19.9998 22.168 19.9998H30.918V11.6665C30.918 10.2915 31.4076 9.11442 32.3867 8.13525C33.3659 7.15609 34.543 6.6665 35.918 6.6665H45.0846C46.4596 6.6665 47.6367 7.15609 48.6159 8.13525C49.5951 9.11442 50.0846 10.2915 50.0846 11.6665V19.9998H58.8346C60.2096 19.9998 61.3867 20.4894 62.3659 21.4686C63.3451 22.4478 63.8346 23.6248 63.8346 24.9998V64.9998C63.8346 66.3748 63.3451 67.5519 62.3659 68.5311C61.3867 69.5103 60.2096 69.9998 58.8346 69.9998C58.8346 70.9443 58.5152 71.7359 57.8763 72.3748C57.2374 73.0137 56.4457 73.3332 55.5013 73.3332C54.5569 73.3332 53.7652 73.0137 53.1263 72.3748C52.4874 71.7359 52.168 70.9443 52.168 69.9998H28.8346C28.8346 70.9443 28.5152 71.7359 27.8763 72.3748C27.2374 73.0137 26.4457 73.3332 25.5013 73.3332C24.5569 73.3332 23.7652 73.0137 23.1263 72.3748C22.4874 71.7359 22.168 70.9443 22.168 69.9998ZM22.168 64.9998H58.8346V24.9998H22.168V64.9998ZM30.918 59.9998H35.918V29.9998H30.918V59.9998ZM45.0846 59.9998H50.0846V29.9998H45.0846V59.9998ZM35.918 19.9998H45.0846V11.6665H35.918V19.9998Z"
      fill={fill}
    />
  </Svg>
);
