import { QRCodeData, useQRCodeMessages } from '@/hooks/useQRCodeMessages';
import {
  ActionButtons,
  LanguageMenu,
  NotFound,
  Spacer,
  Splash,
  ThemedLogoHeader,
} from '@components';
import AppConfig from '@config';
import useLocalization from '@localizations';
import { useAppContext } from '@store';
import { LoginWelcomeLogo } from '@svgs';
import { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';

export const Welcome = () => {
  const { locale, setLocale } = useAppContext();
  const { data, isLoading, isError, getQRCodeMessages } = useQRCodeMessages();
  const { t } = useLocalization();
  const qrCode: QRCodeData | undefined = data?.qrCode[locale];

  useEffect(() => {
    getQRCodeMessages('welcome');
  }, [getQRCodeMessages]);

  if (isLoading) {
    return <Splash />;
  }

  if ((!data && isError) || !data?.checkinQRCodeEnabled) {
    return <NotFound />;
  }

  return (
    <View style={[AppConfig.Styles.container, AppConfig.Styles.center]}>
      <LanguageMenu selected={locale} onClose={setLocale} />
      <View style={styles.container}>
        <ThemedLogoHeader theme={data?.theme}>
          <LoginWelcomeLogo
            style={{ color: data?.theme?.primaryColor }}
            width={AppConfig.Styles.getAdjustedSize(230)}
          />
        </ThemedLogoHeader>
        <Spacer size={4} />
        <Text
          style={[
            AppConfig.Styles.textAlignCenter,
            AppConfig.Styles.headline6,
          ]}>
          {qrCode?.title?.length ? qrCode.title : t('welcome_thank_you_title')}
        </Text>
        <Spacer size={2} />
        <Text
          style={[
            AppConfig.Styles.textAlignCenter,
            AppConfig.Styles.subtitle2,
          ]}>
          {qrCode?.message?.length ? qrCode.message : t('scan_to_checkin')}
        </Text>
        <Spacer size={4} />
        {qrCode?.buttons && <ActionButtons buttons={qrCode.buttons} />}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: AppConfig.Styles.responsiveMaxWidth,
    padding: AppConfig.Styles.getAdjustedSize(16),
  },
});
