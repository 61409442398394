import { colors } from './app.json';

// TODO: update these colours from Invision
export const PRIMARY: string = colors.primary;
export const ACCENT: string = colors.accent;
export const SECONDARY: string = colors.secondary;
export const ON_SECONDARY: string = colors.onSecondary;
export const SECONDARY_CONTAINER: string = colors.secondaryContainer;
export const TERTIARY: string = colors.tertiary;
export const SUCCESS: string = colors.success;
export const WARNING: string = colors.warning;
export const ERROR: string = colors.error;
export const DARK_PRIMARY: string = colors.darkPrimary;
export const DARK_ON_PRIMARY: string = colors.darkOnPrimary;

// house manual icon color
export const ICON_ARRIVAL = '#6213EE';
export const ICON_DEPARTURE = '#37C156';
export const ICON_ENTERTAINMENT = '#26C3FC';
export const ICON_HOUSE_RULES = '#FC7001';

export default {
  PRIMARY,
  ACCENT,
  SECONDARY,
  ON_SECONDARY,
  SECONDARY_CONTAINER,
  TERTIARY,
  SUCCESS,
  WARNING,
  ERROR,
  ICON_ARRIVAL,
  ICON_DEPARTURE,
  ICON_ENTERTAINMENT,
  ICON_HOUSE_RULES,
  DARK_PRIMARY,
  DARK_ON_PRIMARY,
};
