import AppConfig from '@config';
import useLocalization from '@localizations';
import { FunctionComponent } from 'react';
import { Snackbar } from 'react-native-paper';

interface ISnackbarProps {
  title: string;
  color?: string;
  visible?: boolean;
  onDismiss?: () => void;
  duration?: number;
}

/**
 * SnackBar - a themed wrapper to the react-native-paper's Snackbar component
 */
export const SnackBar: FunctionComponent<ISnackbarProps> = ({
  title,
  color = AppConfig.Colors.SUCCESS,
  visible = false,
  onDismiss = () => undefined,
  duration = 10000,
}) => {
  const { t } = useLocalization();

  return visible ? (
    <Snackbar
      duration={duration}
      style={{
        backgroundColor: color,
        alignItems: 'center',
        minWidth: '343px',
        width: 'fit-content',
        marginRight: 'auto',
        marginLeft: 'auto',
      }}
      visible={visible}
      onDismiss={onDismiss}
      action={{
        label: t('close'),
        labelStyle: {
          color: 'white',
        },
      }}>
      {title}
    </Snackbar>
  ) : null;
};

export default SnackBar;
