import { logger } from '@/libs/logger';
import {
  dispatchGetReservations,
  processError,
  useAppContext,
  useAppStore,
} from '@/store';
import { useFeatureList } from '@/store/featured-list';
import { useEffect } from 'react';

const POLL_GP_DATA_INTERVAL = 30000;

export const usePollGuestPortalData = () => {
  const { locale } = useAppContext();
  const { company, reservation } = useAppStore();
  const { fetchFeatureList } = useFeatureList();

  /**
   * Start Polling Reservations Data
   */
  useEffect(() => {
    const pollGuestPortalData = () => {
      try {
        logger.debug('polling reservation data...');
        if (company?.id) {
          fetchFeatureList(company.id, reservation?.property?.id, locale);
          if (company?.reservation) {
            dispatchGetReservations({
              reservationId: company.reservation as string,
              companyId: company.id,
              locale,
            });
          }
        }
      } catch (error) {
        const message = processError(error);
        logger.error(message);
      }
    };

    pollGuestPortalData();

    const id = setInterval(pollGuestPortalData, POLL_GP_DATA_INTERVAL);
    return () => {
      logger.debug('stop polling reservation data...');
      clearInterval(id);
    };
  }, [
    fetchFeatureList,
    company?.id,
    company?.reservation,
    locale,
    reservation?.id,
    reservation?.property?.id,
  ]);
};
