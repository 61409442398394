import { schema } from 'normalizr';

const company = new schema.Entity('companies');
const theme = new schema.Entity('themes');
const host = new schema.Entity('hosts');
const member = new schema.Entity('members');
const reservation = new schema.Entity('reservations');
const amenity = new schema.Entity('amenities');
const nearme = new schema.Entity('nearme');
const chatToken = new schema.Entity('chatToken');
const verification = new schema.Entity('verification');

// lets define relationships here
company.define({
  theme,
  host,
  reservation,
  amenities: [amenity],
  nearme: [nearme],
});

chatToken.define({
  chatToken,
});

theme.define({
  company,
});

host.define({
  members: [member],
});

// blueprint for normalizer
export const AppSchema = {
  companies: [company],
  themes: [theme],
  hosts: [host],
  members: [member],
  reservations: [reservation],
  amenities: [amenity],
  nearme: [nearme],
  chatToken: [chatToken],
  verification: [verification],
};
