import { useEffect, useMemo } from 'react';
import {
  StyleProp,
  StyleSheet,
  TextInputKeyPressEventData,
  View,
  ViewStyle,
} from 'react-native';
import { MD3DarkTheme, TextInput } from 'react-native-paper';

const OtpInput = ({
  value,
  onChange,
  error,
  mode,
  textColor,
  outlineColor,
  outlineStyle,
  contentStyle,
  activeOutlineColor,
  errorColor,
  isLoading = false,
  width = 56,
  height = 56,
  margin = 2,
}: OtpInputProps) => {
  const inputs: unknown[] = useMemo(() => [], []);

  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    inputStyle: {
      width,
      height,
      margin,
      textAlign: 'center',
      alignItems: 'center',
    },
  });

  const handleOtpChange = (newValue: string, index: number) => {
    const newOtpArray = newValue.split('');

    if (newOtpArray.length === value.length) {
      onChange(newOtpArray.map((char, i) => char ?? newOtp[i]));
      return;
    }

    const newOtp = [...value];
    newOtp[index] = newOtpArray[0] ?? '';
    onChange(newOtp);

    // Move focus to the next box if the current one has a value
    if (newValue && index < newOtp.length - 1) {
      //@ts-ignore no index for unknown
      inputs[index + 1].focus();
    }
  };

  const keyPressHandler = (
    nativeEvent: TextInputKeyPressEventData,
    index: number,
  ) => {
    if (nativeEvent.key === 'Backspace') {
      handleOtpChange('', index);
      //@ts-ignore no index for unknown
      inputs[index - 1]?.focus();
    }
  };

  useEffect(() => {
    if (value.join('').length === 0) {
      //@ts-ignore no index for unknown
      inputs?.[0]?.focus();
    }
  }, [value, inputs]);

  return (
    <View style={styles.container}>
      {value.map((digit, index) => (
        <TextInput
          key={index}
          testID={`otp-input-${index}`}
          error={error}
          mode={mode}
          maxLength={value.length}
          keyboardType="numeric"
          textColor={textColor}
          outlineColor={outlineColor}
          outlineStyle={outlineStyle}
          contentStyle={[contentStyle, { minWidth: '56px' }]}
          activeOutlineColor={activeOutlineColor}
          theme={{ colors: { error: errorColor ?? MD3DarkTheme.colors.error } }}
          style={styles.inputStyle}
          onChangeText={newValue => handleOtpChange(newValue, index)}
          onKeyPress={e => keyPressHandler(e.nativeEvent, index)}
          value={digit}
          ref={(instance: unknown) => {
            inputs[index] = instance;
          }}
          disabled={isLoading}
        />
      ))}
    </View>
  );
};

export default OtpInput;

interface OtpInputProps {
  value: string[];
  onChange: (otp: string[]) => void;
  error?: boolean;
  mode?: 'outlined' | 'flat';
  textColor?: string;
  outlineColor?: string;
  outlineStyle?: StyleProp<ViewStyle>;
  contentStyle?: StyleProp<ViewStyle>;
  activeOutlineColor?: string;
  errorColor?: string;
  width?: number;
  height?: number;
  margin?: number;
  isLoading?: boolean;
}
