import { logger } from '@/libs/logger';
import AppConfig from '@config';
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAccessToken,
  processError,
  processResponse,
} from '../actions/utils';
import {
  CategoryItem,
  CategoryRequestHeader,
  ContentItem,
  ContentRequestHeader,
  GuidesState,
  SectionItem,
  SectionRequestHeader,
} from './models';

export const UNCATEGORIZED_ID = 'bb7172e8-8add-4622-92bf-9f739ec279f0';

export const getSection = createAsyncThunk(
  'getSection',
  async (
    { propertyId, locale, companyCode }: SectionRequestHeader,
    thunkAPI,
  ) => {
    try {
      const accessToken = (await getAccessToken())?.token;
      const response = await fetch(
        `${AppConfig.Settings.BASE_API_URL}/portal/guest/properties/${propertyId}/sections?companyCode=${companyCode}&locale=${locale}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      const result = await processResponse(response);

      const { status, message } = result;
      if (status === 'success') {
        return result;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const message = processError(error);
      const metadata = {
        propertyId,
        locale,
      };
      logger.info('get section', metadata, 'state');
      logger.error(message);

      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getCategories = createAsyncThunk(
  'getCategories',
  async (
    { propertyId, sectionId, locale, companyCode }: CategoryRequestHeader,
    thunkAPI,
  ) => {
    const params = new URLSearchParams({
      numPerPage: `${Number.MAX_SAFE_INTEGER}`,
      pageNum: '0',
    });
    try {
      const accessToken = (await getAccessToken())?.token;
      const response = await fetch(
        `${
          AppConfig.Settings.BASE_API_URL
        }/portal/guest/properties/${propertyId}/sections/${sectionId}/categories?companyCode=${companyCode}&${params.toString()}&locale=${locale}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      const result = await processResponse(response);

      const { status, message } = result;
      if (status === 'success') {
        return result;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const message = processError(error);
      const metadata = {
        propertyId,
        sectionId,
        locale,
        params,
      };
      logger.info('get categories', metadata, 'state');
      logger.error(message);

      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getContents = createAsyncThunk(
  'getContents',
  async (
    {
      propertyId,
      sectionId,
      categoryId,
      locale,
      companyCode,
    }: ContentRequestHeader,
    thunkAPI,
  ) => {
    const params = new URLSearchParams({
      numPerPage: `${Number.MAX_SAFE_INTEGER}`,
      pageNum: '0',
    });

    try {
      const accessToken = (await getAccessToken())?.token;
      const response = await fetch(
        `${
          AppConfig.Settings.BASE_API_URL
        }/portal/guest/properties/${propertyId}/sections/${sectionId}/categories/${categoryId}/contents?companyCode=${companyCode}&${params.toString()}&locale=${locale}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      const result = await processResponse(response);

      const { status, message } = result;
      if (status === 'success') {
        return result;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const message = processError(error);
      const metadata = {
        propertyId,
        sectionId,
        categoryId,
        locale,
        params,
      };
      logger.info('get contents', metadata, 'state');
      logger.error(message);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getUncategorizedContents = createAsyncThunk(
  'getUncategorizedContents',
  async (
    { propertyId, sectionId, locale, companyCode }: CategoryRequestHeader,
    thunkAPI,
  ) => {
    const params = new URLSearchParams({
      numPerPage: `${Number.MAX_SAFE_INTEGER}`,
      pageNum: '0',
    });

    try {
      const accessToken = (await getAccessToken())?.token;
      const response = await fetch(
        `${
          AppConfig.Settings.BASE_API_URL
        }/portal/guest/properties/${propertyId}/sections/${sectionId}/categories/${UNCATEGORIZED_ID}/contents?companyCode=${companyCode}&${params.toString()}&locale=${locale}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      const result = await processResponse(response);

      const { status, message } = result;
      if (status === 'success') {
        return result;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const message = processError(error);
      const metadata = {
        propertyId,
        sectionId,
        locale,
        params,
      };
      logger.info('get contents', metadata, 'state');
      logger.error(message);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const addThunkCases = (
  builder: ActionReducerMapBuilder<GuidesState>,
) => {
  builder.addCase(getSection.fulfilled, (state, action) => {
    state.status = 'succeeded';
    // NOTE: dont need to show snackbar for globals
    // state.message = (action.payload as GuestPortalResponse).message;
    state.sections = action.payload.data.records.filter(
      (section: SectionItem) => section.published,
    );
  });
  builder.addCase(getSection.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(getSection.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });

  builder.addCase(getCategories.fulfilled, (state, action) => {
    state.status = 'succeeded';
    state.categoriesOrder = action.payload.data.order;
    state.categories = action.payload.data.records.filter(
      (category: CategoryItem) => category.published,
    );
  });
  builder.addCase(getCategories.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(getCategories.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
  builder.addCase(getContents.fulfilled, (state, action) => {
    state.status = 'succeeded';
    state.contents = action.payload.data.records.filter(
      (content: ContentItem) => content.published,
    );
  });
  builder.addCase(getContents.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(getContents.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });

  builder.addCase(getUncategorizedContents.fulfilled, (state, action) => {
    state.status = 'succeeded';
    state.uncategorized = action.payload.data.records.filter(
      (content: ContentItem) =>
        content.category_id === UNCATEGORIZED_ID && content.published,
    );
  });
  builder.addCase(getUncategorizedContents.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(getUncategorizedContents.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
};
