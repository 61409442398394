import { TranslationKey } from '@/assets/localizations';
import { logger } from '@/libs/logger';
import {
  Company,
  DepositType,
  PropertyType,
  Reservation,
} from '@/store/models';
import { isTimestampEarlierThanNow } from './DateTimeProcessor';
import {
  isIncidentPaymentConfirmed,
  isIncidentPaymentEnabled,
  isVerificationFlowConfirmed,
  isVerificationFlowEnabled,
} from './VerificationFinder';

export enum BannerStatus {
  NONE = 'none',
  VERIFICATION = 'verification',
  TOO_EARLY = 'too_early',
  SECURITY_DEPOSIT = 'security_deposit',
  INCIDENTAL_PAYMENT = 'incidental_payment',
  UNKNOWN = 'unknown',
}

export const determineReservationState = (
  reservation?: Reservation,
): 'departure' | 'arrival' => {
  switch (reservation?.property?.roomStatus) {
    case 'guest-checking-in':
    case 'guest-checking-out':
      return 'departure';

    default:
      return 'arrival';
  }
};

export const findSecurityDeposit = (company?: Company) =>
  company?.deposits?.find(d => d.type === DepositType.SECURITY);

export const bannerLabel = (status: BannerStatus): TranslationKey => {
  logger.info('banner status', status);

  switch (status) {
    case BannerStatus.TOO_EARLY:
      return 'too_early_banner';
    case BannerStatus.SECURITY_DEPOSIT:
      return 'booking_security_deposit_button';
    default:
      return 'limited_access';
  }
};

export const reservationBannerStatus = (
  company?: Company,
  reservation?: Reservation,
) => {
  if (!reservation) {
    return BannerStatus.UNKNOWN;
  }
  const deposit = findSecurityDeposit(company);

  if (isVerificationFlowEnabled(reservation)) {
    if (!isVerificationFlowConfirmed(reservation)) {
      return BannerStatus.VERIFICATION;
    }
  }
  if (isIncidentPaymentEnabled(reservation)) {
    if (!isIncidentPaymentConfirmed(reservation)) {
      return BannerStatus.INCIDENTAL_PAYMENT;
    }
  }

  // Show the security deposit banner if the status is pending and check in time has already started or
  // when it's declined and they're not already in departure, which means theyve already checked in.

  // removing the check until we figure how how much lead time we want before showing the deposit banner
  //  &&
  // isTimestampEarlierThanNow(
  //   reservation.checkin,
  //   reservation.property.timezone,
  // )) ||
  if (
    deposit?.status === 'pending' ||
    (deposit?.status === 'declined' &&
      determineReservationState(reservation) !== 'departure')
  ) {
    return BannerStatus.SECURITY_DEPOSIT;
  }
  return BannerStatus.NONE;
};

export const hasSecurityDeposit = (company?: Company) => {
  if (!company) {
    return false;
  }
  const securityDeposit = findSecurityDeposit(company);
  return (
    (securityDeposit?.status === 'pending' ||
      securityDeposit?.status === 'declined') &&
    securityDeposit?.amount > 0
  );
};

export const isReservationValid = (reservation?: Reservation) => {
  if (!reservation) {
    return false;
  }

  return (
    !isTimestampEarlierThanNow(
      reservation.checkin,
      reservation.property.timezone,
    ) &&
    !isTimestampEarlierThanNow(
      reservation.checkout,
      reservation.property.timezone,
    )
  );
};

export const isReservationActive = (reservation?: Reservation) => {
  if (!reservation) {
    return false;
  }

  return (
    isTimestampEarlierThanNow(
      reservation.checkin,
      reservation.property.timezone,
    ) &&
    !isTimestampEarlierThanNow(
      reservation.checkout,
      reservation.property.timezone,
    )
  );
};

export const isReservationExpired = (reservation?: Reservation) => {
  if (!reservation) {
    return false;
  }

  return (
    isTimestampEarlierThanNow(
      reservation.checkin,
      reservation.property.timezone,
    ) &&
    isTimestampEarlierThanNow(
      reservation.checkout,
      reservation.property.timezone,
    )
  );
};

export const isRoomType = (reservation?: Reservation) => {
  if (!reservation) {
    return false;
  }

  return reservation.property.type === PropertyType.ROOM;
};

export const isRoomTypeCheckInRequested = (reservation?: Reservation) => {
  if (!isRoomType(reservation)) {
    return false;
  }

  if (!isReservationActive(reservation)) {
    return false;
  }

  return isTimestampEarlierThanNow(
    reservation?.checkinRequestedAt,
    reservation?.property?.timezone,
  );
};

export const canScanQRCodeToCheckIn = (reservation?: Reservation) => {
  if (!isRoomType(reservation)) {
    return false;
  }

  if (!isReservationActive(reservation)) {
    return false;
  }

  if (
    isTimestampEarlierThanNow(
      reservation?.checkinRequestedAt,
      reservation?.property?.timezone,
    )
  ) {
    return false;
  }

  return true;
};

export const shouldConfirmRoomAssignment = (reservation?: Reservation) => {
  if (isRoomType(reservation)) {
    return false;
  }

  if (!isReservationActive(reservation)) {
    return false;
  }

  if (
    !isTimestampEarlierThanNow(
      reservation?.checkinRequestedAt,
      reservation?.property?.timezone,
    )
  ) {
    return false;
  }

  return !reservation?.roomAssignmentConfirmed;
};

export const getAccessCode = (
  company?: Company,
  reservation?: Reservation,
  bannerStatus?: BannerStatus,
): string => {
  switch (true) {
    case isRoomType(reservation):
      return '******';
    case !!reservation?.smw:
      return '******';
    case bannerStatus && bannerStatus !== BannerStatus.NONE:
      return '******';
    case !company?.accessCodeControlEnabled:
      return '******';
    default:
      return reservation?.accessCode ?? '******';
  }
};

export const getReservationPropertyName = (
  reservation?: Reservation,
): string => {
  const propertyName = reservation?.property?.name;
  return !isRoomType(reservation) && propertyName ? propertyName : '******';
};

/**
 * Returns if the Early Check in button can be displayed
 */
export const canShowRequestEarlyCheckin = (
  company?: Company,
  reservation?: Reservation,
) => {
  if (!company || !reservation) {
    return false;
  }

  const isEarlyCheckinTime = !isTimestampEarlierThanNow(
    reservation.checkin,
    reservation.property.timezone,
  );
  const isEarlyCheckinEnabled = company.earlyCheckinEnabled;
  const isReservationStateArrival =
    determineReservationState(reservation) === 'arrival';

  return (
    isReservationStateArrival && isEarlyCheckinEnabled && isEarlyCheckinTime
  );
};

export const canShowCheckInOutButton = (company?: Company, state?: string) => {
  if (!company || !state) {
    return false;
  }

  if (state === 'arrival' && company.checkinEnabled) {
    return true;
  }

  if (state === 'departure' && company.checkoutEnabled) {
    return true;
  }

  return false;
};

export const canShowEntranceInstructionsButton = (
  company?: Company,
  reservation?: Reservation,
) => {
  if (!company || !reservation) {
    return false;
  }

  return (
    reservation?.entranceInstructions.enabled &&
    company?.accessCodeControlEnabled
  );
};
