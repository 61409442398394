import AppConfig from '@config';
import { BubbleLoading } from '@svgs';
import React from 'react';
import { View } from 'react-native';
import { PoweredByView } from './PoweredByView';

interface ISplashProps {
  color?: string;
}

export const Splash: React.FC<ISplashProps> = () => (
  <>
    <View
      testID="splash"
      style={[
        AppConfig.Styles.container,
        AppConfig.Styles.center,
        { backgroundColor: 'transparent' },
      ]}>
      <BubbleLoading
        fill="grey"
        width={AppConfig.Styles.getAdjustedSize(200)}
        height={AppConfig.Styles.getAdjustedSize(200)}
      />
    </View>
    <PoweredByView />
  </>
);

export default Splash;
