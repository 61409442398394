import { logger } from '@/libs/logger';
import AppConfig from '@config';
import { ChatToken } from '../models/models';
import { parseChatToken } from '../models/parsers';
import {
  dispatchChatToken,
  getAccessToken,
  processError,
  processResponse,
} from './utils';

export const dispatchMessagingToken = async (
  companyCode: string,
  reservationCode: string,
) => {
  const accessToken = (await getAccessToken())?.token;
  const response = await fetch(
    `${AppConfig.Settings.BASE_API_URL}/portal/guest/chat-token?companyCode=${companyCode}&reservationCode=${reservationCode}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  const result = await processResponse(response);
  const chatToken: ChatToken = parseChatToken(result);
  dispatchChatToken([chatToken]);
};

export const dispatchChatNotify = async (
  request_message: string,
  companyCode: string,
  reservationCode: string,
) => {
  const formData = new FormData();
  formData.append('request_message', request_message);
  formData.append('companyCode', companyCode);
  formData.append('reservationCode', reservationCode);

  try {
    const accessToken = (await getAccessToken())?.token;
    await fetch(`${AppConfig.Settings.BASE_API_URL}/portal/guest/chat-notify`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: formData,
    });
  } catch (error) {
    const message = processError(error);
    const metadata = {
      formData,
    };
    logger.info('patch chat notify', metadata, 'state');
    logger.error(message);
    throw error;
  }
};

export const dispatchBroadcast = async (
  message: string,
  companyCode: string,
  reservationCode: string,
) => {
  const formData = new FormData();
  formData.append('message', message);
  formData.append('companyCode', companyCode);
  formData.append('reservationCode', reservationCode);

  try {
    const accessToken = (await getAccessToken())?.token;
    await fetch(`${AppConfig.Settings.BASE_API_URL}/portal/guest/broadcast`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: formData,
    });
  } catch (error) {
    const msg = processError(error);
    const metadata = {
      formData,
    };
    logger.info('broadcast message', metadata, 'state');
    logger.error(msg);
    throw error;
  }
};
