export const RELEASE_STAGE =
  process.env.REACT_APP_GIT_BRANCH === 'main'
    ? 'production'
    : process.env.REACT_APP_GIT_BRANCH?.startsWith('release')
    ? 'staging'
    : 'development';
export const BUGSNAG_API_KEY = process.env.REACT_APP_BUGSNAG_API_KEY ?? '';

export const isProduction = RELEASE_STAGE === 'production';
export const isStgaing = RELEASE_STAGE === 'staging';
export const isDevelopment = RELEASE_STAGE === 'development';
