import useLocalization from '@/assets/localizations';
import { Text, useTheme } from 'react-native-paper';
import { Card, CardProps } from '../components/Card';

type ReservationCardProps = Omit<
  CardProps,
  'title' | 'imageUri' | 'children'
> & {
  propertyImage: string;
  propertyName: string;
  propertyCity: string;
  checkInDate: string;
  checkOutDate: string;
};

export const ReservationCard = ({
  propertyImage,
  propertyName,
  propertyCity,
  checkInDate,
  checkOutDate,
  ...rest
}: ReservationCardProps) => {
  const { t } = useLocalization();
  const { colors } = useTheme();

  return (
    <Card title={propertyName} imageUri={propertyImage} {...rest}>
      <Text variant="bodyLarge" style={{ color: colors.inverseOnSurface }}>
        {propertyCity}
      </Text>
      <Text variant="bodyLarge" style={{ color: colors.inverseOnSurface }}>
        {t('trip')}: {checkInDate} - {checkOutDate}
      </Text>
    </Card>
  );
};

export default ReservationCard;
