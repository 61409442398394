import { Error, Loading } from '@/screens/components';
import {
  Company,
  dispatchGetCompany,
  dispatchGetReservations,
  dispatchGetSDIntent,
  dispatchPostStripeConfirm,
  Theme,
} from '@/store';
import AppConfig from '@config';
import { SupportedLocale } from '@localizations';
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import {
  Appearance,
  loadStripe,
  PaymentMethod,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { View } from 'react-native';
import { Button } from 'react-native-paper';

import { logger } from '@/libs/logger';
import LoginStyles from '../../login/Styles';
import { PaymentSuccessView } from './';
import { PaymentViewBaseProps } from './PaymentActionSheet';

interface ILabels {
  cancel: string;
  confirm: string;
  depositSuccessful: string;
  despositSuccessfulDescription: string;
}

interface ICheckoutFormProps {
  onDismiss: () => void;
  theme?: Theme;
  publishableKey: string;
  configId: string;
  company: Company | undefined;
  locale: SupportedLocale;
  labels: ILabels;
}

const StripeCheckoutForm = ({
  onDismiss,
  theme,
  publishableKey,
  configId,
  company,
  locale,
  labels,
}: ICheckoutFormProps) => {
  const [clientSecret, setClientSecret] = useState<undefined | string>(
    undefined,
  );
  const [customerId, setCustomerId] = useState<undefined | string>();
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<undefined | string>(
    undefined,
  );

  const appearance: Appearance = {
    theme: 'stripe',
  };

  const options: StripeElementsOptions = {
    clientSecret,
    appearance,
    locale,
  };

  const stripePromise = loadStripe(publishableKey);

  const { depositSuccessful, despositSuccessfulDescription } = labels;

  useEffect(() => {
    if (company) {
      setIsloading(true);
      dispatchGetSDIntent(company.id, company.reservation as string)
        .then(res => {
          const secret = res?.data?.client_secret?.trim();
          setIsloading(false);
          if (secret && publishableKey) {
            setClientSecret(secret);
            setCustomerId(res?.data?.customer_id || undefined);
          } else {
            setErrorMessage(
              'Something went wrong. Please resubmit your payment details.',
            );
            logger.error('Either secret or publishable key is missing');
          }
        })
        .catch(() => {
          setErrorMessage(
            'Something went wrong. Please resubmit your payment details.',
          );
          logger.error('Wrong payment details.');
          setIsloading(false);
        });
    }
  }, [publishableKey, company]);

  const handleTryAgain = () => {
    setErrorMessage(undefined);
    setIsloading(false);
  };

  const handleStripeError = (message: string) => {
    setErrorMessage(message);
    setIsloading(false);
    logger.error(message);
  };
  const handleSuccess = () => {
    if (configId && company) {
      // no need for status update. backend handles that
      dispatchGetCompany(configId);
      dispatchGetReservations({
        reservationId: company.reservation as string,
        companyId: company.id,
        locale: company.locale as SupportedLocale,
      });
    }
    setIsSuccessful(true);
    setTimeout(() => onDismiss(), 1500);
    setIsloading(false);
  };

  return (
    <>
      {(() => {
        if (isLoading) {
          return <Loading />;
        }
        if (errorMessage) {
          return (
            <Error
              title={errorMessage}
              onRetry={handleTryAgain}
              onCancel={onDismiss}
            />
          );
        }
        if (company && clientSecret !== undefined && !isSuccessful) {
          return (
            <Elements options={options} stripe={stripePromise}>
              <StripeElement
                customerId={customerId}
                onDismiss={onDismiss}
                onSuccess={handleSuccess}
                theme={theme}
                onError={handleStripeError}
                labels={labels}
                companyCode={company.id}
                reservationCode={company.reservation as string}
              />
            </Elements>
          );
        }
        if (clientSecret !== undefined && isSuccessful) {
          return (
            <Elements options={options} stripe={stripePromise}>
              <PaymentSuccessView
                theme={theme}
                title={depositSuccessful}
                description={despositSuccessfulDescription}
              />
            </Elements>
          );
        }
        return (
          <Error
            title={errorMessage}
            onRetry={handleTryAgain}
            onCancel={onDismiss}
          />
        );
      })()}
    </>
  );
};

interface IStripeElementProps extends PaymentViewBaseProps {
  onError: (string: string) => void;
  onSuccess: () => void;
  customerId?: string;
  labels: ILabels;
  companyCode: string;
  reservationCode: string;
}

const StripeElement = ({
  onDismiss,
  theme,
  onError,
  onSuccess,
  customerId,
  labels,
  companyCode,
  reservationCode,
}: IStripeElementProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const { cancel, confirm } = labels;

  const onConfirmPressed = () => {
    if (stripe && elements) {
      setIsConfirmLoading(true);
      stripe
        ?.confirmSetup({ elements, redirect: 'if_required' })
        .then(({ setupIntent, error }) => {
          if (error) {
            switch (error?.code) {
              /*
                  stripe has 4 codes under card_declined, each has the following messages
                    generic_decline: Your card has been declined.
                    insufficient_funds: Your card has insufficient funds.
                    lost_card: Your card has been declined.
                    stolen_card: Your card has been declined.

                  you can test these by using the card numbers provided at
                  https://stripe.com/docs/testing under "Declined Payments"
                 */
              case 'card_declined':
                return onError(error.message ?? 'card_declined');
              /*
                  see above comments about testing
                  expired_card: Your card has expired
              */
              case 'expired_card':
                return onError(error.message ?? 'expired_card');
              /*
                  see above comments about testing
                  incorrect_cvc: Your card's security code is incorrect.
              */
              case 'incorrect_cvc':
                return onError(error.message ?? 'incorrect_cvc');
              /*
                  see above comments about testing
                  processing_error: An error occurred while processing your card. Try again in a little bit.
              */
              case 'processing_error':
                return onError(error.message ?? 'processing_error');
              default:
                setIsConfirmLoading(false);
                return onError(
                  'Something went wrong. Please resubmit your payment details.',
                );
            }
          }
          switch (setupIntent?.status) {
            case 'succeeded':
              handleIntentSuccess(setupIntent?.payment_method);
              break;
            case 'processing':
              // onError('Your payment is processing.');
              break;
            case 'requires_payment_method':
              onError('Your payment was not successful, please try again.');
              break;
            default:
              setIsConfirmLoading(false);
              onError(
                'Something went wrong. Please resubmit your payment details.',
              );
              break;
          }
        })
        .catch(() => {
          onError(
            'Something went wrong. Please resubmit your payment details.',
          );
          setIsConfirmLoading(false);
        });
    }
  };

  const handleIntentSuccess = (
    paymentMethod: string | null | PaymentMethod,
  ) => {
    if (customerId && paymentMethod && typeof paymentMethod === 'string') {
      dispatchPostStripeConfirm(
        paymentMethod as string,
        customerId,
        companyCode,
        reservationCode,
      )
        .then(() => {
          onSuccess();
        })
        .catch(() => {
          onError(
            'Something went wrong. Please resubmit your payment details.',
          );
        });
    } else {
      onError('Something went wrong. Please resubmit your payment details.');
    }
  };
  return (
    <View
      style={[
        LoginStyles.contentContainer,
        {
          justifyContent: 'space-between',
        },
      ]}>
      <View style={{ height: 360 }}>
        <PaymentElement id="payment-element" />
      </View>
      <View
        style={[
          AppConfig.Styles.rowContainer,
          {
            justifyContent: 'space-between',
            marginTop: 20,
          },
        ]}>
        <Button
          testID="cancel-button"
          contentStyle={{
            width: 160,
            height: AppConfig.Styles.buttonHeight,
          }}
          textColor={theme?.primaryColor}
          onPress={onDismiss}
          mode="text">
          {cancel}
        </Button>
        <Button
          disabled={isConfirmLoading}
          testID="confirm-payment-button"
          contentStyle={{
            width: 160,
            height: AppConfig.Styles.buttonHeight,
          }}
          labelStyle={{ color: 'white' }}
          buttonColor={theme?.primaryColor}
          onPress={onConfirmPressed}
          mode="contained">
          {confirm}
        </Button>
      </View>
    </View>
  );
};

export default StripeCheckoutForm;
