import { trackEvent } from '@/hooks/useAnalytics';
import useOtp from '@/store/otp/hooks';
import { useLocalization } from '@localizations';
import { useNavigation } from '@react-navigation/core';
import { ParamListBase } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import {
  ICountry,
  TCountryCode,
  getCountryCode,
  getEmojiFlag,
} from 'countries-list';
import libphonenumber from 'google-libphonenumber';
import { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, FAB, MD3LightTheme, Text } from 'react-native-paper';
import PhoneNumberPicker from './PhoneNumberPicker';
import PhoneNumberVerification from './PhoneNumberVerification';

const AddPhoneNumber = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryDialCode, setCountryDialCode] = useState<string>('+1');
  const [countryEmoji, setCountryEmoji] = useState<string>('🇺🇸');
  const [isEmpty, setIsEmpty] = useState(false);
  const [countryCode, setCountryCode] = useState<string>('US');
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { requestOtp } = useOtp();

  const { t } = useLocalization();
  const navigation = useNavigation<StackNavigationProp<ParamListBase>>();

  const onChangeCountryCode = (country: ICountry) => {
    const emoji = getEmojiFlag(
      (getCountryCode(country.name) ?? 'CA') as TCountryCode,
    );
    setCountryEmoji(emoji);
    setCountryCode((getCountryCode(country.name) ?? '') as string);
    setCountryDialCode(`+${country.phone[0]}`);
  };

  const onChangePhoneNumber = (number: string) => {
    setPhoneNumber(number.replace(/\D/g, ''));
    if (number.length === 0) {
      setIsEmpty(false);
      return;
    } else {
      setIsEmpty(true);
    }
  };

  const onSubmitPhoneNumber = async () => {
    const lib = libphonenumber.PhoneNumberUtil.getInstance();
    const parsedNumber = lib.parse(phoneNumber, countryCode);
    const isValid = lib.isValidNumberForRegion(parsedNumber, countryCode);
    if (!isValid) {
      setErrorMsg(t('invalid_phone_message'));
      setShowError(true);
      return;
    } else {
      setShowError(false);
    }

    setIsSendingCode(true);
    trackEvent({
      screen: 'AddPhoneNumber',
      event: 'SUBMITTED',
      feature: 'sign_in',
      action: 'sign_in_phone_number',
    });
    setIsLoading(true);
    const completePhoneNumber = countryDialCode + phoneNumber;

    try {
      await requestOtp(completePhoneNumber);

      navigation.replace('VerifyPhoneNumber', {
        phoneNumber: countryDialCode + phoneNumber,
      });
    } catch (e: unknown) {
      setErrorMsg(t('verification_send_otp_error'));
      setShowError(true);
      setIsSendingCode(false);
    }

    setIsLoading(false);
  };

  return (
    <PhoneNumberVerification
      title={t('add_phone_number_title')}
      subtitle={t('add_phone_number_body')}
      headerType="cancel"
      onHeaderAction={() => {
        navigation.replace('Reservations');
      }}>
      <PhoneNumberPicker
        countryDialCode={countryDialCode}
        countryEmoji={countryEmoji}
        phoneNumber={phoneNumber}
        countryCode={countryCode}
        isValidPhoneNumber={!showError}
        onChangePhoneNumber={onChangePhoneNumber}
        onChangeCountryCode={onChangeCountryCode}
      />
      {showError && (
        <View style={styles.innerContainer}>
          <Text style={styles.errorColor}>{errorMsg}</Text>
        </View>
      )}
      <View style={styles.innerContainer}>
        <Button
          mode="outlined"
          style={styles.skipButton}
          onPress={() => {
            trackEvent({
              screen: 'AddPhoneNumber',
              event: 'CLICKED',
              feature: 'sign_in',
              action: 'sign_in_phone_number_skip',
            });
            navigation.replace('Reservations');
          }}>
          <Text style={[styles.skipText]}>
            {t('add_phone_number_skip_button')}
          </Text>
        </Button>
        <FAB
          mode="flat"
          icon="arrow-right"
          style={[
            styles.nextButton,
            isSendingCode
              ? { backgroundColor: MD3LightTheme.colors.surfaceDisabled }
              : isEmpty
              ? { backgroundColor: MD3LightTheme.colors.primary }
              : {},
          ]}
          onPress={onSubmitPhoneNumber}
          color="white"
          loading={isLoading}
          customSize={48}
          disabled={!isEmpty || isSendingCode}
        />
      </View>
    </PhoneNumberVerification>
  );
};

const styles = StyleSheet.create({
  innerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 16,
  },
  skipButton: { height: 40, minWidth: 76 },
  skipText: {
    height: 28,
    minWidth: 28,
    fontSize: 12,
    color: MD3LightTheme.colors.primary,
  },
  nextButton: {
    borderRadius: 100,
  },
  errorColor: { color: '#B3261E' },
});

export default AddPhoneNumber;
