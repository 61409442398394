import { createSlice } from '@reduxjs/toolkit';
import { panelState } from './models';

export const initialState: panelState = {
  status: 'close',
};

export const slice = createSlice({
  name: 'languagePanel',
  initialState,
  reducers: {
    openLanguagePanel: state => {
      state.status = 'open';
    },
    closeLanguagePanel: state => {
      state.status = 'close';
    },
  },
  extraReducers: {},
});

export const selectLanguagePanel = (state: { languagePanel: panelState }) =>
  state.languagePanel;

export const { openLanguagePanel, closeLanguagePanel } = slice.actions;
