import { hasSecurityDeposit, trackEvent } from '@/libs';
import { logger } from '@/libs/logger';
import { PaymentPayload } from '@/types/payments';
import { SnackBar } from '@components';
import AppConfig from '@config';
import useLocalization from '@localizations';
import { useNavigation } from '@react-navigation/native';
import { ContentView, HeaderView } from '@screens/login/Common';
import { LoginStyles } from '@screens/login/Styles';
import { Deposit, DepositType, useAppStore } from '@store';
import { PaymentsIcon, PriceFrameBGIcon, SecurityDepositIcon } from '@svgs';
import { FC, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, Text } from 'react-native-paper';
import Svg from 'react-native-svg';
import PaymentActionSheet from '../home/payment/PaymentActionSheet';

interface PriceViewProps {
  deposit: Deposit | undefined;
}
// @@ PriceView view
const PriceView: FC<PriceViewProps> = ({ deposit }) => {
  const depositAmount = deposit?.amount || 0;
  const amount = depositAmount / 100;
  const { t } = useLocalization();
  return (
    <View
      testID="price-view"
      style={[
        {
          width: '100%',
          paddingBottom: AppConfig.Styles.getAdjustedSize(24),
          paddingTop: AppConfig.Styles.getAdjustedSize(16),
        },
      ]}>
      <View style={[AppConfig.Styles.container]}>
        <View style={StyleSheet.absoluteFill}>
          <PriceFrameBGIcon style={{ height: 300, width: '100%' }} />
        </View>

        <View
          style={[
            AppConfig.Styles.rowContainer,
            {
              marginTop: 20,
              marginLeft: 16,
              marginRight: 16,
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              flexDirection: 'column',
            },
          ]}>
          <Text
            style={[
              AppConfig.Styles.body1,
              AppConfig.Styles.textAlignCenter,
              AppConfig.Styles.bold,
              styles.PriceDetailLabel,
            ]}>
            {t('p_price_details')}
          </Text>
          <View style={styles.ProductItemContainer}>
            <Text style={[AppConfig.Styles.body2]}>
              {t('security_deposit_title')}
            </Text>
            <View style={{ flexDirection: 'row' }}>
              <Text style={[AppConfig.Styles.body2, { marginRight: 4 }]}>
                $ {amount}
              </Text>
              <Text
                style={[AppConfig.Styles.body2, AppConfig.Styles.lighterText]}>
                USD
              </Text>
            </View>
          </View>
          <View style={styles.TotalContainer}>
            <Text style={[AppConfig.Styles.body1]}>
              {t('p_total')} ({deposit?.currency})
            </Text>
            <Text style={[AppConfig.Styles.body1]}>$ {amount}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

interface ActionViewProps {
  deposit: Deposit | undefined;
}
// @@ ActionView view
const ActionView: FC<ActionViewProps> = ({ deposit }) => {
  const navigation = useNavigation();
  const { t } = useLocalization();
  const [isOpen, setIsOpen] = useState(false);
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);

  const [payload] = useState<PaymentPayload | undefined>({
    title: 'Security Deposit',
    amount: deposit?.amount || 0,
    currency: deposit?.currency || 'USD',
  });

  const { company } = useAppStore();
  const showSecurityDeposit = hasSecurityDeposit(company);

  const onPressApprove = () => {
    if (!showSecurityDeposit) {
      logger.error(t('security_deposit_error'));
      trackEvent('Security Deposit', 'Approve', {
        amount: deposit?.amount,
        status: deposit?.status,
        type: deposit?.type,
      });
      setIsSnackbarVisible(true);
      return;
    }
    setIsOpen(true);
  };

  const onPressCancel = () => {
    navigation.navigate('Home');
  };

  const onDismiss = () => {
    setIsOpen(false);
    navigation.navigate('Home');
  };
  return (
    <View
      style={[
        AppConfig.Styles.rowContainer,
        {
          justifyContent: 'space-between',
          marginBottom: 20,
        },
      ]}>
      <Button
        testID="cancel-button"
        contentStyle={{
          width: 160,
          height: AppConfig.Styles.buttonHeight,
        }}
        mode="text"
        onPress={onPressCancel}>
        {t('cancel')}
      </Button>
      <Button
        testID="deposit-button"
        contentStyle={{
          width: 160,
          height: AppConfig.Styles.buttonHeight,
        }}
        labelStyle={{ color: 'white' }}
        mode="contained"
        onPress={onPressApprove}>
        {t('p_make_deposit')}
      </Button>
      {showSecurityDeposit && (
        <PaymentActionSheet
          payload={payload}
          isOpen={isOpen}
          onDismiss={onDismiss}
        />
      )}
      <SnackBar
        visible={isSnackbarVisible}
        onDismiss={() => setIsSnackbarVisible(false)}
        title={t('security_deposit_error')}
      />
    </View>
  );
};

// @@ Default Component
export const SecurityDeposit = () => {
  const { t } = useLocalization();
  const { theme, company } = useAppStore();
  const securityDeposit = company?.deposits?.find(
    d => d.type === DepositType.SECURITY,
  );

  return (
    <View
      testID="security-deposit"
      style={[AppConfig.Styles.container, AppConfig.Styles.center]}>
      <View
        style={[
          AppConfig.Styles.container,
          LoginStyles.container,
          { justifyContent: 'flex-start' },
        ]}>
        {theme && (
          <HeaderView
            theme={theme}
            logo={
              <PaymentsIcon
                width="100%"
                height="100%"
                viewBox="0 0 50 50"
                fill={theme?.primaryColor}
                style={{ margin: 0, padding: 0 }}
              />
            }
          />
        )}
        <View style={[LoginStyles.contentContainer, styles.LogoContainer]}>
          <Svg
            width="140"
            height="140"
            viewBox="0 0 180 179"
            fill={theme?.primaryColor}
            style={{ margin: 0, padding: 0 }}>
            <SecurityDepositIcon />
          </Svg>
          <ContentView
            title={t('security_deposit_title')}
            description={t('security_deposit_description')}
          />
        </View>
        <PriceView deposit={securityDeposit} />
        <ActionView deposit={securityDeposit} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  PriceDetailLabel: {
    width: '100%',
    textAlign: 'left',
    borderBottomColor: '#dddddd',
    borderBottomWidth: 1,
    paddingBottom: 20,
    marginBottom: 20,
  },
  ProductItemContainer: {
    borderBottomColor: '#dddddd',
    borderBottomWidth: 1,
    paddingBottom: 20,
    marginBottom: 20,
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  TotalContainer: {
    paddingBottom: 20,
    marginBottom: 20,
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  LogoContainer: {
    justifyContent: 'flex-start',
  },
});

export default SecurityDeposit;
