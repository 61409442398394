import { OPERTO_TRPC_URL } from '@/assets/configurations/Settings';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ResponseData } from '../rtkq-utils';
import { AppFeatureData } from './types';

export const TAG = 'APP_FEATURES';

export const appFeaturesApi = createApi({
  reducerPath: 'appFeaturesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: OPERTO_TRPC_URL ?? '',
  }),
  endpoints: builder => ({
    getAppFeatures: builder.query<
      ResponseData<Record<string, AppFeatureData>>,
      void
    >({
      query: () => ({
        url: '/app-features/guest',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetAppFeaturesQuery, useLazyGetAppFeaturesQuery } =
  appFeaturesApi;
