import useLocalization from '@/assets/localizations';
import { StyleSheet, View } from 'react-native';
import { Button, Text, useTheme } from 'react-native-paper';
import AppConfig from '../../assets/configurations';
import { ErrorIcon } from '../../assets/svgs/ErrorIcon';
import { RetryIcon } from '../../assets/svgs/RetryIcon';
import { PoweredByView } from './PoweredByView';

type ErrorProps = {
  onRetry: () => void;
};

const RetryIconButton = () => {
  const { colors } = useTheme();
  return <RetryIcon width={18} height={18} fill={colors.primary} />;
};

export const Error = ({ onRetry }: ErrorProps) => {
  const { t } = useLocalization();
  const { colors } = useTheme();

  return (
    <>
      <View style={styles.container}>
        <View style={{ marginBottom: AppConfig.Styles.getAdjustedSize(16) }}>
          <ErrorIcon
            width={AppConfig.Styles.getAdjustedSize(80)}
            height={AppConfig.Styles.getAdjustedSize(80)}
            fill={colors.onSurface}
          />
        </View>
        <Text variant="headlineMedium" style={styles.margin}>
          {t('web_login_error_title')}
        </Text>
        <Text
          variant="bodyLarge"
          style={[styles.margin, { color: colors.onSurface }]}>
          {t('web_login_error_body')}
        </Text>
        <Button mode="outlined" icon={RetryIconButton} onPress={onRetry}>
          {t('web_login_error_retry_button')}
        </Button>
      </View>
      <View style={styles.footer}>
        <PoweredByView />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'transparent',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: AppConfig.Styles.getAdjustedSize(16),
  },
  margin: {
    marginBottom: AppConfig.Styles.getAdjustedSize(16),
  },
  footer: {
    alignSelf: 'center',
    paddingVertical: AppConfig.Styles.getAdjustedSize(30),
    justifyContent: 'flex-end',
  },
});
