import AppConfig from '@config';
import { FunctionComponent, ReactNode } from 'react';
import {
  ScrollView,
  StyleProp,
  StyleSheet,
  TextStyle,
  View,
} from 'react-native';
import { Caption, Title } from 'react-native-paper';
import { PoweredByView } from './PoweredByView';

interface IContentViewProps {
  title?: string;
  description?: string;
  style?: StyleProp<TextStyle>;
  children?: ReactNode;
  textAlign?: 'auto' | 'left' | 'right' | 'center' | 'justify' | undefined;
}
/**
 * ContentView - generic container view that include templates for title, desctiption, children nodes and powered by operto
 */
export const ContentView: FunctionComponent<IContentViewProps> = ({
  title,
  description,
  children,
  style,
  textAlign,
}) => (
  <View style={[AppConfig.Styles.container, AppConfig.Styles.center]}>
    <ScrollView
      style={[AppConfig.Styles.container, styles.container]}
      contentContainerStyle={style}>
      {title && (
        <Title
          style={{
            marginBottom: AppConfig.Styles.getAdjustedSize(20),
            textAlign: textAlign,
          }}>
          {title}
        </Title>
      )}
      {description && (
        <Caption
          style={[
            AppConfig.Styles.body2,
            {
              textAlign: textAlign,
              marginBottom: AppConfig.Styles.getAdjustedSize(20),
            },
          ]}>
          {description}
        </Caption>
      )}
      {children}
    </ScrollView>
    <PoweredByView />
  </View>
);

const styles = StyleSheet.create({
  container: {
    width: '100%',
    maxWidth: AppConfig.Styles.responsiveMaxWidth,
    padding: AppConfig.Styles.getAdjustedSize(20),
  },
});

export default ContentView;
