// @ts-nocheck - fix me
import { Spacer } from '@components';
import AppConfig from '@config';
import { trackEvent } from '@libs';
import useLocalization, { TranslationKey } from '@localizations';
import { dispatchUpdateCompany, processError, useAppStore } from '@store';
import { LoginContactLogo } from '@svgs';
import React, { FunctionComponent, useState } from 'react';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { Text, View } from 'react-native';
import { HelperText, TextInput, useTheme } from 'react-native-paper';
import { ButtonView, ContentView, HeaderView } from './Common';
import { LoginStyles } from './Styles';

interface IFormErrors {
  name: TranslationKey | null;
  email: TranslationKey | null;
  phone: TranslationKey | null;
  api: string | null;
}

interface IFormProps {
  formErrors: IFormErrors;
  name: string;
  setName: (name: string) => void;
  email: string;
  setEmail: (email: string) => void;
  phone: string;
  setPhone: (phone: string) => void;
  countryCode: string;
  setCountryCode: (code: string) => void;
  phoneNumberChange: (
    phoneNum: string,
    countryCodeNum: string,
    isValid: boolean,
  ) => void;
}

// @@ Form View
const FormView: FunctionComponent<IFormProps> = ({
  formErrors,
  name,
  setName,
  email,
  setEmail,
  phoneNumberChange,
}) => {
  const { t } = useLocalization();
  /**
   * FIXME: investigate how we can leverage code below to
   * forward reference but stil have "IntlTelInput" typings
   *
   * const inputRef = React.useRef<IntlTelInput>();
   */
  const inputRef = React.useRef();

  const inputDesigner = (currentRef: unknown) => {
    if (currentRef) {
      currentRef.tel.style.width = '100%';
      currentRef.tel.style.height = '50px';
      currentRef.tel.style['border-radius'] = '6.055px';
      currentRef.tel.style['border-width'] = '1px';
      currentRef.tel.style['border-color'] = 'rgba(0, 0, 0, 0.54)';
      currentRef.tel.style['font-size'] = '16px';
      currentRef.flagDropDown.childNodes[1].style.bottom = '100%';
      currentRef.flagDropDown.childNodes[1].style.height = '100px';
      currentRef.flagDropDown.childNodes[1].style.font = '-webkit-mini-control';
      currentRef.flagDropDown.childNodes[1].style['border-radius'] = '6px';
    }
  };

  const phoneNumberUpdate = (phoneRef: unknown, isValid: boolean) => {
    if (phoneRef.current) {
      phoneNumberChange(
        phoneRef.current.tel.value.replace(/[^0-9]/gi, ''),
        `+${phoneRef.current.selectedCountryData.dialCode}`,
        isValid,
      );
    }
  };

  // TODO: rethink this... normally we use useRef to things that doesnt
  // want causes state changes. perhaps we should call inputDesgner during
  // the assignment of the reference?
  React.useEffect(() => {
    inputDesigner(inputRef.current);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef.current]);

  return (
    <View
      style={[{ justifyContent: 'space-between' }, AppConfig.Styles.fullWidth]}>
      <TextInput
        style={{
          height: AppConfig.Styles.inputHeight,
        }}
        mode="outlined"
        label={t('name')}
        value={name}
        onChangeText={text => setName(text)}
      />
      <HelperText type="error" visible={formErrors.name !== null}>
        {t(formErrors.name ?? 'validation_required')}
      </HelperText>
      <Spacer size={1} />
      <TextInput
        style={{
          height: AppConfig.Styles.inputHeight,
        }}
        mode="outlined"
        label={t('web_login_email')}
        value={email}
        onChangeText={text => setEmail(text)}
      />
      <HelperText type="error" visible={formErrors.email !== null}>
        {t(formErrors.email ?? 'validation_required')}
      </HelperText>
      <Spacer size={1} />
      <View style={{ flexDirection: 'row', width: '100%' }}>
        <IntlTelInput
          style={{
            width: '100%',
          }}
          containerClassName="intl-tel-input"
          inputClassName="form-control"
          ref={inputRef}
          onPhoneNumberChange={isValid => phoneNumberUpdate(inputRef, isValid)}
          onSelectFlag={isValid => phoneNumberUpdate(inputRef, isValid)}
          onPhoneNumberBlur={isValid => phoneNumberUpdate(inputRef, isValid)}
          onPhoneNumberFocus={isValid => phoneNumberUpdate(inputRef, isValid)}
        />
      </View>
      <HelperText type="error" visible={formErrors.phone !== null}>
        {t(formErrors.phone ?? 'validation_required')}
      </HelperText>
      <HelperText type="error" visible={formErrors.api !== null}>
        {formErrors.api || t('generic_error')}
      </HelperText>
      <Text
        style={[AppConfig.Styles.caption, AppConfig.Styles.textAlignCenter]}>
        {t('information_disclaimer')}
      </Text>
    </View>
  );
};
const computePhoneDisplay = (phone: string) => {
  const display = phone.replace('+', '');
  if (display?.length <= 10) {
    return display;
  }

  return display.slice(display?.length - 10);
};

// @@ Default Component
export const Contact = () => {
  const { t } = useLocalization();
  const themeProvider = useTheme();
  const { company, reservation, theme } = useAppStore();
  const [name, setName] = useState(reservation?.guestName ?? '');
  const [email, setEmail] = useState('');
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);

  const display = computePhoneDisplay('');
  const [phone, setPhone] = useState(display ?? '');

  const code = reservation?.guestPhone?.replace(display, '');
  const [countryCode, setCountryCode] = useState(
    code && code?.length > 0 ? code : '+',
  );
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState<IFormErrors>({
    name: null,
    email: null,
    phone: null,
    api: null,
  });
  const phoneChange = (
    phoneNum: string,
    countryCodeNum: string,
    isValidPhoneNumber: boolean,
  ) => {
    setIsPhoneNumberValid(isValidPhoneNumber);
    setPhone(phoneNum);
    setCountryCode(countryCodeNum);
  };

  const submit = async () => {
    // Verification - there needs to be a better way
    const newErrors: IFormErrors = {
      name: name.length === 0 ? 'validation_required' : null,
      email: email.length === 0 ? 'validation_required' : null,
      phone: phone.length === 0 ? 'validation_required' : null,
      api: null,
    };

    // Additional email regex
    if (newErrors.email === null && !/.+@.+\..+/.test(email)) {
      newErrors.email = 'invalid_email_message';
    }

    // check if phone number is valid
    if (!isPhoneNumberValid && newErrors.phone !== 'validation_required') {
      newErrors.phone = 'invalid_phone_message';
    }

    // Set the form errors and stop submission
    if (Object.values(newErrors).some(error => error !== null)) {
      setFormErrors(newErrors);
      return false;
    }

    setLoading(true);
    try {
      dispatchUpdateCompany({
        ...company,
        reservation: {
          ...reservation,
          guestName: name,
          guestEmail: email,
          guestPhone: countryCode + phone,
          termsAgreed: true,
        },
      });

      trackEvent('Welcome', 'Profile:Submitted', {
        isPhoneFilled: name?.length > 0,
        isEmailFilled: email?.length > 0,
      });
      return true;
    } catch (error) {
      const message = processError(error);
      setFormErrors({ ...newErrors, api: message });
      return false;
    } finally {
      setLoading(false);
    }
  };

  return (
    <View style={[AppConfig.Styles.container, AppConfig.Styles.center]}>
      <View style={[AppConfig.Styles.container, LoginStyles.container]}>
        <View style={[LoginStyles.contentContainer]}>
          <ContentView
            title={t('step_three_title')}
            description={t('step_three_subtitle')}>
            <HeaderView theme={theme} />
            {theme?.welcomeImagesEnabled && (
              <LoginContactLogo
                style={{ color: themeProvider.colors.primary }}
                width={AppConfig.Styles.getAdjustedSize(230)}
              />
            )}
          </ContentView>
          <Spacer size={3} />
          <FormView
            name={name}
            setName={setName}
            email={email}
            setEmail={setEmail}
            phone={phone}
            setPhone={setPhone}
            countryCode={countryCode}
            setCountryCode={setCountryCode}
            formErrors={formErrors}
            phoneNumberChange={phoneChange}
          />
          <Spacer size={3} />
          <View style={{ width: '100%' }}>
            <ButtonView
              onSubmit={submit}
              company={company}
              loading={loading}
              currentPage="Contact"
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default Contact;
