import useLocalization from '@/assets/localizations';
import { useAppContext } from '@/store';
import { useLanguagePanel } from '@/store/languagePanel';
import { ContentView } from '@components';
import AppConfig from '@config';
import { View } from 'native-base';
import { List, ListItemProps } from 'react-native-paper';

const RightIcon = () => <List.Icon icon="menu-right" />;

const ListItem = ({ ...rest }: ListItemProps) => (
  <List.Item style={{ marginHorizontal: 6 }} right={RightIcon} {...rest} />
);

export default function Language() {
  const { languages, t } = useLocalization();
  const { openPanel } = useLanguagePanel();
  const { locale } = useAppContext();

  return (
    <View style={AppConfig.Styles.container}>
      <ContentView title={t('guest_profile_language_title')}>
        <ListItem
          title={languages.find(l => l.locale === locale)?.name ?? 'English'}
          onPress={() => {
            openPanel();
          }}
        />
      </ContentView>
    </View>
  );
}
