import { logger } from '@/libs/logger';
import { ContentView, SnackBar } from '@components';
import AppConfig from '@config';
import { trackEvent } from '@libs';
import useLocalization from '@localizations';
import { dispatchUpdateGuest, processError, useAppStore } from '@store';
import { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, Caption, Checkbox, Text } from 'react-native-paper';

const Notification = () => {
  const { t } = useLocalization();
  const { reservation } = useAppStore();
  const [enableEmail, setEnableEmail] = useState<boolean | undefined>(
    undefined,
  );
  const [enableSMS, setEnableSMS] = useState<boolean | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [snackBarOptions, setSnackBarOptions] = useState({
    title: '',
    color: AppConfig.Colors.SUCCESS,
    visible: false,
  });

  useEffect(() => {
    if (reservation) {
      if (enableEmail === undefined) {
        setEnableEmail(reservation.emailEnabled);
      }
      if (enableSMS === undefined) {
        setEnableSMS(reservation.smsEnabled);
      }
    }
  }, [reservation, enableEmail, enableSMS]);

  const onPress = async () => {
    if (loading || !reservation) {
      return;
    }

    trackEvent('Menu', 'Profile>Notification:Submitted', {
      emailChecked: enableEmail,
      smsChecked: enableSMS,
    });

    setLoading(true);
    setSnackBarOptions({
      ...snackBarOptions,
      visible: false,
    });

    try {
      await dispatchUpdateGuest({
        ...reservation,
        emailEnabled: !!enableEmail,
        smsEnabled: !!enableSMS,
        optin: true, // TODO: why we dont change state and always true?
      });

      setSnackBarOptions({
        title: t('update_profile_success'),
        color: AppConfig.Colors.SUCCESS,
        visible: true,
      });
    } catch (error) {
      const message = processError(error);
      logger.error(message);
      setSnackBarOptions({
        title: message,
        color: AppConfig.Colors.ERROR,
        visible: true,
      });
    }

    setLoading(false);
  };

  return (
    <View style={AppConfig.Styles.container}>
      <ContentView
        title={t('guest_profile_inbox_notifications')}
        description={t('notification_description')}>
        <View style={styles.check}>
          <Checkbox
            status={enableEmail ? 'checked' : 'unchecked'}
            onPress={() => setEnableEmail(!enableEmail)}
          />
          <Text>{t('web_login_email')}</Text>
        </View>

        <View style={styles.check}>
          <Checkbox
            status={enableSMS ? 'checked' : 'unchecked'}
            onPress={() => setEnableSMS(!enableSMS)}
          />
          <Text>{t('phone_number')}</Text>
        </View>

        <Caption>{t('notification_caption')}</Caption>

        <View style={styles.buttonContainer}>
          <Button
            contentStyle={{
              width: '100%',
              height: AppConfig.Styles.buttonHeight,
            }}
            loading={loading}
            mode="contained"
            onPress={onPress}>
            {loading ? '' : t('update')}
          </Button>
        </View>
      </ContentView>
      <SnackBar
        {...snackBarOptions}
        onDismiss={() =>
          setSnackBarOptions({ ...snackBarOptions, visible: false })
        }
      />
    </View>
  );
};

export default Notification;

const styles = StyleSheet.create({
  check: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonContainer: {
    marginTop: AppConfig.Styles.getAdjustedSize(24),
  },
});
