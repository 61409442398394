import useLocalization from '@/assets/localizations';
import { Button, Dialog, Portal, Text } from 'react-native-paper';

interface ConfirmDialogProps {
  open: boolean;
  loading?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onCancel: () => void;
  title: string;
  content?: string;
  submitButtonText?: string;
  cancelButtonText?: string;
  dismissable?: boolean;
  dialogStyle?: object;
  cancelButtonStyle?: object;
  submitButtonStyle?: object;
  submitTextStyle?: object;
}
const ConfirmDialog = ({
  open,
  loading = false,
  onClose,
  onConfirm,
  onCancel,
  title,
  content,
  submitButtonText,
  cancelButtonText,
  dismissable = false,
  dialogStyle,
  cancelButtonStyle,
  submitButtonStyle,
  submitTextStyle,
}: ConfirmDialogProps) => {
  const { t } = useLocalization();

  return (
    <Portal>
      <Dialog
        visible={open}
        onDismiss={onCancel}
        dismissable={dismissable}
        style={{
          backgroundColor: 'white',
          borderRadius: 28,
          alignSelf: 'center',
          ...dialogStyle,
        }}>
        <Dialog.Title>
          <Text variant="headlineSmall">{title}</Text>
        </Dialog.Title>
        <Dialog.Content>
          {content && <Text variant="bodyMedium">{content}</Text>}
        </Dialog.Content>
        <Dialog.Actions>
          <Button onPress={onClose} style={{ ...cancelButtonStyle }}>
            <Text variant="labelLarge">
              {cancelButtonText ? cancelButtonText : t('cancel')}
            </Text>
          </Button>
          <Button
            loading={loading}
            onPress={onConfirm}
            style={{
              backgroundColor: '#000000',
              height: '40px',
              justifyContent: 'center',
              ...submitButtonStyle,
            }}>
            <Text style={submitTextStyle} variant="labelLarge">
              {submitButtonText ? submitButtonText : t('submit')}
            </Text>
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
};

export default ConfirmDialog;
