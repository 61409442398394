import { Reservation } from '@store';

export const isVerificationFlowEnabled = (reservation: Reservation) =>
  reservation?.verification_details?.companyChosenWorkflow !== 'none' &&
  reservation?.verification_details?.isPropertyActive;

export const isVerificationFinished = (reservation: Reservation) =>
  reservation?.verification_details?.status !== 'not_started';

export const isVerificationFlowConfirmed = (reservation: Reservation) =>
  ['confirmed', 'manually_confirmed', 'confirmed_by_flow'].includes(
    reservation?.verification_details?.status,
  );

export const isVerificationFlowPending = (reservation: Reservation) =>
  reservation?.verification_details?.status === 'pending';

export const isVerificationFlowFailed = (reservation: Reservation) =>
  reservation?.verification_details?.status === 'flagged' ||
  reservation?.verification_details?.status === 'manually_flagged';

export const isVerificationFlowEnabledAndConfirmed = (
  reservation: Reservation,
) =>
  isVerificationFlowEnabled(reservation) &&
  isVerificationFlowConfirmed(reservation);

export const isIncidentPaymentEnabled = (reservation: Reservation) =>
  !!reservation?.incident?.paymentEnabled;

export const isIncidentPaymentConfirmed = (reservation: Reservation) =>
  !!reservation?.incident?.paymentConfirmed;

export const isIncidentPaymentEnabledAndConfirmed = (
  reservation: Reservation,
) =>
  isIncidentPaymentEnabled(reservation) &&
  isIncidentPaymentConfirmed(reservation);
