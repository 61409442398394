import { RTKQapi } from '../rtkq-api';
import { ResponseData } from '../rtkq-utils';
import type { UpdateTermsConditionParams } from './types';

export const TAG = 'GUEST_TERMS';

const apiWithTag = RTKQapi.enhanceEndpoints({
  addTagTypes: [TAG],
});

export const guestTermsApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    updateGuestTerms: builder.mutation<
      ResponseData<void>,
      UpdateTermsConditionParams
    >({
      query: ({ reservationCode, ...body }) => ({
        url: `/v2/guest/reservation/${reservationCode}/terms-agreement`,
        method: 'PATCH',
        body,
      }),
    }),
  }),
});

export const { useUpdateGuestTermsMutation } = guestTermsApi;
