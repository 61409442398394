import { useAppStore } from '@/store';
import AppConfig from '@config';
import useLocalization from '@localizations';
import { useNavigation } from '@react-navigation/native';
import { LoginWelcomeLogo } from '@svgs';
import { StyleSheet, View } from 'react-native';
import { Button, Text, useTheme } from 'react-native-paper';
import { Spacer, ThemedLogoHeader } from '../components';
import { QRCodeIcon } from './QRCodeIcon';

export const CheckInRequested = () => {
  const { t } = useLocalization();
  const navigation = useNavigation();
  const { theme } = useAppStore();
  const { colors } = useTheme();

  return (
    <View style={[AppConfig.Styles.container, AppConfig.Styles.center]}>
      <View style={styles.container}>
        <Spacer size={4} />
        <QRCodeIcon />
        <Spacer size={2} />
        <ThemedLogoHeader theme={theme}>
          <LoginWelcomeLogo
            style={{ color: colors.primary }}
            width={AppConfig.Styles.getAdjustedSize(220)}
          />
        </ThemedLogoHeader>
        <Spacer size={4} />
        <Text
          style={[
            AppConfig.Styles.textAlignCenter,
            AppConfig.Styles.headline6,
          ]}>
          {t('successfully_checked_in')}
        </Text>
        <Spacer size={2} />
        <Text
          style={[
            AppConfig.Styles.textAlignCenter,
            AppConfig.Styles.subtitle2,
          ]}>
          {t('room_assignment')}
        </Text>
        <Spacer size={4} />
        <Button
          mode="contained"
          onPress={() => navigation.navigate('Main')}
          contentStyle={{
            height: AppConfig.Styles.buttonHeight,
          }}>
          {t('return_to_guest_portal')}
        </Button>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    alignSelf: 'center',
    justifyContent: 'center',
    maxWidth: AppConfig.Styles.responsiveMaxWidth,
    padding: AppConfig.Styles.getAdjustedSize(16),
  },
});
