import { RTKQapi } from '../rtkq-api';
import { ResponseData } from '../rtkq-utils';
import { parseReservation, parseReservations } from './parsers';
import { ReservationJSON } from './types';

type QueryParametersTypes = {
  reservationCode?: string;
  companyCode?: string;
};

// Encode the query params: fix special characters in the query params
const encodeGetParams = (p: Record<string, string>) =>
  Object.entries(p)
    .map(kv => kv.map(encodeURIComponent).join('='))
    .join('&');

const GUEST_RESERVATION_TAG = 'Guest_Reservations';

const enhancedApi = RTKQapi.enhanceEndpoints({
  addTagTypes: [GUEST_RESERVATION_TAG],
});

export const ReservationApi = enhancedApi.injectEndpoints({
  endpoints: builder => ({
    getReservationsByGuestId: builder.query({
      query: (guestId: string) => ({
        url: `portal/guest/${guestId}/reservations`,
      }),
      transformResponse: ({ data }: ResponseData<ReservationJSON[]>) => ({
        reservations: parseReservations(data),
      }),
    }),
    createReservationClaim: builder.mutation({
      query: (args: { reservationCode: string; guestId: string }) => ({
        url: 'portal/guest/reservation/claim',
        method: 'POST',
        body: args,
      }),
    }),
    getReservation: builder.query({
      query: (args: QueryParametersTypes) => {
        const queryParams = encodeGetParams(args as Record<string, string>);

        return {
          url: `portal/guest/reservation?${queryParams}`,
        };
      },
      transformResponse: ({ data }: ResponseData<ReservationJSON>) => ({
        reservation: parseReservation(data),
      }),
    }),
    deleteReservationClaims: builder.mutation({
      query: (guestId: string) => ({
        url: 'portal/guest/reservation/profile',
        method: 'DELETE',
        body: { guestId },
      }),
    }),
    acceptWelcomeFlow: builder.mutation({
      query: ({ reservation_id }) => ({
        url: 'portal/guest/v2/reservation',
        method: 'PATCH',
        body: {
          welcome_accepted: true,
          reservation_id,
        },
      }),
    }),
  }),
});

export const {
  useGetReservationsByGuestIdQuery,
  useCreateReservationClaimMutation,
  useGetReservationQuery,
  useDeleteReservationClaimsMutation,
  useAcceptWelcomeFlowMutation,
} = ReservationApi;
