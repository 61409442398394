import AppConfig from '@config';
import { DateFormat } from '@libs';
import useLocalization from '@localizations';
import { useAppContext } from '@store';
import { FunctionComponent, useState } from 'react';
import { View } from 'react-native';
import { TextInput } from 'react-native-paper';
import { DatePickerModal } from 'react-native-paper-dates';
import {
  CalendarDates,
  MultiChange,
} from 'react-native-paper-dates/lib/typescript/Date/Calendar';

type CheckInOutProps = {
  checkIn?: Date;
  checkOut?: Date;
  dateFormat?: string;
  disabled?: boolean;
  setDates: (dates: CalendarDates) => void;
};

export const CheckInOut: FunctionComponent<CheckInOutProps> = ({
  checkIn,
  checkOut,
  dateFormat,
  disabled,
  setDates,
}) => {
  const { locale } = useAppContext();
  const { t } = useLocalization();
  const formattingOptions = {
    format: dateFormat ?? 'PP',
    locale,
  };
  const [open, setOpen] = useState(false);
  const [formattedDate, setFormattedDate] = useState(
    checkIn ? DateFormat(checkIn, formattingOptions) : '',
  );

  const pickDates: MultiChange = (params: {
    dates: CalendarDates;
    datePressed: Date;
    change: 'added' | 'removed';
  }) => {
    if (params.dates?.length && params.dates?.length > 1) {
      setOpen(false);
      setDates(params.dates);

      setFormattedDate(
        `${DateFormat(params.dates[0], formattingOptions)} - ${DateFormat(
          params.dates[1],
          formattingOptions,
        )}`,
      );
    }
  };

  return (
    <View>
      <TextInput
        style={{
          height: AppConfig.Styles.inputHeight,
        }}
        autoComplete="off"
        mode="outlined"
        label={`${t('check_in')} + ${t('check_out')}`}
        value={formattedDate}
        disabled={disabled}
        onFocus={() => {
          setOpen(true);
        }}
      />

      <DatePickerModal
        locale={locale}
        mode="multiple"
        visible={open}
        dates={checkIn && checkOut ? [checkIn, checkOut] : null}
        onDismiss={() => {
          setOpen(false);
        }}
        date={checkIn || new Date()}
        onConfirm={() => {
          setOpen(false);
        }}
        saveLabel=""
        onChange={pickDates}
      />
    </View>
  );
};

export default CheckInOut;
