// src/services/cognitoApi.ts
import {
  GUEST_AUTH_COGNITO_DOMAIN,
  GUEST_AUTH_USER_POOL_WEB_CLIENT_ID,
  GUEST_PORTAL_URL,
} from '@/assets/configurations/Settings';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const cognitoApi = createApi({
  reducerPath: 'cognitoApi',
  baseQuery: fetchBaseQuery({
    baseUrl: GUEST_AUTH_COGNITO_DOMAIN,
  }),
  endpoints: builder => ({
    getToken: builder.mutation({
      query: authCode => ({
        url: 'oauth2/token', // Adjust as needed
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          grant_type: 'authorization_code',
          client_id: GUEST_AUTH_USER_POOL_WEB_CLIENT_ID ?? '',
          code: authCode,
          redirect_uri: `${GUEST_PORTAL_URL}/auth/`,
        }),
      }),
    }),
    getUserInfo: builder.query({
      query: accessToken => ({
        url: 'oauth2/userInfo',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
  }),
});

export const { useGetTokenMutation, useGetUserInfoQuery } = cognitoApi;
