import { useQRCodeMessages } from '@/hooks/useQRCodeMessages';
import { shouldConfirmRoomAssignment } from '@/libs';
import { logger } from '@/libs/logger';
import { Company, Reservation, processError, useAppStore } from '@/store';
import { ActionSheet, Error, Loading } from '@components';
import AppConfig from '@config';
import { getAccessCode } from '@libs';
import useLocalization from '@localizations';
import { RoomIcon } from '@svgs';
import { StyleSheet, View } from 'react-native';
import { Button, Text } from 'react-native-paper';

const CheckInConfirmationContent = ({
  onPress,
  company,
  reservation,
}: {
  onPress: () => void;
  company?: Company;
  reservation?: Reservation;
}) => {
  const { t } = useLocalization();

  return (
    <>
      <RoomIcon
        style={{ alignSelf: 'center' }}
        width={AppConfig.Styles.getAdjustedSize(180)}
        height={AppConfig.Styles.getAdjustedSize(180)}
      />
      <Text
        style={[AppConfig.Styles.textAlignCenter, AppConfig.Styles.headline6]}>
        {t('room_assigned_title')}
      </Text>
      <Text
        style={[
          AppConfig.Styles.textAlignCenter,
          AppConfig.Styles.headline6,
          styles.property,
        ]}>
        {reservation?.property.name}
      </Text>

      {company?.accessCodeControlEnabled && (
        <View style={[AppConfig.Styles.rowContainer, AppConfig.Styles.center]}>
          <Text style={AppConfig.Styles.caption}>{t('door_access_code')}</Text>
          <View style={styles.divider} />
          <Text style={[AppConfig.Styles.headline4, AppConfig.Styles.bold]}>
            {getAccessCode(company, reservation)}
          </Text>
        </View>
      )}

      <Button
        mode="contained"
        onPress={onPress}
        style={styles.button}
        contentStyle={{
          height: AppConfig.Styles.buttonHeight,
        }}>
        {t('confirm')}
      </Button>
    </>
  );
};

export const CheckInConfirmationSheet = () => {
  const { reservation, company } = useAppStore();
  const { patchCheckinConfirmation, clearStatus, isLoading, isError } =
    useQRCodeMessages();
  const isOpen = shouldConfirmRoomAssignment(reservation);

  const onConfirmPress = async () => {
    try {
      await patchCheckinConfirmation(company?.reservation as string);
    } catch (error) {
      const message = processError(error);
      logger.error(message);
    }
  };

  const renderContent = () => {
    if (isError) {
      return <Error onCancel={clearStatus} onRetry={onConfirmPress} />;
    }

    if (isLoading) {
      return <Loading />;
    }

    if (reservation) {
      return (
        <CheckInConfirmationContent
          company={company}
          reservation={reservation}
          onPress={onConfirmPress}
        />
      );
    }

    return <Loading />;
  };

  return (
    <ActionSheet isOpen={isOpen} hideDragIndicator>
      <View style={styles.container}>{renderContent()}</View>
    </ActionSheet>
  );
};

const styles = StyleSheet.create({
  container: {
    height: AppConfig.Styles.getAdjustedSize(420),
    width: '100%',
    alignSelf: 'center',
    justifyContent: 'center',
    maxWidth: AppConfig.Styles.responsiveMaxWidth,
    padding: AppConfig.Styles.getAdjustedSize(16),
  },
  property: {
    marginTop: AppConfig.Styles.getAdjustedSize(40),
    marginBottom: AppConfig.Styles.getAdjustedSize(21),
  },
  button: {
    width: '90%',
    maxWidth: AppConfig.Styles.responsiveMaxWidth - 48,
    marginHorizontal: AppConfig.Styles.getAdjustedSize(24),
    marginTop: AppConfig.Styles.getAdjustedSize(50),
    marginBottom: AppConfig.Styles.getAdjustedSize(36),
  },
  divider: {
    width: StyleSheet.hairlineWidth,
    height: AppConfig.Styles.getAdjustedSize(26),
    backgroundColor: 'lightgrey',
    marginHorizontal: AppConfig.Styles.getAdjustedSize(8),
  },
});
