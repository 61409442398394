import { SVGProps } from 'react';
import Svg, { G, Mask, Path } from 'react-native-svg';

export const SignatureIcon = ({
  fill = 'currentColor',
  width = 80,
  height = 80,
}: SVGProps<SVGSVGElement>) => (
  <Svg viewBox="0 0 25 24" width={width} height={height}>
    <G id="signature">
      <Mask
        id="mask0_10727_1859"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="25"
        height="24">
        <rect id="Bounding box" x="0.5" width="24" height="24" fill="#D9D9D9" />
      </Mask>
      <G mask="url(#mask0_10727_1859)">
        <Path
          id="signature_2"
          d="M14.575 11.725C15.7917 10.825 16.7417 9.8375 17.425 8.7625C18.1083 7.6875 18.45 6.61667 18.45 5.55C18.45 5.01667 18.3625 4.625 18.1875 4.375C18.0125 4.125 17.775 4 17.475 4C16.6917 4 16 4.6625 15.4 5.9875C14.8 7.3125 14.5 8.80833 14.5 10.475C14.5 10.7083 14.5042 10.9292 14.5125 11.1375C14.5208 11.3458 14.5417 11.5417 14.575 11.725ZM3.5 21V19H5.5V21H3.5ZM7.5 21V19H9.5V21H7.5ZM11.5 21V19H13.5V21H11.5ZM15.5 21V19H17.5V21H15.5ZM19.5 21V19H21.5V21H19.5ZM3.9 17L2.5 15.6L4.1 14L2.5 12.4L3.9 11L5.5 12.6L7.1 11L8.5 12.4L6.9 14L8.5 15.6L7.1 17L5.5 15.4L3.9 17ZM15.95 16C15.45 16 14.9917 15.9042 14.575 15.7125C14.1583 15.5208 13.8 15.2083 13.5 14.775C13.0833 15.0083 12.6542 15.2167 12.2125 15.4C11.7708 15.5833 11.3167 15.7667 10.85 15.95L10.15 14.075C10.6167 13.9083 11.0625 13.7292 11.4875 13.5375C11.9125 13.3458 12.325 13.1417 12.725 12.925C12.6417 12.5583 12.5792 12.1583 12.5375 11.725C12.4958 11.2917 12.475 10.825 12.475 10.325C12.475 7.925 12.95 5.9375 13.9 4.3625C14.85 2.7875 16.0417 2 17.475 2C18.3417 2 19.05 2.32083 19.6 2.9625C20.15 3.60417 20.425 4.5 20.425 5.65C20.425 7.08333 19.9708 8.5 19.0625 9.9C18.1542 11.3 16.8917 12.5583 15.275 13.675C15.3917 13.7917 15.5125 13.8792 15.6375 13.9375C15.7625 13.9958 15.8917 14.025 16.025 14.025C16.4583 14.025 16.9625 13.75 17.5375 13.2C18.1125 12.65 18.6333 11.925 19.1 11.025L20.925 11.875C20.8083 12.1583 20.7167 12.5 20.65 12.9C20.5833 13.3 20.5917 13.65 20.675 13.95C20.8417 13.8667 21.0375 13.725 21.2625 13.525C21.4875 13.325 21.7167 13.075 21.95 12.775L23.525 14C23.0917 14.6 22.5917 15.0833 22.025 15.45C21.4583 15.8167 20.9333 16 20.45 16C20.1 16 19.7875 15.8958 19.5125 15.6875C19.2375 15.4792 19.0083 15.1583 18.825 14.725C18.3583 15.1417 17.8833 15.4583 17.4 15.675C16.9167 15.8917 16.4333 16 15.95 16Z"
          fill={fill}
        />
      </G>
    </G>
  </Svg>
);
