import useLocalization, { SupportedLocale } from '@/assets/localizations';
import { useAppContext } from '@/store';
import {
  useGetGuestUserQuery,
  usePatchGuestUserByIdMutation,
} from '@/store/services/userApi';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { StyleSheet, View } from 'react-native';

import { RadioButton } from 'react-native-paper';

export default function LanguageSelectorContent({
  onClose,
}: {
  onClose: () => void;
}) {
  const { languages } = useLocalization();
  const { setLocale, locale } = useAppContext();
  const [patchGuestUserById] = usePatchGuestUserByIdMutation();
  const { data: guest } = useGetGuestUserQuery();

  const handleLanguageChange = async (selectedLanguage: string) => {
    await AsyncStorage.setItem('locale', selectedLanguage);

    if (guest?.id) {
      patchGuestUserById({
        ...guest,
        locale: selectedLanguage,
      });
    } else {
      setLocale(selectedLanguage as SupportedLocale);
    }

    onClose();
  };

  return (
    <View style={styles.bodyContainerStyle}>
      <RadioButton.Group onValueChange={handleLanguageChange} value={locale}>
        {languages.map((language, index) => (
          <RadioButton.Item
            key={index}
            style={styles.optionStyle}
            label={language.name}
            value={language.locale}
          />
        ))}
      </RadioButton.Group>
    </View>
  );
}

const styles = StyleSheet.create({
  bodyContainerStyle: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0',
    width: '100%',
  },
  optionStyle: {
    width: '100%',
    paddingTop: '8px',
    paddingRight: '24px',
    paddingBottom: '8px',
    paddingLeft: '16px',
    height: '56px',
  },
});
