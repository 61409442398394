import LoginStyles from '@/screens/login/Styles';
import { Theme } from '@/store';
import AppConfig from '@config';

import { SuccessIcon } from '@svgs';
import { View } from 'react-native';
import { Text } from 'react-native-paper';

interface PaymentSuccessViewProps {
  theme?: Theme;
  title: string;
  description: string;
}

const PaymentErrorView = ({
  theme,
  title,
  description,
}: PaymentSuccessViewProps) => {
  return (
    <View style={{ justifyContent: 'space-between', height: '100%' }}>
      <View
        style={[
          LoginStyles.contentContainer,
          {
            justifyContent: 'center',
            flexDirection: 'column',
          },
        ]}>
        <SuccessIcon
          width="180"
          viewBox="0 0 180 179"
          fill={theme?.primaryColor}
          style={{ margin: 0, padding: 0 }}
        />
        <View style={{ justifyContent: 'center' }}>
          <Text
            style={[
              AppConfig.Styles.headline6,
              { marginTop: 28, textAlign: 'center' },
            ]}>
            {title}
          </Text>
          <Text
            style={
              (AppConfig.Styles.subtitle2,
              { textAlign: 'center', marginTop: 8 })
            }>
            {description}
          </Text>
        </View>
      </View>
      <View
        style={[
          AppConfig.Styles.rowContainer,
          {
            justifyContent: 'space-between',
            marginTop: 20,
          },
        ]}
      />
    </View>
  );
};

export default PaymentErrorView;
