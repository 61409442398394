import React from 'react';
import { View } from 'react-native';
import SignatureCanvas from 'react-signature-canvas';

type SignatureCanvasProps = {
  canvasRef: React.RefObject<SignatureCanvas>;
  onEnd: () => void;
};

export const SignatureDrawPad = ({
  canvasRef,
  onEnd,
}: SignatureCanvasProps) => {
  return (
    <View
      style={{
        backgroundColor: '#FAF8FF',
        borderRadius: 28,
        borderWidth: 1,
        borderColor: '#737688',
        width: '90%',
        maxWidth: 1000,
        height: 176,
        overflow: 'hidden',
      }}>
      <SignatureCanvas
        ref={canvasRef}
        onEnd={onEnd}
        canvasProps={{
          className: 'sigCanvas',
          width: 1000,
          height: 176,
          style: {
            width: 1000,
            height: 176,
          },
        }}
      />
    </View>
  );
};
