/* eslint-disable max-lines */
import { getValidColor } from '@/libs/ColorHelper';
import getUnixTime from 'date-fns/getUnixTime';
import parse from 'date-fns/parse';
import { NIL as NIL_UUID, v5 as uuidv5 } from 'uuid';
import {
  Amenity,
  Challenge,
  ChallengeJson,
  ChallengeNames,
  ChatToken,
  Company,
  ContactChallenge,
  Deposit,
  Host,
  HouseManual,
  LoginChallenge,
  Member,
  NearMe,
  PaymentProvider,
  PropertyType,
  Reservation,
  StripeProvider,
  TermsChallenge,
  TermsChallengeJson,
  Theme,
  VerificationChallenge,
  WelcomeChallenge,
} from './models';

interface IMemberJson extends Member {
  member_id: number;
  is_member: boolean;
}
export const parseMember = (member: IMemberJson): Member => ({
  id:
    (member.member_id > 0 && member.member_id).toString() ||
    uuidv5(member.email, NIL_UUID), // locally generate id for our model since no valid in json response,
  name: member.name,
  email: member.email,
  phone: member.phone,
  member: member.is_member,
});

interface IHostJson {
  host_data: {
    welcome_avatar: string;
    welcome_message: string;
    welcome_message_title: string;
    welcome_title: string;
    goodbye_message: string;
    goodbye_message_title: string;
    hosts: IMemberJson[];
  };
  host_enabled: boolean;
}
export const parseHost = ({
  companyId,
  data,
}: {
  companyId: string;
  data: IHostJson;
}): Host => ({
  id: uuidv5(companyId, NIL_UUID), // locally generate id for our host model since nothing in json response
  enabled: data?.host_enabled,
  welcomeAvatar: data?.host_data?.welcome_avatar,
  welcomeTitle: data?.host_data?.welcome_message_title,
  welcomeMessage: data?.host_data?.welcome_message,
  goodbyeTitle: data?.host_data?.goodbye_message_title,
  goodbyeMessage: data?.host_data?.goodbye_message,
  members: data?.host_data?.hosts?.map(member => parseMember(member)),
});

interface IThemeJson {
  theme_enabled: boolean;
  welcome_images_enabled: boolean;
  theme: {
    primary_color: string;
    accent_color: string;
    dashboard_logo: string;
    dashboard_bg_image: string;
    welcome_logo: string;
    welcome_step1_image: string;
    welcome_step2_image: string;
    welcome_step3_image: string;
    welcome_step4_image: string;
  };
}
export const parseTheme = ({
  companyId,
  data,
}: {
  companyId: string;
  data: IThemeJson;
}): Theme => ({
  id: uuidv5(companyId, NIL_UUID), // locally generate id for our theme model since nothing in json response,
  enabled: data?.theme_enabled,
  primaryColor: getValidColor(data?.theme?.primary_color),
  accentColor: getValidColor(data?.theme?.accent_color),
  dashboardLogoUrl: data?.theme?.dashboard_logo,
  dashboardBackgroundUrl: data?.theme?.dashboard_bg_image,
  welcomeLogoUrl: data?.theme?.welcome_logo,
  welcomeStep1ImageUrl: data?.theme?.welcome_step1_image,
  welcomeStep2ImageUrl: data?.theme?.welcome_step2_image,
  welcomeStep3ImageUrl: data?.theme?.welcome_step3_image,
  welcomeStep4ImageUrl: data?.theme?.welcome_step4_image,
  welcomeImagesEnabled: data?.welcome_images_enabled,
});

interface IReservationJson {
  reservation: {
    id: number;
    guest_name: string;
    guest_email: string;
    guest_phone: string;
    email_enabled: boolean;
    sms_enabled: boolean;
    optin: boolean;
    guest_locale: string;
    access_code: string;
    gp_room_assignment_confirmed: boolean;
    checkin_requested_at: string;
    check_in: string;
    check_out: string;
    time_to_arrival: string;
    time_to_departure: string;
    is_terms_agreed: boolean;
    checkout_access_code: string;
    verification_details: {
      company_verification_provider: 'superhog' | 'none';
      property_verification_enabled: boolean;
      verification_provider_id: number;
      integration_provider_id: number;
      status: string;
      provider_result: unknown;
      verification_provider: 'superhog';
    };
    property: {
      id: string;
      name: string;
      address: string;
      room_status: string;
      type: PropertyType;
      timezone: string;
      longitude: string;
      latitude: string;
    };
    smw: {
      deeplink_url: string;
      booking_code: string;
    };
  };
  mobile_key_button_enabled: boolean;
  entrance_instructions: {
    enabled: boolean;
    title: string;
    details: string;
    button_label: string;
  };
  incident_data: {
    message: string;
    version: string;
  };
  incident_payment: {
    enabled: boolean;
    confirmed: boolean;
  };
  reservation_match: boolean;
  welcome_accepted: boolean;
}

export const parseReservation = ({
  reservationId,
  data,
}: {
  reservationId: string;
  data: IReservationJson;
}): Reservation | string => {
  if (data?.reservation === undefined) {
    return reservationId;
  }

  return {
    id: reservationId,
    reservationNumber: data?.reservation?.id,
    guestName: data?.reservation?.guest_name?.trim(),
    guestEmail: data?.reservation?.guest_email,
    guestPhone: data?.reservation?.guest_phone,
    emailEnabled: data?.reservation.email_enabled,
    smsEnabled: data?.reservation.sms_enabled,
    optin: data?.reservation.optin,
    guestLocale: data?.reservation?.guest_locale,
    accessCode: data?.reservation?.access_code,
    mobile_key_button_enabled: data?.mobile_key_button_enabled,
    roomAssignmentConfirmed:
      data?.reservation.gp_room_assignment_confirmed ?? false,
    checkinWithoutParsing: data?.reservation?.check_in,
    checkinRequestedAt: getUnixTime(
      parse(
        data?.reservation?.checkin_requested_at,
        'dd MMM yyyy hh:mm a',
        new Date(),
      ),
    ),
    checkin: getUnixTime(
      parse(data?.reservation?.check_in, 'dd MMM yyyy hh:mm a', new Date()),
    ),
    checkout: getUnixTime(
      parse(data?.reservation?.check_out, 'dd MMM yyyy hh:mm a', new Date()),
    ),
    checkoutAccessCode: data?.reservation.checkout_access_code,
    incident: {
      paymentConfirmed: data?.incident_payment?.confirmed,
      paymentEnabled: data?.incident_payment?.enabled,
      message: data?.incident_data?.message,
      version: data?.incident_data?.version,
    },
    timeToArrival: data?.reservation?.time_to_arrival,
    timeToDeparture: data?.reservation?.time_to_departure,
    termsAgreed: data?.reservation?.is_terms_agreed,
    matched: data?.reservation_match,
    welcomeAccepted: data?.welcome_accepted,
    property: {
      id: data?.reservation?.property?.id,
      name: data?.reservation?.property?.name,
      address: data?.reservation?.property?.address,
      roomStatus: data?.reservation?.property?.room_status,
      timezone: data?.reservation?.property?.timezone,
      longitude: data?.reservation?.property?.longitude,
      latitude: data?.reservation?.property?.latitude,
      type: data?.reservation?.property?.type,
    },
    verification_details: {
      companyChosenWorkflow:
        data?.reservation?.verification_details?.company_verification_provider,
      isPropertyActive:
        data?.reservation?.verification_details?.property_verification_enabled,
      verificationProviderId:
        data?.reservation?.verification_details?.verification_provider_id,
      status: data?.reservation?.verification_details?.status,
      providerResult: data?.reservation?.verification_details?.provider_result,
      usedProvider:
        data?.reservation?.verification_details?.verification_provider,
    },
    smw: data?.reservation?.smw
      ? {
          deeplink_url: data?.reservation?.smw?.deeplink_url,
          booking_code: data?.reservation?.smw?.booking_code,
        }
      : undefined,
    entranceInstructions: {
      title: data?.entrance_instructions?.title,
      details: data?.entrance_instructions?.details,
      enabled: data?.entrance_instructions?.enabled,
      buttonLabel: data?.entrance_instructions?.button_label,
    },
  };
};

export const parsePaymentProviders = (providers: PaymentProvider[]) =>
  providers?.map(p => {
    switch (p.provider) {
      case 'stripe':
      default:
        return <StripeProvider>p;
    }
  });

interface IAmenititesJson {
  amenities_data: {
    [key: string]: {
      title: string;
      subtitle: string;
      url: string;
      image_url: string;
      description: string;
      address: string;
      latitude: string;
      longitude: string;
      contact: {
        email: string;
        phone: string;
      };
    }[];
  };
}
export const parseAmenities = ({
  amenities_data,
}: IAmenititesJson): Amenity[] => {
  let places: Amenity[] = [];
  if (amenities_data === undefined) {
    return places;
  }

  // lets flatten out(one less nested values) the dictionary and categorize by type. we can use filters later to display
  Object.keys(amenities_data).forEach(key => {
    // combine places in to one array
    const amenities = amenities_data[key];
    places = [
      ...places,
      ...amenities.map(place => ({
        id: uuidv5(
          `${place.title ?? place.subtitle}:${place.url ?? place.image_url}`,
          NIL_UUID,
        ), // locally generate id for our place model since nothing in json response,
        type: key,
        title: place.title,
        subtitle: place.subtitle,
        description: place.description,
        address: place.address,
        url: place.url,
        imageUrl: place.image_url,
        latitude: place.latitude,
        longitude: place.longitude,
        email: place.contact.email,
        phone: place.contact.phone,
      })),
    ];
  });

  return places;
};

type NearMeJson = {
  name: string;
  rating: number;
  open: boolean;
  photo_url: string;
  address: string;
  place_id: string;
  detail_url: string;
  dinstance_url: string;
  origin_latitude: number;
  origin_longitude: number;
  destination_latitude: number;
  destination_longitude: number;
  distance: {
    origin: string;
    destination: string;
    length: string;
    duration: string;
  };
  detail: {
    hoursOfOperation: string[];
    phone: string;
    url: string;
  };
};
export const parseNearMe = async (
  nearme_data: NearMeJson[],
  resource: 'shopping' | 'dining' | 'activities',
) => {
  if (nearme_data === undefined) {
    return [];
  }

  // TODO: should do this lazy loading. possibly under PlaceCard component mounting
  const results = await Promise.all(
    nearme_data.map(async place => ({
      id: place.place_id,
      open: place?.open,
      type: resource,
      title: place.name,
      rating: place.rating,
      address: place.address,
      imageUrl: place.photo_url,
      detailUrl: place.detail_url,
      latitude: place.destination_latitude,
      longitude: place.destination_longitude,
      url: place.detail?.url,
      phone: place.detail?.phone,
      hoursOfOperation: place.detail?.hoursOfOperation,
      distance: {
        url: place.dinstance_url,
        origin: place.distance?.origin,
        destination: place.distance?.destination,
        length: place.distance?.length,
        duration: place.distance?.duration,
      },
    })) as unknown as NearMe[],
  );

  return results;
};

interface IHouseManualJson {
  layout: string;
  instructions: string;
  img_url: string;
  video_url: string;
}
const parseHouseManual = (manual: IHouseManualJson | string): HouseManual => {
  if (typeof manual === 'string') {
    return {
      layout: '1',
      instructions: manual as string,
    };
  }

  const manualJson = manual as IHouseManualJson;
  return {
    layout:
      (manualJson?.layout && manualJson?.layout?.replace('layout_', '')) ?? '1',
    instructions: manualJson?.instructions,
    imageUrl: manualJson?.img_url,
    videoUrl: manualJson?.video_url,
  };
};

export interface ICompanyJson {
  accessed?: boolean;
  loginStepsInProgress?: boolean;
  accessToken: string;
  companyId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  reservationId: string;
}

// looks like data structure is the same for now between server and client
type DepositJSON = Deposit;

export const parseDeposits = (
  deposits?: DepositJSON[],
): Deposit[] | undefined =>
  deposits?.map(deposit => ({
    amount: deposit.amount,
    status: deposit.status,
    currency: deposit.currency,
    type: deposit.type,
  }));

export const parseCompany = ({
  accessed = false,
  loginStepsInProgress = false,
  accessToken,
  companyId,
  data,
  reservationId,
}: ICompanyJson): Company => ({
  id: companyId,
  companyId: data?.company_id,
  accessToken,
  accessed,
  challengePassed: data?.guest_info_created,
  isTestReservation: data?.is_test,
  newLoginEnabled: data?.new_login_enabled,
  verificationEnabled: data?.verification_enabled,
  loginStepsInProgress,
  locale: data?.locale,
  accessCodeControlEnabled: data?.access_code_control_enabled,
  reservationDisplayEnabled: data?.reservation_display_enabled,
  lastName: data?.last_name,
  lockControlEnabled: data?.lock_control_enabled,
  portalConfigEnabled: data?.portal_config_enabled,
  amenitiesEnabled: data?.amenities_enabled,
  checkinEnabled: data?.checkin_enabled,
  checkoutEnabled: data?.checkout_enabled,
  earlyCheckinEnabled: data?.early_checkin_enabled,
  mpGuestMessengerEnabled: data?.mp_guest_messenger_enabled,
  smartButtonMessengerEnabled: data?.smart_button_messenger_enabled,
  extendedCheckoutEnabled: data?.extended_checkout_enabled,
  contactEnabled: data?.contact_enabled,
  nearmeEnabled: data?.nearme_enabled,
  deposits: parseDeposits(data?.deposits) || [],
  incident: {
    message: data?.incident_data?.message,
    version: data?.incident_data?.version,
    paymentEnabled: data?.incident_payment?.enabled,
    paymentConfirmed: data?.incident_payment?.confirmed,
  },
  terms: {
    enabled: data?.terms_data?.enabled ?? data?.terms_enabled,
    signatureEnabled: data?.terms_data?.signature_enabled,
    message: data?.terms_data?.message,
    version: data?.terms_data?.version,
  },
  loginChallenges: parseLoginChallenges(data.login_challenges),
  houseManual: {
    wifi: {
      network: data?.house_manual?.wifi?.network,
      password: data?.house_manual?.wifi?.password,
    },
    rules: parseHouseManual(data?.house_manual?.house_rules),
    entertainment: parseHouseManual(data?.house_manual?.entertainment),
    arrivalInstructions: parseHouseManual(
      data?.house_manual?.arrival_instructions,
    ),
    departureInstructions: parseHouseManual(
      data?.house_manual?.departure_instructions,
    ),
  },
  reservation: parseReservation({ reservationId, data }),
  host: parseHost({ companyId, data }),
  theme: parseTheme({ companyId, data }),
  amenities: parseAmenities(data),
  paymentProviders: parsePaymentProviders(data?.payment_providers),
});

const parseLoginChallenges = (loginChallenges: ChallengeJson[]) => {
  const parsedLoginChallenges = [] as Challenge[];
  loginChallenges?.forEach((challenge: ChallengeJson) => {
    if (challenge.name === ChallengeNames.TERMS) {
      parsedLoginChallenges.push(parseTermsChallenge(challenge));
    } else if (challenge.name === ChallengeNames.LOGIN) {
      parsedLoginChallenges.push(parseLoginChallenge(challenge));
    } else if (challenge.name === ChallengeNames.WELCOME) {
      parsedLoginChallenges.push(parseWelcomeChallenge(challenge));
    } else if (challenge.name === ChallengeNames.CONTACT) {
      parsedLoginChallenges.push(parseContactChallenge(challenge));
    } else if (challenge.name === ChallengeNames.VERIFICATION) {
      parsedLoginChallenges.push(parseVerificationChallenge(challenge));
    }
  });
  return parsedLoginChallenges;
};

const parseTermsChallenge = (challenge: ChallengeJson) => {
  const termsChallenge = {} as TermsChallenge;
  const termsChallengeJsonData = challenge.data as TermsChallengeJson;
  termsChallenge.isCustom = termsChallengeJsonData.is_custom;
  termsChallenge.message = termsChallengeJsonData.message;
  return { ...challenge, data: termsChallenge };
};

const parseWelcomeChallenge = (challenge: ChallengeJson) => {
  // Does not require parsing since key names are the same
  const welcomeChallenge = challenge.data as WelcomeChallenge;
  return { ...challenge, data: welcomeChallenge };
};

const parseContactChallenge = (challenge: ChallengeJson) => {
  // Does not require parsing since key names are the same
  const contactChallenge = challenge.data as ContactChallenge;
  return { ...challenge, data: contactChallenge };
};

const parseLoginChallenge = (challenge: ChallengeJson) => {
  // Does not require parsing since key names are the same
  const loginChallenge = challenge.data as LoginChallenge;
  return { ...challenge, data: loginChallenge };
};

const parseVerificationChallenge = (challenge: ChallengeJson) => {
  // Does not require parsing since key names are the same
  const loginChallenge = challenge.data as VerificationChallenge;
  return { ...challenge, data: loginChallenge };
};

interface IChatTokenJson {
  token: string;
  expiry: string;
  id: string;
  channel_id: string;
}

export const parseChatToken = ({
  data,
}: {
  data: IChatTokenJson;
}): ChatToken => ({
  token: data?.token,
  expiry: data?.expiry,
  id: data?.id,
  channel_id: data?.channel_id,
});
