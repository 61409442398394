import { BorderedListItem, ContentView, Spacer } from '@components';
import AppConfig from '@config';
import {
  isVerificationFlowEnabled,
  isVerificationFlowFailed,
  isVerificationFlowPending,
  trackEvent,
} from '@libs';
import useLocalization from '@localizations';
import { Member, useAppStore } from '@store';
import { Linking } from 'react-native';
import { Text, useTheme } from 'react-native-paper';

export const Verfication = () => {
  const { t } = useLocalization();
  const theme = useTheme();
  const { company, host, reservation } = useAppStore();
  const member = (host?.members as Member[]).find(Boolean);
  const incident = company?.incident;

  // TODO: VERIFICATION: needs to come from backend

  return (
    <ContentView description={incident?.message}>
      {reservation && isVerificationFlowEnabled(reservation) && (
        <>
          <Spacer size={1} />
          <Text
            style={[
              AppConfig.Styles.subtitle1,
              AppConfig.Styles.lighterText,
              { marginBottom: 10, justifyContent: 'center' },
            ]}>
            {isVerificationFlowPending(reservation) &&
              t('sh_limited_guest_portal_access')}
            {isVerificationFlowFailed(reservation) && t('limited_access')}
          </Text>
        </>
      )}
      <Spacer size={3} />
      <BorderedListItem
        title={t('telephone')}
        description={member?.phone}
        icon="phone"
        color={theme.colors.primary}
        onPress={() => {
          Linking.openURL(`tel:${member?.phone}`);
          trackEvent('Verification Details', 'Phone:Clicked');
        }}
      />
      <Spacer size={1} />
      <BorderedListItem
        title={t('web_login_email')}
        description={member?.email}
        icon="email"
        color={theme.colors.primary}
        onPress={() => {
          Linking.openURL(`mailto:${member?.email}`);
          trackEvent('Verification Details', 'Email:Clicked');
        }}
      />
    </ContentView>
  );
};

export default Verfication;
