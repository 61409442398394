import Bugsnag from '@bugsnag/js';
import { CustomLoggerTransport, createLogger } from '@operto/logger';

const bugsnagTransport: CustomLoggerTransport = (props: {
  level: { text: string };
  rawMsg: string;
  msg: string;
}) => {
  if (!Bugsnag.isStarted()) {
    return;
  }

  if (props.level.text === 'error') {
    Bugsnag.notify(props.msg);
  } else {
    Bugsnag.leaveBreadcrumb(props.msg);
  }
};

export const logger = createLogger({
  scope: 'APP',
  subScope: 'GUEST-PORTAL',
  transport: bugsnagTransport,
  verbose: true,
});
