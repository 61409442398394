import AppConfig from '@config';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  AnyAction,
  combineReducers,
  configureStore,
  Reducer,
} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { NIL as NIL_UUID, v5 as uuidv5 } from 'uuid';
import { RESET } from './actions/actions';
import { appFeaturesApi } from './app-features/api-slice';
import { slice as authSlice } from './auth/slice';
import { useAppContext } from './context';
import { slice as featureListSlice } from './featured-list/slice';
import { slice as guidesSlice } from './guides/slice';
import { slice as languagePanelSlice } from './languagePanel/slice';
import {
  Amenity,
  AppState,
  ChatToken,
  Company,
  Host,
  NearMe,
  Reservation,
  Theme,
} from './models';
import reducers from './reducers';
import { RTKQapi } from './rtkq-api';
import { cognitoApi } from './services/cognitoApi';
import { userApi } from './services/userApi';
import { slice as verificationSlice } from './verification/slice';

const combinedReducer = combineReducers({
  guestportal: reducers, // TODO: ideally convert this to its own slice
  [guidesSlice.name]: guidesSlice.reducer,
  [featureListSlice.name]: featureListSlice.reducer,
  [verificationSlice.name]: verificationSlice.reducer,
  [cognitoApi.reducerPath]: cognitoApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [languagePanelSlice.name]: languagePanelSlice.reducer,
  [authSlice.name]: authSlice.reducer,
  [RTKQapi.reducerPath]: RTKQapi.reducer,
  [appFeaturesApi.reducerPath]: appFeaturesApi.reducer,
});

const rootReducer = (
  state: ReturnType<typeof combinedReducer>,
  action: AnyAction,
) => {
  if (action.type === RESET) {
    state = {} as ReturnType<typeof rootReducer>;
  }

  // NOTE: can add other root reducer actions if needed

  return combinedReducer(state, action);
};

const config = {
  key: 'root',
  storage: AsyncStorage,
  transforms: [
    encryptTransform({
      secretKey: uuidv5(AppConfig.Settings.REDUX_DB_SECRET as string, NIL_UUID),
    }),
  ],
  blacklist: ['routes'],
};

const reducer = persistReducer(
  config,
  rootReducer as Reducer<ReturnType<typeof rootReducer>>,
);

const isTesting = process.env.JEST_WORKER_ID !== undefined;

export const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: isTesting
        ? false
        : {
            // https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
          },
      immutableCheck: !isTesting,
    }).concat(
      cognitoApi.middleware,
      userApi.middleware,
      appFeaturesApi.middleware,
      RTKQapi.middleware,
    ),
});

export type RootState = ReturnType<typeof rootReducer>;
export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

type AppStore = {
  company?: Company;
  theme?: Theme;
  host?: Host;
  reservation?: Reservation;
  amenities?: Amenity[];
  nearme?: NearMe[];
  messagingToken?: ChatToken;
};

export const useAppStore = (): AppStore => {
  const { companyId } = useAppContext();
  return useSelector((state: { guestportal: AppState }) => {
    const appState = state.guestportal;
    const company = appState.companies[companyId] as Company;
    if (company === undefined) {
      return {};
    }
    const { chatToken } = appState;
    const host = appState.hosts[company?.host as string];
    const theme = appState.themes[company?.theme as string];
    const reservation = appState.reservations[company?.reservation as string];
    const members = ((host?.members ?? []) as string[]).map(
      id => appState.members[id],
    );
    const amenities = ((company?.amenities ?? []) as string[]).map(
      id => appState.amenities[id],
    );
    const nearme = ((company?.nearme ?? []) as string[]).map(
      id => appState.nearme[id],
    );

    const messageId = Object.keys(chatToken)?.[0] as string;
    const messagingToken = chatToken[messageId] || null;

    return {
      company,
      theme,
      host: {
        ...host,
        members,
      },
      reservation,
      amenities,
      nearme,
      messagingToken,
    };
  });
};
