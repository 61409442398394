import AppConfig from '@config';
import { FunctionComponent } from 'react';
import { View } from 'react-native';

interface ISpacerProps {
  /**
   * Makes the spacer take up width instead of height, default: false
   */
  horizontal?: boolean;
  /**
   * The material-esque size of the spacer adjusted to screen size,  default: 3
   */
  size?: number;
}

/**
 * Spacer
 * Generally used to create vertical space but can be used horizontally as well
 */
export const Spacer: FunctionComponent<ISpacerProps> = ({
  size = 3,
  horizontal = false,
}) => {
  let spacer = 16;

  switch (size) {
    case 1:
      spacer = 8;
      break;
    case 2:
      spacer = 12;
      break;
    case 3:
      spacer = 16;
      break;
    case 4:
      spacer = 24;
      break;
    case 5:
      spacer = 32;
      break;
    case 6:
      spacer = 48;
      break;
    default:
      spacer = 16;
      break;
  }

  const style: { height?: number; width?: number } = {};
  const adjustedSize = Math.max(4, AppConfig.Styles.getAdjustedSize(spacer));

  if (horizontal) {
    style.width = adjustedSize;
  } else {
    style.height = adjustedSize;
  }
  return <View testID="spacer" style={style} />;
};

export default Spacer;
