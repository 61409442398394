import {
  trackEvent as useAnalyticsTrackEvent,
  useTrackScreen,
} from '@/hooks/useAnalytics';
import { usePollGuestPortalData } from '@/hooks/usePollGuestPortalData';
import { useWelcomeFlowRedirect } from '@/hooks/useWelcomeFlowRedirect';
import { logger } from '@/libs/logger';
import { Layout, PoweredByView, SnackBar, Splash } from '@components';
import AppConfig from '@config';
import {
  BannerStatus,
  canScanQRCodeToCheckIn,
  determineReservationState,
  isRoomTypeCheckInRequested,
  isTimestampEarlierThanNow,
  isWelcomeChallengeRequired,
  reservationBannerStatus,
  trackEvent,
} from '@libs';
import useLocalization from '@localizations';
import { useNavigation } from '@react-navigation/native';
import { CheckInConfirmationSheet } from '@screens/room-type/CheckInConfirmationSheet';
import {
  Member,
  dispatchMessagingToken,
  dispatchUnlockDoor,
  dispatchUpdateRoomStatus,
  processError,
  useAppContext,
  useAppStore,
} from '@store';
import formatDuration from 'date-fns/formatDuration';
import intervalToDuration from 'date-fns/intervalToDuration';
import { useCallback, useEffect, useState } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import HostDetails from '../components/HostDetails';
import RootContainer from '../components/RootContainer';
import { HomeHeader } from './HomeHeader';
import { ReservationActions } from './ReservationActions';
import { ReservationDetails } from './ReservationDetails';
import { ReservationFeaturedList } from './ReservationFeaturedList';
import { ReservationHeader } from './ReservationHeader';
import { SwipeLockButton } from './SwipeLockButton';
import { WelcomeDialog } from './WelcomeDialog';

export const Home = () => {
  useAnalyticsTrackEvent({
    screen: 'Home',
    event: 'VIEWED',
    feature: 'guest_portal',
    action: 'reservation_page_booking',
  });
  useTrackScreen({
    screen: 'Home',
    feature: 'guest_portal',
    action: Platform.OS === 'web' ? 'desktop_visitors' : 'mobile_visitors',
  });
  useWelcomeFlowRedirect();
  usePollGuestPortalData();

  const {
    urlConfig: { id: configId },
  } = useAppContext();
  const { t } = useLocalization();
  const navigation = useNavigation();
  const [loadingCheckInOut, setLoadingCheckInOut] = useState(false);
  const [loadingLock, setLoadingLock] = useState(false);
  const [snackBarOptions, setSnackBarOptions] = useState({
    title: '',
    color: AppConfig.Colors.SUCCESS,
    visible: false,
  });
  const { reservation, theme, company, host } = useAppStore();
  const member = (host?.members as Member[])?.find(Boolean);
  const reservationState = determineReservationState(reservation);
  const titleArrivalSuccess = t('check_in_successful_title');
  const titleArrivalEarly = t('booking_early_checkin_title');
  const descriptionArrivalSuccess = t('check_in_successful_content');
  const contentTitle = t('checkout_title');
  const contentDescription = t('checkout_content');
  const timeZoneDisplay = reservation?.property.timezone
    ? `(${reservation?.property.timezone})`
    : '';
  const descriptionArrivalEarly = `${t('booking_early_checkin_body')} ${
    reservation?.checkinWithoutParsing
  }${timeZoneDisplay}\n${t('booking_early_checkin_body2')}`;

  /**
   * START OF VAROUS FUNCTIONS THAT HOME SCREEN HAVE ACTIONS FOR.
   *
   */
  const onConfirmationPress = async () => {
    if (loadingCheckInOut || !reservation) {
      return;
    }

    if (
      reservationBannerStatus(company, reservation) ===
      BannerStatus.SECURITY_DEPOSIT
    ) {
      // on security state, should not go forward to checkin state
      navigation.navigate('SecurityDeposit');
    } else {
      setLoadingCheckInOut(true);
      let success = true;
      let errorMsg = '';
      let trackLabel = '';
      let fsPayload = {};

      try {
        if (company) {
          await dispatchUpdateRoomStatus(
            reservation,
            reservationState,
            company.id,
          );
        }
      } catch (error) {
        const message = processError(error);
        logger.error(message);
        errorMsg = message;
        success = false;
      }

      if (reservationState === 'departure') {
        // either we navigate to departure page or show some error feedback
        if (success) {
          navigation.navigate('Departure');
        } else {
          setSnackBarOptions({
            title: errorMsg,
            color: AppConfig.Colors.ERROR,
            visible: true,
          });
        }

        trackLabel = 'Check Out';
      } else if (reservationState === 'arrival') {
        // either success or too early
        const title = success ? titleArrivalSuccess : titleArrivalEarly;

        const description = success
          ? descriptionArrivalSuccess
          : descriptionArrivalEarly;

        navigation.navigate('Confirmation', {
          contentTitle: title,
          contentDescription: description,
        });
        setLoadingCheckInOut(false);

        // fullstory event
        const diff = formatDuration(
          intervalToDuration({
            start: new Date(reservation?.checkin * 1000),
            end: new Date(),
          }),
        );
        trackLabel = 'Check In';
        fsPayload = {
          isTooEarly: !success,
          timeDiff: diff,
        };
      }
      trackEvent('Home', `${trackLabel}:Clicked`, fsPayload);
    }
  };

  /**
   * On Check OUT - it goes to a popup modal page first before doing API calls
   * On Check IN - it does API call then show a popup modal page
   */
  const onCheckInOutPress = () => {
    if (reservationState === 'departure') {
      navigation.navigate('Confirmation', {
        contentTitle: contentTitle,
        contentDescription: contentDescription,
        mode: 'checkout',
        onPress: onConfirmationPress,
      });
    } else {
      if (canScanQRCodeToCheckIn(reservation)) {
        navigation.navigate('QRCodeCheckIn');
      } else if (isRoomTypeCheckInRequested(reservation)) {
        navigation.navigate('CheckInRequested');
      } else {
        onConfirmationPress();
      }
    }
  };

  /**
   * Swipe To Unlock - does API call then redirect to a popup modal page for results
   */
  const onSwipeToUnlock = useCallback(async () => {
    setLoadingLock(true);
    let success = true;
    try {
      if (company) {
        await dispatchUnlockDoor(company.id, company.reservation as string);
      }
    } catch (error) {
      logger.error(error);
      success = false;
    }

    navigation.navigate('Confirmation', {
      contentTitle: t(
        success ? 'unlock_success_title' : 'unlock_unsuccessful_title',
      ),
      contentDescription: t(
        success ? 'unlock_success_subtitle' : 'unlock_unsuccessful_subtitle',
      ),
    });

    setLoadingLock(false);
  }, [navigation, t, company]);

  useEffect(() => {
    const fetchMessagingToken = async () => {
      try {
        if (company) {
          await dispatchMessagingToken(
            company.id,
            company.reservation as string,
          );
        }
      } catch (error) {
        const message = processError(error);
        logger.error(message);
      }
    };

    fetchMessagingToken();
  }, [company]);

  useEffect(() => {
    if (
      isTimestampEarlierThanNow(
        reservation?.checkout,
        reservation?.property?.timezone,
      )
    ) {
      navigation.navigate('Departure');
    }
  }, [navigation, reservation?.checkout, reservation?.property?.timezone]);

  // ensure the reservation matches the configId or else it means it we
  // might have cached reservation while we are still fetching the new one
  if (reservation === undefined || !configId.endsWith(reservation.id)) {
    return <Splash />;
  }

  return (
    <RootContainer>
      <Layout>
        {reservation && theme && company && (
          <HomeHeader
            reservation={reservation}
            theme={theme}
            company={company}
          />
        )}
        <View style={styles.body}>
          {company?.lockControlEnabled && (
            <View style={styles.innerContainer}>
              <SwipeLockButton
                onEndReached={onSwipeToUnlock}
                loading={loadingLock}
                disabled={
                  reservation?.incident.paymentEnabled &&
                  !reservation?.incident.paymentConfirmed
                }
              />
            </View>
          )}
          {reservation.entranceInstructions.enabled &&
          company?.accessCodeControlEnabled ? (
            <ReservationActions
              loading={loadingCheckInOut}
              onCheckInOutPress={onCheckInOutPress}
            />
          ) : (
            <ReservationHeader />
          )}
          <View style={styles.innerContainer}>
            {reservation && (
              <ReservationDetails
                checkin={reservation.checkin}
                checkout={reservation.checkout}
              />
            )}
          </View>
          {member?.name && (member?.phone || member?.email) && (
            <HostDetails
              name={member.name}
              phone={member?.phone}
              email={member?.email}
              avatar={host?.welcomeAvatar}
            />
          )}
          {(!reservation.entranceInstructions.enabled ||
            !company?.accessCodeControlEnabled) && (
            <ReservationActions
              loading={loadingCheckInOut}
              onCheckInOutPress={onCheckInOutPress}
            />
          )}
          <ReservationFeaturedList />
        </View>

        <PoweredByView />
        <SnackBar
          {...snackBarOptions}
          onDismiss={() =>
            setSnackBarOptions({ ...snackBarOptions, visible: false })
          }
        />
        <CheckInConfirmationSheet />
        {company &&
          company.newLoginEnabled &&
          !company.challengePassed &&
          isWelcomeChallengeRequired(company) &&
          !reservation.welcomeAccepted && <WelcomeDialog />}
      </Layout>
    </RootContainer>
  );
};

export default Home;

const styles = StyleSheet.create({
  body: {
    width: '100%',
    alignSelf: 'center',
    maxWidth: AppConfig.Styles.responsiveMaxWidth,
    paddingBottom: AppConfig.Styles.getAdjustedSize(20),
  },
  innerContainer: {
    padding: AppConfig.Styles.getAdjustedSize(16),
  },
});
