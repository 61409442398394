// @ts-nocheck - fix me
import { Spacer } from '@components';
import AppConfig from '@config';
import { toTitleCase, trackEvent } from '@libs';
import useLocalization from '@localizations';
import { useNavigation } from '@react-navigation/native';
import { useAppStore, useVerification } from '@store';
import { VERIFICATIONFingerprint, VERIFICATIONLicenseFront } from '@svgs';
import { useEffect, useRef, useState } from 'react';
import { Camera } from 'react-camera-pro';
import { View } from 'react-native';
import { Button, Text } from 'react-native-paper';
import Svg from 'react-native-svg';
import { ContentView, HeaderView } from '../../login/Common';
import { LoginStyles } from '../../login/Styles';

// @@ Action view
const ActionView = ({ onTake }: { onTake?: (value: boolean) => void }) => {
  const { verification } = useVerification();
  const navigation = useNavigation();
  const { t } = useLocalization();

  const onPressCancel = () => {
    navigation.navigate('Details');
  };

  const label =
    toTitleCase(verification?.idType) === 'Drivers License'
      ? t('data_page_drivers_license')
      : t('data_page_id_card');

  return (
    <View
      style={{
        width: '100%',
        paddingHorizontal: AppConfig.Styles.getAdjustedSize(24),
        paddingBottom: AppConfig.Styles.getAdjustedSize(24),
      }}>
      <View style={[AppConfig.Styles.container]}>
        <View style={[AppConfig.Styles.container]}>
          <View
            style={[
              AppConfig.Styles.container,
              AppConfig.Styles.alignCenter,
              { marginBottom: 30 },
            ]}>
            <Text
              style={[
                AppConfig.Styles.headline5,
                AppConfig.Styles.alignCenter,
                AppConfig.Styles.lighterText,
              ]}>
              {label}
            </Text>
          </View>
        </View>

        <Spacer />
        <Text
          style={[AppConfig.Styles.subtitle2, AppConfig.Styles.lighterText]}>
          {t('photo_description')}
        </Text>
        <Spacer />
        <Text
          style={[AppConfig.Styles.subtitle2, AppConfig.Styles.lighterText]}>
          - {t('photo_four_corners')}
        </Text>
        <Text
          style={[AppConfig.Styles.subtitle2, AppConfig.Styles.lighterText]}>
          - {t('photo_clear')}
        </Text>
        <Text
          style={[AppConfig.Styles.subtitle2, AppConfig.Styles.lighterText]}>
          - {t('photo_readable')}
        </Text>
        <Text
          style={[AppConfig.Styles.subtitle2, AppConfig.Styles.lighterText]}>
          - {t('photo_security_visible')}
        </Text>
        <Text
          style={[AppConfig.Styles.subtitle2, AppConfig.Styles.lighterText]}>
          - {t('photo_no_blurring')}
        </Text>
        <Text
          style={[AppConfig.Styles.subtitle2, AppConfig.Styles.lighterText]}>
          - {t('photo_lighting')}
        </Text>
        <Text
          style={[AppConfig.Styles.subtitle2, AppConfig.Styles.lighterText]}>
          - {t('photo_contrast')}
        </Text>

        <View
          style={[
            AppConfig.Styles.rowContainer,
            {
              justifyContent: 'space-between',
              borderTopColor: '#dddddd',
              borderTopWidth: 1,
              paddingTop: 20,
              marginTop: 20,
            },
          ]}>
          <Button
            contentStyle={{
              height: AppConfig.Styles.buttonHeight,
            }}
            labelStyle={{ color: 'black' }}
            onPress={onPressCancel}>
            {t('back')}
          </Button>
          <Button
            contentStyle={{
              width: 150,
              height: AppConfig.Styles.buttonHeight,
            }}
            mode="contained"
            onPress={() => {
              onTake(true);
              trackEvent('Superhog', 'ID Photo:Clicked');
            }}
            labelStyle={{ color: 'white' }}>
            {t('photo_take')}
          </Button>
        </View>
      </View>
    </View>
  );
};

// @ Camera Component
export const Cam = ({
  onTake,
  onCapture,
}: {
  onTake?: (value: boolean) => void;
  onCapture: (value?: string) => void;
}) => {
  const camera = useRef(null);
  const { t } = useLocalization();
  return (
    <View style={[AppConfig.Styles.container, AppConfig.Styles.center]}>
      <View
        style={[
          AppConfig.Styles.container,
          {
            // margin: 30,
            // padding: AppConfig.Styles.getWindowHeight() / 60,
            width: '100%',
            height: '100vh',
            overflow: 'clip',
            // borderRadius: 40,
          },
        ]}>
        <Camera
          ref={camera}
          facingMode="environment"
          errorMessages={{
            noCameraAccessible: t('photo_no_camera'),
            permissionDenied: t('photo_no_permission'),
            switchCamera: t('photo_no_switch_cam'),
            canvas: 'Canvas is not supported.',
          }}
        />

        {camera !== null && (
          <View
            style={[
              AppConfig.Styles.rowContainer,
              {
                justifyContent: 'space-evenly',
                top: (AppConfig.Styles.getWindowHeight() * 35) / 100,
              },
            ]}>
            <Button
              style={{
                backgroundColor: 'rgba(0,0,0,0.80)',
                paddingLeft: AppConfig.Styles.getWidthByPercentage(3.5),
                height: AppConfig.Styles.getWidthByPercentage(20),
                width: AppConfig.Styles.getWidthByPercentage(20),
                borderWidth: 0.5,
                borderColor: 'white',
                borderRadius: AppConfig.Styles.getWidthByPercentage(10),
                top: AppConfig.Styles.getWidthByPercentage(2.75),
                alignItems: 'center',
                justifyContent: 'center',
              }}
              labelStyle={{
                fontSize: AppConfig.Styles.getWidthByPercentage(7),
                color: 'white',
                backgroundColor: 'black',
              }}
              onPress={() => {
                onTake(false);
              }}
              icon="close">
              {' '}
            </Button>
            <Button
              style={{
                backgroundColor: 'rgba(0,0,0,0.80)',
                paddingLeft: AppConfig.Styles.getWidthByPercentage(3),
                height: AppConfig.Styles.getWidthByPercentage(30),
                width: AppConfig.Styles.getWidthByPercentage(30),
                borderWidth: 0.5,
                borderColor: 'white',
                borderRadius: AppConfig.Styles.getWidthByPercentage(50),
                marginRight: AppConfig.Styles.getWidthByPercentage(5),
                marginLeft: AppConfig.Styles.getWidthByPercentage(5),
                alignItems: 'center',
                justifyContent: 'center',
              }}
              labelStyle={{
                fontSize: AppConfig.Styles.getWidthByPercentage(10),
                color: 'white',
                backgroundColor: 'black',
              }}
              onPress={() => {
                if (camera && camera.current) {
                  const { takePhoto } = camera.current;
                  if (takePhoto) {
                    onCapture(takePhoto());
                    trackEvent('Superhog', 'ID Photo:Taken');
                  }
                }
                onTake(false);
              }}
              icon="camera">
              {' '}
            </Button>
            <Button
              style={{
                backgroundColor: 'rgba(0,0,0,0.80)',
                height: AppConfig.Styles.getWidthByPercentage(20),
                width: AppConfig.Styles.getWidthByPercentage(20),
                borderWidth: 0.5,
                borderColor: 'white',
                borderRadius: AppConfig.Styles.getWidthByPercentage(10),
                top: AppConfig.Styles.getWidthByPercentage(2.75),
                alignItems: 'center',
                justifyContent: 'center',
                paddingLeft: AppConfig.Styles.getWidthByPercentage(3.5),
              }}
              labelStyle={{
                fontSize: AppConfig.Styles.getWidthByPercentage(7),
                color: 'white',
                backgroundColor: 'black',
              }}
              onPress={() => {
                if (camera && camera.current) {
                  const { switchCamera } = camera.current;
                  if (switchCamera) {
                    switchCamera();
                  }
                }
              }}
              icon="camera-flip">
              {' '}
            </Button>
          </View>
        )}
      </View>
    </View>
  );
};

// @@ Default Component
export const Capture = () => {
  const { company, theme } = useAppStore();
  const navigation = useNavigation();
  const [take, setTake] = useState(false);
  const { verification, setVerification } = useVerification();
  const { t } = useLocalization();

  const onCapture = (pic?: string) => {
    setVerification({ ...verification, governmentIdBase64: pic });
    navigation.navigate('ConfirmId');
    setTake(false);
  };

  useEffect(() => {
    if (!verification?.idType) {
      navigation.navigate('Methods');
    }
  });

  if (take) {
    return <Cam onTake={setTake} onCapture={onCapture} />;
  }
  return (
    <View style={[AppConfig.Styles.container, AppConfig.Styles.center]}>
      <View style={[AppConfig.Styles.container, LoginStyles.container]}>
        <View style={[LoginStyles.contentContainer]}>
          {theme && (
            <HeaderView
              theme={theme}
              logo={
                <Svg
                  width="100%"
                  height="100%"
                  viewBox="0 0 50 50"
                  fill={theme.primaryColor}
                  style={{ margin: 0, padding: 0 }}>
                  <VERIFICATIONFingerprint />
                </Svg>
              }
            />
          )}
          <ContentView title={t('upload_your_id')} description="" />
        </View>
        <View style={[AppConfig.Styles.center]}>
          <View
            style={[
              AppConfig.Styles.center,
              {
                width: AppConfig.Styles.getAdjustedSize(300),
                height: AppConfig.Styles.getAdjustedSize(300),
                marginBottom: AppConfig.Styles.getAdjustedSize(20),
              },
            ]}>
            <Svg
              width="100%"
              height="100%"
              viewBox="0 0 240 200"
              fill={theme.primaryColor}
              style={{ margin: 0, padding: 0 }}>
              <VERIFICATIONLicenseFront />
            </Svg>
          </View>
        </View>
        <ActionView company={company} onTake={setTake} />
      </View>
    </View>
  );
};

export default Capture;
