import { SVGProps } from 'react';
import Svg, { Path } from 'react-native-svg';

export const RetryIcon = ({
  fill = 'currentColor',
  width = 18,
  height = 18,
}: SVGProps<SVGSVGElement>) => (
  <Svg viewBox="0 0 19 18" width={width} height={height}>
    <Path
      d="M13.7378 4.7625C12.6503 3.675 11.1578 3 9.50031 3C6.18531 3 3.50781 5.685 3.50781 9C3.50781 12.315 6.18531 15 9.50031 15C12.2978 15 14.6303 13.0875 15.2978 10.5H13.7378C13.1228 12.2475 11.4578 13.5 9.50031 13.5C7.01781 13.5 5.00031 11.4825 5.00031 9C5.00031 6.5175 7.01781 4.5 9.50031 4.5C10.7453 4.5 11.8553 5.0175 12.6653 5.835L10.2503 8.25H15.5003V3L13.7378 4.7625Z"
      fill={fill}
    />
  </Svg>
);
