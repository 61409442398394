import AppConfig from '@config';
import { OpertoTheme } from '@operto/ui';
import { Actionsheet, ScrollView } from 'native-base';
import { ReactNode } from 'react';
import { Dialog, Portal } from 'react-native-paper';

export type ActionSheetProps = {
  children: ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  testID?: string;
  adaptive?: boolean;
  hideDragIndicator?: boolean;
  isScrollable?: boolean;
};

const BottomSheet = ({ children, ...props }: ActionSheetProps) => {
  return (
    <Actionsheet {...props}>
      <Actionsheet.Content backgroundColor={OpertoTheme.containerAccentBGColor}>
        {
          // if props.isScrollable is true, wrap children in a ScrollView
          props.isScrollable ? (
            <ScrollView width="100%">{children}</ScrollView>
          ) : (
            children
          )
        }
      </Actionsheet.Content>
    </Actionsheet>
  );
};

const ModalSheet = ({
  isOpen,
  onClose,
  children,
  testID,
}: ActionSheetProps) => {
  return (
    <Portal>
      <Dialog
        visible={isOpen}
        onDismiss={onClose}
        style={{
          alignSelf: 'center',
          width: '90%',
          maxWidth: AppConfig.Styles.getAdjustedSize(400),
        }}>
        <Dialog.Content testID={testID}>{children}</Dialog.Content>
      </Dialog>
    </Portal>
  );
};

export const ActionSheet = ({
  children,
  adaptive = false,
  ...props
}: ActionSheetProps) => {
  if (adaptive && !AppConfig.Styles.isMobile()) {
    return <ModalSheet {...props}>{children}</ModalSheet>;
  }

  return <BottomSheet {...props}>{children}</BottomSheet>;
};
