// @ts-nocheck - fix me
/* eslint-disable max-lines */
import useLocalization from '@/assets/localizations';
import AppConfig from '@config';
import { trackEvent } from '@libs';
import { useNavigation } from '@react-navigation/native';
import { useAppContext, useAppStore } from '@store';
import { VERIFICATIONFingerprint } from '@svgs';
import format from 'date-fns-tz/format';
import differenceInYears from 'date-fns/differenceInYears';
import React, { useRef, useState } from 'react';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { View } from 'react-native';
import CountryPicker, { Country } from 'react-native-country-picker-modal';
import { Button, HelperText, TextInput } from 'react-native-paper';
import { DatePickerModal, MultiConfirm } from 'react-native-paper-dates';
import Svg from 'react-native-svg';
import SimpleReactValidator from 'simple-react-validator';
import { useVerification } from '../../../store/verification/hooks';
import { ContentView, HeaderView } from '../../login/Common';
import { LoginStyles } from '../../login/Styles';

// @@ Action view
const ActionView = () => {
  const { locale } = useAppContext();
  const [showErrors, setShowErrors] = React.useState(false);
  const [errors, setErrors] = useState({});
  const { verification, setVerification } = useVerification();
  const [localVerification, setLocalVerification] = useState(verification);
  const [isCountryPickerVisible, setIsCountryPickerVisible] = useState(false);
  const [isEditedCountryOrPhone, setIsEditedCountryOrPhone] = useState(false);
  const [isCountryClicked, setIsCountryClicked] = useState(false);
  const [isPhoneClicked, setIsPhoneClicked] = useState(false);
  const { t } = useLocalization();
  const navigation = useNavigation();
  const [chosenDate, setChosenDate] = React.useState<Date | undefined>(
    undefined,
  );
  const [countryNum, setCountryNum] = useState('');

  const simpleValidator = useRef(
    new SimpleReactValidator({
      messages: {
        required: t('required'),
      },
      validators: {
        dateValidator: {
          message: 'Your age should be more than 18 to verify',
          rule: (val: string) => {
            const userBirthDate = new Date(val);
            const userAge = differenceInYears(Date.now(), userBirthDate);
            return userAge >= 18;
          },
        },
        phoneLengthValidator: {
          message: 'Required',
          rule: val => {
            return val?.length > countryNum?.length;
          },
        },
      },
    }),
  );

  const [openDateModal, setOpenDateModal] = React.useState(false);

  const onPressNext = () => {
    if (simpleValidator.current.allValid()) {
      setVerification({ ...verification, ...localVerification });

      trackEvent('Superhog', 'Personal Data: Submitted', {
        firstNameFilled: verification?.firstName?.length > 0,
        lastNameFilled: verification?.lastName?.length > 0,
        address1Filled: verification?.address?.addressLine1?.length > 0,
        cityFilled: verification?.address?.town?.length > 0,
        provinceFilled: verification?.address?.province?.length > 0,
        countryFilled: verification?.address?.country?.length > 0,
        postalFilled: verification?.address?.postcode?.length > 0,
        emailFilled: verification?.email?.length > 0,
        phoneFilled: verification?.phone?.length > 0,
        birthFilled: verification?.dateOfBirth?.length > 0,
      });
      if (isCountryClicked && isPhoneClicked) {
        trackEvent('Superhog', 'Personal Data: Country ISO mismatched');
      }
      navigation.navigate('Methods');
    } else {
      simpleValidator.current.showMessages();
      setErrors(simpleValidator.current.errorMessages);
      setShowErrors(true);
    }
  };
  const onPressBack = () => {
    navigation.navigate('Disclaimer');
  };

  /**
   * FIXME: investigate how we can leverage code below to
   * forward reference but stil have "IntlTelInput" typings
   *
   * const inputRef = React.useRef<IntlTelInput>();
   */
  const inputRef = React.useRef();
  const inputDesigner = (currentRef: unknown) => {
    if (currentRef) {
      currentRef.tel.style.width = '100%';
      currentRef.tel.style.height = '50px';
      currentRef.tel.style['border-radius'] = '7.055px';
      currentRef.tel.style['border-width'] = '1.45px';
      if (showErrors && errors['phone number']) {
        currentRef.tel.style['border-color'] = '#c62828';
      } else {
        currentRef.tel.style['border-color'] = 'rgba(0, 0, 0, 0.54)';
      }
      currentRef.tel.style['font-size'] = '16px';
      currentRef.flagDropDown.childNodes[1].style.bottom = '100%';
      currentRef.flagDropDown.childNodes[1].style.height = '100px';
      currentRef.flagDropDown.childNodes[1].style.font = '-webkit-mini-control';
      currentRef.flagDropDown.childNodes[1].style['border-radius'] = '6px';
    }
  };

  const phoneNumberUpdate = (phoneRef: unknown) => {
    if (phoneRef.current) {
      const phoneNum = phoneRef.current.tel.value.replace(/[^0-9]/gi, '');
      const countryCodeNum = `${phoneRef.current.selectedCountryData.dialCode}`;
      setCountryNum(countryCodeNum);
      setLocalVerification({
        ...localVerification,
        phone: `${countryCodeNum}${phoneNum}`,
      });
      if (!isEditedCountryOrPhone) {
        const chosenCountryISO = phoneRef?.current?.selectedCountryData
          ?.iso2 as string | null;
        const chosenCountryName = phoneRef?.current?.selectedCountryData
          ?.name as string | null;
        if (chosenCountryISO && chosenCountryName) {
          setIsPhoneClicked(true);
          setLocalVerification({
            ...localVerification,
            address: {
              ...localVerification?.address,
              country: chosenCountryName,
            },
            countryISO: chosenCountryISO.toUpperCase(),
          });
          setIsEditedCountryOrPhone(true);
        }
      }
    }
  };

  const localVerificaionSetter = (e: unknown) => {
    const { id, value } = e.target;
    if (
      ['addressLine1', 'town', 'province', 'country', 'postcode'].includes(id)
    ) {
      setLocalVerification({
        ...localVerification,
        address: { ...localVerification.address, [id]: value },
      });
    } else {
      setLocalVerification({ ...localVerification, [id]: value });
    }
  };

  // TODO: rethink this... normally we use useRef to things that doesnt
  // want causes state changes. perhaps we should call inputDesgner during
  // the assignment of the reference?
  React.useEffect(() => {
    inputDesigner(inputRef.current);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef.current]);

  const onSelectCountry = (chosenCountry: Country) => {
    const chosenCCA = chosenCountry?.cca2 as string | null;
    const chosenName = chosenCountry?.name as string | null;
    if (!isEditedCountryOrPhone) {
      setIsEditedCountryOrPhone(true);
      setIsCountryClicked(true);
      inputRef?.current?.setFlag(chosenCCA?.toLowerCase());
    }
    setLocalVerification({
      ...localVerification,
      address: { ...localVerification.address, country: chosenName },
      countryISO: chosenCCA?.toLowerCase(),
    });
    setIsCountryPickerVisible(false);
  };
  return (
    <View
      style={{
        width: '100%',
        paddingHorizontal: AppConfig.Styles.getAdjustedSize(24),
        paddingBottom: AppConfig.Styles.getAdjustedSize(24),
      }}>
      <View style={[AppConfig.Styles.container]}>
        <DatePickerModal
          locale={locale}
          mode="single"
          visible={openDateModal}
          onDismiss={() => {
            setOpenDateModal(false);
          }}
          date={chosenDate}
          onConfirm={(params: MultiConfirm) => {
            setChosenDate(params.date);
            setLocalVerification({
              ...localVerification,
              dateOfBirth: format(params.date, 'yyyy-MM-dd'),
            });
            setOpenDateModal(false);
          }}
          animationType="slide" // optional, default is 'slide' on ios/android and 'none' on web
        />
        <View style={[AppConfig.Styles.container, { display: 'none' }]}>
          <CountryPicker
            countryCode="CA"
            withAlphaFilter
            withCallingCode
            onSelect={onSelectCountry}
            onClose={() => {
              setIsCountryPickerVisible(false);
            }}
            visible={isCountryPickerVisible}
          />
        </View>

        <TextInput
          nativeID="firstName"
          style={{
            height: AppConfig.Styles.inputHeight,
          }}
          mode="outlined"
          label={t('signup_first_name')}
          placeholder={t('signup_first_name')}
          value={localVerification?.firstName ?? verification?.firstName}
          onChange={localVerificaionSetter}
          error={errors?.firstName && showErrors}
        />
        <HelperText type="error">
          {simpleValidator.current.message(
            'firstName',
            localVerification.firstName,
            'required',
          )}
        </HelperText>
        <TextInput
          nativeID="lastName"
          style={{
            height: AppConfig.Styles.inputHeight,
          }}
          mode="outlined"
          label={t('signup_last_name')}
          placeholder={t('signup_last_name')}
          value={localVerification?.lastName ?? verification?.lastName}
          onChange={localVerificaionSetter}
          error={errors?.lastName && showErrors}
        />
        <HelperText type="error">
          {simpleValidator.current.message(
            'lastName',
            localVerification?.lastName,
            'required',
          )}
        </HelperText>
        <TextInput
          nativeID="addressLine1"
          style={{
            height: AppConfig.Styles.inputHeight,
          }}
          mode="outlined"
          label={t('address_line_1')}
          placeholder={t('street_address')}
          value={
            localVerification?.address?.addressLine1 ??
            verification?.address?.addressLine1
          }
          onChange={localVerificaionSetter}
          error={errors?.addressLine1 && showErrors}
        />
        <HelperText type="error">
          {simpleValidator.current.message(
            'addressLine1',
            localVerification?.address?.addressLine1,
            'required',
          )}
        </HelperText>

        <View
          style={[
            AppConfig.Styles.rowContainer,
            {
              justifyContent: 'space-between',
              marginBottom: `${
                !errors?.province && !errors?.town ? '10px' : ''
              }`,
            },
          ]}>
          <TextInput
            nativeID="town"
            style={{
              height: AppConfig.Styles.inputHeight,
              width: '49%',
            }}
            mode="outlined"
            label={t('city')}
            placeholder={t('city_description')}
            value={
              localVerification?.address?.town ?? verification?.address?.town
            }
            onChange={localVerificaionSetter}
            error={errors?.town && showErrors}
          />
          <TextInput
            nativeID="province"
            style={{
              height: AppConfig.Styles.inputHeight,
              width: '49%',
            }}
            mode="outlined"
            label={t('province')}
            placeholder={t('province_description')}
            value={
              localVerification?.address?.province ??
              verification?.address?.province
            }
            onChange={localVerificaionSetter}
            error={errors?.province && showErrors}
          />
        </View>
        <View
          style={[
            AppConfig.Styles.rowContainer,
            {
              justifyContent: 'flex-start',
              display: `${!errors?.province && !errors?.town ? 'none' : ''}`,
            },
          ]}>
          <View style={[AppConfig.Styles.container]}>
            <HelperText type="error">
              {simpleValidator.current.message(
                'town',
                localVerification?.address?.town,
                'required',
              )}
            </HelperText>
          </View>
          <View style={[AppConfig.Styles.container]}>
            <HelperText type="error">
              {simpleValidator.current.message(
                'province',
                localVerification?.address?.province,
                'required',
              )}
            </HelperText>
          </View>
        </View>

        <View
          style={[
            AppConfig.Styles.rowContainer,
            {
              justifyContent: 'space-between',
              marginBottom: `${
                !errors['postal code'] && !errors?.country ? '10px' : ''
              }`,
            },
          ]}>
          <TextInput
            nativeID="chosenCountry"
            style={{
              height: AppConfig.Styles.inputHeight,
              width: '49%',
            }}
            mode="outlined"
            label={t('country')}
            autoComplete="false"
            auto
            value={
              localVerification?.address?.country ??
              verification?.address?.country
            }
            onFocus={() => {
              setIsCountryPickerVisible(true);
            }}
            onTouchStart={() => {
              setIsCountryPickerVisible(true);
            }}
            error={errors?.chosenCountry && showErrors}
          />

          <TextInput
            nativeID="postcode"
            style={{
              height: AppConfig.Styles.inputHeight,
              width: '49%',
            }}
            mode="outlined"
            label={t('postal_code')}
            placeholder="V6B 1A8"
            value={
              localVerification?.address?.postcode ??
              verification?.address?.postcode
            }
            onChange={localVerificaionSetter}
            error={errors['postal code'] && showErrors}
          />
        </View>
        <View
          style={[
            AppConfig.Styles.rowContainer,
            {
              justifyContent: 'flex-start',
              display: `${
                !errors['postal code'] && !errors?.country ? 'none' : ''
              }`,
            },
          ]}>
          <View style={[AppConfig.Styles.container]}>
            <HelperText type="error">
              {simpleValidator.current.message(
                'chosenCountry',
                localVerification?.address?.country,
                'required',
              )}
            </HelperText>
          </View>
          <View style={[AppConfig.Styles.container]}>
            <HelperText type="error">
              {simpleValidator.current.message(
                'postal code',
                localVerification?.address?.postcode,
                'required',
              )}
            </HelperText>
          </View>
        </View>
        <TextInput
          nativeID="email"
          style={{
            height: AppConfig.Styles.inputHeight,
          }}
          mode="outlined"
          label={t('web_login_email')}
          placeholder={t('email_description')}
          value={localVerification?.email ?? verification?.email}
          onChange={localVerificaionSetter}
          error={errors?.email && showErrors}
        />
        <HelperText type="error">
          {simpleValidator.current.message(
            'email',
            localVerification?.email,
            'required|email',
          )}
        </HelperText>
        <View style={{ flexDirection: 'row', width: '100%' }}>
          <IntlTelInput
            fieldId="phone number"
            style={{
              width: '100%',
            }}
            containerClassName="intl-tel-input"
            inputClassName="form-control"
            ref={inputRef}
            onPhoneNumberChange={() => phoneNumberUpdate(inputRef)}
            onSelectFlag={() => phoneNumberUpdate(inputRef)}
            onPhoneNumberBlur={() => phoneNumberUpdate(inputRef)}
            geoIpLookup
          />
        </View>
        <HelperText type="error">
          {simpleValidator.current.message(
            'phone number',
            localVerification?.phone,
            'required|phone|phoneLengthValidator',
          )}
        </HelperText>
        <View
          style={[
            AppConfig.Styles.rowContainer,
            { justifyContent: 'space-between', marginBottom: '8px' },
          ]}>
          <TextInput
            nativeID="dateOfBirth"
            style={{
              height: AppConfig.Styles.inputHeight,
              width: '100%',
            }}
            mode="outlined"
            label={t('date_of_birth')}
            placeholder={t('date_of_birth')}
            value={chosenDate ? format(chosenDate, 'yyyy-MM-dd') : ''}
            onFocus={() => {
              setOpenDateModal(true);
            }}
            error={errors['date of birth'] && showErrors}
          />
        </View>
        <HelperText type="error">
          {simpleValidator.current.message(
            'date of birth',
            localVerification?.dateOfBirth,
            'required|dateValidator',
          )}
        </HelperText>
        <View
          style={[
            AppConfig.Styles.rowContainer,
            {
              justifyContent: 'space-between',
              borderTopColor: '#dddddd',
              borderTopWidth: 1,
              paddingTop: 20,
              marginTop: 20,
            },
          ]}>
          <Button
            contentStyle={{
              width: 120,
              height: AppConfig.Styles.buttonHeight,
            }}
            labelStyle={{ color: 'black' }}
            onPress={onPressBack}>
            {t('back')}
          </Button>
          <Button
            contentStyle={{
              width: 120,
              height: AppConfig.Styles.buttonHeight,
            }}
            labelStyle={{ color: 'white' }}
            mode="contained"
            onPress={onPressNext}>
            {t('next')}
          </Button>
        </View>
      </View>
    </View>
  );
};

// @@ Default Component
export const Details = () => {
  const { theme } = useAppStore();
  const { t } = useLocalization();
  return (
    <View style={[AppConfig.Styles.container, AppConfig.Styles.center]}>
      <View style={[AppConfig.Styles.container, LoginStyles.container]}>
        <View style={[LoginStyles.contentContainer]}>
          {theme && (
            <HeaderView
              theme={theme}
              logo={
                <Svg
                  width="100%"
                  height="100%"
                  viewBox="0 0 50 50"
                  fill={theme.primaryColor}
                  style={{ margin: 0, padding: 0 }}>
                  <VERIFICATIONFingerprint />
                </Svg>
              }
            />
          )}
          <ContentView
            title={t('personal_details')}
            description={t('personal_details_description')}
          />
        </View>
        <ActionView />
      </View>
    </View>
  );
};

export default Details;
