import { ReactNode, useEffect } from 'react';
import { View } from 'react-native';
import GlobalBottomBar from './GlobalBottomBar';

type RootContainerProps = {
  children: ReactNode;
};

const DIV_SCROLL_ID = 'root-scroll'; // the 'id' attribute to add to the div that will be scrolled
const DIV_SCROLL_FROM_PARENT = 4; // the dom depth to search up from the root container to find the div to scroll

export const RootContainer = ({ children }: RootContainerProps) => {
  const findParentScrollElement = (
    element: HTMLElement,
  ): HTMLElement | undefined => {
    let childElement = element;
    for (let i = 0; i < DIV_SCROLL_FROM_PARENT; i++) {
      if (childElement?.parentElement) {
        childElement = childElement.parentElement;
      }
    }
    return childElement;
  };

  useEffect(() => {
    const rootContainer = document.getElementById('root-container');
    if (rootContainer) {
      const parentDiv = findParentScrollElement(rootContainer);
      if (parentDiv) {
        parentDiv.id = DIV_SCROLL_ID;
      }
    }
  }, []);
  return (
    <div id="root-container">
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          minHeight: '100vh',
          height: '100%',
        }}>
        {children}
        <GlobalBottomBar />
      </View>
    </div>
  );
};

export default RootContainer;
