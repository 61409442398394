import { QRCodeData, useQRCodeMessages } from '@/hooks/useQRCodeMessages';
import { logger } from '@/libs/logger';
import {
  ActionButtons,
  Loading,
  QRCodeScanner,
  SnackBar,
  Spacer,
} from '@components';
import AppConfig from '@config';
import useLocalization from '@localizations';
import { StackActions, useNavigation } from '@react-navigation/native';
import { processError, useAppContext, useAppStore } from '@store';
import { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { OnResultFunction } from 'react-qr-reader';
import { NotFoundWithBackToHome } from '../components/NotFound';
import { QRCodeIcon } from './QRCodeIcon';

export const QRCodeCheckIn = () => {
  const { t } = useLocalization();
  const navigation = useNavigation();
  const { locale } = useAppContext();
  const { company, reservation } = useAppStore();
  const {
    data,
    isLoading,
    isError,
    message,
    postQRCodeCheckin,
    getQRCodeMessages,
    clearStatus,
  } = useQRCodeMessages();
  const qrCode: QRCodeData | undefined = data?.qrCode[locale];

  const onQRCodeResult: OnResultFunction = async (result, err) => {
    if (!result?.getText()?.endsWith(`${company?.id}/welcome`)) {
      return;
    }

    logger.debug('onQRCodeResult', result, err);
    try {
      await postQRCodeCheckin();
      navigation.dispatch(StackActions.replace('CheckInRequested'));
    } catch (error) {
      logger.error(processError(error));
    }
  };

  useEffect(() => {
    getQRCodeMessages('checkin');
  }, [getQRCodeMessages]);

  if (isLoading) {
    return (
      <View style={[AppConfig.Styles.container, AppConfig.Styles.center]}>
        <Loading />
      </View>
    );
  }

  if (!data?.checkinQRCodeEnabled) {
    return <NotFoundWithBackToHome />;
  }

  return (
    <View style={[AppConfig.Styles.container, AppConfig.Styles.center]}>
      <Icon
        name="window-close"
        size={AppConfig.Styles.getAdjustedSize(24)}
        style={styles.close}
        onPress={() => navigation.navigate('Main')}
      />
      <Spacer size={4} />
      <QRCodeIcon />
      <Spacer size={2} />
      <View style={styles.container}>
        <Text
          style={[
            AppConfig.Styles.textAlignCenter,
            AppConfig.Styles.headline6,
            styles.paddingHorizontal,
          ]}>
          {reservation?.property?.name ?? t('scan_qr_code')}
        </Text>
        <Spacer size={2} />
        <Text
          style={[
            AppConfig.Styles.textAlignCenter,
            AppConfig.Styles.subtitle2,
            styles.paddingHorizontal,
          ]}>
          {qrCode?.message?.length ? qrCode.message : t('scan_to_checkin')}
        </Text>
        <View style={styles.camera}>
          <QRCodeScanner onResult={onQRCodeResult} />
          <Text
            style={[
              AppConfig.Styles.textAlignCenter,
              AppConfig.Styles.subtitle2,
              styles.cameraText,
            ]}>
            {t('scan_qr_code')}
          </Text>
        </View>
        {qrCode?.buttons && (
          <ActionButtons
            style={styles.paddingHorizontal}
            buttons={qrCode.buttons}
          />
        )}
      </View>
      <SnackBar
        title={message ?? ''}
        visible={isError}
        color={'red'}
        onDismiss={clearStatus}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: AppConfig.Styles.responsiveMaxWidth,
  },
  camera: {
    width: '100%',
    marginTop: AppConfig.Styles.getAdjustedSize(28),
    marginBottom: AppConfig.Styles.getAdjustedSize(36),
    backgroundColor: 'lightgray',
  },
  cameraText: {
    color: 'white',
    position: 'absolute',
    top: AppConfig.Styles.getAdjustedSize(16),
    left: 0,
    right: 0,
  },
  close: {
    position: 'absolute',
    top: AppConfig.Styles.getAdjustedSize(29),
    left: AppConfig.Styles.getAdjustedSize(29),
  },
  paddingHorizontal: {
    paddingHorizontal: AppConfig.Styles.getAdjustedSize(16),
  },
});
