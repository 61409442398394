import useLocalization from '@/assets/localizations';
import { Spacer } from '@components';
import AppConfig from '@config';
import { trackEvent } from '@libs';
import { useNavigation } from '@react-navigation/native';
import { dispatchVerifyGuest, useAppStore, useVerification } from '@store';
import { VERIFICATIONFingerprint, VERIFICATIONProcessing } from '@svgs';
import { useState } from 'react';
import { View } from 'react-native';
import { Button, Text } from 'react-native-paper';
import Svg from 'react-native-svg';
import { ContentView, HeaderView } from '../../login/Common';
import { LoginStyles } from '../../login/Styles';

// @@ Action view
const ActionView = () => {
  const { company, reservation } = useAppStore();
  const { verification } = useVerification();
  const navigation = useNavigation();
  const { t } = useLocalization();

  const [loading, setLoading] = useState(false);

  const onPressCancel = () => {
    navigation.navigate('Details');
  };

  const onPressSubmit = () => {
    setLoading(true);

    if (verification && reservation && company) {
      dispatchVerifyGuest(verification, reservation, company)
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });

      trackEvent('Superhog', 'Submit Check-in:Submitted');
    }
  };
  return (
    <View
      style={{
        width: '100%',
        paddingHorizontal: AppConfig.Styles.getAdjustedSize(24),
        paddingBottom: AppConfig.Styles.getAdjustedSize(24),
      }}>
      <View style={[AppConfig.Styles.container]}>
        <Spacer />
        <Text
          style={[AppConfig.Styles.subtitle2, AppConfig.Styles.lighterText]}>
          {t('verification_success')}
        </Text>
        <Spacer />
        <Text
          style={[AppConfig.Styles.subtitle2, AppConfig.Styles.lighterText]}>
          {t('verification_failed')}
        </Text>
        <Spacer />

        <View
          style={[
            AppConfig.Styles.rowContainer,
            {
              justifyContent: 'space-between',
              borderTopColor: '#dddddd',
              borderTopWidth: 1,
              paddingTop: 20,
              marginTop: 20,
            },
          ]}>
          <Button
            contentStyle={{
              height: AppConfig.Styles.buttonHeight,
            }}
            labelStyle={{ color: 'black' }}
            onPress={onPressCancel}>
            {t('back')}
          </Button>
          <Button
            contentStyle={{
              width: 150,
              height: AppConfig.Styles.buttonHeight,
            }}
            mode="contained"
            loading={loading}
            disabled={loading}
            onPress={onPressSubmit}
            labelStyle={{ color: 'white' }}>
            {loading ? '' : t('submit')}
          </Button>
        </View>
      </View>
    </View>
  );
};

// @@ Default Component
export const Submit = () => {
  const { theme } = useAppStore();
  const { t } = useLocalization();

  return (
    <View style={[AppConfig.Styles.container, AppConfig.Styles.center]}>
      <View style={[AppConfig.Styles.container, LoginStyles.container]}>
        <View style={[LoginStyles.contentContainer]}>
          {theme && (
            <HeaderView
              theme={theme}
              logo={
                <Svg
                  width="100%"
                  height="100%"
                  viewBox="0 0 50 50"
                  fill={theme?.primaryColor}
                  style={{ margin: 0, padding: 0 }}>
                  <VERIFICATIONFingerprint />
                </Svg>
              }
            />
          )}
          <ContentView title={t('submit_check_in')} description="" />
        </View>
        <View style={[AppConfig.Styles.center]}>
          <View
            style={[
              AppConfig.Styles.center,
              {
                width: AppConfig.Styles.getAdjustedSize(300),
                height: AppConfig.Styles.getAdjustedSize(300),
                marginBottom: AppConfig.Styles.getAdjustedSize(0),
              },
            ]}>
            <Svg
              width="100%"
              height="100%"
              viewBox="0 0 240 200"
              fill={theme?.primaryColor}
              style={{ margin: 0, padding: 0 }}>
              <VERIFICATIONProcessing />
            </Svg>
          </View>
        </View>
        <ActionView />
      </View>
    </View>
  );
};

export default Submit;
