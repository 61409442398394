import useLocalization from '@/assets/localizations';
import { isContactChallengeRequired } from '@/libs';
import { Spacer } from '@components';
import AppConfig from '@config';
import { dispatchUpdateGuest, dispatchUpdateTerms, useAppStore } from '@store';
import { LoginContactLogo } from '@svgs';
import { useState } from 'react';
import 'react-intl-tel-input/dist/main.css';
import { View } from 'react-native';
import { useTheme } from 'react-native-paper';
import { ButtonView, ContentView, HeaderView } from './Common';
import { LoginStyles } from './Styles';

// @@ Default Component
export const Summary = () => {
  const themeProvider = useTheme();
  const { company, reservation, theme } = useAppStore();
  const [loading, setLoading] = useState(false);
  const { t } = useLocalization();

  const submit = async () => {
    try {
      reservation &&
        (await dispatchUpdateGuest(
          reservation,
          !isContactChallengeRequired(company),
        ));
      if (reservation?.termsAgreed) {
        await dispatchUpdateTerms(company?.reservation as string);
      }
      return true;
    } catch (error) {
      return false;
    } finally {
      setLoading(false);
    }
  };

  return (
    <View style={[AppConfig.Styles.container, AppConfig.Styles.center]}>
      <View style={[AppConfig.Styles.container, LoginStyles.container]}>
        <View style={[LoginStyles.contentContainer]}>
          <ContentView
            title={t('login_summary_title')}
            description={t('login_summary_description')}>
            <HeaderView theme={theme} />
            {theme?.welcomeImagesEnabled && (
              <LoginContactLogo
                style={{ color: themeProvider.colors.primary }}
                width={AppConfig.Styles.getAdjustedSize(230)}
              />
            )}
          </ContentView>
          <Spacer size={3} />

          <Spacer size={3} />
          <View style={{ width: '100%' }}>
            <ButtonView
              onSubmit={submit}
              company={company}
              loading={loading}
              currentPage="Summary"
              customNextText={t('login_summary_button')}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default Summary;
