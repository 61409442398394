import { ReactNode, createContext, useContext, useState } from 'react';

const ClaimErrorContext = createContext({
  showClaimError: false,
  setShowClaimError: (_error: boolean) => {
    // initialize as empty function
  },
});

export const ClaimErrorProvider = ({ children }: { children: ReactNode }) => {
  const [showError, setShowError] = useState(false);

  const setShowClaimError = (error: boolean) => {
    setShowError(error);
  };

  return (
    <ClaimErrorContext.Provider
      value={{ showClaimError: showError, setShowClaimError }}>
      {children}
    </ClaimErrorContext.Provider>
  );
};

export const useClaimError = () => useContext(ClaimErrorContext);
