import { REGION } from '@/assets/configurations/Settings';
import { trackEvent } from '@/hooks/useAnalytics';
import { logger } from '@/libs/logger';
import { SnackBar } from '@/screens/components';
import ConfirmDialog from '@/screens/components/ConfirmDialog';
import { getCognitoAccessTokenFromAsyncStorage } from '@/screens/login/LoginCognito/utils';
import { useDeleteReservationClaimsMutation } from '@/store/reservations/api-slice';
import {
  useDeleteGuestUserByIdMutation,
  useGetGuestUserQuery,
  usePatchGuestUserByIdMutation,
} from '@/store/services/userApi';
import {
  CognitoIdentityProviderClient,
  DeleteUserCommand,
} from '@aws-sdk/client-cognito-identity-provider';
import AppConfig from '@config';
import { useLocalization } from '@localizations';
import { useNavigation } from '@react-navigation/native';
import { useAppContext, useAppStore } from '@store';
import { dispatchReset } from '@store/actions/utils';
import { useLanguagePanel } from '@store/languagePanel';
import React, { useEffect } from 'react';
import { Dimensions, Image, StyleSheet, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import {
  Button,
  Divider,
  Icon,
  List,
  ListItemProps,
  Switch,
  Text,
  useTheme,
} from 'react-native-paper';

const RightIcon = () => <List.Icon icon="menu-right" />;

const ListItem = ({ ...rest }: ListItemProps) => (
  <List.Item style={{ marginHorizontal: 6 }} right={RightIcon} {...rest} />
);

export const GuestProfile = () => {
  const { t, languages } = useLocalization();
  const { locale, openSnackbar } = useAppContext();
  const { openPanel } = useLanguagePanel();
  const { company } = useAppStore();
  const navigation = useNavigation();

  const { data: guest } = useGetGuestUserQuery();

  const theme = useTheme();
  const [isEmailSwitchOn, setIsEmailSwitchOn] = React.useState(
    guest?.emailNotification ?? false,
  );
  const [isPhoneSwitchOn, setIsPhoneSwitchOn] = React.useState(
    guest?.smsNotification ?? false,
  );
  const [isDeletingOnLoading, setIsDeletingOnLoading] = React.useState(false);
  const [snackBarOptions, setSnackBarOptions] = React.useState({
    visible: false,
    color: AppConfig.Colors.ERROR,
    title: '',
  });

  const [patchGuestUserById] = usePatchGuestUserByIdMutation();
  const [deleteReservationClaims] = useDeleteReservationClaimsMutation();

  const onToggleEmailSwitch = async () => {
    const updatedEmailSwitchOn = !isEmailSwitchOn;
    setIsEmailSwitchOn(updatedEmailSwitchOn);

    try {
      if (guest?.id) {
        await patchGuestUserById({
          ...guest,
          emailNotification: updatedEmailSwitchOn,
        });
      }
    } catch (error) {
      setIsEmailSwitchOn(!isEmailSwitchOn);
    }
  };
  const onTogglePhoneSwitch = async () => {
    const updatedPhoneSwitchOn = !isPhoneSwitchOn;
    setIsPhoneSwitchOn(updatedPhoneSwitchOn);

    try {
      if (guest?.id) {
        await patchGuestUserById({
          ...guest,
          smsNotification: updatedPhoneSwitchOn,
        });
      }
    } catch (error) {
      setIsPhoneSwitchOn(!isPhoneSwitchOn);
    }
  };

  const [deleteAccountDialogOpen, setDeleteAccountDialogOpen] =
    React.useState(false);
  const [logoutDialogOpen, setLogoutDialogOpen] = React.useState(false);
  const [isLogoutDialogLoading, setIsLogoutDialogLoading] =
    React.useState(false);

  const [deleteGuestUserById] = useDeleteGuestUserByIdMutation();

  const closeDeleteAccountDialog = () => {
    setDeleteAccountDialogOpen(false);
  };

  const handleDeleteAccount = async () => {
    trackEvent({
      screen: 'GuestProfile',
      event: 'CLICKED',
      feature: 'user_profile',
      action: 'profile_delete',
    });
    if (!guest?.id) {
      closeDeleteAccountDialog();
      return;
    }

    setIsDeletingOnLoading(true);
    const accessToken = await getCognitoAccessTokenFromAsyncStorage();
    const client = new CognitoIdentityProviderClient({ region: REGION });
    const command = new DeleteUserCommand({ AccessToken: accessToken });
    const response = (await client.send(command)) as unknown as {
      $metadata: { httpStatusCode: number };
    };

    try {
      if (response?.$metadata?.httpStatusCode === 200) {
        await deleteGuestUserById(guest.id).unwrap();
        await deleteReservationClaims(guest.id).unwrap();

        openSnackbar({
          title: t('account_deletion_popup'),
          color: theme.colors.inverseSurface,
        });
        setIsDeletingOnLoading(false);
        closeDeleteAccountDialog();
        await dispatchReset();
      } else {
        throw new Error('Error deleting account in cognito');
      }
    } catch (error) {
      logger.error('Error deleting guest account', error);
      openSnackbar({
        title: t('account_deletion_popup_error_cta'),
        color: theme.colors.error,
      });
      setIsDeletingOnLoading(false);
      closeDeleteAccountDialog();
    }
  };

  const handleSignOut = async () => {
    setIsLogoutDialogLoading(true);
    setTimeout(async () => {
      await dispatchReset();
    }, 1000);
  };

  useEffect(() => {
    if (company?.isTestReservation) {
      navigation.navigate('Home');
    }
  }, [company, navigation]);

  trackEvent({
    screen: 'GuestProfile',
    event: 'VIEWED',
    feature: 'user_profile',
    action: 'profile_visitors',
  });

  return (
    <>
      <ConfirmDialog
        open={deleteAccountDialogOpen}
        loading={isDeletingOnLoading}
        title={t('deactivate_account_delete_account_title')}
        content={t('deactivate_account_delete_account_popup_body')}
        onClose={() => {
          closeDeleteAccountDialog();
        }}
        onConfirm={() => {
          handleDeleteAccount();
        }}
        onCancel={() => {
          closeDeleteAccountDialog();
        }}
        dismissable={true}
        dialogStyle={{ maxWidth: '312px' }}
        submitTextStyle={{ color: '#FFFFFF' }}
        submitButtonStyle={{ minWidth: '144px' }}
        submitButtonText={t('guest_profile_delete_account_button_cta')}
      />

      <ConfirmDialog
        open={logoutDialogOpen}
        loading={isLogoutDialogLoading}
        title={t('guest_profile_logout_popup')}
        onClose={() => {
          setLogoutDialogOpen(false);
        }}
        onConfirm={() => {
          handleSignOut();
        }}
        onCancel={() => {
          setLogoutDialogOpen(false);
        }}
        dismissable={true}
        dialogStyle={{ minWidth: '312px' }}
        submitTextStyle={{ color: '#FFFFFF' }}
        submitButtonStyle={{ minWidth: '96px' }}
        submitButtonText={t('guest_profile_logout_popup_button')}
      />

      <View style={StyleSheet.absoluteFill}>
        <ScrollView
          style={styles.containerMaxWidth}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}>
          <View style={[styles.container, { marginTop: 40 }]}>
            <Icon
              source={require('@images/profile.png')}
              size={AppConfig.Styles.getAdjustedSize(100)}
            />
          </View>

          <Text
            style={[
              AppConfig.Styles.headline4,
              styles.container,
              styles.nameContainer,
            ]}>
            {`${guest?.givenName}${
              guest?.preferredName ? ` (${guest?.preferredName}) ` : ' '
            }${guest?.familyName}`}
          </Text>

          <Text
            style={[
              AppConfig.Styles.headline5,
              {
                margin: 20,
                alignSelf: 'flex-start',
              },
            ]}>
            {t('guest_profile_basic_info')}
          </Text>

          <Text
            style={[
              AppConfig.Styles.subtitle1,
              {
                marginHorizontal: 22,
                marginVertical: 5,
                alignSelf: 'flex-start',
              },
            ]}>
            {guest?.emailPrimary}
          </Text>

          <Text
            style={[
              AppConfig.Styles.caption,
              {
                marginHorizontal: 22,
                marginTop: 0,
                marginBottom: 20,
                alignSelf: 'flex-start',
              },
            ]}>
            {
              /* {t('connected_email_account')} removed until future development */ ''
            }
          </Text>

          {guest?.phonePrimary && (
            <Text
              style={[
                AppConfig.Styles.subtitle1,
                {
                  margin: 22,
                  alignSelf: 'flex-start',
                },
              ]}>
              {guest?.phonePrimary}
            </Text>
          )}

          <Divider />

          <Text
            style={[
              AppConfig.Styles.headline5,
              {
                margin: 20,
                alignSelf: 'flex-start',
              },
            ]}>
            {t('notifications')}
          </Text>

          <View style={[styles.switchContainer]}>
            <Text style={AppConfig.Styles.subtitle1}>
              {t('web_login_email')}
            </Text>

            <Switch
              color={AppConfig.Colors.SECONDARY}
              value={isEmailSwitchOn}
              onValueChange={onToggleEmailSwitch}
            />
          </View>

          <View style={styles.switchContainer}>
            <Text style={[AppConfig.Styles.subtitle1]}>
              {t('guest_profile_sms_title')}
            </Text>

            <Switch
              color={AppConfig.Colors.SECONDARY}
              value={isPhoneSwitchOn}
              onValueChange={onTogglePhoneSwitch}
            />
          </View>

          <Divider style={{ marginTop: 20 }} />

          <Text
            style={[
              AppConfig.Styles.headline5,
              {
                margin: 20,
                alignSelf: 'flex-start',
              },
            ]}>
            {t('guest_profile_language_title')}
          </Text>

          <ListItem
            title={languages.find(l => l.locale === locale)?.name ?? 'English'}
            onPress={() => {
              openPanel();
            }}
          />

          <Divider style={{ marginTop: 20 }} />

          <Text
            style={[
              AppConfig.Styles.headline5,
              {
                margin: 20,
                alignSelf: 'flex-start',
              },
            ]}>
            {t('guest_profile_legal_title')}
          </Text>

          <ListItem
            title={t('terms_conditions_title')}
            onPress={() => {
              window.open('https://operto.com/terms-and-conditions/', '_blank');
            }}
          />

          <ListItem
            title={t('privacy_policy_title')}
            onPress={() => {
              window.open('https://operto.com/company/privacy', '_blank');
            }}
          />

          {/* HIDDEN until further decisions */}
          {/* <Button
            style={[
              styles.button,
              {
                alignSelf: 'flex-start',
                backgroundColor: AppConfig.Colors.SECONDARY_CONTAINER,
                borderWidth: 0,
              },
            ]}
            mode="outlined"
            textColor={'#1B1C1C'}
            onPress={() => {
              setDeleteAccountDialogOpen(true);
            }}>
            {t('guest_profile_delete_account_button_cta')}
          </Button> */}

          <Image
            source={require('@images/powered_by_operto.png')}
            style={{
              alignSelf: 'center',
              marginVertical: 20,
              height: 40,
              width: 375,
            }}
          />
        </ScrollView>

        <View style={styles.footerContainer}>
          <Divider style={{ marginTop: 1 }} />

          <Button
            style={styles.button}
            mode="contained"
            buttonColor={AppConfig.Colors.SECONDARY}
            onPress={() => {
              setLogoutDialogOpen(true);
            }}>
            {t('logout')}
          </Button>
        </View>
      </View>

      <SnackBar
        {...snackBarOptions}
        onDismiss={() =>
          setSnackBarOptions({ ...snackBarOptions, visible: false })
        }
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
    marginHorizontal: 20,
  },
  nameContainer: {
    alignSelf: 'center',
    wordBreak: 'break-all',
  },
  containerMaxWidth: {
    alignSelf: 'center',
    backgroundColor: AppConfig.Colors.ON_SECONDARY,
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    maxWidth: AppConfig.Styles.getAdjustedSize(600),
  },
  footerContainer: {
    width: Dimensions.get('screen').width,
    alignSelf: 'center',
    backgroundColor: AppConfig.Colors.ON_SECONDARY,
    marginBottom: 8,
    flex: 0.1,
  },
  switchContainer: {
    flexDirection: 'row',
    marginVertical: 10,
    marginHorizontal: 22,
    alignItems: 'stretch',
    justifyContent: 'space-between',
  },
  button: {
    alignSelf: 'center',
    marginVertical: 20,
    alignItems: 'center',
    justifyContent: 'space-between',
    marginHorizontal: 20,
  },
});
