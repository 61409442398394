import useLocalization from '@/assets/localizations';
import { Draw, Keyboard } from '@/assets/svgs';
import { trackEvent } from '@/hooks/useAnalytics';
import { format } from 'date-fns';
import { useRef, useState } from 'react';
import { StyleSheet, View, useWindowDimensions } from 'react-native';
import { Button, Dialog, Portal, Switch, Text } from 'react-native-paper';
import SignatureCanvas from 'react-signature-canvas';
import { SignatureDrawPad } from './SignatureDrawPad.web';
import { drawSignatureToCanvas } from './SignatureHelpers';
import { SignatureTextField } from './SignatureTextField';

const SignatureType = {
  DRAW: 'draw',
  TEXT: 'text',
};
export type SignatureType = typeof SignatureType[keyof typeof SignatureType];

export type SignatureDialogProps = {
  visible: boolean;
  loading?: boolean;
  writer: string;
  onClose: () => void;
  onSubmit: (base64Image: string, saveToProfile: boolean) => void;
};

export const SignatureDialog = ({
  onSubmit,
  onClose,
  visible,
  writer,
  loading,
}: SignatureDialogProps) => {
  const [signatureType, setSignatureType] = useState<SignatureType>(
    SignatureType.DRAW,
  );
  const [textSignature, setTextSignature] = useState('');
  const [isSaveToProfileOn, setIsSaveToProfileOn] = useState(false);
  const [isSignatureEmpty, setIsSignatureEmpty] = useState(true);
  const padRef = useRef<SignatureCanvas>(null);

  const { t } = useLocalization();

  const handleTextSignatureChange = (text: string) => {
    setTextSignature(text);
    setIsSignatureEmpty(!text.replace(/\s/g, '').length);
  };

  const handleSignatureChange = () => {
    if (padRef.current) {
      const isEmpty = padRef.current.isEmpty();
      setIsSignatureEmpty(isEmpty);
    }
  };

  const eventLabels: { [key in SignatureType]: string } = {
    [SignatureType.DRAW]: 'guest_terms_esign',
    [SignatureType.TEXT]: 'guest_terms_text',
  };

  const handleSignatureSubmit = async (type: SignatureType) => {
    const eventLabel = eventLabels[type];

    trackEvent({
      screen: 'SignatureDialog',
      event: 'SUBMITTED',
      feature: 'guest_terms',
      details: {
        signatureType: eventLabel,
      },
    });

    let base64Image = '';
    if (type === SignatureType.DRAW) {
      const dataURL = padRef.current?.toDataURL();
      base64Image = dataURL ?? '';
    } else {
      const dataURL = await drawSignatureToCanvas();
      base64Image = dataURL ?? '';
    }
    onSubmit(base64Image, isSaveToProfileOn);
  };

  const clearSignatureCanvas = () => {
    padRef.current?.clear();
    setTextSignature('');
    setIsSignatureEmpty(true);
  };

  const windowWidth = useWindowDimensions().width;
  const windowHeight = useWindowDimensions().height;
  const landscapeView = windowWidth > windowHeight;

  return (
    <View>
      <Portal>
        <Dialog
          visible={visible}
          onDismiss={onClose}
          style={[
            styles.dialog,
            {
              width: windowWidth > 430 && windowHeight > 430 ? '80%' : '100%',
              minHeight: windowWidth > 430 ? '75%' : '100%',
            },
          ]}>
          <View
            style={[
              styles.dialogHeaderContent,
              { marginTop: landscapeView ? '5%' : '2%' },
            ]}>
            <View>
              <Dialog.Actions style={styles.headerWrapper}>
                <View style={styles.signatureTypeButtons}>
                  <Button
                    style={{ width: '88px' }}
                    mode="text"
                    onPress={() => setSignatureType(SignatureType.DRAW)}>
                    <View
                      style={{
                        backgroundColor:
                          signatureType === SignatureType.DRAW
                            ? '#D9D9D9'
                            : '#FFFFFF',
                        paddingLeft: '21px',
                        paddingRight: '21px',
                        width: '64px',
                        height: '32px',
                        borderRadius: 50,
                        display: 'flex',
                        justifyContent: 'center',
                      }}>
                      <Draw />
                    </View>
                    <Text style={styles.iconText}>{t('draw')}</Text>
                  </Button>
                  <Button
                    style={{ width: '88px' }}
                    onPress={() => setSignatureType(SignatureType.TEXT)}>
                    <View
                      style={{
                        backgroundColor:
                          signatureType === SignatureType.TEXT
                            ? '#D9D9D9'
                            : '#FFFFFF',
                        paddingLeft: '21px',
                        paddingRight: '21px',
                        width: '64px',
                        height: '32px',
                        borderRadius: 50,
                        display: 'flex',
                        justifyContent: 'center',
                      }}>
                      <Keyboard />
                    </View>
                    <Text style={styles.iconText}>{t('text')}</Text>
                  </Button>
                </View>
              </Dialog.Actions>
              <Text>{t('p_signature_agreement')}</Text>
            </View>

            <Dialog.Content
              style={{
                minHeight: '200px',
                height: 'auto',
                alignItems: 'center',
                marginTop: '16px',
                marginBottom: '16px',
                paddingBottom: '0',
                paddingRight: '0px',
                paddingLeft: '0px',
              }}>
              {signatureType === SignatureType.DRAW ? (
                <SignatureDrawPad
                  canvasRef={padRef}
                  onEnd={handleSignatureChange}
                />
              ) : (
                <SignatureTextField
                  textSignature={textSignature}
                  onChangeText={handleTextSignatureChange}
                />
              )}
            </Dialog.Content>

            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: landscapeView ? '70px' : '8px',
              }}>
              <View style={styles.issueDateWrapper}>
                <View>
                  <Text style={{ fontWeight: 'bold' }}>{writer}</Text>
                  <Text>{`Issued on ${format(
                    new Date(),
                    "LLLL d, yyyy, 'at' h:mm a.",
                  )}`}</Text>
                </View>
                <Button
                  mode="text"
                  textColor="#155EFF"
                  style={{ height: '40px', width: '85px' }}
                  disabled={isSignatureEmpty}
                  onPress={clearSignatureCanvas}>
                  {t('clear')}
                </Button>
              </View>

              <View style={styles.toggleWrapper}>
                <Switch
                  style={styles.switch}
                  value={isSaveToProfileOn}
                  color="black"
                  onValueChange={() => setIsSaveToProfileOn(!isSaveToProfileOn)}
                />
                <Text>{t('save_to_online_profile')}</Text>
              </View>
            </View>
          </View>

          <View style={styles.footer}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingTop: '16px',
                paddingLeft: '16px',
                borderTopWidth: 1,
                borderTopColor: '#C3C5D9',
              }}>
              <Button
                loading={loading}
                buttonColor="#155EFF"
                style={{ height: '40px' }}
                mode="contained"
                disabled={isSignatureEmpty}
                onPress={() => handleSignatureSubmit(signatureType)}
                testID={'submit-button'}>
                {t('submit')}
              </Button>
              <Button
                textColor="#155EFF"
                onPress={onClose}
                mode="outlined"
                style={{ marginLeft: '8px', height: '40px' }}>
                {t('cancel')}
              </Button>
            </View>
          </View>
        </Dialog>
      </Portal>
    </View>
  );
};

const styles = StyleSheet.create({
  dialog: {
    alignSelf: 'center',
    backgroundColor: '#FFFFFF',
    padding: '16px',
    justifyContent: 'space-between',
    overflow: 'hidden',
    borderRadius: 10,
  },
  toggleWrapper: {
    display: 'none', // hide the footer for now, will be shown in the future
    flexDirection: 'row',
    paddingTop: '16px',
    borderRadius: 10,
  },
  switch: {
    marginRight: '16px',
  },
  issueDateWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: '16px',
  },
  headerWrapper: {
    justifyContent: 'center',
    maxHeight: '80px',
    marginTop: '0px',
    overflow: 'hidden',
    paddingBottom: '16px',
    paddingRight: '0px',
    paddingLeft: '0px',
  },
  footer: {
    paddingBottom: '16px',
    position: 'absolute',
    backgroundColor: '#FFFFFF',
    left: '0',
    bottom: '0',
    width: '100%',
    marginTop: 'auto',
  },
  iconText: {
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  signatureTypeButtons: {
    display: 'flex',
    flexDirection: 'row',
    gap: '30vw',
  },
  dialogHeaderContent: {
    overflow: 'scroll',
    height: '90%',
    display: 'flex',
    justifyContent: 'space-between',
  },
});
