import AppConfig from '@config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAccessToken } from './actions/utils';

const opertoFetchBaseQuery = () => {
  return fetchBaseQuery({
    baseUrl: AppConfig.Settings.BASE_API_URL,
    prepareHeaders: async headers => {
      const accessToken = (await getAccessToken())?.token;
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      return headers;
    },
  });
};

export const RTKQapi = createApi({
  reducerPath: 'RTKQapi',
  baseQuery: opertoFetchBaseQuery(),
  refetchOnFocus: false,
  refetchOnReconnect: false,
  endpoints: () => ({}),
});
