import { Linking, ViewProps } from 'react-native';
import MapView, { LatLng } from 'react-native-maps';

export const Map = (props: ViewProps & LatLng) => {
  const {
    style,
    latitude = 34.2428895825304,
    longitude = -116.91506088827596,
  } = props;

  return (
    <MapView
      style={style}
      region={{
        latitude,
        longitude,
        latitudeDelta: 0.0043,
        longitudeDelta: 0.0034,
      }}>
      {/* @ts-ignore: react-native-web-maps which is alias of rn-maps doesnt have exact interface for marker */}
      <MapView.Marker
        coordinate={props}
        onPress={() =>
          Linking.openURL(`https://maps.google.com/?q=${latitude},${longitude}`)
        }
      />
    </MapView>
  );
};

export default Map;
