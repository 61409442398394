/* eslint-disable max-lines */
import { logger } from '@/libs/logger';
import AppConfig from '@config';
import { DateFormat } from '@libs';
import isEqual from 'lodash/isEqual';
import { CheckInOut } from '../../types/reservations';
import { Company, Reservation } from '../models/models';
import { ICompanyJson, parseCompany, parseNearMe } from '../models/parsers';
import { IVerification } from '../verification';
import {
  DispatchGetReservationsParams,
  UpdateGuestParams,
  UpdateRoomStatusParams,
} from './models';
import {
  createResInfoFormData,
  dispatchCompanies,
  dispatchReservations,
  getAccessToken,
  processResponse,
  setLocalStorageForKey,
} from './utils';

const authorizeGuest = async (
  company: Company,
  inputLastName?: string,
  checkInOut?: CheckInOut,
) => {
  // get token from app state
  const accessToken = (await getAccessToken())?.token;
  const formData = new FormData();

  // a hack for vrscheduler OR the external ID that has @sign
  let resId = company.reservation as string;
  resId = resId.replace('%40', '@');
  resId = resId.replace('%2540', '@');

  formData.append('company_code', company.id);
  formData.append('reservation_external_id', resId);

  if (inputLastName) {
    formData.append('lastname', inputLastName);
  }

  if (checkInOut) {
    formData.append(
      'valid_from',
      DateFormat(checkInOut.checkIn, { apiFormat: true }),
    );
    formData.append(
      'valid_until',
      DateFormat(checkInOut.checkOut, { apiFormat: true }),
    );
  }

  const response = await fetch(
    `${AppConfig.Settings.BASE_API_URL}/v2/guest/auth`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: formData,
    },
  );

  const res = await processResponse(response);
  return { ...res.data, expiredAt: res.data.expired_at };
};

const fetchGuestReservationInfo = async (
  accessToken: string,
  locale: string | undefined,
  companyCode: string,
  reservationCode: string,
) => {
  let isSessionStart = true;

  if (sessionStorage.getItem('sessionStart') === 'true') {
    isSessionStart = false;
  }

  const response = await fetch(
    `${AppConfig.Settings.BASE_API_URL}/portal/guest/reservation?version=2&locale=${locale}&sessionStart=${isSessionStart}&companyCode=${companyCode}&reservationCode=${reservationCode}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  if (response.status === 200) {
    sessionStorage.setItem('sessionStart', 'true');
  }
  return processResponse(response);
};

const fetchGetReservation = async (
  accessToken: string,
  reservationId: string,
) => {
  const response = await fetch(
    `${AppConfig.Settings.BASE_API_URL}/v2/guest/reservation/${reservationId}/verification`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
  return processResponse(response);
};

const verifyGuest = async (
  verification: IVerification,
  reservationId: string | number,
) => {
  const accessToken = (await getAccessToken())?.token;
  const response = await fetch(
    `${AppConfig.Settings.BASE_API_URL}/v2/guest/reservation/${reservationId}/verification/superhog?verificationProviderId=1`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        first_name: verification.firstName,
        last_name: verification.lastName,
        email: verification.email,
        address_line1: verification.address?.addressLine1,
        postal_code: verification.address?.postcode,
        town: verification.address?.town,
        country_iso: verification.countryISO,
        province: verification.address?.province,
        government_id_content_type: 'image/jpeg',
        selfie_content_type: 'image/jpeg',
        government_id_base64: verification.governmentIdBase64
          ?.split(';base64,')
          .pop(),
        selfie_base64: verification.selfieBase64?.split(';base64,').pop(),
        date_of_birth: verification.dateOfBirth,
      }),
    },
  );
  return processResponse(response);
};

const postGetSDIintent = async (
  companyCode: string,
  reservationCode: string,
) => {
  const accessToken = (await getAccessToken())?.token;
  const formData = createResInfoFormData(companyCode, reservationCode);

  const response = await fetch(
    `${AppConfig.Settings.BASE_API_URL}/portal/guest/payment/stripe/setup`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: formData,
    },
  );
  return processResponse(response);
};

const postStripeConfirm = async (
  paymentMethodID: string,
  customerId: string,
  companyCode: string,
  reservationCode: string,
) => {
  const accessToken = (await getAccessToken())?.token;
  const formData = createResInfoFormData(companyCode, reservationCode);
  formData.append('payment_method', paymentMethodID);
  formData.append('customer_id', customerId);

  const response = await fetch(
    `${AppConfig.Settings.BASE_API_URL}/portal/guest/payment/stripe/confirm`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: formData,
    },
  );
  return processResponse(response);
};

const updateGuest = async ({
  guestName,
  guestEmail,
  guestPhone,
  emailEnabled = true,
  smsEnabled = true,
  optin = true,
  isPms,
  id,
}: UpdateGuestParams) => {
  const accessToken = (await getAccessToken())?.token;
  const response = await fetch(
    `${AppConfig.Settings.BASE_API_URL}/portal/guest/profile`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        guest_name: guestName,
        guest_email: guestEmail,
        guest_mobile: guestPhone,
        guest_email_enabled: emailEnabled,
        guest_sms_enabled: smsEnabled,
        guest_optin: optin,
        is_pms: isPms,
        reservationCode: id,
      }),
    },
  );

  return processResponse(response);
};

const fetchGuest = async (reservationCode: string) => {
  // get token from app state
  const accessToken = (await getAccessToken())?.token;
  const response = await fetch(
    `${AppConfig.Settings.BASE_API_URL}/portal/guest/profile?reservationCode=${reservationCode}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  return processResponse(response);
};

const updateTermsCondition = async (reservationCode: string) => {
  // get token from app state
  const accessToken = (await getAccessToken())?.token;
  const response = await fetch(
    `${AppConfig.Settings.BASE_API_URL}/portal/guest/terms-agreement`,
    {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: new URLSearchParams({ reservationCode }),
    },
  );

  return processResponse(response);
};

const updateRoomStatus = async ({
  propertyId,
  roomStatus,
  companyCode,
  reservationCode,
}: UpdateRoomStatusParams) => {
  const accessToken = (await getAccessToken())?.token;
  const response = await fetch(
    `${AppConfig.Settings.BASE_API_URL}/portal/guest/properties/${propertyId}/room-status`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: new URLSearchParams({
        room_status: roomStatus,
        companyCode,
        reservationCode,
      }),
    },
  );

  return processResponse(response);
};

const unlockDoor = async (companyCode: string, reservationCode: string) => {
  // get token from app state
  const accessToken = (await getAccessToken())?.token;
  const formData = createResInfoFormData(companyCode, reservationCode);

  const response = await fetch(
    `${AppConfig.Settings.BASE_API_URL}/portal/guest/unlock-door`,
    {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: formData,
    },
  );

  return processResponse(response);
};

const getNearMe = async (
  accessToken: string,
  resource: 'shopping' | 'dining' | 'activities',
  reservationCode: string,
) => {
  const response = await fetch(
    `${AppConfig.Settings.BASE_API_URL}/portal/guest/near-me/${resource}?reservationCode=${reservationCode}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  const json = await processResponse(response);
  return parseNearMe(json?.data, resource);
};

export const dispatchVerifyGuest = async (
  verification: IVerification,
  reservation: Reservation,
  company: Company,
) => {
  await verifyGuest(verification, company?.reservation as string | number);
  const updated: Reservation = {
    ...reservation,
    verification_details: {
      ...reservation.verification_details,
      status: 'pending',
    },
  };
  dispatchCompanies([
    {
      ...company,
      reservation: updated,
      accessed: true,
      challengePassed: true,
      loginStepsInProgress: false,
    },
  ]);
};

export const dispatchGetGuest = async (
  company: Company,
  lastname?: string,
  checkInOut?: CheckInOut,
) => {
  const reservationId = company.reservation as string;

  // 1st leg of the call is to auth the guest by last name
  const { accessed, token, expiredAt } = await authorizeGuest(
    company,
    lastname,
    checkInOut,
  );

  // 2nd leg of the call to get actual guest reservations data

  // This call contains accurate metadata as of July 29th 2022
  // TODO: migrate this call to Java core
  const ReservationWithoutVerificationJson = await fetchGuestReservationInfo(
    token,
    company.locale,
    company.id,
    reservationId,
  );

  // TODO:
  // the company call above should be deprecated. this call should not contain any information
  // about the reservation
  // as a patch right now. we use the id in company level call.

  const companyObj = {} as ICompanyJson;
  Object.assign(companyObj, ReservationWithoutVerificationJson);

  try {
    const verificationIncludedReservationJson = await fetchGetReservation(
      token,
      reservationId,
    );
    companyObj.data.reservation.verification_details =
      verificationIncludedReservationJson.data;
  } catch (error) {
    logger.error(error);
  }

  // TODO: fix the response as it should not look like company response.
  // seems like the response here is just a more complete company data response, we can just use same parser.
  const updated: Company = parseCompany({
    companyId: company.id,
    accessed,
    accessToken: token, // refreshed accessToken
    reservationId,
    data: companyObj?.data,
  });

  setLocalStorageForKey(reservationId, 'expiry', expiredAt);
  setLocalStorageForKey(reservationId, 'token', token);

  // so it doesn't cause re-render if values are same
  if (!isEqual(updated, company)) {
    await dispatchCompanies([updated]);
  }
};

export const dispatchUpdateGuest = async (
  reservation: Reservation,
  isPms = false,
) => {
  const json = await updateGuest({ ...reservation, isPms: isPms });

  const updated: Reservation = {
    ...reservation,
    guestName: json?.data?.guest_name,
    guestEmail: json?.data?.guest_email,
    guestPhone: json?.data?.guest_phone,
    guestLocale: json?.data?.guest_locale,
  };

  // so it doesn't cause re-render if values are same
  if (!isEqual(updated, reservation)) {
    await dispatchReservations([updated]);
  }
};

export const dispatchFetchGuest = async (reservation: Reservation) => {
  const json = await fetchGuest(reservation.id);
  const updated: Reservation = {
    ...reservation,
    guestName: json?.data?.guest_name,
    guestEmail: json?.data?.guest_email,
    guestPhone: json?.data?.guest_phone,
    guestLocale: json?.data?.guest_locale,
    smsEnabled: json?.data?.guest_sms_enabled,
    emailEnabled: json?.data?.guest_email_enabled,
  };

  // so it doesn't cause re-render if values are same
  if (!isEqual(updated, reservation)) {
    await dispatchReservations([updated]);
  }
};

export const dispatchUpdateTerms = async (reservationCode: string) => {
  return await updateTermsCondition(reservationCode);
};

export const dispatchUpdateRoomStatus = async (
  reservation: Reservation,
  status: 'arrival' | 'departure',
  companyCode: string,
) => {
  const roomStatus =
    status === 'arrival' ? 'guest-checking-in' : 'guest-checking-out';
  await updateRoomStatus({
    propertyId: reservation.property.id,
    roomStatus,
    companyCode,
    reservationCode: reservation.id,
  });

  const updated: Reservation = {
    ...reservation,
    property: {
      ...reservation.property,
      roomStatus,
    },
  };

  // so it doesn't cause re-render if values are same
  if (!isEqual(updated, reservation)) {
    await dispatchReservations([updated]);
  }
};

export const dispatchUnlockDoor = async (
  companyCode: string,
  reservationCode: string,
) => {
  await unlockDoor(companyCode, reservationCode);
};

export const dispatchLocale = async (locale: string, company?: Company) => {
  // get token from app state
  const accessToken = (await getAccessToken())?.token;
  const reservationCode = (company?.reservation as string) || '';
  const resource = company
    ? `companies/${company.id}/reservations/${company.reservation}`
    : 'guest';
  const response = await fetch(
    `${AppConfig.Settings.BASE_API_URL}/portal/${resource}/locale`,
    {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: new URLSearchParams({ locale, reservationCode }),
    },
  );

  return processResponse(response);
};

export const dispatchGetReservations = async ({
  reservationId,
  companyId,
  locale,
}: DispatchGetReservationsParams) => {
  const accessToken = (await getAccessToken())?.token;
  const ReservationWithoutVerificationJson = await fetchGuestReservationInfo(
    accessToken,
    locale,
    companyId,
    reservationId,
  );

  const companyObj = {} as ICompanyJson;
  Object.assign(companyObj, ReservationWithoutVerificationJson);

  try {
    const verificationIncludedReservationJson = await fetchGetReservation(
      accessToken,
      reservationId,
    );
    companyObj.data.reservation.verification_details =
      verificationIncludedReservationJson.data;
  } catch (error) {
    logger.error(error);
  }

  const updated: Company = parseCompany({
    accessToken,
    companyId,
    reservationId,
    data: companyObj?.data,
  });

  await dispatchCompanies([updated]);
};

export const dispatchGetNearMe = async (company: Company) => {
  const nearme = [
    ...(await getNearMe(
      company.accessToken,
      'activities',
      company.reservation as string,
    )),
    ...(await getNearMe(
      company.accessToken,
      'dining',
      company.reservation as string,
    )),
    ...(await getNearMe(
      company.accessToken,
      'shopping',
      company.reservation as string,
    )),
  ];

  const updated = { ...company, nearme };

  // so it doesn't cause re-render if values are same
  if (!isEqual(updated, company)) {
    await dispatchCompanies([updated]);
  }

  return nearme;
};

// to grab the payment intent for security deposit
export const dispatchGetSDIntent = async (
  companyCode: string,
  reservationCode: string,
) => await postGetSDIintent(companyCode, reservationCode);

export const dispatchPostStripeConfirm = async (
  paymentMethodID: string,
  customerId: string,
  companyCode: string,
  reservationCode: string,
) =>
  await postStripeConfirm(
    paymentMethodID,
    customerId,
    companyCode,
    reservationCode,
  );
