import AppConfig from '@config';
import { OpertoLogo } from '@svgs';
import { StyleSheet, View } from 'react-native';
import { Caption, Text } from 'react-native-paper';

export const PoweredByView = () => {
  const gitBranch = process.env?.REACT_APP_GIT_BRANCH;
  return (
    <View style={styles.container}>
      <View style={styles.poweredBy}>
        <Text style={styles.text}>POWERED BY</Text>
        <OpertoLogo
          fill={'black'}
          width={AppConfig.Styles.getAdjustedSize(110)}
          height={AppConfig.Styles.getAdjustedSize(40)}
        />
      </View>
      {gitBranch !== 'main' && (
        <View style={styles.devContainer}>
          <Caption>{gitBranch}</Caption>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: AppConfig.Styles.getAdjustedSize(80),
    marginTop: AppConfig.Styles.getAdjustedSize(20),
  },
  devContainer: {
    position: 'absolute',
    left: AppConfig.Styles.getAdjustedSize(30),
    top: AppConfig.Styles.getAdjustedSize(15),
    opacity: 0.4,
  },
  poweredBy: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  text: {
    fontSize: 11,
    fontWeight: '400',
    letterSpacing: 2,
    color: 'black',
  },
});

export default PoweredByView;
