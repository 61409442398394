import AppConfig from '@config';
import { StyleSheet, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Icon, Text } from 'react-native-paper';

interface GuestProfileProps {
  children?: React.ReactNode;
  title?: string;
  subtitle?: string;
  onHeaderAction?: () => void;
}

const UpdateGuestProfile = ({
  children,
  title,
  subtitle,
  onHeaderAction,
}: GuestProfileProps) => {
  return (
    <View style={[styles.container, styles.center]}>
      <View style={styles.header}>
        <TouchableOpacity
          testID="previous-btn"
          style={styles.icon}
          onPress={onHeaderAction}>
          <Icon
            source="arrow-left"
            size={24}
            color={AppConfig.Colors.TERTIARY}
          />
        </TouchableOpacity>
        {title && <Text style={styles.title}>{title}</Text>}
      </View>
      <View style={styles.innerContainer}>
        {subtitle && <Text style={styles.subtitle}>{subtitle}</Text>}
        {children}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: AppConfig.Colors.ON_SECONDARY,
    padding: 15,
  },
  icon: { padding: 4, marginRight: 12 },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    width: '100%',
    paddingBottom: 24,
    flexDirection: 'row',
  },
  innerContainer: {
    flex: 1,
    maxWidth: '400px',
  },
  title: {
    padding: 2,
    fontSize: 24,
  },
  subtitle: {
    fontSize: 16,
    letterSpacing: 0.5,
    fontWeight: '400',
  },
});

export default UpdateGuestProfile;
