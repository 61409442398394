import {
  BannerStatus,
  getAccessCode,
  getReservationPropertyName,
  reservationBannerStatus,
} from '@/libs';
import { logger } from '@/libs/logger';
import { SnackBar } from '@components';
import AppConfig from '@config';
import useLocalization from '@localizations';
import { useNavigation } from '@react-navigation/native';
import {
  Company,
  Reservation,
  dispatchUnlockDoor,
  useAppContext,
  useAppStore,
} from '@store';
import { YourKeyIconBlack } from '@svgs';
import { Actionsheet } from 'native-base';
import { lighten } from 'polished';
import React, { useCallback, useEffect, useState } from 'react';
import { isChrome, isIOS } from 'react-device-detect';
import { Linking, StyleSheet, View } from 'react-native';
import { Button, FAB, Text, useTheme } from 'react-native-paper';
import { SwipeLockButton } from './SwipeLockButton';

export const mobileKeyDisabled = (
  company: Company | undefined,
  reservation: Reservation | undefined,
) => {
  return (
    reservationBannerStatus(company, reservation) !== BannerStatus.NONE &&
    reservation?.mobile_key_button_enabled
  );
};

export const accessCodeDisabled = (
  company: Company | undefined,
  reservation: Reservation | undefined,
) => {
  return !company?.accessCodeControlEnabled && !reservation?.smw;
};

const renderIcon = (
  company: Company | undefined,
  reservation: Reservation | undefined,
) => {
  return (
    <YourKeyIconBlack
      width={AppConfig.Styles.getAdjustedSize(40)}
      opacity={mobileKeyDisabled(company, reservation) ? 0.3 : 1}
    />
  );
};

interface IAccessCodeActionSheetProps {
  onDismiss?: () => void;
  isOpen?: boolean;
}

export const AccessCodeActionSheet: React.FC<IAccessCodeActionSheetProps> = ({
  onDismiss,
  isOpen,
}) => {
  const paperTheme = useTheme();
  const { t } = useLocalization();
  const navigation = useNavigation();
  const [loadingLock, setLoadingLock] = useState(false);
  const { company, reservation, theme } = useAppStore();
  const accessCode = getAccessCode(
    company,
    reservation,
    reservationBannerStatus(company, reservation),
  );
  const { locale } = useAppContext();

  /**
   * Swipe To Unlock - does API call then redirect to a popup modal page for results
   */
  const onSwipeToUnlock = useCallback(async () => {
    setLoadingLock(true);
    let success = true;
    try {
      if (company) {
        await dispatchUnlockDoor(company.id, company.reservation as string);
      }
    } catch (error) {
      logger.error(error);
      success = false;
    }

    navigation.navigate('Confirmation', {
      contentTitle: t(
        success ? 'unlock_success_title' : 'unlock_unsuccessful_title',
      ),
      contentDescription: t(
        success ? 'unlock_success_subtitle' : 'unlock_unsuccessful_subtitle',
      ),
    });

    setLoadingLock(false);
    onDismiss?.();
  }, [navigation, onDismiss, t, company]);

  const lighterPrimary = lighten(0.1, paperTheme.colors.primary);
  const lastName = reservation?.guestName.split(/[, ]+/).pop() || '';
  const [snackbarTitle, setSnackbarTitle] = useState('');
  const [visibleSnack, setVisibleSnack] = useState(false);
  const [colorSnack, setColorSnack] = useState('green');
  const dataObj = `~MKOPERTOID~/${company?.id}_${reservation?.id}/${lastName}/${locale}/${paperTheme.colors.primary}/${theme?.dashboardLogoUrl}`;
  const urlObj = `${company?.id}_${reservation?.id}/${lastName}/${locale}`;
  const [isMobileChrome, setIsMobileChrome] = useState('&efr=1');

  const copyToClipboard = (
    text: string,
    title: string,
    callback: (isDone: boolean | null) => void,
  ) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setSnackbarTitle(`${title} has been copied successfully.`);
        setColorSnack('green');
        setVisibleSnack(true);
        callback(true);
      })
      .catch(err => {
        logger.error(err);
        setSnackbarTitle(`${title} has NOT copied successfully.`);
        setColorSnack('red');
        setVisibleSnack(true);
        callback(false);
      });
  };

  const renderAccessCode = () => {
    if (!company?.accessCodeControlEnabled) {
      return null;
    }

    return (
      <>
        <Text
          style={[
            AppConfig.Styles.subtitle1,
            {
              color: 'white',
              marginBottom: AppConfig.Styles.getAdjustedSize(16),
              marginTop: AppConfig.Styles.getAdjustedSize(16),
            },
          ]}>
          {t('unit_access_code_title')}
        </Text>
        <Text
          style={[
            AppConfig.Styles.headline5,
            {
              color: 'white',
              marginBottom: AppConfig.Styles.getAdjustedSize(24),
            },
          ]}>
          {accessCode}
        </Text>
      </>
    );
  };

  useEffect(() => {
    if (isIOS && isChrome) {
      setIsMobileChrome('');
    }
  }, []);

  return (
    <>
      <Actionsheet isOpen={isOpen} onClose={onDismiss} hideDragIndicator>
        <Actionsheet.Content
          style={{ backgroundColor: paperTheme.colors.primary }}>
          <View
            style={[
              styles.sheetContent,
              { backgroundColor: paperTheme.colors.primary },
            ]}>
            <Text
              style={[
                AppConfig.Styles.subtitle1,
                {
                  color: 'white',
                  marginBottom: AppConfig.Styles.getAdjustedSize(12),
                  marginTop: AppConfig.Styles.getAdjustedSize(25),
                },
              ]}>
              {t('unit_name')}
            </Text>
            <Text
              style={[
                AppConfig.Styles.headline5,
                {
                  color: 'white',
                  marginBottom: AppConfig.Styles.getAdjustedSize(16),
                },
              ]}>
              {getReservationPropertyName(reservation)}
            </Text>

            {renderAccessCode()}

            <Text
              style={[
                AppConfig.Styles.subtitle2,
                {
                  color: 'white',
                  marginBottom: AppConfig.Styles.getAdjustedSize(36),
                  textAlign: 'center',
                },
              ]}>
              {company?.lockControlEnabled &&
              !accessCodeDisabled(company, reservation)
                ? t('unit_access_code_subtitle_yourkey')
                : mobileKeyDisabled(company, reservation) &&
                  accessCodeDisabled(company, reservation)
                ? t('unit_access_code_subtitle_yourkey_disabled_wo_access_code')
                : !mobileKeyDisabled(company, reservation) &&
                  accessCodeDisabled(company, reservation)
                ? t('unit_access_code_subtitle_yourkey_wo_access_code')
                : mobileKeyDisabled(company, reservation)
                ? t('unit_access_code_subtitle_yourkey_disabled')
                : t('unit_access_code_subtitle_wo_yourkey')}
            </Text>
            {company?.lockControlEnabled && (
              <SwipeLockButton
                testID="access-code-sheet-swipe-lock-button"
                onEndReached={onSwipeToUnlock}
                loading={loadingLock}
                disabled={
                  reservation?.incident.paymentEnabled &&
                  !reservation?.incident.paymentConfirmed
                }
              />
            )}
            {reservation?.mobile_key_button_enabled && (
              <>
                <Button
                  disabled={mobileKeyDisabled(company, reservation)}
                  style={[styles.downloadBtn]}
                  icon={() => renderIcon(company, reservation)}
                  onPress={() => {
                    copyToClipboard(
                      dataObj,
                      'Login Credential',
                      (isDone: boolean | null) => {
                        if (isDone) {
                          Linking.openURL(
                            `https://yourkey.page.link/?link=http://www.operto.com/${urlObj}&apn=com.operto.mk&isi=1571807311&ibi=com.operto.mobile-key${isMobileChrome}`,
                          );
                        }
                      },
                    );
                  }}>
                  <Text
                    style={{
                      opacity: mobileKeyDisabled(company, reservation)
                        ? 0.3
                        : 1,
                      color: 'black',
                      fontSize: 16,
                      textTransform: 'none',
                      fontWeight: '600',
                      marginLeft:
                        (AppConfig.Styles.getWindowWidth() - 121) / 4 -
                        AppConfig.Styles.getAdjustedSize(40),
                    }}>
                    {t('launch_yourkey')}
                  </Text>
                </Button>
              </>
            )}
            <FAB
              testID="action-sheet-close"
              icon="close"
              onPress={onDismiss}
              color="white"
              style={{
                backgroundColor: lighterPrimary,
                height: '70px',
                width: '70px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'center',
                borderRadius: 35,
                marginBottom: AppConfig.Styles.getAdjustedSize(20),
              }}
            />
          </View>
          <SnackBar
            title={snackbarTitle}
            visible={visibleSnack}
            color={colorSnack}
            onDismiss={() => {
              setVisibleSnack(false);
            }}
          />
        </Actionsheet.Content>
      </Actionsheet>
    </>
  );
};

const styles = StyleSheet.create({
  sheetContent: {
    width: '100%',
    minWidth: AppConfig.Styles.getAdjustedSize(300),
    maxWidth: AppConfig.Styles.responsiveMaxWidth,
    padding: AppConfig.Styles.getAdjustedSize(15),
    alignItems: 'center',
    alignSelf: 'center',
    paddingLeft: AppConfig.Styles.getAdjustedSize(32),
    paddingRight: AppConfig.Styles.getAdjustedSize(32),
  },
  downloadBtn: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    borderRadius: 40,
    backgroundColor: 'white',
    padding: 15,
    paddingLeft: 1,
    marginBottom: AppConfig.Styles.getAdjustedSize(48),
  },
});
