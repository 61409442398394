import AppConfig from '@config';
import { StyleSheet, View } from 'react-native';
import { useTheme } from 'react-native-paper';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

export const QRCodeIcon = () => {
  const { colors } = useTheme();

  return (
    <View
      style={[
        AppConfig.Styles.center,
        styles.qrCode,
        { backgroundColor: colors.primary },
      ]}>
      <Icon
        name="qrcode-scan"
        size={AppConfig.Styles.getAdjustedSize(18)}
        color="white"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  qrCode: {
    alignSelf: 'center',
    borderRadius: AppConfig.Styles.getAdjustedSize(26),
    width: AppConfig.Styles.getAdjustedSize(52),
    height: AppConfig.Styles.getAdjustedSize(52),
  },
});
