import { OPERTO_TRPC_URL } from '@/assets/configurations/Settings';
import { useAnalytics } from '@/hooks/useAnalytics';
import { canShowHomePage } from '@/libs';
import { logger } from '@/libs/logger';
import { useAuth } from '@/store/auth';
import { useGetGuestUserQuery } from '@/store/services/userApi';
import { TRPCProvider } from '@operto/trpc-client';
import { LinkingOptions, NavigationContainer } from '@react-navigation/native';
import {
  dispatchReset,
  getAccessToken,
  useAppContext,
  useAppStore,
} from '@store';
import { useEffect, useState } from 'react';
import { useTheme } from 'react-native-paper';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Splash } from '../components';
import Home from '../home';
import Login from '../login';
import LanguageSelector from '../login/LoginCognito/LanguageSelector/LanguageSelector';

export const AppContainer = (props: { linking: LinkingOptions }) => {
  useAnalytics();
  const [isLoadingToken, setIsLoadingToken] = useState(true);

  const { company } = useAppStore();
  const {
    authState: {
      tokenType,
      idToken,
      decodedToken: { isAdmin },
    },
  } = useAuth();

  const theme = useTheme();
  const { urlConfig, openSnackbar } = useAppContext();
  const { id: companyReservationCode } = urlConfig;

  const {
    isLoading: isLoadingGuestInfo,
    isUninitialized: isGuestInfoUninitialized,
    data: guest,
  } = useGetGuestUserQuery();

  useEffect(() => {
    const loadToken = async () => {
      setIsLoadingToken(true);
      try {
        await getAccessToken();
      } catch (error) {
        logger.error('Error loading token', error);
      }
      setIsLoadingToken(false);
    };

    loadToken();
  }, []);

  const hasAccessTokenAWS = tokenType === 'AWS' && idToken;
  const isLoadingResources =
    isLoadingToken ||
    isLoadingGuestInfo ||
    (hasAccessTokenAWS && isGuestInfoUninitialized && !!idToken);
  const adminHasGuestInfo = !isLoadingResources && guest;

  if (hasAccessTokenAWS && isAdmin) {
    if (!isLoadingResources && !adminHasGuestInfo) {
      dispatchReset().then(() => {
        if (!companyReservationCode) {
          openSnackbar({
            title:
              'Please use a reservation-specific guest portal URL to access the guest portal',
            color: theme.colors.inverseSurface,
          });
        } else {
          openSnackbar({
            title: 'This reservation has not been claimed yet.',
            color: theme.colors.inverseSurface,
          });
        }
      });
    }
  }

  if (isLoadingResources || (isAdmin && !adminHasGuestInfo)) {
    return <Splash />;
  }

  return (
    <SafeAreaProvider>
      <NavigationContainer {...props}>
        <>
          {
            // NOTE: reference from https://reactnavigation.org/docs/auth-flow
            hasAccessTokenAWS || (canShowHomePage(company) && idToken) ? (
              <TRPCProvider baseAPIURL={OPERTO_TRPC_URL ?? ''} token={idToken}>
                <Home />
              </TRPCProvider>
            ) : (
              <Login />
            )
          }

          <LanguageSelector />
        </>
      </NavigationContainer>
    </SafeAreaProvider>
  );
};
