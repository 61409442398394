import AppConfig from '@config';
import { ReactNode } from 'react';
import { StyleSheet, View, useWindowDimensions } from 'react-native';
import { Card as RNPCard, Text, useTheme } from 'react-native-paper';
import { ImagePlaceHolder } from '../../assets/svgs/ImagePlaceholder';

// NOTE: derived from https://dev.to/saadbashar/dynamic-height-width-and-aspect-ratio-in-react-native-515c
// use 1:1 aspect ratio since the card itself takes care of the padding
const FIGMA_CARD_TO_SCREEN_WIDTH_RATIO = 1;
const CARD_PADDING = AppConfig.Styles.getAdjustedSize(16);
const CARD_MAX_SIZE = AppConfig.Styles.getAdjustedSize(600);

const useCardSize = (aspectRatio: number) => {
  const { width } = useWindowDimensions();
  const cardWidth = width * FIGMA_CARD_TO_SCREEN_WIDTH_RATIO;
  const cardHeight = cardWidth / aspectRatio;

  return {
    width: Math.min(cardWidth, CARD_MAX_SIZE),
    height: Math.min(cardHeight, CARD_MAX_SIZE),
    contentWidth: Math.min(cardWidth - CARD_PADDING * 2, CARD_MAX_SIZE),
    contentHeight: Math.min(cardHeight - CARD_PADDING * 2, CARD_MAX_SIZE),
  };
};

export type CardProps = {
  title: string;
  children?: ReactNode;
  imageUri?: string;
  aspectRatio?: number;
};

export const Card = ({
  title,
  children,
  imageUri,
  aspectRatio = 1,
}: CardProps) => {
  const { colors } = useTheme();
  const { width, contentWidth, height, contentHeight } =
    useCardSize(aspectRatio);

  return (
    <View style={[styles.container, { width, height }]}>
      <RNPCard style={[styles.cardContainer, styles.cardSize]}>
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            width: contentWidth,
            height: contentHeight,
            backgroundColor: colors.onSecondary,
          }}>
          <ImagePlaceHolder width="60%" height="60%" fill="white" />
        </View>

        {imageUri && (
          <RNPCard.Cover
            style={[
              StyleSheet.absoluteFill,
              { width: contentWidth, height: contentHeight },
            ]}
            resizeMode="cover"
            source={{ uri: imageUri }}
          />
        )}

        <View style={[StyleSheet.absoluteFill, styles.contentBackground]}>
          <RNPCard.Content style={[styles.textContainer, styles.cardSize]}>
            <Text
              variant="titleMedium"
              numberOfLines={2}
              style={[styles.text, { color: colors.inverseOnSurface }]}>
              {title}
            </Text>
            {children}
          </RNPCard.Content>
        </View>
      </RNPCard>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    maxWidth: CARD_MAX_SIZE,
    maxHeight: CARD_MAX_SIZE,
    padding: CARD_PADDING,
  },
  cardContainer: {
    borderRadius: AppConfig.Styles.getAdjustedSize(24),
    overflow: 'hidden',
    backgroundColor: '#79747E',
  },
  textContainer: {
    justifyContent: 'flex-end',
    padding: CARD_PADDING,
  },
  cardSize: {
    height: '100%',
    width: '100%',
  },
  text: {
    marginBottom: AppConfig.Styles.getAdjustedSize(4),
  },
  // NOTE: Should use LinearGradient from 'expo-linear-gradient' in a monorepo
  contentBackground: {
    backgroundColor: 'linear-gradient(to bottom, transparent, #00000080)',
    // https://github.com/necolas/react-native-web/issues/298
    backgroundImage: 'linear-gradient(to bottom, transparent, #00000080)',
  },
});
