import AppConfig from '@config';
import useLocalization from '@localizations';
import { useNavigation } from '@react-navigation/native';
import { FunctionComponent, ReactNode } from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, Headline, Subheading } from 'react-native-paper';

interface ICheckOutModeButtonProps {
  onPress: () => void;
}
const CheckOutModeButton: FunctionComponent<ICheckOutModeButtonProps> = ({
  onPress,
}) => {
  const navigation = useNavigation();
  const { t } = useLocalization();

  return (
    <>
      <Button
        style={{ width: '48%', borderColor: 'white' }}
        contentStyle={{
          width: '100%',
          height: AppConfig.Styles.buttonHeight,
        }}
        textColor="white"
        mode="outlined"
        onPress={navigation.goBack}>
        {t('cancel')}
      </Button>
      <View
        style={[AppConfig.Styles.container, { backgroundColor: 'transparent' }]}
      />
      <Button
        style={{ width: '48%' }}
        contentStyle={{
          width: '100%',
          height: AppConfig.Styles.buttonHeight,
        }}
        buttonColor="white"
        textColor="black"
        mode="contained"
        onPress={() => onPress()}>
        {t('check_out')}
      </Button>
    </>
  );
};

interface IConfirmationProps {
  route: {
    params: {
      contentTitle: string;
      contentDescription: string;
      onPress: () => void;
      mode?: string;
      children?: ReactNode;
    };
  };
}
const Confirmation: FunctionComponent<IConfirmationProps> = ({
  route: {
    params: {
      contentTitle,
      children,
      contentDescription,
      onPress,
      mode = 'generic',
    },
  },
}) => {
  const navigation = useNavigation();
  const { t } = useLocalization();

  return (
    <View style={[AppConfig.Styles.container, styles.container]}>
      <Headline style={styles.text}>{contentTitle}</Headline>
      <View style={styles.content}>
        <Subheading style={[styles.text, AppConfig.Styles.body1]}>
          {contentDescription}
        </Subheading>
        {children}
      </View>
      <View style={styles.button}>
        {mode === 'checkout' ? (
          <CheckOutModeButton onPress={onPress} />
        ) : (
          <Button
            style={{ width: '48%' }}
            contentStyle={{
              width: '100%',
              height: AppConfig.Styles.buttonHeight,
            }}
            buttonColor="white"
            textColor="black"
            mode="contained"
            onPress={navigation.goBack}>
            {t('close')}
          </Button>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: AppConfig.Styles.getAdjustedSize(50),
    paddingVertical: AppConfig.Styles.getAdjustedSize(70),
    backgroundColor: '#FF6800',
  },
  content: {
    flex: 5,
    borderTopWidth: StyleSheet.hairlineWidth,
    borderTopColor: 'white',
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: 'white',
    paddingVertical: AppConfig.Styles.getAdjustedSize(40),
    marginVertical: AppConfig.Styles.getAdjustedSize(40),
  },
  text: {
    color: 'white',
  },
  button: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});

export default Confirmation;
