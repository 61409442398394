import { logger } from '@/libs/logger';
import { ContentView } from '@components';
import AppConfig from '@config';
import { DecodeHtmlFormat } from '@libs';
import useLocalization from '@localizations';
import { dispatchReset, useAppStore } from '@store';
import { LoginChallengeLogo } from '@svgs';
import { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { useTheme } from 'react-native-paper';
import { ReservationDetails } from './ReservationDetails';

export const Departure = () => {
  const { t } = useLocalization();
  const theme = useTheme();
  const { host, reservation } = useAppStore();

  useEffect(() => {
    logger.info('capturing departure...', {
      reservationCheckin: reservation?.checkin,
      reservationCheckout: reservation?.checkout,
      roomStatus: reservation?.property?.roomStatus,
      propertyTimeZone: reservation?.property?.timezone,
      isDeparture: true,
    });

    // reset on unmount
    return () => {
      dispatchReset();
    };
  }, [
    reservation?.checkin,
    reservation?.checkout,
    reservation?.property?.roomStatus,
    reservation?.property?.timezone,
  ]);

  return (
    <View style={AppConfig.Styles.container}>
      <View style={[AppConfig.Styles.center, { height: '35%' }]}>
        <LoginChallengeLogo
          style={{ color: theme.colors.primary }}
          width={AppConfig.Styles.getAdjustedSize(230)}
        />
      </View>
      <ContentView
        title={
          host?.goodbyeTitle
            ? DecodeHtmlFormat(host?.goodbyeTitle)
            : t('closing_screen_title')
        }
        description={
          host?.goodbyeMessage
            ? DecodeHtmlFormat(host?.goodbyeMessage)
            : t('closing_screen_subtitle')
        }>
        {reservation?.property && (
          <View style={[AppConfig.Styles.border, styles.reservationContainer]}>
            <ReservationDetails
              name={reservation.property.name}
              address={reservation.property.address}
              checkin={reservation.checkin}
              checkout={reservation.checkout}
            />
          </View>
        )}
      </ContentView>
    </View>
  );
};

export default Departure;

const styles = StyleSheet.create({
  reservationContainer: {
    padding: AppConfig.Styles.getAdjustedSize(10),
  },
});
