import { Reservation, ReservationJSON } from './types';

export const parseReservation = (response: ReservationJSON): Reservation => {
  return {
    id: response.reservation_id,
    propertyImage: response.property_image,
    propertyName: response.property_name,
    propertyCity: response.property_city,
    checkInDate: response.check_in,
    checkOutDate: response.check_out,
    companyCode: response.company_code,
    reservationCode: response.external_id,
    guestId: response.guest_service?.guest_id,
  };
};

const collator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base',
});
export const parseReservations = (response: ReservationJSON[]) => {
  const sorted = response.sort((a, b) =>
    collator.compare(a.reservation_id, b.reservation_id),
  );
  return sorted.map(parseReservation);
};
