import AppConfig from '@config';
import { StyleSheet } from 'react-native';

export const LoginStyles = StyleSheet.create({
  container: {
    width: '100%',
    maxWidth: AppConfig.Styles.responsiveMaxWidth,
    maxHeight: AppConfig.Styles.responsiveMaxHeight,
    justifyContent: 'space-between',
    padding: AppConfig.Styles.getAdjustedSize(24),
  },
  contentContainer: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default LoginStyles;
