import { logger } from '@/libs/logger';
import AppConfig from '@config';
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAccessToken,
  processError,
  processResponse,
} from '../actions/utils';
import { FeatureList, PayloadData } from './models';

export const getFeatureList = createAsyncThunk(
  'getFeatureList',
  async ({ propertyId, locale, companyCode }: PayloadData, thunkAPI) => {
    try {
      const accessToken = (await getAccessToken())?.token;
      const response = await fetch(
        `${AppConfig.Settings.BASE_API_URL}/portal/guest/properties/${propertyId}/sections/features?companyCode=${companyCode}&locale=${locale}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      const result = await processResponse(response);
      const { status, message } = result;

      if (status === 'success') {
        return result;
      }

      return thunkAPI.rejectWithValue(message);
    } catch (error) {
      const message = processError(error);
      const metadata = {
        propertyId,
        locale,
      };
      logger.info('get featureList', metadata, 'state');
      logger.error(message);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const addThunkCases = (
  builder: ActionReducerMapBuilder<FeatureList>,
) => {
  builder.addCase(getFeatureList.fulfilled, (state, action) => {
    state.status = 'success';
    state.featureList = action.payload.data;
  });
  builder.addCase(getFeatureList.pending, state => {
    state.status = 'loading';
    state.message = null;
  });
  builder.addCase(getFeatureList.rejected, (state, action) => {
    state.status = 'failed';
    state.message = (action.payload as string) ?? null;
  });
};
