import useLocalization from '@/assets/localizations';
import { StyleSheet, View } from 'react-native';
import { Text, useTheme } from 'react-native-paper';
import AppConfig from '../../assets/configurations';
import { LuggageIcon } from '../../assets/svgs/LuggageIcon';

export const NoReservation = () => {
  const { t } = useLocalization();
  const { colors } = useTheme();

  return (
    <View style={styles.container}>
      <View style={styles.margin}>
        <LuggageIcon
          width={AppConfig.Styles.getAdjustedSize(80)}
          height={AppConfig.Styles.getAdjustedSize(80)}
          fill={colors.onSurface}
        />
      </View>
      <Text variant="headlineMedium" style={styles.margin}>
        {t('no_upcoming_stays_trips_title')}
      </Text>
      <Text
        variant="bodyLarge"
        style={[
          styles.margin,
          {
            color: colors.onSurface,
            textAlign: 'center',
          },
        ]}>
        {t('trips_description')}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: 'transparent',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: AppConfig.Styles.getAdjustedSize(16),
    height: '80%',
  },
  margin: {
    marginBottom: AppConfig.Styles.getAdjustedSize(16),
  },
});
