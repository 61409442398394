import { trackEvent } from '@/hooks/useAnalytics';
import {
  GuidesSectionsRouteParams,
  SectionItem,
  getNavigationParamsForSection,
  useGuidesSections,
} from '@/store/guides';
import { Layout, RootContainer } from '@components';
import { useNavigation, useRoute } from '@react-navigation/native';
import { FlatList } from 'native-base';
import { useCallback, useLayoutEffect } from 'react';
import { TouchableOpacity, View, useWindowDimensions } from 'react-native';
import CardRouter from '../components/CardRouter';

const CARD_MAX_WIDTH = 568;
const CARD_HEIGHT = 215;

const SectionsList = () => {
  trackEvent({
    screen: 'SectionsList',
    event: 'VIEWED',
    feature: 'guides',
    action: 'reservation_page_guides',
  });
  const navigation = useNavigation();
  const { width } = useWindowDimensions();
  const route = useRoute();
  const { sections } = useGuidesSections(
    route.params as GuidesSectionsRouteParams,
  );

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: true,
    });
  }, [navigation]);

  const renderItem = useCallback(
    ({ item }: { item: SectionItem }) => {
      const cardWidth = Math.min(width - 20, CARD_MAX_WIDTH);
      const element = {
        id: item.section_id || '',
        title: item.section || '',
        imageUri:
          item?.section_cover.length > 0
            ? `${item?.section_cover}?tr=w-384,h-384`
            : `https://ik.imagekit.io/operto/tr:w-${cardWidth},h-${CARD_HEIGHT}/portals/guest-portal-assets/placeholders/guides_placeholder.png`,
      };
      return (
        <TouchableOpacity
          style={{
            height: 205,
            width: cardWidth,
            margin: 10,
          }}
          onPress={() => {
            navigation.navigate('Section', getNavigationParamsForSection(item));
            trackEvent({
              screen: 'SectionsList',
              event: 'CLICKED',
              feature: 'guides',
              action: 'guides_section_clicked',
              details: {
                sectionId: item.section_id,
                title: item.section,
              },
            });
          }}>
          <CardRouter contentItem={element} />
        </TouchableOpacity>
      );
    },
    [navigation, width],
  );

  return (
    <RootContainer>
      <Layout>
        <View style={{ height: '100%' }}>
          <FlatList
            testID="section-list"
            data={sections}
            contentContainerStyle={{
              position: 'relative',
              alignItems: 'center',
            }}
            renderItem={({ item }) => renderItem({ item })}
          />
        </View>
      </Layout>
    </RootContainer>
  );
};

export default SectionsList;
