export function DecodeHtmlFormat(html?: string) {
  if (!html) {
    return '';
  }

  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
}

export function camelize(str: string) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word: string, index: number) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
}

export function toTitleCase(str?: string) {
  return str?.replace(/\w\S*/g, function (txt: string) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
